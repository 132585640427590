// these are the same event definitions as used by the native app
export default {
  Common: {
    Init: "App_Session",
    BeginUpgrade: "Common_BeginUpgrade",
    ShareApp: "Common_ShareApp",
    OpenSharingModal: "Common_OpenSharingModal",
  },
  Onboarding: {
    Start: "Onboarding_Start",
    CompleteStep1Terms: "Onboarding_CompleteStep_Terms",
    CompleteStep2Garden: "Onboarding_CompleteStep_Garden",
    CompleteStep3TopicsOfInterest: "Onboarding_CompleteStep_TopicsOfInterest",
    StartRegister: "Onboarding_StartRegister",
    CompleteRegister: "Onboarding_CompleteRegister",
    CompleteOnboarding: "Onboarding_Complete",
  },
  Discover: {
    Search: "Discover_Search",
    Article: {
      GotoMagazine: "Discover_Article_GotoMagazine",
      GotoShop: "Discover_Article_GotoShop",
      Search: "Discover_Article_Search",
      Open: "Discover_Article_Open",
    },
    Podcast: {
      Search: "Discover_Podcast_Search",
      Open: "Discover_Podcast_Open",
    },
    OpenShop: "Discover_Shop_Open",
    OpenTip: "Discover_Tip_Open",
    OpenReleaseNotes: "Discover_ReleaseNotes_Open",
    Recipe: {
      Open: "Discover_Recipe_Open",
      Search: "Discover_Recipe_Search",
    },
  },
  Library: {
    CreateCrop: "Library_Crop_Create",
    OpenCropDetails: "Library_Crop_OpenDetails",
  },
  Planning: {
    Menu: {
      Open: "Planning_Menu_Open",
      OpenLocation: "Planning_Menu_OpenLocation",
      OpenPatches: "Planning_Menu_OpenPatches",
      OpenMicroclimate: "Planning_Menu_OpenMicroclimate",
      OpenCropsList: "Planning_Menu_OpenCropsList",
      OpenCropRotation: "Planning_Menu_OpenCropRotation",
    },
    Location: {
      SelectAddress: "Planning_Location_SelectAddress",
      UseGPS: "Planning_Location_UseGPS",
      DrawGarden: "Planning_Location_DrawGarden",
      FinishDrawing: "Planning_Location_FinishDrawing",
      DeleteLocation: "Planning_Location_DeleteLocation",
    },
    Patches: {
      AddPatch: "Planning_Patches_AddPatch",
      DeletePatch: "Planning_Patches_DeletePatch",
      DeleteShape: "Planning_Patches_DeleteShape",
      OpenPatchDetails: "Planning_Patches_OpenPatchDetails",
      GoBack: "Planning_Patches_GoBack",
      ChangeName: "Planning_Patches_ChangeName",
      ChangeSizeWithHandles: "Planning_Patches_ChangeSizeWithHandles",
      ChangeSizeWithInput: "Planning_Patches_ChangeSizeWithInput",
      ChangeSizeWithButtons: "Planning_Patches_ChangeSizeWithButtons",
      ChangeGroundType: "Planning_Patches_ChangeGroundType",
      ChangeRoof: "Planning_Patches_ChangeRoof",
      Duplicate: "Planning_Patches_Duplicate",
    },
    Microclimate: {
      DrawSun: "Planning_Microclimate_DrawSun",
      DrawShadow: "Planning_Microclimate_DrawShadow",
      Finish: "Planning_Microclimate_Finish",
      Update: "Planning_Microclimate_Update",
    },
    CropsList: {
      InputCropName: "Planning_CropsList_InputCropName",
      SelectCrop: "Planning_CropsList_SelectCrop",
      Finish: "Planning_CropsList_Finish",
    },
    Overview: {
      SelectPatch: "Planning_Overview_SelectPatch",
    },
    Plan: {
      AddPlant: "Planning_Plan_AddPlant",
      DeletePlant: "Planning_Plan_DeletePlant",
      OpenPlantDetails: "Planning_Plan_OpenPlantDetails",
      ChangePlantSize: "Planning_Plan_ChangePlantSize",
      GoBack: "Planning_Plan_GoBack",
      OpenSeasonModal: "Planning_Plan_OpenSeasonModal",
      ChangeSeason: "Planning_Plan_ChangeSeason",
      SelectVariety: "Planning_Plan_SelectVariety",
    },
    MagicWand: {
      Open: "Planning_MagicWand_Open",
      SelectAutoFill: "Planning_MagicWand_SelectAutoFill",
      SelectAutoFillWithFavorites: "Planning_MagicWand_FillWithFavorites",
      SelectAutoFillWithSurprise: "Planning_MagicWand_FillWithSurprise",
      SelectOptimization: "Planning_MagicWand_SelectOptimization",
      GetNewSuggestion: "Planning_MagicWand_GetNewSuggestion",
      ApproveSuggestion: "Planning_MagicWand_ApproveSuggestion",
      DeclineSuggestion: "Planning_MagicWand_DeclineSuggestion",
    },
    ShowReward: "Planning_ShowReward",
    GotoRegister: "Planning_GotoRegister",
    CompleteRegister: "Planning_CompleteRegister",
  },
  ChangeYear: "ChangeYear",
  Paywall: {
    OpenPlus: "Paywall_OpenPlus",
    OpenPro: "Paywall_OpenPro",
    ShowAllProducts: "Paywall_ShowAllProducts",
    SelectPlus: "Paywall_SelectPlus",
    SelectPro: "Paywall_SelectPro",
    StartPayment: "Paywall_StartPayment",
  },
  Gardening: {
    OpenSeason: "Gardening_OpenSeason",
    OpenTodos: "Gardening_OpenTodos",
    OpenTodoDetails: "Gardening_OpenTodoDetails",
  },
  Community: {
    NewPost: "Community_Post_Create",
    NewComment: "Community_Comment_Create",
    OpenPost: "Community_Post_Open",
    ContactSupport: "Community_ContactSupport",
    LikePost: "Community_Post_Like",
    LikeComment: "Community_Comment_Like",
    DislikePost: "Community_Post_Dislike",
    DislikeComment: "Community_Comment_Dislike",
    Search: "Community_Search",
    SearchWithTag: "Community_SearchWithTag",
    ExpandPostText: "Community_Post_ExpandText",
    SharePost: "Community_Post_Share",
  },
};
