import Routes from "../../Core/Routes";
import { fetchData } from "../Api/Actions";

export const RECEIVE_CONTENT_ELEMENT = "CONTENT_RECEIVE_CONTENT_ELEMENT";

export const receiveContentElement = (data: object) => ({
  type: RECEIVE_CONTENT_ELEMENT,
  data,
});

export const fetchContentById = (contentId: string) => (dispatch: any) => {
  dispatch(
    fetchData(
      Routes.API_ROUTE_CONTENT_ELEMENT.replace("{contentId}", contentId),
      receiveContentElement
    )
  );
};
