import {
  ADD_PATCH_NAMES,
  COMPLETE_TUTORIAL,
  DECREASE_YEAR,
  HIDE_TUTORIAL,
  INCREASE_YEAR,
  INCREMENT_TUTORIAL_STEP,
  INITIAL_PLANNING_COMPLETED,
  RECEIVE_DELETE_GARDEN,
  RECEIVE_GARDEN,
  RECEIVE_GARDENS,
  RECEIVE_SAVE_GARDEN,
  RECEIVE_SAVE_GARDEN_ERROR,
  RECEIVE_SAVE_PLAN,
  RECEIVE_SAVE_PLAN_ERROR,
  REMOVE_PLANT,
  REMOVE_PATCH,
  REMOVE_ALL_PATCHES,
  RESET_SELECTED_PLANT,
  SELECT_CROP,
  SELECT_PATCH_BY_UUID,
  SELECT_PLANT_BY_UUID,
  SELECT_VARIETY_FOR_PLANT,
  SET_DATA,
  SET_DATA_INITIALIZED,
  SET_FAVORITES_DEFINED,
  SET_GARDEN_LOCATION,
  SET_MICROCLIMATE_DEFINED,
  SET_PATCH_TUTORIAL_SHOWN,
  SET_PATCH_VALUE,
  SET_PLAN_INITIALIZED,
  SET_PLANNING_SCREEN_SEEN,
  SET_PLANT_TUTORIAL_SHOWN,
  SET_READY_TUTORIAL_SHOWN,
  SET_SEASON,
  START_SAVE_PLAN,
  START_SAVE_GARDEN,
  START_LOAD_GARDEN,
  UPDATE_PLANT,
  UPDATE_PREV_AND_NEXT_PLANT,
  SET_GARDEN_PATCH_AREA,
  SET_GARDEN_OCCUPANCY,
  SET_USE_SEASONS,
  SET_PLAN_MODE,
  MAGIC_WAND_START_AUTO_FILL,
  MAGIC_WAND_COMPLETE_AUTO_FILL,
  MAGIC_WAND_START_OPTIMIZATION,
  MAGIC_WAND_COMPLETE_OPTIMIZATION,
  MAGIC_WAND_FINISH,
  MAGIC_WAND_RECEIVE_ERROR,
  MAGIC_WAND_RECEIVE_WARNING,
  SET_GARDEN_SUNLIGHT,
  SET_GARDEN_GROUND_AND_WATER,
  RESET_SAVE_STATUS,
  HIDE_BETA_HINT,
  RECEIVE_GARDEN_ERROR,
  QUEUE_SAVE_PLAN,
} from "./Actions";
import Moment from "moment";
import { extendMoment } from "moment-range";
import {
  filterPlantsForSeason,
  filterPlantsWithoutSeason,
} from "./PlanningUtils";
import { MagicWandOptions } from "../../Screens/Planning/PatchPlan/Consts";

const moment = extendMoment(Moment);

export const initialState = {
  gardens: undefined,
  garden: undefined,
  gardenDeleted: false,
  gardenDeleteError: false,
  gardenReset: false,
  gardenIsLoading: false,
  gardenLoadingError: false,
  patchesDeleted: false,
  planData: undefined,
  planInitialized: false,
  initialPlanningCompleted: false,
  showTutorial: true,
  tutorialStep: 1,
  tutorialCompleted: false,
  year: moment().year(),
  season: "main", // pre, main, post
  seasonChanged: false,
  selectedPatchUuid: undefined,
  selectedPatch: undefined,
  patchChanged: false,
  selectedPlant: undefined,
  selectedCrop: undefined,
  selectedVariety: undefined,
  varietyChanged: false,
  patchesDefined: false,
  microclimateDefined: false,
  favoritesDefined: false,
  patchTutorialShown: false,
  plantTutorialShown: false,
  readyTutorialShown: false,
  planIsSaving: false,
  planSaveIsInQueue: false,
  planSaved: false,
  planSaveTimestamp: undefined,
  planSaveError: false,
  dataInitialized: false,
  useSeasons: false,
  processMagicWand: false,
  magicWandIsActive: false,
  magicWandType: undefined,
  magicWandHasError: false,
  magicWandHasWarning: false,
  magicWarningType: undefined,
  magicAutoFillMode: undefined,
  savedAt: moment(), // TODO: planSaveTimestamp and savedAt are basically the same
  showBetaHint: true,
};

const getPlanDataFiltered = (planData, season, year, useSeasons) => {
  return planData
    ? {
        ...planData,
        patches: useSeasons
          ? filterPlantsForSeason(planData.patches, season, year)
          : filterPlantsWithoutSeason(planData.patches),
      }
    : undefined;
};

const getLocallyIntersectionRatio = (r1, r2) => {
  const w_intersection =
    Math.min(r1.x + r1.width, r2.x + r2.width) - Math.max(r1.x, r2.x);
  const h_intersection =
    Math.min(r1.y + r1.height, r2.y + r2.height) - Math.max(r1.y, r2.y);

  if (w_intersection <= 0 || h_intersection <= 0) {
    return 0;
  }

  const intersection = w_intersection * h_intersection;
  const refBoxArea = Math.min(r1.height * r1.width, r2.width * r2.height);

  return intersection / refBoxArea;
};

const getRangeForPlantInPatch = (plant) => {
  let plantStart = plant.dateInPatch || plant.datePlanted;
  let plantEnd = plant.dateHarvestEnd;

  if (plant.hasPrecultivation) {
    plantStart = plant.dateInPatch;
  }

  return moment.range(plantStart, plantEnd);
};

const getDaysBetweenDates = (dateStart, dateEnd) => {
  return moment.range(dateStart, dateEnd).diff("days");
};

export default function PlanningReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_DATA: {
      const { data } = action;
      const { patches } = data;

      const removePlants = [];
      const mergePlants = (patch) => {
        const existingPatch = state.planData?.patches.find(
          (p) => p.uuid === patch.uuid
        );
        if (!existingPatch) {
          return patch.plants;
        }
        const newPlants = patch.plants;
        const existingPlants = existingPatch.plants;
        const mergedPlants = existingPlants.map(
          (existingPlant) =>
            newPlants.find((p) => p.uuid === existingPlant.uuid) ??
            existingPlant
        );
        newPlants.forEach((newPlant) => {
          if (!mergedPlants.find((mp) => mp.uuid === newPlant.uuid)) {
            mergedPlants.push(newPlant);
            state.planData?.patches.forEach((p) => {
              if (p.uuid === patch.uuid) {
                return;
              }
              p.plants.forEach((pl) => {
                if (pl.uuid === newPlant.uuid) {
                  console.log(
                    "plant exists in different patch -> mark to be deleted: " +
                      pl.uuid
                  );
                  removePlants.push({ patchUuid: p.uuid, plantUuid: pl.uuid });
                }
              });
            });
          }
        });
        return mergedPlants;
      };

      const mergedPlanData = {
        ...data,
        patches: patches.map((patch) => ({
          ...patch,
          plants: mergePlants(patch),
        })),
      };
      // remove plants that have been moved between patches
      mergedPlanData.patches = mergedPlanData.patches.map((p) => ({
        ...p,
        plants: p.plants.filter(
          (pl) =>
            !removePlants.some(
              (rp) => rp.patchUuid === p.uuid && rp.plantUuid === pl.uuid
            )
        ),
      }));

      let updatedSelectedPlant = undefined;
      if (state.selectedPlant) {
        patches.forEach((p) =>
          p.plants.forEach((pl) => {
            if (pl.uuid === state.selectedPlant.uuid) {
              updatedSelectedPlant = pl;
            }
          })
        );
      }

      const processedPlanData = getPlanDataFiltered(
        mergedPlanData,
        state.season,
        state.year,
        state.useSeasons
      );

      // update selectedPatch to reflect width/height changes.
      // otherwise, in case SET_PATCH_VALUE, unpersisted width/height
      // value will be discarded.
      let selectedPatch =
        state.selectedPatch &&
        processedPlanData.patches.find(
          (p) => p.uuid === state.selectedPatch.uuid
        );
      if (selectedPatch) {
        selectedPatch = { ...selectedPatch };
      }

      return {
        ...state,
        planData: processedPlanData,
        patchesDefined: action.data.patches && action.data.patches.length > 0,
        planSaved: false,
        gardenDeleted: false,
        patchesDeleted: false,
        selectedPlant: updatedSelectedPlant,
        selectedPatch,
        patchChanged: false,
      };
    }

    case UPDATE_PREV_AND_NEXT_PLANT: {
      const { patches } = state.planData;

      if (!patches) {
        return { ...state };
      }

      const updatedPatches = patches.map((patch) => {
        const updatedPlants = patch.plants.map((refPlant) => {
          const refPlantRange = getRangeForPlantInPatch(refPlant);
          const closestPlants = {
            previous: {
              plant: undefined,
            },
            next: {
              plant: undefined,
            },
          };
          for (let i = 0; i < patch.plants.length; i++) {
            const checkPlant = patch.plants[i];
            //If its same plant
            if (checkPlant.uuid === refPlant.uuid) {
              continue;
            }
            //Check for locally intersection
            if (getLocallyIntersectionRatio(checkPlant, refPlant) < 0.25) {
              continue;
            }

            const checkPlantRange = getRangeForPlantInPatch(checkPlant);

            //Check for previous Plant
            if (
              moment(checkPlantRange.end).isBefore(moment(refPlantRange.start))
            ) {
              const distance = getDaysBetweenDates(
                checkPlantRange.end,
                refPlantRange.start
              );

              if (!closestPlants.previous.plant) {
                closestPlants.previous = { plant: checkPlant, distance };
                continue;
              }

              if (distance <= closestPlants.previous.distance) {
                closestPlants.previous = { plant: checkPlant, distance };
              }

              continue;
            }

            //Check for next Plant
            if (
              moment(checkPlantRange.start).isAfter(moment(refPlantRange.end))
            ) {
              const distance = getDaysBetweenDates(
                refPlantRange.end,
                checkPlantRange.start
              );

              if (!closestPlants.next.plant) {
                closestPlants.next = { plant: checkPlant, distance };
                continue;
              }

              if (distance <= closestPlants.next.distance) {
                closestPlants.next = { plant: checkPlant, distance };
              }
            }
          }

          /**
           * removes information of previous and next plant to avoid loop
           */
          closestPlants.previous.plant = {
            ...closestPlants.previous.plant,
            previous: undefined,
            next: undefined,
          };
          closestPlants.next.plant = {
            ...closestPlants.next.plant,
            previous: undefined,
            next: undefined,
          };

          return {
            ...refPlant,
            previousPlant: closestPlants.previous.plant,
            nextPlant: closestPlants.next.plant,
          };
        });

        return {
          ...patch,
          plants: updatedPlants,
        };
      });

      return {
        ...state,
        planData: { ...state.planData, patches: updatedPatches },
      };
    }

    case REMOVE_PLANT: {
      // remove plant via uuid
      return {
        ...state,
        planData: {
          ...state.planData,
          patches: state.planData.patches.map((patch) => ({
            ...patch,
            plants: patch.plants.filter(
              (plant) => plant.uuid !== action.plant.uuid
            ),
          })),
        },
      };
    }

    case REMOVE_PATCH: {
      return {
        ...state,
        planData: {
          ...state.planData,
          patches: state.planData.patches.filter(
            (p) => p.uuid !== action.patch.uuid
          ),
        },
      };
    }

    case REMOVE_ALL_PATCHES: {
      return {
        ...state,
        planData: {
          ...state.planData,
          patches: [],
        },
        patchesDeleted: true,
      };
    }

    case SET_PLAN_INITIALIZED: {
      return {
        ...state,
        planInitialized: action.initialized,
      };
    }

    case SET_FAVORITES_DEFINED: {
      return {
        ...state,
        favoritesDefined: action.defined,
      };
    }

    case SET_MICROCLIMATE_DEFINED: {
      return {
        ...state,
        microclimateDefined: action.defined,
      };
    }

    case INCREMENT_TUTORIAL_STEP: {
      return {
        ...state,
        tutorialStep:
          action.nextStep < state.tutorialStep
            ? state.tutorialStep
            : action.nextStep,
        showTutorial: true,
      };
    }

    case COMPLETE_TUTORIAL: {
      return {
        ...state,
        tutorialCompleted: true,
        showTutorial: false,
      };
    }

    case HIDE_TUTORIAL: {
      return {
        ...state,
        showTutorial: false,
      };
    }

    case INITIAL_PLANNING_COMPLETED: {
      return {
        ...state,
        initialPlanningCompleted: true,
      };
    }

    case SET_PLANNING_SCREEN_SEEN: {
      return {
        ...state,
        planningScreenAlreadySeen: true,
      };
    }

    case INCREASE_YEAR: {
      const newYear = state.year + 1;
      return {
        ...state,
        year: newYear,
        planData: getPlanDataFiltered(
          state.planData,
          state.season,
          newYear,
          state.useSeasons
        ),
      };
    }

    case DECREASE_YEAR: {
      const newYear = state.year - 1;
      return {
        ...state,
        year: newYear,
        planData: getPlanDataFiltered(
          state.planData,
          state.season,
          newYear,
          state.useSeasons
        ),
      };
    }

    case SET_SEASON: {
      return {
        ...state,
        season: action.season,
        planData: getPlanDataFiltered(
          state.planData,
          action.season,
          state.year,
          state.useSeasons
        ),
      };
    }

    case SET_GARDEN_LOCATION: {
      const { gardenImage, ...gardenData } = action.garden;

      return {
        ...state,
        garden: {
          ...gardenData,
          ...(state.garden && state.garden.id ? { id: state.garden.id } : {}),
        },
        planInitialized: true,
      };
    }

    case SELECT_PATCH_BY_UUID: {
      let selectedPatch = state.planData?.patches.find(
        (p) => p.uuid === action.uuid
      );
      if (selectedPatch) {
        selectedPatch = { ...selectedPatch };
      }
      return {
        ...state,
        selectedPatchUuid: action.uuid,
        selectedPatch,
        patchChanged: false,
      };
    }

    case SET_GARDEN_SUNLIGHT: {
      return {
        ...state,
        garden: {
          ...state.garden,
          lightRaster: action.lightRaster,
          lightLines: action.lightLines,
        },
        planSaved: false,
        gardenSaved: false,
      };
    }

    case SET_GARDEN_GROUND_AND_WATER: {
      return {
        ...state,
        garden: {
          ...state.garden,
          groundType: action.groundType,
          hasWaterAccess: action.hasWaterAccess,
        },
        planSaved: false,
        gardenSaved: false,
      };
    }

    case SET_PATCH_VALUE: {
      if (state.selectedPatch) {
        console.log("update patch " + action.field + " -> " + action.value);
        const updatedPatch = { ...state.selectedPatch };
        updatedPatch[action.field] = action.value;
        return {
          ...state,
          planData: {
            ...state.planData,
            patches: state.planData.patches.map((p) =>
              p.uuid === updatedPatch.uuid ? updatedPatch : p
            ),
          },
          selectedPatch: updatedPatch,
          patchChanged: !action.skipUpdate,
        };
      } else {
        console.warn("no patch selected for update");
      }
      return { ...state };
    }

    case SELECT_PLANT_BY_UUID: {
      let selectedPlant;
      state.planData.patches.forEach((p) => {
        const plant = p.plants.find((pl) => pl.uuid === action.uuid);
        if (plant) {
          selectedPlant = plant;
        }
      });
      if (selectedPlant) {
        console.log("selected plant by uuid");
        return {
          ...state,
          selectedPlantUuid: action.uuid,
          selectedPlant,
        };
      }
      console.warn(
        "cannot select plant by uuid -> plant not found (uuid: " + action.uuid
      );
      return { ...state };
    }

    case RESET_SELECTED_PLANT: {
      return {
        ...state,
        selectedPlantUuid: undefined,
        selectedPlant: undefined,
      };
    }

    case UPDATE_PLANT: {
      const updatedPlant = { ...action.plant, ...action.values };

      return {
        ...state,
        planData: {
          ...state.planData,
          patches: state.planData.patches.map((patch) => ({
            ...patch,
            plants: patch.plants.map((plant) =>
              plant.uuid === updatedPlant.uuid
                ? { ...plant, ...updatedPlant }
                : plant
            ),
          })),
        },
        planSaved: false,
      };
    }

    case SET_PATCH_TUTORIAL_SHOWN: {
      return {
        ...state,
        patchTutorialShown: true,
      };
    }

    case SET_PLANT_TUTORIAL_SHOWN: {
      return {
        ...state,
        plantTutorialShown: true,
      };
    }

    case SET_READY_TUTORIAL_SHOWN: {
      return {
        ...state,
        readyTutorialShown: true,
      };
    }

    case SELECT_CROP: {
      return {
        ...state,
        selectedCrop: action.crop,
        selectedVariety: action.variety,
        varietyChanged: false,
      };
    }

    case ADD_PATCH_NAMES: {
      const patches = state.planData?.patches;
      let i = 1;
      patches.forEach((patch) => {
        if (
          patch.name?.substr(0, 5) === "Beet " &&
          !isNaN(Number(patch.name.substr(5)))
        ) {
          i = Math.max(Number(patch.name.substr(5)) + 1, i);
        }
      });
      return {
        ...state,
        planData: {
          ...state.planData,
          patches: patches.map((patch) => ({
            ...patch,
            ...(!patch.name ? { name: "Beet " + i++ } : undefined),
          })),
        },
      };
    }

    case SELECT_VARIETY_FOR_PLANT: {
      const { variety } = action;
      const varietySize = Math.round(
        (Number(variety.seedingDistance.default) +
          Number(variety.rowDistance.default)) /
          2
      );
      let imageUrl = action.plant.crop.imageUrl;
      if (variety.imageUrl) {
        imageUrl = variety.imageUrl;
      }

      const updatedPlant = {
        ...action.plant,
        ...action.dates,
        crop: {
          ...action.plant.crop,
          imageUrl,
          varietyId: variety.id,
          isDefaultVariety: false,
          width: varietySize,
          height: varietySize,
        },
      };

      let newPlanData = {
        ...state.planData,
        patches: state.planData.patches.map((patch) => ({
          ...patch,
          plants: patch.plants.map((plant) =>
            plant.uuid === updatedPlant.uuid
              ? { ...plant, ...updatedPlant }
              : plant
          ),
        })),
      };
      newPlanData = getPlanDataFiltered(
        newPlanData,
        action.season,
        state.year,
        state.useSeasons
      );

      return {
        ...state,
        planData: newPlanData,
        selectedPlant: { ...updatedPlant },
        selectedVariety: { ...action.variety },
        season: state.useSeasons ? action.season : state.season,
        seasonChanged: state.useSeasons && state.season !== action.season,
        varietyChanged: action.variety.id !== state.selectedVariety?.id,
        planSaved: false,
      };
    }

    case START_SAVE_GARDEN: {
      return {
        ...state,
        gardenSaved: false,
        gardenIsSaving: true,
        gardenSaveError: false,
      };
    }

    case START_LOAD_GARDEN: {
      return {
        ...state,
        gardenIsLoading: true,
        gardenLoadingError: false,
      };
    }

    case RECEIVE_GARDEN_ERROR: {
      return {
        ...state,
        gardenIsLoading: false,
        gardenLoadingError: true,
      };
    }

    case RECEIVE_SAVE_GARDEN: {
      return {
        ...state,
        garden: {
          ...state.garden,
          deleteImage: false,
          id: action.data.id,
        },
        gardenSaved: true,
        gardenIsSaving: false,
        gardenSaveError: false,
      };
    }

    case RECEIVE_SAVE_GARDEN_ERROR: {
      return {
        ...state,
        gardenSaved: false,
        gardenIsSaving: false,
        gardenSaveError: true,
      };
    }

    case START_SAVE_PLAN:
      return {
        ...state,
        planSaveIsInQueue: false,
        planSaved: false,
        planIsSaving: true,
        planSaveError: false,
      };

    case QUEUE_SAVE_PLAN:
      return {
        ...state,
        planSaveIsInQueue: true,
      };

    case RECEIVE_SAVE_PLAN: {
      return {
        ...state,
        planIsSaving: false,
        planSaved: true,
        planSaveTimestamp: moment().unix(),
        savedAt: moment(),
      };
    }

    case RECEIVE_SAVE_PLAN_ERROR: {
      return {
        ...state,
        planIsSaving: false,
        planSaved: false,
        planSaveError: true,
      };
    }

    case RECEIVE_GARDENS: {
      const gardens = action.gardens;
      return {
        ...state,
        gardens,
        gardenIsLoading: false,
      };
    }

    case RECEIVE_GARDEN: {
      const { data } = action;

      const { gardenImage, ...gardenData } = data.gardenData;

      return {
        ...state,
        garden: {
          ...gardenData,
          id: data.id,
          deleteImage: false,
        },
        planData: { ...data.planData },
        planInitialized: true,
        gardenSaved: true,
        gardenReset: false,
        gardenIsLoading: false,
      };
    }

    case SET_DATA_INITIALIZED: {
      return {
        ...state,
        dataInitialized: true,
      };
    }

    case RECEIVE_DELETE_GARDEN: {
      if (action.status === 200) {
        return {
          ...initialState,
          gardenDeleted: true,
        };
      } else {
        return { ...state, gardenDeleteError: true };
      }
    }

    case SET_GARDEN_PATCH_AREA: {
      const { area } = action;
      return {
        ...state,
        garden: { ...state.garden, totalPatchArea: area },
      };
    }

    case SET_GARDEN_OCCUPANCY: {
      const { occupancy } = action;
      return {
        ...state,
        garden: { ...state.garden, occupancy: { ...occupancy } },
      };
    }

    case SET_USE_SEASONS: {
      console.log("use seasons? " + action.useSeasons);
      return { ...state, useSeasons: action.useSeasons };
    }
    case SET_PLAN_MODE: {
      const { mode } = action;
      return {
        ...state,
        planMode: mode,
      };
    }

    case MAGIC_WAND_START_AUTO_FILL: {
      return {
        ...state,
        processMagicWand: true,
        magicWandType: MagicWandOptions.AUTO_FILL,
        magicAutoFillMode: action.mode || state.magicAutoFillMode || undefined,
        magicWandIsActive: true,
        magicWandHasError: false,
        magicWandHasWarning: false,
        magicWarningType: undefined,
      };
    }

    case MAGIC_WAND_COMPLETE_AUTO_FILL: {
      return {
        ...state,
        processMagicWand: false,
      };
    }

    case MAGIC_WAND_START_OPTIMIZATION: {
      return {
        ...state,
        processMagicWand: true,
        magicWandIsActive: true,
        magicWandType: MagicWandOptions.OPTIMIZATION,
        magicWandHasWarning: false,
        magicWandHasError: false,
        magicWarningType: undefined,
      };
    }

    case MAGIC_WAND_COMPLETE_OPTIMIZATION: {
      return { ...state, processMagicWand: false };
    }

    case MAGIC_WAND_FINISH: {
      return {
        ...state,
        processMagicWand: false,
        magicWandIsActive: false,
        magicWandType: undefined,
        magicWandHasError: false,
        magicWandHasWarning: false,
        magicWarningType: undefined,
        magicAutoFillMode: undefined,
      };
    }

    case MAGIC_WAND_RECEIVE_ERROR: {
      const { warningType } = action;
      return {
        ...state,
        processMagicWand: false,
        magicWandIsActive: false,
        magicWandHasError: true,
        magicWarningType: warningType,
      };
    }

    case MAGIC_WAND_RECEIVE_WARNING: {
      const { warningType } = action;
      return {
        ...state,
        magicWandHasWarning: true,
        magicWarningType: warningType,
      };
    }

    case RESET_SAVE_STATUS: {
      return {
        ...state,
        planIsSaving: false,
        planSaved: true,
        gardenSaved: true,
        gardenReset: true,
      };
    }

    case HIDE_BETA_HINT: {
      return {
        ...state,
        showBetaHint: false,
      };
    }

    default:
      return state;
  }
}
