export function getValueFromCropOrVariety(crop, variety, value, forDisplay) {
  if (variety && variety[value]) {
    return getValueFromCrop(variety, value, forDisplay);
  } else if (crop && crop[value]) {
    return getValueFromCrop(crop, value, forDisplay);
  } else {
    return undefined;
  }
}

export function getValueFromCrop(crop, value, forDisplay) {
  if (crop[value]) {
    if (
      forDisplay &&
      crop[value].min !== undefined &&
      crop[value].max !== undefined
    ) {
      return crop[value].min + "-" + crop[value].max;
    } else if (crop[value].default !== undefined) {
      return crop[value].default;
    } else {
      if (Array.isArray(crop[value])) {
        return undefined;
      }
      return crop[value];
    }
  } else {
    return undefined;
  }
}

export function getValueFromPatchfieldGroupOrCrop(
  patchfieldGroup,
  crop,
  value
) {
  if (patchfieldGroup && patchfieldGroup[value]) {
    return patchfieldGroup[value];
  }
  const valueFromCrop = getValueFromCrop(crop, value);
  return valueFromCrop ? valueFromCrop : 1;
}

export const getNeighboursForCrop = (crop, crops) => {
  const good = [];
  const bad = [];
  if (!crop.compatibility) {
    return null;
  }

  Object.keys(crop.compatibility).forEach((identifier) => {
    const fullCrop = crops.find((c) => c.identifier === identifier);
    if (!fullCrop) {
      return;
    }
    if (crop.compatibility[identifier] === 1) {
      good.push(fullCrop);
    } else if (crop.compatibility[identifier] === -1) {
      bad.push(fullCrop);
    }
  });
  return {
    good,
    bad,
  };
};

export const getDiseasesForCrop = (crop, diseases) => {
  if (!crop.cropDisease) {
    return null;
  }

  return diseases.filter((disease) =>
    crop.cropDisease.find((cd) => cd.disease?.id === disease.id)
  );
};

export const getPestsForCrop = (crop, pests) => {
  if (!crop.cropPest) {
    return null;
  }

  return pests.filter((pest) =>
    crop.cropPest.find((cp) => cp.pest?.id === pest.id)
  );
};

export function createParentVarietiesRelationship(crops) {
  if (!crops || crops.length < 1) {
    return crops;
  }

  const parentCrops = crops.filter((c) => !c.parentCrop);
  const varietyCrops = crops.filter(
      (c) =>
          !!c.parentCrop &&
          parentCrops.map((pc) => pc.id).indexOf(c.parentCrop.id) > -1
  );

  const preparedParentCrops = parentCrops.map((c) => ({
    ...c,
    varieties: [],
    seasons: [],
  }));

  varietyCrops.forEach((c) => {
    const parentIndex = preparedParentCrops.findIndex(
        (thisCrop) => thisCrop.id === c.parentCrop.id
    );
    if (parentIndex < 0) {
      return;
    }

    const fittingSeasons = [
      ...(preparedParentCrops[parentIndex].seasons || []),
    ];

    c.seasons?.forEach((s) => {
      if (!c.isApproved && !c.isFavorite && !c.isMine) {
        return;
      }

      if (fittingSeasons.indexOf(s) >= 0) {
        return;
      }

      preparedParentCrops[parentIndex].seasons.push(s);
    });

    preparedParentCrops[parentIndex].varieties.push({
      ...c,
      parentCrop: preparedParentCrops[parentIndex],
    });
  });

  const preparedVarietyCrops = varietyCrops.map((c) => {
    const parentIndex = preparedParentCrops.findIndex(
        (thisCrop) => thisCrop.id === c.parentCrop.id
    );
    if (parentIndex < 0) {
      return;
    }
    return {
      ...c,
      parentCrop: preparedParentCrops[parentIndex],
    };
  });

  return [...preparedParentCrops, ...preparedVarietyCrops];
}

export function removeParentsWithoutVarieties(crops) {
  return crops.filter((c) => {
    return c.parentCrop || (c.varieties && c.varieties.length > 0);
  });
}
