import "./wdyr";

import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import Main from "./Core/Main";
import { CookiesProvider } from "react-cookie";
import "mdn-polyfills/String.prototype.padStart";
import "mdn-polyfills/String.prototype.repeat";
import numeral from "numeral";
import * as Sentry from "@sentry/react";
import "./array";

import BrowserNotSupported from "./Core/BrowserNotSupported";
import packageJson from "../package.json";

import Images from "./Core/Images";

import { detect } from "detect-browser";

// unregister old service workers
try {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    console.log("got serviceworkers: " + registrations.length);
    for (let registration of registrations) {
      registration.unregister();
      console.log("unregister serviceworker");
    }
  });
} catch (e) {
  console.log("cannot read serviceworkers");
}

// dev/stage
// gtm_auth=w13hxW9wvR2dgrdWgqoOIg&gtm_preview=env-13

// live
// gtm_auth=_f1w7JMme_zdc4nvWlWMMw&gtm_preview=env-2

// initialize sentry
if (
  process.env.REACT_APP_STAGE === "prod" ||
  process.env.REACT_APP_STAGE === "test"
) {
  let versionString = packageJson.version;
  Sentry.init({
    dsn: "https://af0cba0c7ad34ad893057afacdedca24@sentry.io/1805721",
    environment: process.env.REACT_APP_STAGE,
    release: versionString,
    beforeSend(event, hint) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception) {
        Sentry.showReportDialog({
          eventId: event.event_id,
          lang: "de",
          title: "Oh, da ist etwas schief gelaufen!",
          subtitle: "Das Fryd Team wurde bereits über den Fehler informiert.",
          subtitle2:
            "Wenn du uns unterstützen möchtest, beschreibe bitte was du genau getan hast, bevor es zum Fehler kam.",
        });
      }
      return event;
    },
  });
}

// load a locale
numeral.register("locale", "de", {
  delimiters: {
    thousands: ".",
    decimal: ",",
  },
  currency: {
    symbol: "€",
  },
});

// switch between locales
numeral.locale("de");

function errorFallback() {
  return (
    <div className={"error-content"}>
      <div>
        <img src={Images.images.sadVegetables} alt="" />
        <h3>So ein Mist, da ging etwas schief!</h3>
      </div>
    </div>
  );
}

const browser = detect();
if (
  browser &&
  (browser.name === "ie" ||
    (browser.name === "safari" && parseInt(browser.version) < 12))
) {
  ReactDOM.render(<BrowserNotSupported />, document.getElementById("root"));
} else {
  ReactDOM.render(
    <Sentry.ErrorBoundary fallback={errorFallback}>
      <CookiesProvider>
        <Main />
      </CookiesProvider>
    </Sentry.ErrorBoundary>,
    document.getElementById("root")
  );
}
