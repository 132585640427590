import styled, { css, keyframes } from "styled-components";
import Box from "../../Layout/Box";

const ripple = keyframes`
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
`;

const StyledButtonEffect = styled(Box)(
  ({ theme }) => css`
    cursor: pointer;
    &:after,
    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
    &:hover:before {
      background-color: ${theme.colors.hover};
      transition: background-color 100ms ease-out;
    }
    &:active:after {
      background-color: ${theme.colors.hover};
      animation: ${ripple} 100ms linear;
    }
  `
);

const ButtonEffect = () => {
  return (
    <Box position="absolute" top="0px" right="0px" bottom="0px" left="0px">
      <StyledButtonEffect position="relative" size="100%" />
    </Box>
  );
};

export default ButtonEffect;
