import { ReactNode } from "react";
import { Box, Center, IconButton } from "../../index";
import CloseSmall from "../../../Resources/SVG/CloseSmall";
import { LayoutProps } from "../../Styled/layout";
import { SpaceProps } from "../../Styled/space";
import { FlexItemProps } from "../../Styled/flexItem";
import { ColorProps } from "../../Styled/color";
import Back from "../../../Resources/SVG/ArrowLeft";
import theme from "../../../Theme";

export type ModalProps = {
  iconSize?: "md" | "sm";
  iconPadding?: keyof typeof theme.spaces;
  position?: "fixed" | "absolute";
  open?: boolean;
  zIndex?: number;
  showCloseButton?: boolean;
  showBackButton?: boolean;
  onClose?: () => void;
  onBack?: () => void;
  children?: ReactNode;
} & LayoutProps &
  SpaceProps &
  FlexItemProps &
  ColorProps;

const Modal = ({
  iconSize = "sm",
  position = "fixed",
  open = true,
  zIndex = 9999,
  showCloseButton = true,
  showBackButton = false,
  onClose,
  onBack,
  children,
  p,
  iconPadding = typeof p === "string" ? p : "16px",
  ...props
}: ModalProps) => {
  if (!open) {
    return null;
  }

  return (
    <Center
      position={position}
      top="0px"
      right="0px"
      bottom="0px"
      left="0px"
      p={{ base: "16px", sm: "20px" }}
      backgroundColor="scrim"
      onClick={() => {
        onClose?.();
      }}
      zIndex={zIndex}
    >
      <Box
        position="relative"
        overflow="hidden"
        borderRadius="md"
        boxShadow="md"
        backgroundColor="beigeLight"
        onClick={(e) => {
          e.stopPropagation();
        }}
        width={"max-content"}
        maxWidth={"100%"}
        maxHeight={"100%"}
        overflowY={"auto"}
        p={p}
        {...props}
      >
        {showCloseButton && (
          <IconButton
            zIndex={1000}
            position="absolute"
            top={iconPadding}
            right={iconPadding}
            size={iconSize}
            icon={<CloseSmall />}
            onClick={() => {
              onClose?.();
            }}
            aria-label={"Close"}
          />
        )}
        {showBackButton && (
          <IconButton
            zIndex={1000}
            position="absolute"
            top={iconPadding}
            left={iconPadding}
            size={iconSize}
            icon={<Back />}
            onClick={onBack}
          />
        )}
        {children}
      </Box>
    </Center>
  );
};

export default Modal;
