import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Theme from "../../../Core/Theme";

import { NavLink, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  navbtn: {
    display: "inline-flex",
    height: 24,
    padding: 16,
    color: Theme.white,
    backgroundColor: "transparent",
    textDecoration: "none",
    "&:hover:not(.active)": {
      backgroundColor: ({ backgroundColor }) => backgroundColor.hover,
      opacity: 0.6,
    },
    "&.active": {
      backgroundColor: ({ backgroundColor }) => backgroundColor.active,
    },
  },
}));

const NavigationButton = ({
  routePath,
  exactForActiveState = true,
  backgroundColor = {
    hover: Theme.aubergineMedium,
    active: Theme.aubergineMedium,
  },
  testId,
  children,
}) => {
  const classes = useStyles({ backgroundColor });
  const location = useLocation();
  const match = exactForActiveState
    ? routePath === location.pathname
    : routePath.indexOf(location.pathname) === 0;
  return (
    <NavLink
      to={routePath}
      state={{ from: location }}
      className={(match ? "active " : "") + classes.navbtn}
      data-testid={testId}
    >
      {children}
    </NavLink>
  );
};
export default NavigationButton;
