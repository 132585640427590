import * as actions from "./Actions";
import _ from "lodash";
import { RESET_TODOS } from "./Actions";
export const initialState = {
  // - Ein-/Aus-/Umpflanzen + Ernten
  // - Aussäen und Anzucht
  // - Pflegehinweise, Schneiden, Geizen, etc.
  // - Schädlinge und Krankheiten
  // - Wetter (Gießen, Witterungsschutz, Frost)
  // - Beschaffung, Bestellung
  todos: [],
  todosLoaded: false,
};

export default function WorkReducer(state = initialState, action = {}) {
  switch (action.type) {
    case actions.RECEIVE_TODO_LIST: {
      if (action.data.todos !== undefined) {
        state = {
          ...state,
          todos: action.data.todos,
          todosLoaded: true,
        };
      }
      return state;
    }
    case actions.RECEIVE_DELETE_TODO:
      let todos = state.todos;
      _.remove(todos, (t) => {
        return t.id === action.id;
      });
      state = {
        ...state,
        todos: todos,
      };
      return state;
    case actions.RECEIVE_TODO: {
      if (action.data !== undefined) {
        let todo = action.data[0];
        if (!todo && action.data.success) {
          todo = { ...state.todos.find((t) => t.id === action.data.id) };
        }
        const todos = [...state.todos];
        // if todo already existed, remove it
        if (todo && todo.id) {
          const index = todos.findIndex((t) => t.id === todo.id);
          if (index === -1) {
            todos.push(todo);
          } else {
            todos.splice(index, 1, todo);
          }
        }
        state = {
          ...state,
          todos,
          todosLoaded: true,
        };
      }
      return state;
    }

    case RESET_TODOS: {
      return {
        ...state,
        todos: [],
      };
    }

    default:
      return state;
  }
}
