import Crown from "../../../Resources/SVG/Crown";
import Moderator from "../../../Resources/SVG/Moderator";
import Trusted from "../../../Resources/SVG/Trusted";
import User from "../../../Types/User";
import Box from "../../Layout/Box";
import { MarginProps } from "../../Styled/margin";
import { PositionProps } from "../../Styled/position";
import ButtonEffect from "../ButtonEffect";
import Image from "../Image";

type AvatarProps = {
  user: Pick<
    User,
    | "imageUrl"
    | "isModerator"
    | "isTrustedUser"
    | "isSuperModerator"
    | "isCreator"
  >;
  size?: "lg" | "md" | "sm";
  onClick?: (event?: any) => void;
} & PositionProps &
  MarginProps;

const Avatar = ({ user, size = "md", onClick, ...props }: AvatarProps) => {
  return (
    <Box
      flexShrink={0}
      size={
        (size === "lg" && "48px") ||
        (size === "md" && "40px") ||
        (size === "sm" && "32px") ||
        undefined
      }
      borderRadius="full"
      as={onClick && "button"}
      onClick={onClick}
      {...props}
    >
      <Box position="relative" size="100%">
        <Box
          position="relative"
          overflow="hidden"
          borderRadius="full"
          size="100%"
        >
          {onClick && <ButtonEffect />}
          {user.imageUrl ? (
            <Image size="100%" src={user.imageUrl} />
          ) : (
            <Box size="100%" backgroundColor="greyMedium" />
          )}
        </Box>
        {(user.isSuperModerator ||
          user.isModerator ||
          user.isTrustedUser ||
          user.isCreator) && (
          <Box
            position="absolute"
            right="0px"
            bottom="0px"
            size={
              (size === "lg" && "24px") ||
              (size === "md" && "20px") ||
              (size === "sm" && "16px") ||
              undefined
            }
          >
            {(user.isSuperModerator && <Moderator size="100%" />) ||
              (user.isModerator && <Moderator size="100%" />) ||
              (user.isTrustedUser && <Trusted size="100%" />) ||
              (user.isCreator && <Crown size="100%" />)}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Avatar;
