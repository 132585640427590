import theme from "../../Theme";
import IconProps from "../../Types/IconProps";

export default ({ size = "md", color = "currentColor" }: IconProps) => (
  <svg
    width={
      (size === "xl" && "48px") ||
      (size === "lg" && "40px") ||
      (size === "md" && "32px") ||
      (size === "sm" && "24px") ||
      (size === "xs" && "16px") ||
      undefined
    }
    height={
      (size === "xl" && "48px") ||
      (size === "lg" && "40px") ||
      (size === "md" && "32px") ||
      (size === "sm" && "24px") ||
      (size === "xs" && "16px") ||
      undefined
    }
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 19.7239L22.8619 10.8619C23.1223 10.6016 23.5444 10.6016 23.8047 10.8619C24.0651 11.1223 24.0651 11.5444 23.8047 11.8048L14.4714 21.1381C14.211 21.3984 13.7889 21.3984 13.5286 21.1381L9.52858 17.1381C9.26823 16.8777 9.26823 16.4556 9.52858 16.1953C9.78892 15.9349 10.211 15.9349 10.4714 16.1953L14 19.7239Z"
      fill={theme.colors[color]}
    />
  </svg>
);
