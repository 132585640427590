import { css } from "styled-components";
import theme from "../../Theme";
import {
  createResponisiveCSSProp,
  createResponisiveThemeProp,
  ResponsiveValue,
} from "./Core";

export type PositionProps = {
  position?: ResponsiveValue<
    "static" | "relative" | "absolute" | "fixed" | "sticky"
  >;
  top?: ResponsiveValue<keyof typeof theme.spaces>;
  right?: ResponsiveValue<keyof typeof theme.spaces>;
  bottom?: ResponsiveValue<keyof typeof theme.spaces>;
  left?: ResponsiveValue<keyof typeof theme.spaces>;
  zIndex?: ResponsiveValue<"auto" | number>;
};

export const positionDefaultProps: PositionProps = {
  position: "static",
  top: "auto",
  right: "auto",
  bottom: "auto",
  left: "auto",
  zIndex: "auto",
};

const position = css<PositionProps>(
  ({ position, top, right, bottom, left, zIndex }) => css`
    ${createResponisiveCSSProp({
      prop: position,
      property: "position",
    })}
    ${createResponisiveThemeProp({
      prop: top,
      property: "top",
      scale: "spaces",
    })}
    ${createResponisiveThemeProp({
      prop: right,
      property: "right",
      scale: "spaces",
    })}
    ${createResponisiveThemeProp({
      prop: bottom,
      property: "bottom",
      scale: "spaces",
    })}
    ${createResponisiveThemeProp({
      prop: left,
      property: "left",
      scale: "spaces",
    })}
    ${createResponisiveCSSProp({
      prop: zIndex,
      property: "z-index",
    })}
  `
);

export default position;
