import { borderDefaultProps } from "../border";
import { colorDefaultProps } from "../color";
import { flexDefaultProps } from "../flex";
import { layoutDefaultProps } from "../layout";
import { positionDefaultProps } from "../position";
import { shadowDefaultProps } from "../shadows";
import { spaceDefaultProps } from "../space";
import { typographyDefaultProps } from "../typography";
import { cursorDefaultProps } from "../cursor";

export default {
  ...flexDefaultProps,
  ...positionDefaultProps,
  ...layoutDefaultProps,
  ...spaceDefaultProps,
  ...borderDefaultProps,
  ...colorDefaultProps,
  ...shadowDefaultProps,
  ...typographyDefaultProps,
  ...cursorDefaultProps,
};
