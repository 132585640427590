import React from "react";
import PasswordResetNewPassword from "./PasswordResetNewPassword/container";
import PasswordResetRequest from "./PasswordResetRequest/container";

import { Page as GardeningPage, SeasonalOverview, Work } from "./Gardening";

export default {
  Buy: React.lazy(() => import("./Buy")),
  CropCreator: React.lazy(() => import("./CropCreator")),
  CropDetails: React.lazy(() => import("./Library/CropDetails")),
  DiseaseDetails: React.lazy(() => import("./Discover/Library/DiseaseDetails")),
  Library: React.lazy(() => import("./Library")),
  Login: React.lazy(() => import("./Login")),
  PestDetails: React.lazy(() => import("./Discover/Library/PestDetails")),
  Planning: React.lazy(() => import("./Planning")),
  Community: React.lazy(() => import("./Community")),
  Onboarding: React.lazy(() => import("./Onboarding")),
  Privacy: React.lazy(() => import("./Privacy")),
  UserPage: React.lazy(() => import("./Profile")),
  Profile: React.lazy(() => import("./Profile/CommunityProfile")),
  Account: React.lazy(() => import("./Profile/Account")),
  RegisterConfirm: React.lazy(() => import("./RegisterConfirm")),
  Gardening: {
    Work,
    GardeningPage,
    SeasonalOverview,
  },
  Terms: React.lazy(() => import("./Terms")),
  PasswordResetNewPassword,
  PasswordResetRequest,
  VarietyList: React.lazy(() => import("./Library/VarietyList")),
  VarietyDetails: React.lazy(() => import("./Library/VarietyDetails")),
  FavoritesList: React.lazy(() => import("./Library/FavoritesList")),
  MyVarietiesList: React.lazy(() => import("./Library/MyVarietiesList")),
  CreateCrop: React.lazy(() => import("./Library/CreateCrop")),
  CreateVariety: React.lazy(() => import("./Library/CreateVariety")),
  Moderation: React.lazy(() => import("./Library/Moderation")),
  CropChanges: React.lazy(() => import("./Library/CropChanges")),
  NoMatch: React.lazy(() => import("./Library/NoMatch")),
};
