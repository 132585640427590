import Box, { BoxProps } from "../../Layout/Box";
import { ColorProps } from "../../Styled/color";
import { LayoutProps } from "../../Styled/layout";

type HRProps = {
  color?: ColorProps["$color"];
  size?: LayoutProps["size"];
  rounded?: boolean;
} & BoxProps;

const HR = ({ color, size, rounded, ...props }: HRProps) => {
  return (
    <Box
      height={size}
      borderRadius={rounded ? "full" : "none"}
      backgroundColor={color}
      width={"100%"}
      {...props}
    />
  );
};

HR.defaultProps = {
  color: "currentColor",
  size: "1px",
  rounded: false,
};

export default HR;
