import { css } from "styled-components";
import theme from "../../Theme";
import {
  createResponisiveCSSProp,
  createResponisiveThemeProp,
  ResponsiveValue,
} from "./Core";

export type FlexItemProps = {
  flexGrow?: ResponsiveValue<number>;
  flexShrink?: ResponsiveValue<number>;
  flexBasis?: ResponsiveValue<keyof Omit<typeof theme.sizes, "none">>;
  order?: ResponsiveValue<number>;
};

export const flexItemDefaultProps: FlexItemProps = {
  flexGrow: 0,
  flexShrink: 1,
  flexBasis: "auto",
  order: 0,
};

const flexItem = css<FlexItemProps>(
  ({ flexGrow, flexShrink, flexBasis, order }) => css`
    ${createResponisiveCSSProp({
      prop: flexGrow,
      property: "flex-grow",
    })}
    ${createResponisiveCSSProp({
      prop: flexShrink,
      property: "flex-shrink",
    })}
    ${createResponisiveThemeProp({
      prop: flexBasis,
      property: "flex-basis",
      scale: "sizes",
    })}
    ${createResponisiveCSSProp({
      prop: order,
      property: "order",
    })}
  `
);

export default flexItem;
