import Box from "../../Layout/Box";
import { ColorProps } from "../../Styled/color";
import { LayoutProps } from "../../Styled/layout";
import { MarginProps } from "../../Styled/margin";

type DotProps = {
  size?: LayoutProps["size"];
  color?: ColorProps["$color"];
} & MarginProps;

const Dot = ({ size = "4px", color = "currentColor", ...props }: DotProps) => {
  return (
    <Box size={size} borderRadius="full" backgroundColor={color} {...props} />
  );
};

export default Dot;
