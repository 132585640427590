import theme from "../../Theme";
import IconProps from "../../Types/IconProps";

export default ({ size = "md", color = "currentColor" }: IconProps) => (
  <svg
    width={size === "sm" ? "24px" : "32px"}
    height={size === "sm" ? "24px" : "32px"}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.8047 26.8619L22.6659 21.7231C24.326 19.8426 25.3333 17.3723 25.3333 14.6667C25.3333 8.77563 20.5577 4 14.6667 4C8.77563 4 4 8.77563 4 14.6667C4 20.5577 8.77563 25.3333 14.6667 25.3333C17.3723 25.3333 19.8426 24.326 21.7231 22.6659L26.8619 27.8047C27.1223 28.0651 27.5444 28.0651 27.8047 27.8047C28.0651 27.5444 28.0651 27.1223 27.8047 26.8619ZM24 14.6667C24 19.8213 19.8213 24 14.6667 24C9.51201 24 5.33333 19.8213 5.33333 14.6667C5.33333 9.51201 9.51201 5.33333 14.6667 5.33333C19.8213 5.33333 24 9.51201 24 14.6667Z"
      fill={theme.colors[color]}
    />
  </svg>
);
