export const TopicOfInterest = {
  OWN_CULTIVATION: "ownCultivation",
  SEED_PROOF: "seedProof",
  OLD_VARIETIES: "oldVarieties",
  ECOLOGICAL_GARDENING: "ecologicalGardening",
  RAISED_BEDS: "raisedBeds",
  PERMA_CULTURE: "permaCulture",
  MIXED_CULTURE: "mixedCulture",
  PRESERVE_HARVEST: "preserveHarvest",
  GARDENING_WITH_KIDS: "gardeningWithKids",
  SOIL_IMPROVEMENT: "soilImprovement",
  WINTER_VEGETABLES: "winterVegetables",
  GREEN_FERTILIZATION: "greenFertilization",
  GREEN_HOUSE: "greenHouse",
  PEST_PREVENTION: "pestPrevention",
  BENEFICIAL_INSECTS: "beneficialInsects",
  BALCONY_GARDENING: "balconyGardening",
  TREES_AND_SHRUBS: "treesAndShrubs",
  HERBS: "herbs",
  FRUITS: "fruits",
  VEGETABLES: "vegetables",
} as const;

export type TopicOfInterestValues =
  typeof TopicOfInterest[keyof typeof TopicOfInterest];

export const SurveyChoice = {
  SEARCH: "search",
  RECOMMENDATION: "recommendation",
  READ: "read",
  ADS: "ads",
  OTHER: "other",
  NONE: "none",
};

export const Step = {
  None: 0,
  Welcome: 1,
  GardenType: 2,
  TopicsOfInterest: 3,
  SignUp: 4,
  NameAndVoucher: 5,
  Survey: 6, // was 5
  AccountCreation: 7, // was: 6
  Welcoming: 8, // was: 7
};
