import { css } from "styled-components";
import theme from "../../Theme";
import { createResponisiveThemeProp, ResponsiveValue } from "./Core";

export type BorderProps = {
  border?: ResponsiveValue<keyof typeof theme.borders>;
  borderTop?: ResponsiveValue<keyof typeof theme.borders>;
  borderRight?: ResponsiveValue<keyof typeof theme.borders>;
  borderBottom?: ResponsiveValue<keyof typeof theme.borders>;
  borderLeft?: ResponsiveValue<keyof typeof theme.borders>;
  borderRadius?: ResponsiveValue<keyof typeof theme.radii>;
  borderRadiusTop?: ResponsiveValue<keyof typeof theme.radii>;
  borderRadiusRight?: ResponsiveValue<keyof typeof theme.radii>;
  borderRadiusBottom?: ResponsiveValue<keyof typeof theme.radii>;
  borderRadiusLeft?: ResponsiveValue<keyof typeof theme.radii>;
  borderRadiusTopLeft?: ResponsiveValue<keyof typeof theme.radii>;
  borderRadiusTopRight?: ResponsiveValue<keyof typeof theme.radii>;
  borderRadiusBottomLeft?: ResponsiveValue<keyof typeof theme.radii>;
  borderRadiusBottomRight?: ResponsiveValue<keyof typeof theme.radii>;
  borderColor?: ResponsiveValue<keyof typeof theme.colors>;
  outline?: ResponsiveValue<keyof typeof theme.borders>;
  outlineColor?: ResponsiveValue<keyof typeof theme.colors>;
};

export const borderDefaultProps: BorderProps = {
  border: "none",
  borderTop: "none",
  borderRight: "none",
  borderBottom: "none",
  borderLeft: "none",
  borderRadius: "none",
  borderColor: "currentColor",
  outline: "none",
  outlineColor: "currentColor",
};

const border = css<BorderProps>(
  ({
    border,
    borderTop,
    borderBottom,
    borderLeft,
    borderRight,
    borderRadius,
    borderRadiusTop,
    borderRadiusRight,
    borderRadiusBottom,
    borderRadiusLeft,
    borderRadiusTopLeft,
    borderRadiusTopRight,
    borderRadiusBottomLeft,
    borderRadiusBottomRight,
    borderColor,
    outline,
    outlineColor,
  }) => css`
    ${createResponisiveThemeProp({
      prop: border,
      property: ["border-top", "border-bottom", "border-left", "border-right"],
      scale: "borders",
    })}
    ${createResponisiveThemeProp({
      prop: borderTop,
      property: "border-top",
      scale: "borders",
    })}
    ${createResponisiveThemeProp({
      prop: borderBottom,
      property: "border-bottom",
      scale: "borders",
    })}
    ${createResponisiveThemeProp({
      prop: borderLeft,
      property: "border-left",
      scale: "borders",
    })}
    ${createResponisiveThemeProp({
      prop: borderRight,
      property: "border-right",
      scale: "borders",
    })}
    ${createResponisiveThemeProp({
      prop: borderRadius,
      property: "border-radius",
      scale: "radii",
    })}
    ${createResponisiveThemeProp({
      prop: borderRadiusTop,
      property: ["border-top-left-radius", "border-top-right-radius"],
      scale: "radii",
    })}
    ${createResponisiveThemeProp({
      prop: borderRadiusRight,
      property: ["border-top-right-radius", "border-bottom-right-radius"],
      scale: "radii",
    })}
    ${createResponisiveThemeProp({
      prop: borderRadiusBottom,
      property: ["border-bottom-left-radius", "border-bottom-right-radius"],
      scale: "radii",
    })}
    ${createResponisiveThemeProp({
      prop: borderRadiusLeft,
      property: ["border-top-left-radius", "border-bottom-left-radius"],
      scale: "radii",
    })}
    ${createResponisiveThemeProp({
      prop: borderRadiusTopLeft,
      property: "border-top-left-radius",
      scale: "radii",
    })}
    ${createResponisiveThemeProp({
      prop: borderRadiusTopRight,
      property: "border-top-right-radius",
      scale: "radii",
    })}
    ${createResponisiveThemeProp({
      prop: borderRadiusBottomLeft,
      property: "border-bottom-left-radius",
      scale: "radii",
    })}
    ${createResponisiveThemeProp({
      prop: borderRadiusBottomRight,
      property: "border-bottom-right-radius",
      scale: "radii",
    })}
    ${createResponisiveThemeProp({
      prop: borderColor,
      property: "border-color",
      scale: "colors",
    })}
    ${createResponisiveThemeProp({
      prop: outline,
      property: ["outline"],
      scale: "borders",
    })}
    ${createResponisiveThemeProp({
      prop: outlineColor,
      property: "outline-color",
      scale: "colors",
    })}
  `
);

export default border;
