/*
 * Shamelessly copied/adapted from https://github.com/c99rahul/SVG-Pi
 */

import "./ProgressBar.scss";
import theme from "../../../Theme";

type Color = keyof typeof theme.colors;

type IndicatorCap = "butt" | "round" | "square";

interface CircularProgressProps {
  size?: number;
  progress?: number;
  trackWidth?: number;
  trackColor?: Color;
  indicatorWidth?: number;
  indicatorColor?: Color;
  indicatorCap?: IndicatorCap;
  label?: string;
  labelColor?: Color;
  spinnerMode?: boolean;
  spinnerSpeed?: number;
}

const CircularProgress = ({
  size = 150,
  progress = 0,
  trackWidth = 10,
  trackColor = "greenAppInactive",
  indicatorWidth = 10,
  indicatorColor = "greenApp",
  indicatorCap = "round",
  label = "Lädt...",
  labelColor = "greyDark",
  spinnerMode = false,
  spinnerSpeed = 1,
}: CircularProgressProps) => {
  const center = size / 2,
    radius =
      center - (trackWidth > indicatorWidth ? trackWidth : indicatorWidth),
    dashArray = 2 * Math.PI * radius,
    dashOffset = dashArray * ((100 - progress) / 100);

  let hideLabel = !!(size < 100 || !label.length || spinnerMode);

  return (
    <>
      <div className="svg-pi-wrapper" style={{ width: size, height: size }}>
        <svg className="svg-pi" style={{ width: size, height: size }}>
          <circle
            className="svg-pi-track"
            cx={center}
            cy={center}
            fill="transparent"
            r={radius}
            stroke={theme.colors[trackColor]}
            strokeWidth={trackWidth}
          />
          <circle
            className={`svg-pi-indicator ${
              spinnerMode ? "svg-pi-indicator--spinner" : ""
            }`}
            style={{ animationDuration: "" + spinnerSpeed * 1000 }}
            cx={center}
            cy={center}
            fill="transparent"
            r={radius}
            stroke={theme.colors[indicatorColor]}
            strokeWidth={indicatorWidth}
            strokeDasharray={dashArray}
            strokeDashoffset={dashOffset}
            strokeLinecap={indicatorCap}
          />
        </svg>

        {!hideLabel && (
          <div className="svg-pi-label" style={{ color: labelColor }}>
            <span className="svg-pi-label__loading">{label}</span>

            {!spinnerMode && (
              <span className="svg-pi-label__progress">
                {`${progress > 100 ? 100 : progress}%`}
              </span>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default CircularProgress;
