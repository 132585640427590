import { useEffect, useState } from "react";
import Box from "../../Layout/Box";
import Row from "../../Layout/Row";
import { MarginProps } from "../../Styled/margin";
import { PositionProps } from "../../Styled/position";
import Dot from "../Dot";

type IndexIndicatorProps = {
  index?: number;
  maxIndex: number;
  onChangeIndex?: (index: number) => void;
} & PositionProps &
  MarginProps;

const IndexIndicator = ({
  index: controlledIndex,
  maxIndex,
  onChangeIndex,
  ...props
}: IndexIndicatorProps) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    onChangeIndex?.(index);
  }, [index]);

  useEffect(() => {
    setIndex(controlledIndex ?? 0);
  }, [controlledIndex]);

  return (
    <Row gap="8px" {...props}>
      {[...Array(maxIndex)].map((e, i) => (
        <Box
          key={i}
          as="button"
          onClick={() => {
            if (controlledIndex == null) return setIndex(i);
            if (controlledIndex !== i) return onChangeIndex?.(i);
          }}
        >
          <Dot size="8px" color={i === index ? "greenApp" : "beigeDark"} />
        </Box>
      ))}
    </Row>
  );
};

export default IndexIndicator;
