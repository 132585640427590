import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { fetchContentById } from "../../../Reducers/Content/Actions";
import { useDispatch, useSelector } from "react-redux";

const ArticleRedirect = () => {
  const params = useParams();
  const { articleId } = params;
  const { contentElement } = useSelector((state: any) => state.Content);
  const dispatch = useDispatch();

  useEffect(() => {
    if (articleId !== undefined && articleId !== "undefined") {
      dispatch(fetchContentById(articleId));
    }
  }, [articleId]);

  useEffect(() => {
    if (contentElement?.webUrl !== undefined) {
      location.href = contentElement.webUrl;
    }
  }, [contentElement]);

  return null;
};

export default ArticleRedirect;
