import { css, CSSObject } from "styled-components";
import type * as CSS from "csstype";
import theme from "../../../Theme";

export type ResponsiveValue<T extends string | number | symbol | undefined> =
  | Partial<{
      [key in keyof typeof theme.breakpoints]: T;
    }>
  | T;

export type TrueResponsiveValue<
  T extends string | number | symbol | undefined
> = Partial<{
  [key in keyof typeof theme.breakpoints]: T;
}>;

export const isResponsiveValue = <
  T extends string | number | symbol | undefined
>(
  prop: ResponsiveValue<T>
): prop is TrueResponsiveValue<T> => {
  return typeof prop === "object";
};

type ResponsiveCSSProp<P extends keyof CSS.PropertiesHyphen> = {
  prop?: ResponsiveValue<CSS.PropertiesHyphen[P]>;
  property: P | P[];
};

type ResponsiveThemeProp<S extends keyof typeof theme> = {
  prop?: ResponsiveValue<keyof typeof theme[S]>;
  property: keyof CSS.PropertiesHyphen | (keyof CSS.PropertiesHyphen)[];
  scale: S;
};

export const createResponisiveCSSProp = <T extends keyof CSS.PropertiesHyphen>({
  prop,
  property,
}: ResponsiveCSSProp<T>) => {
  if (prop == null) return;

  return css(({ theme }) => {
    if (isResponsiveValue(prop)) {
      return (
        Object.keys(prop) as Array<keyof typeof theme.breakpoints>
      ).reduce((obj: CSSObject, breakpoint) => {
        return {
          ...obj,
          [`@media (min-width: ${theme.breakpoints[breakpoint]})`]: {
            ...cssProp(property, prop[breakpoint]),
          },
        };
      }, {});
    } else {
      return cssProp(property, prop);
    }
  });
};

export const createResponisiveThemeProp = <T extends keyof typeof theme>({
  prop,
  property,
  scale,
}: ResponsiveThemeProp<T>) => {
  if (prop == null) return;

  return css(({ theme }) => {
    if (theme[scale] === undefined) {
      throw Error(
        `'theme["${scale}"]' is undefined. Are you testing without the styled-components ThemeProvider?`
      );
    }
    if (isResponsiveValue(prop)) {
      return (
        Object.keys(prop) as Array<keyof typeof theme.breakpoints>
      ).reduce((obj: CSSObject, breakpoint) => {
        const key = prop[breakpoint];
        if (!key) {
          console.warn(
            `breakpoint(${breakpoint}) resulted in an undefined key, should not have happened`
          );
          return obj;
        }
        const value = theme[scale][key];
        if (typeof value != "string" && typeof value != "number") {
          console.warn(
            `theme.${scale}.${String(
              key
            )} resulted in a value that wasn't a string or a number, should not have happened`
          );
          return obj;
        }
        return {
          ...obj,
          [`@media (min-width: ${theme.breakpoints[breakpoint]})`]: {
            ...cssProp(property, value),
          },
        };
      }, {});
    } else {
      const value = theme[scale][prop];
      if (typeof value != "string" && typeof value != "number") {
        console.warn(
          `theme.${scale}.${String(
            prop
          )} resulted in a value that wasn't a string or a number, should not have happened`
        );
        return;
      }
      return cssProp(property, value);
    }
  });
};

const cssProp = <T extends keyof CSS.PropertiesHyphen>(
  property: T | T[],
  value?: CSS.PropertiesHyphen[T]
): CSSObject =>
  Array.isArray(property)
    ? property.reduce(
        (obj: CSSObject, property) => ({ ...obj, [property]: value }),
        {}
      )
    : {
        [convertHyphen(property)]: value,
      };

const convertHyphen = (str: string) =>
  str.replace(/-([a-z])/g, (m, s) => s.toUpperCase());
