import { ReactNode, useEffect, useState } from "react";
import { useWindowSize } from "../../../Core/CustomHooks";
import theme from "../../../Theme";

type HiddenProps = {
  children?: ReactNode;
  up?: keyof typeof theme.breakpoints;
  down?: keyof typeof theme.breakpoints;
  query?: (width: number) => boolean;
};

const Hidden = ({ children, up, down, query }: HiddenProps) => {
  const [width] = useWindowSize();

  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    if (up && width >= parseInt(theme.breakpoints[up], 10)) {
      setHidden(true);
      return;
    }
    if (down && width < parseInt(theme.breakpoints[down], 10)) {
      setHidden(true);
      return;
    }
    if (query?.(width)) {
      setHidden(true);
      return;
    }
    setHidden(false);
  }, [width, up, down, query]);

  return <>{!hidden && children}</>;
};

export default Hidden;
