import * as actions from "./Actions";
import _ from "lodash";
import {
  RECEIVE_CROP,
  RECEIVE_CROP_ACTIONS,
  RECEIVE_CROP_LOCK,
  RECEIVE_CROP_USAGE,
  RECEIVE_PROPERTIES_FOR_CROP,
  RESET_CROP_LOCK,
  SET_CROPS_FETCHING,
  START_FETCHING,
  START_FETCHING_CROP,
} from "./Actions";
import {createParentVarietiesRelationship, removeParentsWithoutVarieties} from "../../Helpers/CropHelper";

export const initialState = {
  crops: [],
  cropMapById: {},
  cropcategories: [],
  selectedCrop: undefined,
  selectedVariety: undefined,
  selectedPest: undefined,
  selectedDisease: undefined,
  plans: [],
  cropSaved: false,
  savedCrop: undefined,
  pests: [],
  isInEditMode: false,
  changeset: [],
  bestRatedCrops: [],
  newCommunityCrops: [],
  cropSort: "Alphabetisch",
  varietySort: "Alphabetisch",
  selectedFilters: {
    crops: {
      Lichtbedarf: [],
      Nährstoffbedarf: [],
      Wasserbedarf: [],
    },
    varieties: {},
  },
  isLoading: false,
  openChanges: null,
  openModerationChanges: [],
  openApprovals: [],
  appliedChanges: [],
  cropEdits: null,
  locallyAcceptedCropEdits: [],
  locallyRejectedCropEdits: [],
  cropsFetching: false,
  cropsFetched: false,
  cropFetching: false,
  cropFetched: false,
  cropFetchingError: false,
  fetchingCropId: undefined,
  plantFamilies: [],
  properties: [],
  propertiesForSelectedCrop: [],
  cropCategories: [],
  cropLockResult: undefined,
  cropUsageAmount: 0,
  cropActions: undefined,
  cropActionsFetching: false,
  cropActionsFetched: false,
  cropActionError: false,
  cropContent: undefined,
  cropContentFetching: false,
  cropContentFetched: false,
  cropContentFetchingError: false,
};


function prepareCrops(crops, isSuperModerator) {
  if (!crops) {
    return crops;
  }
  //add varieties property to crop object of parentCrops
  const cropsWithParentChildRelationship =
    createParentVarietiesRelationship(crops);

  // remove all parentCrops wihout any varieties
  const cropsFiltered = isSuperModerator
    ? cropsWithParentChildRelationship
    : removeParentsWithoutVarieties(cropsWithParentChildRelationship);

  return cropsFiltered;
}

function getFilteredVarieties(varieties = [], crops) {
  const result = [];
  varieties.map((cr) => {
    crops.forEach((crop) => {
      if (crop.varieties) {
        crop.varieties.forEach((variety) => {
          if (variety.id === cr.id) {
            result.push({ ...variety });
          }
        });
      }
    });
    return null;
  });
  return result;
}

const createCropMap = (crops) => {
  let map = {};
  crops.forEach((crop) => {
    crop.varieties &&
      crop.varieties.forEach((variety) => {
        map[variety.id] = variety;
      });
    map[crop.id] = crop;
  });
  return map;
};

const addParentCropsToPlans = (plans, crops) => {
  if (!crops) return plans;

  plans.forEach((pl) => {
    pl.planCrop.forEach((pc) => {
      if (pc.crop && pc.crop.parentCrop && crops) {
        pc.crop.parentCrop = crops.find((c) => c.id === pc.crop.parentCrop.id);
      }
    });
  });
  return plans;
};

const mergeCropWithCrops = (crop, crops, isSuperModerator) => {
  const index = crops.findIndex((oldCrop) => oldCrop.id === crop.id);
  if (index !== -1) {
    //if crop existed, replace
    crops.splice(index, 1, crop);
  } else {
    crops.push(crop);
  }
  // if parent is new, also push to existing crops
  if (crop.parentCrop) {
    const parentIndex = crops.findIndex(
      (oldCrop) => oldCrop.id === crop.parentCrop.id
    );
    if (index !== -1) {
      //if crop existed, replace
      crops.splice(parentIndex, 1, crop.parentCrop);
    } else {
      crops.push(crop.parentCrop);
    }
  }
  crops = prepareCrops(crops, isSuperModerator);
  return crops;
};

export default function CropDataReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_CROPS_FETCHING: {
      return { ...state, cropsFetching: action.status };
    }

    case START_FETCHING:
      return { ...state, cropsFetching: true, cropsFetched: false };

    case START_FETCHING_CROP: {
      return {
        ...state,
        cropFetching: true,
        cropFetched: false,
        fetchingCropId: Number(action.cropId),
      };
    }

    case actions.RECEIVE_UPDATED_CROP: {
      if (action.status === 200) {
        let crop = action.data.crops[0];
        let crops = state.crops;
        // if crop already existed, remove it
        _.remove(crops, { id: crop.id });
        // add new crop to existing crops
        crops.push(crop);
        crops = prepareCrops(crops, action.isSuperModerator);
        let selectedVariety = state.selectedVariety;
        let selectedCrop = state.selectedCrop;
        if (selectedCrop && selectedCrop.id === crop.id) {
          selectedCrop = crops.find((c) => c.id === crop.id);
        }
        if (selectedVariety && selectedVariety.id === crop.id) {
          selectedVariety = crops.find((c) => c.id === crop.id);
        }

        return {
          ...state,
          crops: crops,
          selectedVariety,
          selectedCrop,
        };
      }
      return state;
    }

    case actions.RECEIVE_CREATED_CROP: {
      if (action.status === 200) {
        const crop = action.data.crops[0];
        const crops = mergeCropWithCrops(
          crop,
          state.crops,
          action.isSuperModerator
        );

        return {
          ...state,
          crops,
          selectedVariety: crop,
          selectedCrop: crop.parentCrop,
          cropSaved: true,
          savedCrop: crop,
        };
      }
      return state;
    }

    case actions.RECEIVE_CROPS: {
      // prepare crop data
      let crops = action.data.crops;
      crops = prepareCrops(crops, action.isSuperModerator);

      let plans = state.plans;
      if (state.plans && state.plans.length > 0) {
        plans = addParentCropsToPlans(state.plans, crops);
      }
      let selectedCrop;
      if (state.selectedCrop) {
        // re-select crop
        selectedCrop = crops.find((c) => c.id === state.selectedCrop.id);
      }
      let selectedVariety;
      if (state.selectedVariety) {
        // re-select variety
        selectedVariety = crops.find((c) => c.id === state.selectedVariety.id);
      }

      return {
        ...state,
        crops: crops,
        cropMapById: createCropMap(crops),
        selectedCrop,
        selectedVariety,
        plans,
        cropsFetching: false,
        cropsFetched: true,
      };
    }

    case RECEIVE_CROP: {
      if (action.status !== 200) {
        return {
          ...state,
          cropFetchingError: true,
          cropFetching: false,
          fetchingCropId: undefined,
          selectedCrop: undefined,
        };
      }
      const newCrops =
        action.data.crops.length === 1 //check if reload of childless crop
          ? action.data.crops
          : prepareCrops(action.data.crops, action.isSuperModerator);
      let selectedCrop = state.selectedCrop;
      if (action.select && state.fetchingCropId) {
        selectedCrop = newCrops.find((c) => c.id === state.fetchingCropId);
      }

      return {
        ...state,
        crops: newCrops,
        selectedCrop: selectedCrop,
        cropFetching: false,
        cropFetched: true,
        fetchingCropId: undefined,
      };
    }

    case actions.RECEIVE_PLANS:
      let plans = action.data.plans;
      if (state.crops && state.crops.length > 0) {
        plans = addParentCropsToPlans(action.data.plans, state.crops);
      }
      return {
        ...state,
        plans,
      };

    case actions.CROP_SELECT_CROP:
      return {
        ...state,
        selectedCrop: action.crop,
      };

    case actions.CROP_UNSELECT_CROP_AND_VARIETY:
      return {
        ...state,
        selectedCrop: undefined,
        selectedVariety: undefined,
      };

    case actions.CROP_SELECT_VARIETY:
      return {
        ...state,
        selectedVariety: action.variety,
      };

    case actions.CROP_SELECT_CROP_BY_IDENTIFIER: {
      const selectedCrop = state.crops.find(
        (crop) => crop.identifier === action.identifier
      );
      return {
        ...state,
        selectedCrop,
      };
    }

    case actions.RECEIVE_PESTS: {
      if (action.status === 200) {
        return {
          ...state,
          pests: action.data.pests.map((pest) => ({
            ...pest,
            linkParameter:
              pest.name &&
              encodeURIComponent(
                pest.name.replace(/ /g, "+").replace(/\//g, "")
              ),
          })),
        };
      }
      return state;
    }

    case actions.RECEIVE_BEST_RATED_CROPS: {
      // prepare crop data
      const crops = action.data.crops;
      const bestRatedCrops = getFilteredVarieties(crops, state.crops);
      return {
        ...state,
        bestRatedCrops,
      };
    }

    case actions.RECEIVE_NEW_COMMUNITY_CROPS: {
      // prepare crop data
      const crops = action.data.crops;
      const newCommunityCrops = getFilteredVarieties(crops, state.crops);

      return {
        ...state,
        newCommunityCrops,
      };
    }

    case actions.RECEIVE_DISEASES: {
      if (action.status === 200) {
        return {
          ...state,
          diseases: action.data.diseases.map((disease) => ({
            ...disease,
            linkParameter:
              disease.name &&
              encodeURIComponent(
                disease.name.replace(/ /g, "+").replace(/\//g, "")
              ),
          })),
        };
      }
      return state;
    }

    case actions.RECEIVE_CROP_CATEGORIES: {
      return {
        ...state,
        cropcategories: action.data.cropcategories,
      };
    }

    case actions.RECEIVE_PLANT_FAMILIES: {
      return {
        ...state,
        plantFamilies: action.data.plantfamilies,
      };
    }
    case actions.RECEIVE_PROPERTIES: {
      const { properties } = action.data;
      const propertyGroups = [];
      properties.forEach((property) => {
        if (
          !property.propertygroup ||
          propertyGroups
            .map((propGroup) => propGroup.id)
            .indexOf(property.propertygroup.id) > -1
        ) {
          return;
        }
        propertyGroups.push(property.propertygroup);
      });
      return {
        ...state,
        properties: action.data.properties,
        propertyGroups: propertyGroups,
      };
    }
    case actions.RECEIVE_PROPERTIES_FOR_CROP: {
      const properties = [...state.properties];
      action.data.properties.forEach((cp) => {
        if (!properties.find((p) => p.id === cp.id)) {
          properties.push(cp);
        }
      });
      return {
        ...state,
        properties,
        propertiesForSelectedCrop: action.data.properties,
      };
    }

    case actions.RESET_SAVE_CROP: {
      return {
        ...state,
        cropSaveError: undefined,
        cropSaved: false,
        savedCrop: undefined,
      };
    }

    case actions.RECEIVE_SAVE_CROP: {
      if (action.status === 200) {
        const newPlant = action.data.crops[0];
        const crops = mergeCropWithCrops(
          newPlant,
          state.crops,
          action.isSuperModerator
        );
        let variety = state.selectedVariety;
        let crop = state.selectedCrop;
        if (variety) {
          variety = crops.find((crop) => crop.id === variety.id);
        }
        if (crop) {
          crop = crops.find((crop) => crop.id === crop.id);
        }

        return {
          ...state,
          cropSaved: true,
          savedCrop: newPlant,
          crops,
          selectedVariety: variety,
          selectedCrop: crop,
          changeset: [],
        };
      } else if (action.status === 403) {
        // not authorized -> redirect to login
      } else {
        let errorText = "";
        switch (
          action.data &&
          action.data.error &&
          action.data.error.code // const CROP_IDENTIFIER_EXISTS = 11;
        ) {
          // const CROP_NOT_FOUND = 12;
          // const CANNOT_UPDATE_DEFAULT_CROP = 13;
          // const CROP_DOES_NOT_BELONG_TO_USER = 14;
          case 11:
            errorText = "Sorte existiert bereits";
            break;
          default:
            errorText = "Unbekannter Fehler";
            break;
        }

        return {
          ...state,
          cropSaveError: errorText,
        };
      }

      return state;
    }

    case actions.RECEIVE_DELETE_CROP: {
      // remove deleted crop
      const cropId = Number(action.cropId);
      const crops = state.crops.filter((cr) => cr.id !== cropId);
      // remove from varieties
      crops.forEach((cr) => {
        if (cr.varieties) {
          cr.varieties.forEach((va, i) => {
            if (va.id === cropId) {
              cr.varieties.splice(i, 1);
            }
          });
        }
      });
      const variety = state.crops.find((cr) => cr.id === cropId);
      if (
        variety &&
        state.selectedCrop.id === variety.parentCrop.id &&
        state.selectedCrop.varieties
      ) {
        state.selectedCrop.varieties.forEach((va, i) => {
          if (va.id === cropId) {
            state.selectedCrop.varieties.splice(i, 1);
          }
        });
      }
      return {
        ...state,
        crops: crops,
        selectedVariety: undefined,
      };
    }
    case actions.SET_FAVORITE: {
      const cropId = Number(action.cropId);
      const crops = state.crops.slice();
      const selectedVarietySlice = {};
      const selectedCropSlice = {};
      const crop = crops.find((c) => c.id === cropId);
      if (crop) {
        crop.isFavorite = action.isFavorite;
        if (state.selectedVariety && state.selectedVariety.id === crop.id) {
          selectedVarietySlice.selectedVariety = {
            ...state.selectedVariety,
            isFavorite: action.isFavorite,
          };
        }
        if (state.selectedCrop && state.selectedCrop.id === crop.id) {
          selectedCropSlice.selectedCrop = {
            ...state.selectedCrop,
            isFavorite: action.isFavorite,
          };
        }
      } else {
        // TODO handle crop not found
      }
      return { ...state, ...selectedVarietySlice, ...selectedCropSlice, crops };
    }
    case actions.CHANGE_EDIT_MODE: {
      return {
        ...state,
        isInEditMode: action.isInEditMode,
      };
    }
    case actions.ADD_VARIETY_CHANGE: {
      // todo: validate if correct variety is changed?
      const changeset = [...state.changeset];
      const existingChange = changeset.find(
        (c) => c.field === action.change.field
      );
      if (existingChange) {
        existingChange.value = action.change.value;
      } else {
        changeset.push(action.change);
      }
      return { ...state, changeset };
    }

    case actions.DISCARD_VARIETY_CHANGES: {
      return { ...state, changeset: [] };
    }

    case actions.SET_VARIETY_SORT: {
      return { ...state, varietySort: action.sort };
    }

    case actions.SET_CROP_SORT: {
      return { ...state, cropSort: action.sort };
    }

    case actions.RESET_SORT_AND_FILTERS: {
      return {
        ...state,
        selectedFilters: initialState.selectedFilters,
      };
    }

    case actions.SET_CROP_FILTER: {
      const { filterType, activeFilters } = action;
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          crops: {
            ...state.selectedFilters.crops,
            [filterType]: activeFilters,
          },
        },
      };
    }

    case actions.SET_VARIETY_FILTER: {
      const { filterType, activeFilters } = action;
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          varieties: {
            ...state.selectedFilters.varieties,
            [filterType]: activeFilters,
          },
        },
      };
    }

    case actions.RECEIVE_RATE_CROP: {
      const cropId = Number(action.data.cropId);
      const ratedVariety = { ...state.selectedVariety };
      if (ratedVariety?.id === cropId) {
        ratedVariety.rating = action.data.rating;
        ratedVariety.ratingCount = action.data.ratingCount;
        ratedVariety.ratedByUser = true;
      }
      return { ...state, selectedVariety: ratedVariety };
    }

    case actions.RECEIVE_OPEN_APPROVALS: {
      const approvals = (action.data && action.data.approvals) || [];
      approvals.forEach((a) => {
        if (a.crop && a.crop.parentCrop) {
          const replaceParent = state.crops.find(
            (c) => c.id === a.crop.parentCrop.id
          );
          if (replaceParent) {
            a.crop.parentCrop = replaceParent;
          }
        }
      });

      return {
        ...state,
        openApprovals: approvals,
        isLoading: false,
      };
    }

    case actions.RECEIVE_OPEN_CHANGES: {
      return {
        ...state,
        openChanges:
          action.data && action.data.changesets
            ? action.data.changesets.filter((c) => !c.isModeration)
            : [],
        openModerationChanges:
          action.data && action.data.changesets
            ? action.data.changesets.filter((c) => c.isModeration)
            : [],
        isLoading: false,
      };
    }

    case actions.RECEIVE_APPLIED_CHANGES: {
      return {
        ...state,
        appliedChanges: (action.data && action.data.changesets) || [],
        isLoading: false,
      };
    }

    case actions.RECEIVE_CHANGES_FOR_CROP: {
      const currentCropEdits = action && action.data && action.data.cropedits;

      // It's fine getting the first element of the cropedits array, since we get all the edits of one crop
      const cropId =
        currentCropEdits &&
        currentCropEdits.length > 0 &&
        currentCropEdits[0] &&
        currentCropEdits[0].crop &&
        currentCropEdits[0].crop.id;

      if (!cropId) {
        return state;
      }

      const otherEdits = state.cropEdits
        ? state.cropEdits.filter((edit) => edit.crop.id !== cropId)
        : [];

      return {
        ...state,
        cropFetching: false,
        cropFetched: true,
        fetchingCropId: undefined,
        cropEdits: [...otherEdits, ...currentCropEdits],
      };
    }

    case actions.ACCEPT_EDIT_LOCALLY: {
      const { editId } = action;

      const editToAccept = state.cropEdits.find(
        (edit) => edit && edit.id === editId
      );

      return {
        ...state,
        cropEdits: state.cropEdits.filter((edit) => edit && edit.id !== editId),
        locallyAcceptedCropEdits: [
          ...state.locallyAcceptedCropEdits,
          editToAccept,
        ],
      };
    }

    case actions.RECEIVE_ACCEPT_EDIT_RESPONSE: {
      const { cropId, editId, data } = action;

      if (!data || !data.success) {
        return {
          ...state,
          cropEdits: [
            ...state.cropEdits,
            state.locallyAcceptedCropEdits.find((edit) => edit.id === editId),
          ],
          locallyAcceptedCropEdits: state.locallyAcceptedCropEdits.filter(
            (edit) => edit.id !== editId
          ),
        };
      }

      const remainingOpenChanges = state.cropEdits.filter(
        (change) => change.crop && change.crop.id === cropId
      );
      return {
        ...state,
        locallyAcceptedCropEdits: state.locallyAcceptedCropEdits.filter(
          (edit) => edit.id !== editId
        ),
        ...(remainingOpenChanges.length === 0 && {
          openChanges: [
            ...state.openChanges.filter((change) => change.crop.id !== cropId),
            {
              ...state.openChanges.find((change) => change.crop.id === cropId),
              allChangesApplied: true,
            },
          ],
        }),
      };
    }

    case actions.REJECT_EDIT_LOCALLY: {
      const { editId } = action;

      const editToReject = state.cropEdits.find(
        (edit) => edit && edit.id === editId
      );

      return {
        ...state,
        cropEdits: state.cropEdits.filter((edit) => edit && edit.id !== editId),
        locallyRejectedCropEdits: [
          ...state.locallyRejectedCropEdits,
          editToReject,
        ],
      };
    }

    case actions.RECEIVE_REJECT_EDIT_RESPONSE: {
      const { cropId, editId, data } = action;

      if (!data || !data.success) {
        return {
          ...state,
          cropEdits: [
            ...state.cropEdits,
            state.locallyRejectedCropEdits.find((edit) => edit.id === editId),
          ],
          locallyRejectedCropEdits: state.locallyRejectedCropEdits.filter(
            (edit) => edit.id !== editId
          ),
        };
      }

      const remainingOpenChanges = state.cropEdits.filter(
        (change) => change.crop && change.crop.id === cropId
      );

      return {
        ...state,
        locallyRejectedCropEdits: state.locallyRejectedCropEdits.filter(
          (edit) => edit.id !== editId
        ),
        ...(remainingOpenChanges.length === 0 && {
          openChanges: [
            ...state.openChanges.filter(
              (change) => change && change.crop.id !== cropId
            ),
            {
              ...state.openChanges.find(
                (change) => change && change.crop.id === cropId
              ),
              allChangesApplied: true,
            },
          ],
        }),
      };
    }

    case RECEIVE_CROP_USAGE: {
      return { ...state, cropUsageAmount: action.users };
    }

    case RESET_CROP_LOCK: {
      return { ...state, cropLockResult: false };
    }

    case RECEIVE_CROP_LOCK: {
      return { ...state, cropLockResult: true };
    }

    case actions.RECEIVE_CROP_ACTIONS: {
      return {
        ...state,
        cropActions: action.cropactions.map((ca) => {
          const caCopy = { ...ca };
          caCopy.cropId = ca.crop.id;
          delete caCopy.crop;
          return caCopy;
        }),
        cropActionsFetching: false,
        cropActionsFetched: true,
      };
    }
    case actions.SET_CROP_ACTIONS_FETCHED: {
      const cropActionsSlice = action.cropActionsFetched
        ? {}
        : { cropActions: undefined };
      return {
        ...state,
        ...cropActionsSlice,
        cropActionsFetched: action.cropActionsFetched,
      };
    }
    case actions.START_CROP_ACTIONS_FETCHING: {
      return { ...state, cropActionsFetching: true, cropActionsFetched: false };
    }

    case actions.RECEIVE_CROP_ACTION_UPSERT: {
      return { ...state, cropActionError: !action.success };
    }

    case actions.RECEIVE_CROP_ACTION_DELETE: {
      return { ...state, cropActionError: !action.success };
    }

    case actions.RECEIVE_CROP_CONTENT: {
      if (action.status === 200) {
        return {
          ...state,
          cropContent: action.content,
          cropContentFetched: true,
          cropContentFetching: false,
          cropContentFetchingError: false,
        };
      } else {
        return {
          ...state,
          cropContent: undefined,
          cropContentFetched: false,
          cropContentFetching: false,
          cropContentFetchingError: true,
        };
      }
    }

    case actions.SET_CROP_CONTENT_FETCHING: {
      if (state.cropContentFetching !== action.cropContentFetching) {
        return { ...state, cropContentFetching: action.cropContentFetching };
      } else {
        return state;
      }
    }

    case actions.SET_CROP_CONTENT_FETCHED: {
      if (state.cropContentFetched !== action.cropContentFetched) {
        return { ...state, cropContentFetched: action.cropContentFetched };
      } else {
        return state;
      }
    }

    default:
      return state;
  }
}
