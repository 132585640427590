export default {
  common: {
    loading: "Loading..."
  },
  Home: {
    welcome: "Welcome home!",
    callApi: "call the API"
  },
  Screen1: {
    welcome: "Welcome to Screen 1!"
  }
};
