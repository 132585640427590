import React, { useEffect, useState } from "react";
import Theme from "../../../../../Core/Theme";
import moment from "moment";
import { getDurationInDaysForYear } from "../../../../../Reducers/Planning/PlanningUtils";
import { DATE_FORMAT_FRONTEND } from "../../../../../Core/Constants";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  getDaysInCalendarData,
  getDaysInYear,
  getMonthValues,
} from "../../seasonHelpers";

const DATEMARKER_SIZE_IN_PIXELS = 12;

const useStyles = makeStyles((theme) => ({
  SeasonsBarContainer: {
    display: "flex",
    flexDirection: "row",
    height: 10,
    borderRadius: 5,
    overflow: "hidden",
  },
  SeasonsBarItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const Period = ({ duration, color }) => {
  const classes = useStyles();
  return (
    <div
      className={classes.SeasonsBarItem}
      style={{
        flex: duration,
        backgroundColor: color,
      }}
    />
  );
};

const DateMarker = ({ value, color }) => {
  return (
    <div
      className={"dateMarker"}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: DATEMARKER_SIZE_IN_PIXELS / 2,
        backgroundColor: color,
        width: DATEMARKER_SIZE_IN_PIXELS,
        height: DATEMARKER_SIZE_IN_PIXELS,
      }}
    >
      <span
        style={{
          color: Theme.colors.white,
          fontSize: DATEMARKER_SIZE_IN_PIXELS - 2,
          lineHeight: 1.1,
        }}
      >
        {value}
      </span>
    </div>
  );
};

export const PlantPeriodsBar = ({
  style,
  plantPeriodDurations,
  periodDates,
  withPeriodMarks,
  range,
  continuous,
  year,
}) => {
  const classes = useStyles();

  const [periodMarks, setPeriodMarks] = useState([]);
  const [periodDurations, setPeriodDurations] = useState([]);
  const [calendarData, setCalendarData] = useState([]);

  useEffect(() => {
    if (!periodDates || periodDates.length < 1) {
      return;
    }

    if (plantPeriodDurations) {
      setPeriodDurations(plantPeriodDurations);
      return;
    }

    const periodValues = [];

    for (let i = 0; i < periodDates.length - 1; i++) {
      const start = moment(periodDates[i].value, DATE_FORMAT_FRONTEND);
      const end = moment(periodDates[i + 1].value, DATE_FORMAT_FRONTEND);

      const duration = year
        ? getDurationInDaysForYear(start, end, year)
        : Math.abs(start.diff(end, "days"));

      periodValues.push({
        color: periodDates[i].color,
        duration: duration,
      });
    }

    if (!continuous) {
      setPeriodDurations(periodValues);
      return;
    }

    periodValues.push(
      {
        color: periodValues[periodValues.length - 2].color,
        duration: 365 - periodValues[periodValues.length - 1].duration,
      },
      {
        color: periodValues[periodValues.length - 1].color,
        duration: periodValues[periodValues.length - 1].duration,
      }
    );

    setPeriodDurations(periodValues);
  }, [periodDates]);

  useEffect(() => {
    if (!withPeriodMarks) {
      return;
    }

    if (!periodDates || periodDates.length === 0) {
      return;
    }

    if (!range) {
      return;
    }

    const marks = periodDates
      .map((date, index) => {
        const offset = moment(date.value, DATE_FORMAT_FRONTEND).diff(
          moment(range.start),
          "days"
        );

        return {
          color: date.color,
          value: index + 1,
          offset,
        };
      })
      .filter((mark) => mark !== undefined);

    if (continuous) {
      marks.push(
        {
          ...marks[marks.length - 2],
          offset: marks[marks.length - 2].offset + 365,
        },
        {
          ...marks[marks.length - 1],
          offset: marks[marks.length - 1].offset + 365,
        }
      );
    }

    setPeriodMarks(marks);
  }, [periodDates]);

  useEffect(() => {
    if (!range || !range.start || !range.end) {
      return;
    }

    setCalendarData(getMonthValues(range));
  }, [range]);

  if (!periodDurations || periodDurations.length < 1) {
    return null;
  }
  const daysInCalendarData =
    calendarData.length !== 0
      ? getDaysInCalendarData(calendarData)
      : getDaysInYear(year);

  return (
    <>
      {withPeriodMarks &&
        periodMarks.length > 0 &&
        periodMarks.map((marker, index) => (
          <div
            key={`marker-${index - 1}`}
            style={{
              position: "absolute",
              left: `calc(${(marker.offset / daysInCalendarData) * 100}% - ${
                DATEMARKER_SIZE_IN_PIXELS / 2
              }px)`,
              bottom: 24,
              height: 10,
            }}
          >
            <DateMarker value={marker.value} color={marker.color} />
          </div>
        ))}

      <div
        className={classes.SeasonsBarContainer}
        style={{
          ...style,
          ...((continuous && {
            width: style.width + 365 * 1.5,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }) ||
            {}),
        }}
      >
        {periodDurations &&
          periodDurations.length > 0 &&
          periodDurations.map((period, index) => (
            <Period
              key={"plant-period" + index}
              duration={period.duration}
              color={period.color}
            />
          ))}
      </div>
      {continuous && (
        <div
          className={classes.SeasonsBarContainer}
          style={{
            ...style,
            zIndex: 4,
            backgroundColor: Theme.colors.red,
            width: "100%",
          }}
        >
          <Period duration={200} color={Theme.colors.greenLight} />
        </div>
      )}
    </>
  );
};
