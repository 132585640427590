import { css } from "styled-components";
import flexContainer, {
  flexContainerDefaultProps,
  FlexContainerProps,
} from "./flexContainer";
import flexItem, { flexItemDefaultProps, FlexItemProps } from "./flexItem";

export type FlexProps = FlexContainerProps & FlexItemProps;

export const flexDefaultProps: FlexProps = {
  ...flexContainerDefaultProps,
  ...flexItemDefaultProps,
};

const flex = css<FlexProps>`
  ${flexContainer};
  ${flexItem};
`;

export default flex;
