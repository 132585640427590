import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import _ from "lodash";

import "./Styles.scss";
import TodoListWeek from "../TodoListWeek";
import { TodoGroup } from "../../Screens/Work";

class TodoList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { width: window.innerWidth };
    window.addEventListener("resize", () => {
      this.setState({ width: window.innerWidth });
    });
  }

  render() {
    const {
      todos,
      startWeek,
      endWeek,
      adjacantStartWeek,
      adjacantEndWeek,
      nextAdjacantStartWeek,
      nextAdjacantEndWeek,
    } = this.props;
    const todosSelectedWeek = _.filter(todos, (t) => {
      return (
        moment(moment(t.dateDue.date).format("MM/DD/YYYY")).isSameOrAfter(
          startWeek.format("MM/DD/YYYY")
        ) &&
        moment(moment(t.dateDue.date).format("MM/DD/YYYY")).isSameOrBefore(
          endWeek.format("MM/DD/YYYY")
        )
      );
    });

    const todosSelectedNextWeek = _.filter(todos, (t) => {
      return (
        moment(moment(t.dateDue.date).format("MM/DD/YYYY")).isSameOrAfter(
          adjacantStartWeek.format("MM/DD/YYYY")
        ) &&
        moment(moment(t.dateDue.date).format("MM/DD/YYYY")).isSameOrBefore(
          adjacantEndWeek.format("MM/DD/YYYY")
        )
      );
    });

    const todosSelectedNextSecondWeek = _.filter(todos, (t) => {
      return (
        moment(moment(t.dateDue.date).format("MM/DD/YYYY")).isSameOrAfter(
          nextAdjacantStartWeek.format("MM/DD/YYYY")
        ) &&
        moment(moment(t.dateDue.date).format("MM/DD/YYYY")).isSameOrBefore(
          nextAdjacantEndWeek.format("MM/DD/YYYY")
        )
      );
    });

    let todoGroupsMap = [];
    TodoGroup.forEach((group) => {
      const res = todosSelectedWeek.filter((todo) => {
        if (todo.type && todo.title) {
          return todo.type.toLowerCase() === group.identifier.toLowerCase();
        }
        return false;
      });
      group = {
        ...group,
        todos: res,
      };
      res.length > 0 && todoGroupsMap.push(group);
    });

    let todoGroupsNextMap = [];
    TodoGroup.forEach((group) => {
      const res = todosSelectedNextWeek.filter((todo) => {
        if (todo.type && todo.title) {
          return todo.type.toLowerCase() === group.identifier.toLowerCase();
        }
        return false;
      });
      group = {
        ...group,
        todos: res,
      };
      res.length > 0 && todoGroupsNextMap.push(group);
    });

    let todoGroupsNextSecondMap = [];
    TodoGroup.forEach((group) => {
      const res = todosSelectedNextSecondWeek.filter((todo) => {
        if (todo.type && todo.title) {
          return todo.type.toLowerCase() === group.identifier.toLowerCase();
        }
        return false;
      });
      group = {
        ...group,
        todos: res,
      };
      res.length > 0 && todoGroupsNextSecondMap.push(group);
    });

    const startDateWeek = startWeek.format("DD.MM.YYYY");
    const endDateWeek = endWeek.format("DD.MM.YYYY");
    const startDateNextWeek = adjacantStartWeek.format("DD.MM.YYYY");
    const endDateNextWeek = adjacantEndWeek.format("DD.MM.YYYY");
    const startDateNextSecondWeek = nextAdjacantStartWeek.format("DD.MM.YYYY");
    const endDateNextSecondWeek = nextAdjacantEndWeek.format("DD.MM.YYYY");

    return (
      <div className="todo-list-container">
        <div className="todo-list">
          <TodoListWeek
            onCheckTodo={this.props.onCheckTodo}
            todos={todoGroupsMap}
            label={
              startWeek.week() === moment().week()
                ? "Diese Woche"
                : "KW" + startWeek.week()
            }
            selectedWeek={startWeek.week()}
            startWeekLabel={startDateWeek}
            endWeekLabel={endDateWeek}
            deleteTodo={this.props.deleteTodo}
            addCustomTodo={this.props.addCustomTodo}
            onUpdate={this.props.updateTodoList}
            snoozeTodo={this.props.snoozeTodo}
          />
          <TodoListWeek
            onCheckTodo={this.props.onCheckTodo}
            todos={todoGroupsNextMap}
            label={
              adjacantStartWeek.week() === moment().week()
                ? "Diese Woche"
                : "KW" + adjacantStartWeek.week()
            }
            selectedWeek={adjacantStartWeek.week()}
            startWeekLabel={startDateNextWeek}
            endWeekLabel={endDateNextWeek}
            deleteTodo={this.props.deleteTodo}
            addCustomTodo={this.props.addCustomTodo}
            onUpdate={this.props.updateTodoList}
            snoozeTodo={this.props.snoozeTodo}
          />
          <TodoListWeek
            onCheckTodo={this.props.onCheckTodo}
            todos={todoGroupsNextSecondMap}
            label={
              nextAdjacantStartWeek.week() === moment().week()
                ? "Diese Woche"
                : "KW" + nextAdjacantStartWeek.week()
            }
            selectedWeek={nextAdjacantStartWeek.week()}
            startWeekLabel={startDateNextSecondWeek}
            endWeekLabel={endDateNextSecondWeek}
            deleteTodo={this.props.deleteTodo}
            addCustomTodo={this.props.addCustomTodo}
            onUpdate={this.props.updateTodoList}
            snoozeTodo={this.props.snoozeTodo}
          />
        </div>
      </div>
    );
  }
}

TodoList.propTypes = {
  onCheckTodo: PropTypes.func.isRequired,
  todos: PropTypes.array.isRequired,
};

export default TodoList;
