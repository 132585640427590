import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Theme from "../../Core/Theme";
import { useDispatch, useSelector } from "react-redux";
import { dispatchSavedActions } from "../../Reducers/Api/Actions";
import { useTranslation } from "react-i18next";
import { Dialog } from "@material-ui/core";
import Images from "../../Core/Images";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: Theme.hellbeige,
    padding: "25px 50px 120px 50px",
    borderRadius: 25,
    margin: 0,
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
      height: "60%",
      position: "absolute",
      bottom: 0,
      borderRadius: "25px 25px 0px 0px",
    },
    "& .secondary-navigation": {
      position: "absolute",
      boxShadow: "none",
      borderTop: "1px solid " + Theme.lightBlue,
    },
  },
  smallImage: {
    width: "200px",
    margin: "0px 0px -70px 150px",
  },
  bigImage: {
    width: "300px",
  },
  button: {
    width: "fit-content",
    display: "flex",
    alignItems: "center",
    backgroundColor: "transparent",
    border: "none",
    outline: "none",
    cursor: "pointer",
    color: Theme.mainGreen,
    "&:hover": {
      color: Theme.mainGreenHover,
    },
  },
  buttonLabel: {
    marginLeft: "7px",
  },
  container: {
    textAlign: "center",
    width: "80%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const ConnectionErrorModal = () => {
  const classes = useStyles();
  const { t } = useTranslation("connectionErrorModal");
  const dispatch = useDispatch();
  const isConnected = useSelector((state) => state.Api.isConnected);

  const handleClick = () => {
    dispatch(dispatchSavedActions());
  };

  const handleReload = () => {
    window.location.reload();
  };

  return (
    <Dialog
      PaperProps={{
        classes: { root: classes.modal },
      }}
      open={isConnected !== undefined && isConnected === false}
    >
      <img src={Images.images.clouds} className={classes.smallImage} />
      <img src={Images.images.cloudsSad} className={classes.bigImage} />

      <h2>{t("errorMessage")}</h2>
      <button className={classes.button} onClick={handleClick}>
        <img src={Images.icons.refreshDouble} />
        <p className={classes.buttonLabel}>{t("tryAgain")}</p>
      </button>
      <button className={classes.button} onClick={handleReload}>
        <img src={Images.icons.refreshDouble} />
        <p className={classes.buttonLabel}>{t("reload")}</p>
      </button>
    </Dialog>
  );
};

export default ConnectionErrorModal;
