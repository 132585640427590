import { css } from "styled-components/macro";

import { cloneElement, ReactElement } from "react";
import IconProps from "../../../Types/IconProps";
import Box from "../../Layout/Box";
import Center from "../../Layout/Center";
import Row from "../../Layout/Row";
import { LayoutProps } from "../../Styled/layout";
import { MarginProps } from "../../Styled/margin";
import { PositionProps } from "../../Styled/position";
import Text from "../../Typography/Text";

type TagProps = {
  iconLeft?: ReactElement<IconProps>;
  iconRight?: ReactElement<IconProps>;
  label: string;
  colorVariant?: "beige" | "blue" | "white";
  outline?: boolean;
  size?: "md" | "sm";
  fontWeight?: "normal" | "bold";
  onClick?: () => void;
} & Pick<LayoutProps, "width"> &
  PositionProps &
  MarginProps;

const Tag = ({
  iconLeft,
  iconRight,
  label,
  colorVariant = "beige",
  outline = false,
  size = "md",
  fontWeight = "bold",
  width = "max-content",
  onClick,
  ...props
}: TagProps) => {
  return (
    <Box
      data-testid="tag"
      as={onClick && "button"}
      onClick={onClick}
      height={
        (size === "md" && "40px") || (size === "sm" && "32px") || undefined
      }
      backgroundColor={
        (colorVariant === "beige" && "beigeMidi") ||
        (colorVariant === "blue" && "blueLight") ||
        (colorVariant === "white" && "white") ||
        undefined
      }
      borderColor={
        (colorVariant === "beige" && "beigeDarkest") ||
        (colorVariant === "blue" && "blueDark") ||
        (colorVariant === "white" && "greyMedium") ||
        undefined
      }
      border={outline ? "1pxSolid" : "none"}
      borderRadius="md"
      css={`
        ${css(
          ({ theme }) => css`
            padding-left: ${outline ? "15px" : "16px"};
            padding-right: ${outline ? "15px" : "16px"};

            cursor: ${onClick && "pointer"};
            &:hover {
              background-color: ${onClick &&
              ((colorVariant === "beige" && theme.colors.beigeMedium) ||
                (colorVariant === "blue" && theme.colors.blueMedium) ||
                (colorVariant === "white" && theme.colors.beigeMedium))};
              border-color: ${onClick &&
              ((colorVariant === "beige" && theme.colors.beigeDark) ||
                (colorVariant === "blue" && theme.colors.blueDark) ||
                (colorVariant === "white" && theme.colors.beigeDark))};
            }
          `
        )}
      `}
      flexShrink={0}
      width={width}
      {...props}
    >
      <Row alignItems="center" size="100%" gap="4px">
        {iconLeft && (
          <Center ml="-8px">
            {cloneElement(iconLeft, {
              size: size === "sm" ? "sm" : "md",
              ...iconLeft.props,
            })}
          </Center>
        )}
        <Text fontWeight={fontWeight}>{label}</Text>
        {iconRight && (
          <Center mr="-8px">
            {cloneElement(iconRight, {
              size: size === "sm" ? "sm" : "md",
              ...iconRight.props,
            })}
          </Center>
        )}
      </Row>
    </Box>
  );
};

export default Tag;
