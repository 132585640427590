import { LayoutProps } from "../../Base/Styled/layout";

type BadgeProps = {
  size: string;
};

export default ({ size }: BadgeProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 26C5.8203 26 0 20.1797 0 13C0 5.8203 5.8203 0 13 0C20.1797 0 26 5.8203 26 13C26 20.1797 20.1797 26 13 26Z"
      fill="#7198D3"
    />
    <path
      d="M8.14911 19C7.77702 19 7.49248 18.8949 7.29549 18.6846C7.0985 18.4744 7 18.1812 7 17.805V8.19502C7 7.81881 7.10397 7.52559 7.3119 7.31535C7.53078 7.10512 7.82627 7 8.19836 7C8.52668 7 8.78386 7.06639 8.9699 7.19917C9.16689 7.32089 9.34747 7.53665 9.51163 7.84647L13.3037 14.8506H12.7456L16.5376 7.84647C16.7018 7.53665 16.8769 7.32089 17.0629 7.19917C17.2599 7.06639 17.5171 7 17.8345 7C18.2066 7 18.4911 7.10512 18.6881 7.31535C18.896 7.52559 19 7.81881 19 8.19502V17.805C19 18.1812 18.9015 18.4744 18.7045 18.6846C18.5075 18.8949 18.223 19 17.8509 19C17.4788 19 17.1888 18.8949 16.9808 18.6846C16.7839 18.4744 16.6854 18.1812 16.6854 17.805V11.083H17.0465L13.9932 16.61C13.8618 16.8202 13.7196 16.9806 13.5663 17.0913C13.4241 17.1909 13.2326 17.2407 12.9918 17.2407C12.751 17.2407 12.554 17.1853 12.4008 17.0747C12.2476 16.964 12.1108 16.8091 11.9904 16.61L8.90424 11.0664H9.29822V17.805C9.29822 18.1812 9.19973 18.4744 9.00274 18.6846C8.81669 18.8949 8.53215 19 8.14911 19Z"
      fill="white"
    />
  </svg>
);
