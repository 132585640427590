import { ComponentProps } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";
import { RichText } from ".";
import Routes from "../../../Core/Routes";
import { selectCrop, selectVariety } from "../../../Reducers/CropData/Actions";

const Container =
  (Component: typeof RichText) =>
  ({ ...props }: Omit<ComponentProps<typeof RichText>, "onTagClick">) => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();
    const { cropMapById } = useSelector((state: any) => state.CropData);

    return (
      <Component
        {...props}
        onTagClick={(tag, event) => {
          switch (tag.type) {
            case "hash_tag":
              if (searchParams.get("p")) {
                searchParams.delete("p");
              }
              setSearchParams(searchParams);

              navigate(
                {
                  pathname: Routes.community.root,
                  search: `?q=${encodeURIComponent(tag.content)}`,
                },
                {
                  replace: true,
                }
              );
              break;
            case "user_tag":
              navigate(
                generatePath(Routes.community.userProfile, {
                  id: String(tag.additional.userHash),
                })
              );
              return;
            case "crop_tag":
              const crop = cropMapById[tag.additional.cropId];
              if (!crop) {
                console.warn(`crop with id ${tag.additional.cropId} not found`);
                break;
              }
              if (crop.parentCrop) {
                dispatch(selectCrop(crop.parentCrop));
                dispatch(selectVariety(crop));
                navigate(
                  generatePath(Routes.library.varietyDetails, {
                    cropParam: `${crop.parentCrop.id}-${crop.parentCrop.identifier}`,
                    varietyParam: `${crop.id}-${crop.identifier}`,
                  })
                );
                break;
              }
              dispatch(selectCrop(crop));
              navigate(
                generatePath(Routes.library.cropDetails, {
                  cropParam: `${crop.id}-${crop.identifier}`,
                })
              );
              break;
          }
        }}
      />
    );
  };

export default Container;
