import { postData } from "../Api/Actions";
import { loadCurrentUser } from "../User/Actions";

export const SET_SHOW_ACCOUNT_UPGRADE = "SET_SHOW_ACCOUNT_UPGRADE";
export const SET_IS_PLUS_ACCOUNT = "SET_IS_PLUS_ACCOUNT";
export const SET_IS_PRO_ACCOUNT = "SET_IS_PRO_ACCOUNT";
export const SET_PLUS_EXPIRED = "SET_PLUS_EXPIRED";
export const SET_PRO_EXPIRED = "SET_PRO_EXPIRED";

export const SET_SHOW_TRIAL_EXPIRATION = "SET_SHOW_TRIAL_EXPIRATION";
export const SET_VOUCHER = "SET_VOUCHER";
export const SET_PAID_EXPIRED = "SET_PAID_EXPIRED";
export const SET_SHOW_VOUCHER_REDEEMER = "SET_SHOW_VOUCHER_REDEEMER";

export const showAccountUpgrade = (options) => (dispatch, getState) => {
  dispatch({
    type: SET_SHOW_ACCOUNT_UPGRADE,
    show: true,
    skipTrial: options && options.skipTrial,
    closeRoute: options && options.close,
    showVoucherRedeemer: options && options.showVoucherRedeemer,
    skipBenefits: options && options.skipBenefits,
  });
};

export const hideAccountUpgrade = () => ({
  type: SET_SHOW_ACCOUNT_UPGRADE,
  show: false,
});

export const setIsPlusAccount = () => ({
  type: SET_IS_PLUS_ACCOUNT,
});

export const setIsProAccount = () => ({
  type: SET_IS_PRO_ACCOUNT,
});

export const setProExpired = () => ({
  type: SET_PRO_EXPIRED,
});

export const setPlusExpired = () => ({
  type: SET_PLUS_EXPIRED,
});

export const setVoucher = (voucher) => ({
  type: SET_VOUCHER,
  voucher,
});

export const showVoucherRedeemer = () => ({
  type: SET_SHOW_VOUCHER_REDEEMER,
  show: true,
});

export const hideVoucherRedeemer = () => ({
  type: SET_SHOW_VOUCHER_REDEEMER,
  show: false,
});

const receiveVoucherRedemption = (data, status) => (dispatch) => {
  if (status === 200) {
    dispatch(loadCurrentUser());
  }
};

export const redeemVoucher = (voucherCode) => (dispatch) => {
  dispatch(
    postData("/voucher/redeem", { code: voucherCode }, receiveVoucherRedemption)
  );
};

export const receiveUpgradeTestingAccount = (data, status) => (dispatch) => {
  if (status === 200) {
    dispatch(loadCurrentUser());
  }
};

export const upgradeTestingAccount = (accountType) => (dispatch) => {
  dispatch(
    postData(
      "/users/account/freeupgrade",
      { accountType },
      receiveUpgradeTestingAccount
    )
  );
};
