import React from "react";
import Theme from "../../../../Core/Theme";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  container: {
    display: ({ visible }) => (visible ? "flex" : "none"),
    flexDirection: "column",
    alignItems: "stretch",
    flexGrow: 1,
    "& > :first-child": {
      borderBottom: `1px solid ${Theme.colors.greenAppInactive}`,
      padding: 16,
      alignItems: "baseline",
      "& > *": {
        fontSize: Theme.sizes.h4,
      },
      "& > :first-child, & > :last-child": {
        fontWeight: 700,
      },
      "& > :first-child": {
        justifySelf: "left",
      },
      "& > :nth-child(2)": {
        justifySelf: "center",
      },
      "& > :last-child": {
        justifySelf: "right",
      },
    },
  },
  menuBar: {
    display: "grid",
    gridTemplateColumns: ({ matches }) =>
      matches ? "auto 2fr 1fr" : "1fr 1fr 1fr",
  },
}));

const Container = ({ visible = true, className, menuButtons, children }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const classes = useStyles({ matches, visible });
  return (
    <div className={classes.container}>
      <div className={classes.menuBar}>{menuButtons}</div>
      <div className={className}>{children}</div>
    </div>
  );
};
export default Container;
