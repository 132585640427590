import {
  LOCK_FAILED,
  LOCK_SUCCESSFUL,
  SET_DEVICE_DETAILS,
  START_LOCKING,
  UNLOCK_SUCCESSFUL,
} from "./Actions";

export const initialState = {
  deviceId: undefined,
  deviceDescription: {},
  isLocking: true,
  hasLock: false,
  isLockedByOtherDevice: false,
  otherDeviceId: undefined,
  otherDeviceDescription: undefined,
};

export default function GardenLockReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_DEVICE_DETAILS: {
      return {
        ...state,
        deviceId: action.deviceId,
        deviceDescription: {
          name: action.deviceName,
          version: action.appVersion,
          type: "web",
        },
      };
    }

    case LOCK_SUCCESSFUL: {
      return {
        ...state,
        isLocking: false,
        hasLock: true,
        isLockedByOtherDevice: false,
        otherDeviceId: undefined,
        otherDeviceDescription: undefined,
      };
    }

    case LOCK_FAILED: {
      return {
        ...state,
        isLocking: false,
        hasLock: false,
        isLockedByOtherDevice: true,
        otherDeviceId: action.deviceId,
        otherDeviceDescription: action.deviceDescription,
      };
    }

    case UNLOCK_SUCCESSFUL: {
      return {
        ...state,
        isLocking: false,
        hasLock: false,
        isLockedByOtherDevice: false,
        otherDeviceId: undefined,
        otherDeviceDescription: undefined,
      };
    }

    case START_LOCKING: {
      return {
        ...state,
        isLocking: true,
      };
    }

    default:
      return state;
  }
}
