import { useState } from "react";
import TextInput, { TextInputProps } from "../TextInput";
import { IconButton } from "../../index";
import Eye from "../../../Resources/SVG/Eye";
import EyeSlash from "../../../Resources/SVG/EyeSlash";

type PasswordInputProps = Omit<TextInputProps, "type" | "elementRight">;
const PasswordInput = (props: PasswordInputProps) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <TextInput
      type={showPassword ? "text" : "password"}
      elementRight={
        <IconButton
          icon={
            showPassword ? (
              <EyeSlash size={"md"} color={props.error ? "red" : "black"} />
            ) : (
              <Eye size={"md"} color={props.error ? "red" : "black"} />
            )
          }
          size="md"
          onClick={() => setShowPassword(!showPassword)}
        />
      }
      {...props}
    />
  );
};
export default PasswordInput;
