import { css } from "styled-components";
import margin, { marginDefaultProps, MarginProps } from "./margin";
import padding, { paddingDefaultProps, PaddingProps } from "./padding";

export type SpaceProps = MarginProps & PaddingProps;

export const spaceDefaultProps: SpaceProps = {
  ...marginDefaultProps,
  ...paddingDefaultProps,
};

const space = css<SpaceProps>`
  ${margin};
  ${padding};
`;
export default space;
