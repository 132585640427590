import * as React from "react";
import * as PropTypes from "prop-types";
import SmallZwiconButton from "../../../Components/SmallZwiconButton";

import "./Styles.scss";

const NavigationButton = ({
  icon,
  iconElement,
  onClick,
  label,
  disabled,
  hidden,
  ...smallZwiconButtonProps
}) => {
  return (
    <div
      className={`navigation-button ${disabled ? "disabled" : ""} ${
        hidden ? "hidden" : ""
      }`}
      onClick={onClick}
    >
      {icon ? (
        <SmallZwiconButton
          icon={icon}
          disabled={disabled}
          {...smallZwiconButtonProps}
        />
      ) : (
        iconElement
      )}
      <span>{label}</span>
    </div>
  );
};

NavigationButton.propTypes = {
  icon: PropTypes.oneOf([
    "close",
    "zwicon-close-circle",
    "arrow-right",
    "arrow-left",
    "checkmark",
  ]),
  iconElement: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
};

NavigationButton.defaultProps = {
  disabled: false,
  hidden: false,
};

export default NavigationButton;
