import { css } from "styled-components/macro";
import Box from "../../Layout/Box";
import { FlexItemProps } from "../../Styled/flexItem";
import { LayoutProps } from "../../Styled/layout";
import { SpaceProps } from "../../Styled/space";
import { PositionProps } from "../../Styled/position";
import { ReactNode } from "react";
import { ShadowProps } from "../../Styled/shadows";

type CardProps = {
  shadow?: ShadowProps["boxShadow"];
  onClick?: () => void;
  htmlId?: string;
  className?: string;
  children?: ReactNode;
} & PositionProps &
  LayoutProps &
  SpaceProps &
  FlexItemProps;

const Card = ({
  shadow = "md",
  onClick,
  htmlId = "",
  className = "",
  children,
  ...props
}: CardProps) => {
  return (
    <Box
      id={htmlId}
      className={className}
      borderRadius="md"
      backgroundColor="white"
      boxShadow={shadow}
      onClick={onClick}
      tabIndex={onClick ? 0 : undefined}
      css={`
        ${onClick &&
        css`
          cursor: pointer;
        `}
      `}
      {...props}
    >
      {children}
    </Box>
  );
};

export default Card;
