export const PlantingType = {
  PRECULTIVATION: "precultivation",
  DIRECT: "direct",
  SEEDLINGS: "seedlings",
};
export const PlantModalSubComponentType = {
  NONE: "None",
  MAIN: "Main",
  DATEPICKER: "DatePicker",
};
