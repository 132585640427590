import styled from "styled-components";
import border, { BorderProps } from "../../Styled/border";
import color, { ColorProps } from "../../Styled/color";
import flexItem, { FlexItemProps } from "../../Styled/flexItem";
import layout, { LayoutProps } from "../../Styled/layout";
import position, { PositionProps } from "../../Styled/position";
import shadow, { ShadowProps } from "../../Styled/shadows";
import space, { SpaceProps } from "../../Styled/space";
import cursor from "../../Styled/cursor";
import styledDefaultProps from "../../Styled/Core/styledDefaultProps";

export type BoxProps = PositionProps &
  LayoutProps &
  SpaceProps &
  BorderProps &
  ColorProps &
  FlexItemProps &
  ShadowProps & { ref?: any };

const propKeys = Object.keys(styledDefaultProps);
const Box = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !propKeys.includes(prop) && defaultValidatorFn(prop),
})<BoxProps>`
  // TODO: move CSS resets to GlobalStyles
  // CSS resets
  display: block;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: none;
  color: currentColor;
  background-color: transparent;
  font: inherit;

  ${position};
  ${layout};
  ${space};
  ${border};
  ${color};
  ${flexItem};
  ${shadow};
  ${cursor};
`;

export default Box;
