import { postData } from "../Api/Actions";
import Routes from "../../Core/Routes";

export const SET_SURVEY_STARTED = "SURVEY_SET_SURVEY_STARTED";
export const SET_SCORE = "SURVEY_SET_SCORE";
export const RECEIVE_SURVEY = "SURVEY_RECEIVE_SURVEY";
export const RECEIVE_SURVEY_ERROR = "SURVEY_RECEIVE_SURVEY_ERROR";
export const SET_SURVEY_MODAL_VISIBLE = "SURVEY_SET_SURVEY_MODAL_VISIBLE";
export const SET_SURVEY_MODAL_HIDDEN = "SURVEY_SET_SURVEY_MODAL_HIDDEN";

export interface NpsSurveyState {
  surveyCompleted: boolean;
  surveyDeclined: boolean;
  surveyStarted: boolean;
  surveyModalVisible: boolean;
  score?: number;
}

export type NpsSurveyAction = {
  type: string;
  score?: number;
};

type DispatchType = (args: NpsSurveyAction | any) => NpsSurveyAction;
type GetStateType = () => { NpsSurvey: NpsSurveyState };

export const setSurveyModalVisible = () => ({
  type: SET_SURVEY_MODAL_VISIBLE,
});

export const setSurveyModalHidden = () => ({
  type: SET_SURVEY_MODAL_HIDDEN,
});

export const setSurveyStarted = () => ({
  type: SET_SURVEY_STARTED,
});

export const receiveSurvey = (data: any, status: number) => ({
  type: RECEIVE_SURVEY,
  data,
  status,
});

export const receiveSurveyError = (source: any, error: any) => ({
  type: RECEIVE_SURVEY_ERROR,
  source,
  error,
});

export const combinedSurveyReceiver =
  (source: string) => (data: any, status: number) => {
    if (status === 200) {
      return receiveSurvey(data, status);
    } else {
      receiveSurveyError(source, { ...data, status });
    }
  };

export const saveSurvey =
  (text?: string) => (dispatch: DispatchType, getState: GetStateType) => {
    const { score } = getState().NpsSurvey;
    dispatch(
      postData(
        Routes.API_ROUTE_NPS,
        { score, ...(text ? { feedback: text } : {}) },
        combinedSurveyReceiver(Routes.API_ROUTE_NPS)
      )
    );
  };

export const setScore = (score: number | undefined) => ({
  type: SET_SCORE,
  score,
});
