import { useEffect, useRef, useState } from "react";
import useControlledState from "../../../../Helpers/useControlledState";
import Row from "../../../Layout/Row";
import { LayoutProps } from "../../../Styled/layout";
import ImageAddItem from "../ImageAddItem";
import ImagePreviewItem from "../ImagePreviewItem";

type ImageInputListProps = {
  sortable?: boolean;
  colCount?: number;
  withAddItem?: boolean;
  maxImageCount?: number;
  images?: string[];
  onChangeImages?: (images?: string[]) => void;
} & LayoutProps;

const ImageInputList = ({
  sortable,
  colCount = 4,
  withAddItem = false,
  maxImageCount,
  images: ImagesExtern,
  onChangeImages,
  ...props
}: ImageInputListProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [imageWidth, setImageWidth] = useState(0);
  useEffect(() => {
    if (containerRef.current)
      setImageWidth(
        (containerRef.current.clientWidth - 12 - 24 * (colCount - 1)) / colCount
      );
  }, [containerRef, colCount]);

  const [draggedIndex, setDraggedIndex] = useState<number | undefined>();

  const [images, setImages] = useControlledState(ImagesExtern, onChangeImages);

  return (
    <Row
      ref={containerRef}
      flexWrap="wrap"
      gap="24px"
      pt="12px"
      pr="12px"
      {...props}
    >
      {images &&
        images.map((image, index) => (
          <ImagePreviewItem
            key={index}
            width={`${imageWidth}px`}
            image={image}
            index={index}
            onDelete={() => {
              const imagesTemp = [...images];
              imagesTemp.splice(index, 1);
              setImages(imagesTemp);
            }}
            draggable={sortable}
            onDragStart={(index) => {
              setDraggedIndex(index);
            }}
            onDragEnd={(index) => {
              setDraggedIndex(undefined);
            }}
            onDragEnter={(index) => {
              if (draggedIndex != null) {
                const imagesTemp = [...images];
                imagesTemp.splice(draggedIndex, 1);
                imagesTemp.splice(index, 0, images[draggedIndex]);
                setDraggedIndex(index);
                setImages(imagesTemp);
              }
            }}
          />
        ))}
      {withAddItem && (
        <ImageAddItem
          width={`${imageWidth}px`}
          maxImageCount={maxImageCount}
          images={images}
          onChangeImages={setImages}
        />
      )}
    </Row>
  );
};

export default ImageInputList;
