export const postMessage = (type, data) => {
  window.postMessage(JSON.stringify({ type, data }));
};

export const getLocationFeatureRemovedAcknowledged = () => {
  return !!localStorage.getItem("LocationFeatureRemovedAcknowledged");
};

export const setLocationFeatureRemovedAcknowledged = (acknowledged) => {
  localStorage.setItem(
    "LocationFeatureRemovedAcknowledged",
    JSON.stringify(acknowledged)
  );
};
