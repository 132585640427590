import theme from "../../Theme";
import IconProps from "../../Types/IconProps";

export default ({ size = "md", color = "currentColor" }: IconProps) => (
  <svg
    width={
      (size === "lg" && "40px") ||
      (size === "md" && "32px") ||
      (size === "sm" && "24px") ||
      (size === "xs" && "16px") ||
      undefined
    }
    height={
      (size === "lg" && "40px") ||
      (size === "md" && "32px") ||
      (size === "sm" && "24px") ||
      (size === "xs" && "16px") ||
      undefined
    }
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 13C2 18.799 6.70101 23.5 12.5 23.5C18.299 23.5 23 18.799 23 13C23 7.20101 18.299 2.5 12.5 2.5C6.70101 2.5 2 7.20101 2 13ZM22 13C22 18.2467 17.7467 22.5 12.5 22.5C7.25329 22.5 3 18.2467 3 13C3 7.75329 7.25329 3.5 12.5 3.5C17.7467 3.5 22 7.75329 22 13ZM13 13C13 13.2761 12.7761 13.5 12.5 13.5C12.2239 13.5 12 13.2761 12 13V9C12 8.72386 12.2239 8.5 12.5 8.5C12.7761 8.5 13 8.72386 13 9V13ZM13 16.5V15.5H12V16.5H13Z"
      fill={theme.colors[color]}
    />
  </svg>
);
