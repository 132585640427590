import {} from "styled-components/macro";
import Box from "../../Layout/Box";
import Image from "../Image";

type ImageStackProps = {
  imageUrls: string[];
  onClick?: () => void;
};

const ImageStack = ({ imageUrls, onClick, ...props }: ImageStackProps) => {
  return (
    <Box
      position="relative"
      size="56px"
      onClick={onClick}
      css={`
        cursor: ${onClick && "pointer"};
      `}
      {...props}
    >
      {imageUrls.length > 1 && (
        <>
          <Box
            position="absolute"
            top="0px"
            right="0px"
            size="48px"
            borderRadius="sm"
            backgroundColor="beigeDarkest"
          />
          <Box
            position="absolute"
            top="4px"
            right="4px"
            size="48px"
            borderRadius="sm"
            backgroundColor="white"
          />
        </>
      )}

      <Image
        position="absolute"
        bottom="0px"
        left="0px"
        size="48px"
        borderRadius="sm"
        objectFit="cover"
        src={imageUrls[0]}
      />
    </Box>
  );
};

export default ImageStack;
