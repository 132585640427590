import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Theme from "../../../../Core/Theme";
import Modal from "@material-ui/core/Modal";
import Container from "../../Components/Container";
import { PlantDetails } from "../PlantDetails";
import { EditDate } from "../EditDate";
import { ButtonBase } from "@material-ui/core";
import ChevronLeft from "../../../../Resources/SVG/ChevronLeft";
import { DATE_FORMAT_API } from "../../../../Core/Constants";
import moment from "moment";
import { PlantModalSubComponentType } from "../../Consts";

const useStyles = makeStyles((theme) => ({
  root: {
    ...Theme.ui.editOverlayStyles,
    ...Theme.ui.shadow,
    width: ({ matches }) => (matches ? 375 : 712),
    maxHeight: ({ matches }) => (matches ? "calc(100% - 48px)" : 500),
    overflowY: "hidden",
    backgroundColor: ({ matches }) =>
      matches ? Theme.colors.beigeLight : Theme.colors.greyLight,
    flexDirection: ({ matches }) => (matches ? "column" : "row"),
    gap: 16,

    top: ({ matches }) => (matches ? 112 + 24 : "initial"),
    right: ({ matches }) => (matches ? 24 : "initial"),
    bottom: ({ matches }) => {
      if (matches) {
        return "initial";
      } else {
        return 48;
      }
    },
    left: ({ matches }) => (matches ? "initial" : "calc((100% - 712px) / 2)"),
    "& h1, & h2, & h3, & h4": {
      margin: 0,
    },
  },
}));

export const PlantModal = ({ visible, onDismiss, plantData }) => {
  const { t } = useTranslation("season");
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const classes = useStyles({ matches });

  const [step, setStep] = useState(PlantModalSubComponentType.MAIN);
  const [calendarRange, setCalendarRange] = useState(undefined);
  const [dateToEdit, setDateToEdit] = useState(null);

  useEffect(() => {
    if (visible) {
      setStep(PlantModalSubComponentType.MAIN);
    } else {
      setStep(PlantModalSubComponentType.NONE);
    }
  }, [visible]);

  const openEditDateScreen = ({ date, range }) => {
    setStep(PlantModalSubComponentType.DATEPICKER);
    setCalendarRange(range);
    setDateToEdit(date);
  };

  return (
    <Modal open={step !== PlantModalSubComponentType.NONE} onClose={() => {}}>
      <div className={classes.root} style={{ padding: 0 }}>
        <PlantDetails
          visible={step === PlantModalSubComponentType.MAIN}
          plant={plantData}
          onDismiss={onDismiss}
          onSave={onDismiss}
          openCalendar={openEditDateScreen}
        />

        <Container
          visible={visible && step === PlantModalSubComponentType.DATEPICKER}
          className={"scrollContainer"}
          menuButtons={[
            <ButtonBase
              onClick={() => {
                setStep(PlantModalSubComponentType.MAIN);
              }}
            >
              <ChevronLeft size="sm" />
              <span>{t("editSeasons")}</span>
            </ButtonBase>,
            <span />,
            <span />,
          ]}
        >
          <EditDate
            date={dateToEdit}
            closestPlants={{
              previous: plantData.previousPlant,
              next: plantData.nextPlant,
            }}
            range={calendarRange}
            onDaySelected={(day) => {
              const dt = moment(day).format(DATE_FORMAT_API);
              dateToEdit.set(dt);
              setStep(PlantModalSubComponentType.MAIN);
            }}
            onDismiss={() => {
              setStep(PlantModalSubComponentType.MAIN);
            }}
          />
        </Container>
      </div>
    </Modal>
  );
};
