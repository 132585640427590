import React from "react";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import IconClose from "@material-ui/icons/Close";
import "./Styles.scss";
import Modal from "@material-ui/core/Modal";
import withWidth from "@material-ui/core/withWidth/withWidth";
import clsx from "clsx";

const CustomModal = withWidth()(
  ({
    open,
    className,
    containerClassName,
    onClose,
    children,
    showCloseButton,
    style,
    width,
    zIndex,
  }) => (
    <Modal
      open={open}
      onClose={onClose}
      disableBackdropClick={width === "xs"}
      BackdropProps={{
        classes: { root: "modal-backdrop" },
      }}
      style={{ zIndex: zIndex ? zIndex : 10 }}
    >
      <div
        className={clsx("custom-modal", "modal-content", className)}
        style={style}
      >
        {showCloseButton && (
          <div className="button-bar close-button">
            <IconButton onClick={onClose}>
              <IconClose />
            </IconButton>
          </div>
        )}
        <div className={clsx("custom-modal-content", containerClassName)}>
          {children}
        </div>
      </div>
    </Modal>
  )
);

CustomModal.defaultProps = {
  showCloseButton: true,
};

CustomModal.propTypes = {
  open: PropTypes.bool,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  onClose: PropTypes.func,
  showCloseButton: PropTypes.bool,
};

export default CustomModal;
