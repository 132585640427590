import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NavigationBar from "../../../Components/NavigationBar";
import AppBarProgress from "../../../Common/AppBarProgress";
import {
  initializeData,
  loadGardens,
} from "../../../Reducers/Planning/Actions";
import { setBodyBackgroundColor } from "../../../Reducers/Application/Actions";
import Theme from "../../../Core/Theme";
import CreatePlanModal from "../Components/CreatePlanModal";
import Routes from "../../../Core/Routes";

const Page = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isFetching } = useSelector((state) => state.Api);
  const { planInitialized, dataInitialized, planIsSaving, planSaveIsInQueue } =
    useSelector((state) => state.Planning);
  const { hasLock } = useSelector((state) => state.GardenLock);

  useEffect(() => {
    if (!dataInitialized) {
      dispatch(initializeData());
    }
  }, [dataInitialized, initializeData]);

  dispatch(setBodyBackgroundColor(Theme.colors.white));
  useEffect(() => {
    if (planSaveIsInQueue || planIsSaving || hasLock) {
      return;
    }
    dispatch(loadGardens());
  }, []);

  const createPlan = () => {
    navigate(Routes.plan.root);
  };

  return (
    <div>
      {isFetching && <AppBarProgress />}

      <div style={{ position: "fixed", zIndex: 1000, left: 0, right: 0 }}>
        <NavigationBar />
      </div>

      <CreatePlanModal
        open={!planInitialized && dataInitialized}
        onConfirm={createPlan}
      />

      <main
        style={{
          position: "absolute",
          top: 112,
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <Outlet />
      </main>
    </div>
  );
};
export default Page;
