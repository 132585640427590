import React, { useEffect, useState } from "react";
import qs from "qs";
import * as ApiActions from "../../../Reducers/Api/Actions";
import * as UserActions from "../../../Reducers/User/Actions";
import { connect, useDispatch, useSelector } from "react-redux";
import ModalNavigator from "../../../Common/ModalNavigator";
import NavigationButton from "../../../Common/ModalNavigator/NavigationButton";
import StyledTextField from "../../../Common/StyledTextField";
import Images from "../../../Core/Images";
import { useLocation, useNavigate } from "react-router-dom";
import {
  confirmPaypalSubscription,
  editUser,
  saveUser,
} from "../../../Reducers/User/Actions";

const AccountPaypalConfirm = ({ onClose, onContinue }) => {
  const [paymentId, setPaymentId] = useState("");
  const [paymentTagged, setPaymentTagged] = useState(false);

  const { isFetching } = useSelector((state) => state.Api);
  const { subscriptionConfirmed, unSavedUser: user } = useSelector(
    (state) => state.User
  );

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const query = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    if (query.paymentId) {
      dispatch(confirmPaypalSubscription(query.paymentId, query.PayerID));
      setPaymentId(query.paymentId);
    } else if (query.token) {
      dispatch(confirmPaypalSubscription(query.token, ""));
    }
  }, []);

  const onClickNext = () => {
    dispatch(saveUser(user));
    onContinue();
  };

  return (
    <>
      <div style={{ zIndex: 2, position: "relative" }}>
        <h1>Abo wählen</h1>
        {!subscriptionConfirmed && (
          <div>
            <p>
              {isFetching
                ? "Bitte warte, während dein Konto aktiviert wird"
                : "Beim Aktivieren deines Accounts ist ein Problem aufgetreten. Bitte kontaktiere uns unter support@fryd.app"}
            </p>
            {isFetching && (
              <img
                src={Images.scribble.bee}
                alt=""
                style={{
                  position: "absolute",
                  right: 70,
                  top: 200,
                  width: 75,
                }}
              />
            )}
          </div>
        )}
        {subscriptionConfirmed && (
          <div>
            <p>
              Vielen Dank für deine Zahlung, dein Account ist jetzt aktiv. Bitte
              aktualisiere jetzt noch deine Account-Daten, damit du deinen
              Account jederzeit wiederherstellen kannst, fallst du dein Gerät
              verlierst.
            </p>
            <div>
              <StyledTextField
                className="textfield-100"
                label={"Vorname"}
                value={user.firstname || ""}
                onChange={(e) =>
                  dispatch(editUser({ firstname: e.target.value }))
                }
              />
              <StyledTextField
                className="textfield-100"
                label={"Nachname"}
                value={user.lastname || ""}
                onChange={(e) =>
                  dispatch(editUser({ lastname: e.target.value }))
                }
              />
              <StyledTextField
                className="textfield-100"
                label={"E-Mail"}
                value={user.email || ""}
                onChange={(e) =>
                  dispatch(
                    editUser({ email: e.target.value, emailChanged: true })
                  )
                }
              />
              <StyledTextField
                className="textfield-100"
                label={"Straße"}
                value={user.addressLine1 || ""}
                onChange={(e) =>
                  dispatch(editUser({ addressLine1: e.target.value }))
                }
              />
              <StyledTextField
                className="textfield-100"
                label={"PLZ"}
                value={user.zipCode || ""}
                onChange={(e) =>
                  dispatch(editUser({ zipCode: e.target.value }))
                }
              />
              <StyledTextField
                className="textfield-100"
                label={"Ort"}
                value={user.city || ""}
                onChange={(e) => dispatch(editUser({ city: e.target.value }))}
              />
            </div>
          </div>
        )}

        {(subscriptionConfirmed || !isFetching) && (
          <ModalNavigator
            left={
              <NavigationButton
                icon="close"
                onClick={onClose}
                label="Schließen"
              />
            }
            right={
              <NavigationButton
                icon="arrow-right"
                onClick={onClickNext}
                label="Zu meinen Beeten"
                disabled={!subscriptionConfirmed}
              />
            }
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  subscriptionConfirmed: state.User.subscriptionConfirmed,
  isFetching: state.Api.isFetching,
  user: state.User.unSavedUser,
});

const mapDispatchToProps = (dispatch) => ({
  editUser: (change) => dispatch(UserActions.editUser(change)),
  saveUser: (user) =>
    dispatch(ApiActions.postData("/users", user, UserActions.receiveUser)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountPaypalConfirm);
