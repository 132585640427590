import { deleteData, postData } from "../Api/Actions";
import Routes from "../../Core/Routes";
import { detect } from "detect-browser";
import packageJson from "../../../package.json";
import { clearSaveTimeout, loadGarden } from "../Planning/Actions";

export const SET_DEVICE_DETAILS = "GARDEN_LOCK_SET_DEVICE_DETAILS";
export const START_LOCKING = "GARDEN_LOCK_START_LOCKING";
export const LOCK_SUCCESSFUL = "GARDEN_LOCK_SUCCESSFUL";
export const LOCK_FAILED = "GARDEN_LOCK_FAILED";
export const UNLOCK_SUCCESSFUL = "GARDEN_UNLOCK_SUCCESSFUL";
export const UNLOCK_FAILED = "GARDEN_UNLOCK_FAILED";

let _lockingTimeout = undefined;

const receiveGetLock = (data, status) => (dispatch, getState) => {
  const { garden } = getState().Planning;
  if (status === 200) {
    dispatch({
      type: LOCK_SUCCESSFUL,
    });
    // start timer to keep lock
    clearTimeout(_lockingTimeout);
    _lockingTimeout = setTimeout(() => {
      dispatch(lockGarden(garden.id));
    }, 5000);
  } else {
    // clear save timeout in planning reducer in this case
    dispatch(clearSaveTimeout());
    // reload garden
    dispatch(loadGarden(garden.id));
    dispatch({
      type: LOCK_FAILED,
      deviceId: data?.details?.device_id,
      deviceDescription: data?.details?.device_description,
    });
  }
};

export const resetLock = () => (dispatch) => {
  clearTimeout(_lockingTimeout);
  dispatch(clearSaveTimeout());
  dispatch({
    type: UNLOCK_SUCCESSFUL,
  });
};

export const setDeviceDetails = (deviceId) => (dispatch) => {
  const browser = detect();
  const browserName = browser.name + " " + browser.version + " " + browser.os;

  dispatch({
    type: SET_DEVICE_DETAILS,
    deviceId,
    deviceName: browserName,
    appVersion: packageJson.version,
  });
};

export const startLocking = () => ({
  type: START_LOCKING,
});

export const lockGarden = (gardenId) => (dispatch, getState) => {
  if (!getState().GardenLock.deviceId) {
    console.warn("cannot lock garden without cookie");
    return;
  }
  if (!gardenId) {
    console.warn("cannot lock garden without defined gardenId");
    return;
  }
  const { deviceId, deviceDescription } = getState().GardenLock;

  dispatch(startLocking());

  return dispatch(
    postData(
      Routes.API_ROUTE_LOCK_GARDEN.replace("{gardenId}", gardenId),
      { deviceId, deviceDescription },
      receiveGetLock,
      undefined,
      { ignoreFetchingState: true }
    )
  );
};

const receiveUnlock = (data, status) => (dispatch) => {
  if (status === 200) {
    dispatch({
      type: UNLOCK_SUCCESSFUL,
    });
  } else {
    dispatch({
      type: UNLOCK_FAILED,
    });
  }
};

export const deleteExistingLock = (gardenId) => (dispatch, getState) => {
  const { otherDeviceId } = getState().GardenLock;
  if (!otherDeviceId) {
    console.warn(
      "cannot delete existing lock without deviceId of other device"
    );
    return;
  }
  if (!gardenId) {
    console.warn("cannot delete existing lock without defined gardenId");
    return;
  }
  return dispatch(
    deleteData(
      Routes.API_ROUTE_LOCK_GARDEN.replace("{gardenId}", gardenId),
      { deviceId: otherDeviceId },
      receiveUnlock
    )
  );
};

export const unlockGarden = (gardenId) => (dispatch, getState) => {
  if (!getState().GardenLock.deviceId) {
    console.warn("cannot unlock garden without defined device id");
    return;
  }
  if (!gardenId) {
    console.warn("cannot unlock garden without defined gardenId");
    return;
  }
  const { deviceId, hasLock } = getState().GardenLock;

  if (!hasLock) {
    console.log("there is no lock that needs to be unlocked");
    return;
  }

  return dispatch(
    deleteData(
      Routes.API_ROUTE_LOCK_GARDEN.replace("{gardenId}", gardenId),
      { deviceId },
      receiveUnlock
    )
  );
};
