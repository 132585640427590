import moment from "moment";
import {
  Season,
  AMOUNT_OF_HARVEST_PER_SQUAREMETRE,
} from "../../Screens/Planning/PatchPlan/Consts";

export const seasons = {
  pre: {
    start: { month: 1, day: 1 },
    end: { month: 5, day: 14 },
  },
  main: {
    start: { month: 5, day: 15 },
    end: { month: 9, day: 15 },
  },
  post: {
    start: { month: 9, day: 16 },
    end: { month: 12, day: 31 },
  },
};
export const usableCropComparator = (c) =>
  c.isApproved || c.isMine || (c.isFavorite && c.canBeUsedForPlanning);

export const getWeekAfterIceSaints = (year) => {
  let y = year;
  if (typeof year === "undefined") {
    y = moment().year();
  }
  return moment([y, 4, 15]).week() + 1;
};

export const getStartAndEnd = (season, year) => {
  const startMonth = seasons[season].start.month;
  const startDay = seasons[season].start.day;
  const start = moment([year, startMonth - 1, startDay]);
  const endMonth = seasons[season].end.month;
  const endDay = seasons[season].end.day;
  const end = moment([year, endMonth - 1, endDay, 23, 59, 59]);

  return { start, end, startWeek: start.week() + 1 };
};

/**
 * checks if a plant is to be displayed in this season
 *
 * @param plant
 * @param season
 * @param year
 * @returns {boolean}
 */
export const isPlantInSeason = (plant, season, year) => {
  // season = pre / main / post
  // preseason plants MUST start and MUST end in preseason
  // mainseason plants CAN start in preseason and CAN end in postseason
  // postseason plants MUST start in postseason and CAN end in preseason in the following year
  // ---pre---- | ---main--- | ---post---
  // --xxxx---- | ---------- | ---------- (pre)
  // --xxxxxxxx | xxxxx----- | ---------- (main)
  // --------xx | xxxxxxxxxx | xx-------- (main)
  // ---------- | --xxxxxxx- | ---------- (main)
  // ---------- | --xxxxxxxx | x--------- (main)
  // ---------- | ---------- | -xxx------ (post)
  // xx-------- | ---------- | -------xxx (post)

  const { start, end } = getStartAndEnd(season, year);

  //Check if plant is in current year
  if (
    moment(plant.dateInPatch).year() > year ||
    moment(plant.dateHarvestEnd).year() < year
  ) {
    return false;
  }

  return (
    (season === Season.PRE &&
      moment(plant.dateInPatch) >= start &&
      moment(plant.dateHarvestEnd) <= end) ||
    (season === Season.MAIN &&
      moment(plant.dateHarvestEnd) >= start &&
      moment(plant.dateInPatch) <= end) ||
    (season === Season.POST && moment(plant.dateInPatch) >= start)
  );
};

/**
 * checks if a plant blocks a season but is not in season
 *
 * @param plant
 * @param season
 * @param year
 * @returns {boolean}
 */
const isPlantBlockingSeason = (plant, season, year) => {
  const { start, end } = getStartAndEnd(season, year);
  return (
    (season === Season.PRE &&
      moment(plant.dateInPatch) > start &&
      moment(plant.dateInPatch) < end &&
      moment(plant.dateHarvestEnd) > end) ||
    (season === Season.POST &&
      moment(plant.dateInPatch) < start &&
      moment(plant.dateHarvestEnd) > start)
  );
};

export const filterPlantsForSeason = (patches, season, year) => {
  return patches.map((patch) => ({
    ...patch,
    plants: patch.plants.map((plant) => ({
      ...plant,
      inSeason: isPlantInSeason(plant, season, year),
      isBlockingSeason: isPlantBlockingSeason(plant, season, year),
    })),
  }));
};

export const filterPlantsWithoutSeason = (patches) => {
  return patches.map((patch) => ({
    ...patch,
    plants: patch.plants.map((plant) => ({
      ...plant,
      inSeason: true,
      isBlockingSeason: false,
    })),
  }));
};

export const getCropPlantingDates = (crop, year, season = Season.MAIN) => {
  let startWeekIsPrecultivationStart =
    crop.hasPrecultivation && !!crop.precultivationSeason;
  let startWeek = startWeekIsPrecultivationStart
    ? Number(crop.precultivationSeason.start)
    : Number(crop.germinationSeason.start);
  const growthDurationInWeeks = getGrowthDurationInWeeks(crop, true);
  const totalDuration = getGrowthDurationInWeeks(crop);
  // get week of ice saints
  const weekAfter = getWeekAfterIceSaints(year);

  // set week to ice saints week
  if (!crop.coldResistant && startWeek < weekAfter) {
    startWeek = weekAfter;
    startWeekIsPrecultivationStart = false;
  }

  const precultivationDuration = crop.hasPrecultivation
    ? Math.ceil(
        Number(crop.germinationDuration.default) +
          Number(crop.precultivationDuration.default)
      )
    : 0;

  if (season === Season.POST) {
    const startAndEnd = getStartAndEnd(season, year);
    if (startWeek < startAndEnd.startWeek) {
      startWeek = startAndEnd.startWeek;
      startWeekIsPrecultivationStart = false;
    }
  }

  let datePlanted = moment().year(year).week(startWeek).startOf("week");
  if (precultivationDuration > 0 && !startWeekIsPrecultivationStart) {
    datePlanted.subtract(precultivationDuration, "weeks");
  }

  const dateInPatch = moment().year(year).week(startWeek).startOf("week");
  const dateHarvestPlanned = moment(datePlanted).add(
    growthDurationInWeeks,
    "weeks"
  );
  const dateHarvestStart = moment(datePlanted)
    .add(growthDurationInWeeks, "weeks")
    .startOf("week");
  let dateHarvestEnd = moment(datePlanted)
    .add(totalDuration, "weeks")
    .endOf("week");

  if (season === Season.PRE) {
    const { end } = getStartAndEnd(season, year);
    if (dateHarvestEnd > end) {
      dateHarvestEnd = end;
    }
  }

  return {
    datePlanted,
    dateInPatch,
    dateHarvestPlanned,
    dateHarvestStart,
    dateHarvestEnd,
  };
};

/**
 * returns the duration between two dates
 * Limit is start and end of given year
 *
 * @param {integer}
 */
export const getDurationInDaysForYear = (startDate, endDate, year) => {
  if (!startDate || !endDate || isNaN(year)) {
    return 0;
  }

  const startDt = moment(startDate);
  const endDt = moment(endDate);

  if (startDt > endDt) {
    return 0;
  }

  [startDt, endDt].forEach((date) => {
    if (date.year() < year) {
      //Minimum Start date is start of current year
      date
        .year(year)
        .month(0)
        .date(1)
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);
    } else if (date.year() > year) {
      //Maximum End date is end of current year
      date
        .year(year + 1)
        .month(0)
        .date(1)
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);
    }
  });

  return endDt.diff(startDt, "days");
};

/**
 * checks if a crop fits into the given season and year
 *
 * @param crop
 * @param season
 * @param year
 * @returns {boolean}
 */
export const cropFitsInSeason = (crop, season, year) => {
  if (!crop.varieties) {
    return false;
  }
  const { datePlanted, dateHarvestStart } = getCropPlantingDates(crop, year);
  let maxPlanted = datePlanted;
  let minHarvestStart = dateHarvestStart;
  let coldResistant = crop.coldResistant;
  crop.varieties.forEach((variety) => {
    const { datePlanted, dateHarvestStart } = getCropPlantingDates(
      variety,
      year
    );
    if (variety.coldResistant) {
      coldResistant = true;
    }
    if (!maxPlanted || maxPlanted < datePlanted) {
      maxPlanted = datePlanted;
    }
    if (!minHarvestStart || minHarvestStart > dateHarvestStart) {
      minHarvestStart = dateHarvestStart;
    }
  });
  if (!minHarvestStart || !maxPlanted) {
    return false;
  }

  const { start, end } = getStartAndEnd(season, year);

  if (season === Season.PRE) {
    return !!(coldResistant && moment(minHarvestStart) < moment(end));
  } else if (season === Season.POST) {
    return !!(coldResistant && moment(maxPlanted) > moment(start));
  }
  return true;
};

export const getSeasonForVariety = (variety, year,   preferredSeason = Season.MAIN
) => {
  if (!variety.seasons || variety.seasons.length === 0) {
    return getSeasonForVarietyFallback(variety, year);
  }
  const seasons = [Season.PRE, Season.MAIN, Season.POST];

  if (
      preferredSeason &&
      seasons.indexOf(preferredSeason) !== -1 &&
      variety.seasons.indexOf(preferredSeason) !== -1
  ) {
    return preferredSeason;
  }

  return seasons.find((season) => variety.seasons.indexOf(season) !== -1);
};

export const getSeasonForVarietyFallback = (variety, year) => {
  const seasons = [Season.PRE, Season.MAIN, Season.POST];
  let selectedSeason = undefined;
  seasons.forEach((season) => {
    const plant = {
      ...getCropPlantingDates(variety, year),
    };
    if (!selectedSeason && isPlantInSeason(plant, season, year)) {
      selectedSeason = season;
    }
  });
  return selectedSeason;
};

export const getVarietyForSeason = (crop, season, year) => {
  const { varieties } = crop;
  const usableVarieties =
    varieties && varieties.filter((v) => usableCropComparator(v));

  if (!varieties || varieties.length < 1) {
    return crop;
  }

  if (!crop.seasons) {
    return getVarietyForSeasonFallback(crop, season, year);
  }

  let selectedVariety;

  usableVarieties.forEach((variety) => {
    if (selectedVariety) {
      return;
    }
    if (!usableCropComparator(variety)) {
      return;
    }
    if (!variety.seasons) {
      return;
    }
    if (variety.seasons.indexOf(season) < 0) {
      return;
    }

    selectedVariety = variety;
  });

  if (!selectedVariety) {
    return getVarietyForSeasonFallback(crop, season, year);
  }
  return selectedVariety;
};

export const getVarietyForSeasonFallback = (crop, season, year) => {
  const { varieties } = crop;
  const usableVarieties =
    varieties && varieties.filter((v) => usableCropComparator(v));

  if (!varieties || varieties.length < 1) {
    return crop;
  }

  let selectedVariety;
  let selectedDateHarvestStart;
  let selectedDateInPatch;
  let selectedDatePlanted;

  if (season === Season.PRE) {
    usableVarieties.forEach((variety) => {
      const { dateHarvestStart } = getCropPlantingDates(variety, year);
      if (!variety.coldResistant) {
        return;
      }
      if (!selectedVariety || dateHarvestStart < selectedDateHarvestStart) {
        selectedVariety = variety;
        selectedDateHarvestStart = dateHarvestStart;
      }
    });
  } else if (season === Season.MAIN) {
    // TODO: add default variety flag to crops
    selectedVariety = usableVarieties.find(
      (variety) => variety.isDefaultVariety
    );
    if (!selectedVariety) {
      usableVarieties.forEach((variety) => {
        const { month, day } = seasons.main.start;
        const mainStart = moment()
          .month(month - 1)
          .day(day);
        const { dateInPatch } = getCropPlantingDates(variety, year);
        if (
          !selectedVariety ||
          (dateInPatch < selectedDateInPatch &&
            dateInPatch > mainStart &&
            !variety.communityCropCreator)
        ) {
          selectedVariety = variety;
          selectedDateInPatch = dateInPatch;
        }
      });
    }
  } else {
    usableVarieties.forEach((variety) => {
      const { datePlanted } = getCropPlantingDates(variety, year);
      if (!variety.coldResistant) {
        return;
      }
      if (!selectedVariety || datePlanted < selectedDatePlanted) {
        selectedVariety = variety;
        selectedDatePlanted = datePlanted;
      }
    });
  }
  if (!selectedVariety) {
    // console.warn("use fallback variety for crop " + crop.identifier);
    selectedVariety = varieties.find((v) => v.isApproved);
    if (!selectedVariety) {
      console.warn("no approved variety found, use first existing variety");
      selectedVariety = varieties[0];
    }
  }
  return selectedVariety;
};

export const getSeasonByMonth = (month, year) => {
  const seasonNames = ["pre", "main", "post"];
  for (let i = 0; i <= seasonNames.length; i++) {
    const { start, end } = getStartAndEnd(seasonNames[i], year);
    if (month <= end.month() && month >= start.month()) {
      return seasonNames[i];
    }
  }
};

export const getPlantsDateInPatch = (plant) => {
  return plant.dateInPatch || plant.datePlanted;
};

export const getTotalAreaOfPatches = (patches) => {
  let totalArea = 0;
  //@todo need Calculation for different types of combined shapes
  patches.map((patch) => {
    patch.shapes.map((shape) => {
      totalArea += shape.width * shape.height;
    });
  });
  return totalArea;
};

export const calculatePlantAreaForSeason = (patches, season, year) => {
  let totalPlantArea = 0;
  patches.map((patch) => {
    patch.plants.forEach((plant) => {
      if (!isPlantInSeason(plant, season, year)) {
        return;
      }
      totalPlantArea += plant.width * plant.height;
    });
  });

  return totalPlantArea;
};

export const calculateTotalPlantArea = (patches, year) => {
  const PRE_SEASON_FACTOR = 1;
  const MAIN_SEASON_FACTOR = 3;
  const POST_SEASON_FACTOR = 1;

  //Calculated Area for each season
  const plantAreaForPreSeason = calculatePlantAreaForSeason(
    patches,
    "pre",
    year
  );
  const plantAreaForMainSeason = calculatePlantAreaForSeason(
    patches,
    "main",
    year
  );
  const plantAreaForPostSeason = calculatePlantAreaForSeason(
    patches,
    "post",
    year
  );

  return (
    PRE_SEASON_FACTOR * plantAreaForPreSeason +
    MAIN_SEASON_FACTOR * plantAreaForMainSeason +
    POST_SEASON_FACTOR * plantAreaForPostSeason
  );
};

export const calculateMaximumUsableArea = (patchArea) => {
  const FACTOR = 5; // 1 x preSeason + 3 x mainSeason + 1 x postSeason = 5 x
  return patchArea * FACTOR;
};

export const getMaximumHarvestAmount = (patchArea) => {
  const UNIT_QUOTIENT = 10000; //10.000cm2 equals 1m2
  return (patchArea / UNIT_QUOTIENT) * AMOUNT_OF_HARVEST_PER_SQUAREMETRE;
};

export const calculatePossibleHarvest = (patches, patchArea, year) => {
  const totalPlantArea = calculateTotalPlantArea(patches, year);
  const maximumUsableArea = calculateMaximumUsableArea(patchArea);
  const maximumHarvestAmount = getMaximumHarvestAmount(patchArea);

  return (totalPlantArea / maximumUsableArea) * maximumHarvestAmount;
};

/**
 * gets the full growth duration of a crop
 *
 * @param crop
 * @returns {*}
 */
export const getGrowthDurationInWeeks = (
  crop,
  withoutHarvest,
  continuousDuration = 8
) => {
  if (!crop) return 0;

  const germinationDuration = checkDuration(crop.germinationDuration);
  const precultivationDuration = checkDuration(crop.precultivationDuration);
  const growthDuration = checkDuration(crop.growthDuration);

  let harvestDuration = 0;
  if (!withoutHarvest) {
    harvestDuration =
      crop.harvestDuration === "continuous"
        ? continuousDuration // todo: get from season data
        : !isNaN(crop.harvestDuration)
        ? Number(crop.harvestDuration)
        : 2;
  }

  return Math.ceil(
    germinationDuration +
      precultivationDuration +
      growthDuration +
      harvestDuration
  );
};

const checkDuration = (duration) => {
  let durationNumber = 0;

  if (duration && Object.keys(duration).length) {
    if (duration.default) {
      let defaultValue = duration.default;

      if (typeof defaultValue === "string") {
        defaultValue = defaultValue.replace(",", ".");
      }

      durationNumber = Number(defaultValue);
    }
  }

  return durationNumber;
};

/**
 *
 * @param {object} plant
 * @returns
 */

export const getPlantHasPrecultivation = (plant) => {
  if (!plant) {
    return false;
  }

  if (plant.plantingType === "seedlings" || plant.plantingType === "direct") {
    return false;
  }

  if (plant.plantingType === "precultivation") {
    return true;
  }

  if (
    plant.crop &&
    plant.crop.hasPrecultivation &&
    plant.crop.precultivationSeason
  ) {
    return true;
  }

  if (
    plant.dateInPatch &&
    plant.datePlanted &&
    plant.dateInPatch !== plant.datePlanted
  ) {
    return true;
  }

  return false;
};

export const getPreferredVariety = (crop, season, year) => {
  // check if user has defined favorite variety; if so, use this variety
  const { varieties } = crop;
  if (!varieties || varieties.length < 1) {
    return crop;
  }
  const favoriteVarieties = varieties.filter(
    (v) => v.isFavorite && v.canBeUsedForPlanning
  );
  if (favoriteVarieties.length > 0) {
    // TODO: also check for season here?
    return favoriteVarieties[0];
  }
  // get early / default / late variety, depending on season and use variety
  return getVarietyForSeason(crop, season, year);
};

export const processingCropData = (crop, season, year) => {
  const variety = getPreferredVariety(crop, season, year);
  const varietySize = Math.round(
    (Number(variety.seedingDistance.default) +
      Number(variety.rowDistance.default)) /
      2
  );
  return {
    id: crop.id,
    varietyId: variety.id,
    isDefaultVariety: true,
    name: crop.name,
    latinName: crop.latinName,
    width: varietySize,
    height: varietySize,
    imageUrl: crop.imageUrl,
    identifier: crop.identifier,
    isFavorite: crop.isFavorite,
    minimalSize: 20,
    plantInRows: !!crop.plantInRows,
    lightingDemand: crop.lightDemand ?? crop.lightingDemand,
    groundType: crop.groundType,
    rowDistance: crop.rowDistance,
    seedingDistance: crop.seedingDistance,
    plantFamily: crop.plantfamily?.id,
    dates: getCropPlantingDates(variety, year, season),
  };
};
