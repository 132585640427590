export default {
  API_ROUTE_DISCOVER: "/inspiration/feed?{versionBuild}",
  API_ROUTE_DISCOVER_AUTOCOMPLETE: "/inspiration/autocomplete?q={query}",
  API_ROUTE_DISCOVER_SEARCH: "/inspiration/search?q={query}",
  API_ROUTE_DISCOVER_ARTICLE: "/inspiration/article/{articleId}",
  API_ROUTE_RECIPE_FEED: "/recipes",
  API_ROUTE_PODCAST_FEED: "/inspiration/podcasts",
  API_ROUTE_PODCAST_AUTOCOMPLETE: "/inspiration/podcasts?q={query}",
  API_ROUTE_ARTICLE_FEED: "/inspiration/articles",
  API_ROUTE_RECIPE_AUTOCOMPLETE: "/recipes/autocomplete?q={query}",
  API_ROUTE_ARTICLES_AUTOCOMPLETE: "/inspiration/articles?q={query}",
  API_ROUTE_RECIPE_SEARCH: "/recipes/search?q={query}",
  API_ROUTE_FEED: "/feed",
  API_ROUTE_FEED_BOOKMARKS: "/feed/bookmarks",
  API_ROUTE_POSTS: "/posts",
  API_ROUTE_POSTS_COMMENT: "/posts/{id}/comment",
  API_ROUTE_POSTS_BOOKMARK: "/posts/{id}/bookmark",
  API_ROUTE_POSTS_LIKE: "/posts/{id}/like",
  API_ROUTE_POSTS_ID: "/posts/{id}",
  API_ROUTE_COMMENTS_LIKE: "/posts/{postId}/comments/{id}/like",
  API_ROUTE_COMMENT_ID: "/posts/{postId}/comments/{id}",
  API_ROUTE_REPORT_POST: "/posts/{postId}/report",
  API_ROUTE_REPORT_COMMENT: "/posts/{postId}/comments/{id}/report",
  API_ROUTE_CATEGORIZE_POST: "/posts/{postId}/categorize",
  APU_ROUTE_ACCOUNT_CONFIRM_PURCHASE: "/users/account/upgrade",
  API_ROUTE_ACCOUNT_FREEUPGRADE: "/users/account/freeupgrade",
  API_ROUTE_LOGIN: "/users/login",
  API_ROUTE_SOCIAL_LOGIN: "/users/sociallogin",
  API_ROUTE_RATING: "/users/rating",
  API_ROUTE_NPS: "/nps",
  API_ROUTE_NPS_SKIP: "/nps/skip",
  API_ROUTE_USERS: "/users",
  API_ROUTE_USERS_CHECK_EMAIL: "/users/checkemail",
  API_ROUTE_USERS_REGISTER: "/users/register",
  API_ROUTE_USERS_CHECK_NICKNAME: "/users/checknickname",
  API_ROUTE_USERS_UNREGISTER: "/users/unregister",
  API_ROUTE_USER_CURRENT: "/users/current",
  API_ROUTE_USER_RECOVER_LOGIN: "/users/recoverlogin",
  API_ROUTE_DEVICES: "/devices/register",
  API_ROUTES_DEVICE_REGISTER: "/users/registerdevice",
  API_ROUTE_LOCATIONS_AUTOCOMPLETE: "/location/autocomplete?query={query}",
  API_ROUTE_LOCATIONS_FROM_GEO: "/location/getfromgeo?lat={lat}&lon={lon}",
  API_ROUTE_LOCATIONS_GET_PLACE: "/location/places/{placeId}",
  API_ROUTE_LOCATIONS_GET_MAPS_IMAGE: "/location/mapsimage",
  API_ROUTE_RESEND_OPTIN: "/users/register/resend",
  API_ROUTE_NOTIFICATIONS: "/notifications",
  API_ROUTE_TAGS: "/feed/tags",
  API_ROUTE_FEED_USER_PROFILE: "/feed/profiles/{hash}",
  API_ROUTE_USERS_FOLLOWING: "/users/following",
  API_ROUTE_USERS_FOLLOWERS: "/users/followers",
  API_ROUTE_USER_FOLLOW: "/users/{hash}/follow",
  API_ROUTE_USER_UNFOLLOW: "/users/{hash}/unfollow",

  API_ROUTE_CONTENT_ELEMENT: "/content/{contentId}",

  API_ROUTE_CROPS: "/crops",
  API_ROUTE_NEW_COMMUNITY_CROPS: "/crops/new",
  API_ROUTE_BEST_RATED_CROPS: "/crops/bestrated",
  API_ROUTE_CROPS_DETAIL: "/crops/{cropId}",
  API_ROUTE_CROPS_CHANGE_SETS: "/crops/changesets",
  API_ROUTE_CROPS_APPROVALS: "/crops/approvals",
  API_ROUTE_CROPS_CHANGE_SETS_APPLIED: "/crops/changesets?applied=true",
  API_ROUTE_CROP_CHANGES: "/crops/{cropId}/edits",
  API_ROUTE_CROP_CHANGES_APPLIED: "/crops/{cropId}/edits?applied=true",
  API_ROUTE_CROP_CHANGES_ACCEPT: "/crops/{cropId}/edits/{editId}/apply",
  API_ROUTE_CROP_CHANGES_REJECT: "/crops/{cropId}/edits/{editId}/decline",
  API_ROUTE_CROPS_RATE: "/crops/{cropId}/rate",
  API_ROUTE_CROPS_LIKE: "/crops/{cropId}/like",
  API_ROUTE_CROPS_DISLIKE: "/crops/{cropId}/dislike",
  API_ROUTE_CROP_ACTIONS_LOAD: "/crop/{cropId}/cropactions",
  API_ROUTE_CROP_ACTION_UPSERT: "/cropactions",
  API_ROUTE_CROP_ACTION_DELETE: "/cropactions/{cropactionId}",
  API_ROUTE_PESTS: "/pests",
  API_ROUTE_DISEASES: "/diseases",
  API_ROUTE_PLANS: "/plans",

  API_ROUTE_GARDENS: "/gardens",
  API_ROUTE_GARDEN: "/gardens/{gardenId}",
  API_ROUTE_LOCK_GARDEN: "/gardens/{gardenId}/lock",
  API_ROUTE_GARDEN_HASH: "/gardens/{gardenId}/hash",

  API_ROUTE_CART: "/shop/cart",

  API_ROUTE_PLANT_FAMILIES: "/plantfamilies",
  API_ROUTE_PROPERTIES: "/properties",
  API_ROUTE_PROPERTIES_FOR_CROP: "/properties/crop/{cropId}",
  API_ROUTE_CROP_CATEGORIES: "/cropcategories",

  API_ROUTE_CONTENT_FOR_CROP: "/content/crop/{cropId}",

  root: "/",
  buy: "/buy",
  login: "/login",
  logout: "/logout",
  profile: "/profile",
  register: {
    root: "/register",
    confirm: "/register/confirm/:hash",
  },
  overview: "/overview",
  onboarding: "/onboarding",
  createPatch: "/patch/create",
  terms: "/terms",
  privacy: "/privacy",
  passwordReset: {
    root: "/password-reset",
    newPassword: "/password-reset/:hash",
  },
  content: {
    article: "/article/:articleId",
  },
  websiteLibrary: {
    root: "/",
    cropDetails: "/crops/:cropParam",
    varieties: "/crops/:cropParam/varieties",
    varietyDetails: "/crops/:cropParam/varieties/:varietyParam",
  },
  // the library routes are used in the website project for the generation of sitemap.xml and also
  // in the api project to generate deeplinks; if anything is changed here, these two projects have
  // be considered too; so handle with care please!
  library: {
    root: "/library",
    cropRoot: "/library/crops", // only used for parsing. redirect to website if user is not logged in
    cropDetails: "/library/crops/:cropParam",
    varieties: "/library/crops/:cropParam/varieties",
    varietyDetails: "/library/crops/:cropParam/varieties/:varietyParam",
    favorites: "/library/favorites",
    isMine: "/library/ismine",
    createCrop: "/library/createcrop",
    createVariety: "/library/createvariety",
    cropCreatorNewCrop: "/library/cropcreator",
    cropCreatorEditCrop: "/library/cropcreator/:id",
    moderation: "/library/moderation",
    changes: "/library/changes",
    noMatch: "/library/crops/*",
    /*diseases: "/discover/library/diseases",
    pests: "/discover/library/pests"*/
  },
  account: {
    root: "/account",
    upgrade: "/account/upgrade",
    cancel: "/account/cancel",
    paypal: {
      payment: "/account/paypal/payment",
      confirm: "/account/paypal/confirm",
    },
  },
  shop: {
    root: "/shop",
    payment: {
      confirm: "/shop/payment/confirm",
      cancel: "/shop/payment/cancel",
    },
  },
  gardening: {
    root: "/gardening",
    work: "/gardening/work",
    season: "/gardening/season",
  },
  growth: { season: "/growth", forecast: "/growth/forecast" },
  plan: {
    root: "/plan",
    patches: "/plan/patches",
    conditions: "/plan/conditions",
    planting: "/plan/planting",
  },
  community: {
    root: "/community",
    bookmarks: "/community/bookmarks",
    post: "/community/post/:id",
    newPost: "/community/post/new",
    userProfile: "/community/profile/:id",
    notifications: "/community/notifications",
    trends: "/community/trends",
  },
  settings: "/settings",
};
