import React, { useEffect, useState } from "react";
import Theme from "../../../../../Core/Theme";
import ChevronRight from "../../../../../Resources/SVG/ChevronRight";

import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  plantDatesList: {
    flex: 1,
    "& > div:nth-last-child(n+2)": {
      borderBottom: `1px solid ${Theme.colors.greenAppInactive}`,
    },
  },
}));

export const PlantDatesList = ({
  plantPeriodsDates,
  openCalendar,
  calendarRange,
}) => {
  const classes = useStyles({});
  return (
    <div className={classes.plantDatesList}>
      {plantPeriodsDates.map((date, index) => (
        <PlantDateItem
          key={`${index}-date-${date}`}
          date={date}
          index={index}
          openCalendar={openCalendar}
          calendarRange={calendarRange}
        />
      ))}
    </div>
  );
};

const PlantDateItem = ({ index, date, openCalendar, calendarRange }) => {
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    if (!date) {
      return;
    }
    setSelectedDate(date);
  }, [date]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "12px 0",
        cursor: "pointer",
      }}
      onClick={() => {
        openCalendar({
          date: selectedDate,
          range: calendarRange,
        });
      }}
      data-testid={`PlantDateItem.${index}`}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "baseline",
        }}
      >
        <span
          style={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            backgroundColor: date.color,
            width: 16,
            height: 16,
            padding: 4,
            fontWeight: 700,
            fontSize: Theme.sizes.small,
            color: Theme.colors.white,
          }}
        >
          {index + 1}
        </span>

        <span style={{ fontWeight: 700, marginLeft: 8 }}>{date.label}</span>
      </div>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <span>{date.value}</span>
        <ChevronRight />
      </div>
    </div>
  );
};
