import { createGlobalStyle, css } from "styled-components";

const GlobalStyle = createGlobalStyle(
  ({ theme }) => css`
    button {
      cursor: pointer;
    }
    *:disabled {
      cursor: default;
    }
  `
);

export default GlobalStyle;
