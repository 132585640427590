import * as axios from "axios";
import networkConf from "../../Config/Network";

export const validateVoucherCode = async (code, context, setFetching) => {
  try {
    const response = await axios.post(
      `${networkConf.baseURL}/voucher/validate`,
      { code, context }
    );
    return response.status !== 200 || response.data.valid === false
      ? { valid: false }
      : context === "register"
      ? { valid: true, type: response.data.type }
      : {
          valid: true,
          type: response.data.type,
          parameters: response.data.parameters
        };
  } catch (error) {
    console.error(`Error happened while validating voucher code: ${error}`);
  } finally {
    setFetching && setFetching(false);
  }
};
