import { ReactNode } from "react";
import Error from "../../../Resources/SVG/Error";
import Info from "../../../Resources/SVG/Info";
import Warning from "../../../Resources/SVG/Warning";
import Flex from "../../Layout/Flex";
import Row from "../../Layout/Row";
import { FlexItemProps } from "../../Styled/flexItem";
import { LayoutProps } from "../../Styled/layout";
import { MarginProps } from "../../Styled/margin";
import Text from "../../Typography/Text";

type MessageProps = {
  type?: "info" | "warning" | "error";
  outline?: boolean;
  content: ReactNode;
} & LayoutProps &
  FlexItemProps &
  MarginProps;

const Message = ({
  type = "info",
  outline = false,
  content,
  ...props
}: MessageProps) => {
  return (
    <Row
      border={outline ? "1pxSolid" : undefined}
      borderColor={
        (type === "info" && "greyDark") ||
        (type === "warning" && "orangeDark") ||
        (type === "error" && "red") ||
        undefined
      }
      borderRadius="md"
      py="8px"
      pl="8px"
      pr="16px"
      gap="12px"
      backgroundColor="beigeMedium"
      {...props}
    >
      <Flex flexShrink={0} m="-4px">
        {(type === "info" && <Info color="greyDark" />) ||
          (type === "warning" && <Warning color="orangeDark" />) ||
          (type === "error" && <Error color="red" />)}
      </Flex>
      <Text whiteSpace="pre-line">{content}</Text>
    </Row>
  );
};

export default Message;
