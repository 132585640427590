import { useTranslation } from "react-i18next";
import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import Images from "../../../../Core/Images";

import RoundedButton from "../../../../Common/RoundedButton";
import Close from "../../../../Resources/SVG/Close";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Theme from "../../../../Core/Theme";

const useStyles = makeStyles((theme) => ({
  modalSmall: {
    position: "absolute",
    width: 407,
    height: 580,
    left: "calc(50% - 203px)",
    top: "calc(50% - 290px)",
    borderRadius: 8,
    backgroundColor: Theme.colors.beigeLight,

    "& h3": {
      margin: "16px 0 8px 0",
    },
    "& p": {
      margin: "8px 0 0 0",
    },
  },
  modalContent: {
    padding: "0 32px 24px",
  },
}));

const CreatePlanModal = ({ open, onConfirm }) => {
  const { t } = useTranslation("gardening");
  const classes = useStyles();
  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        return !(reason === "backdropClick" || reason === "backdropClick");
      }}
    >
      <div className={classes.modalSmall}>
        <div className={classes.modalContent}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: 16,
                alignItems: "flex-start",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  paddingTop: 16,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h2>{t("CreatePlanModal.headline")}</h2>

                <img
                  style={{ paddingLeft: 60 }}
                  src={Images.images.createPlan}
                />
              </div>

              <span style={{ paddingTop: 24 }}>
                <IconButton onClick={onConfirm}>
                  <Close size={"sm"} />
                </IconButton>
              </span>
            </div>

            <p style={{ justifySelf: "center", padding: "16px 0" }}>
              {t("CreatePlanModal.description")}
            </p>
            <div style={{ display: "flex", gap: 8, padding: "16px 0" }}>
              <img src={Images.images.tileTodos} />
              <img src={Images.images.tileSeason} />
            </div>
            <div
              style={{
                paddingTop: 16,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <RoundedButton
                style={{ padding: "16px 32px" }}
                title={t("CreatePlanModal.button")}
                onClick={onConfirm}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default CreatePlanModal;
