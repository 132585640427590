import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";
import typography, { TypographyProps } from "../../Styled/typography";
import { ReactNode } from "react";
import theme from "../../../Theme";

export type LinkProps = {
  to: string;
  inline?: boolean;
  children?: ReactNode;
  onClick?: (event: any) => void;
  target?: string;
  color?: keyof typeof theme.colors;
} & TypographyProps;
const StyledLink = styled(RouterLink)<LinkProps>`
  display: ${({ inline }) => inline && "inline"};
  color: ${({ color }) => color && theme.colors[color]};
  &:hover {
    cursor: pointer;
    text-underline: currentColor;
    text-decoration: underline;
  }
  ${typography};
`;

const Link = ({ inline = false, children, ...props }: LinkProps) => {
  return (
    <StyledLink inline={inline} {...props}>
      {children}
    </StyledLink>
  );
};

export default Link;
