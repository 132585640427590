export const SET_BODY_BACKGROUND_COLOR = "SET_BODY_BACKGROUND_COLOR";
export const SET_IS_WEBSITE = "SET_IS_WEBSITE";

export const setBodyBackgroundColor = (color) => ({
  type: SET_BODY_BACKGROUND_COLOR,
  color,
});

export const setIsWebsite = () => ({
  type: SET_IS_WEBSITE,
});
