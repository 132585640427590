import Property from "./Property";
import CropCategory from "./CropCategory";

export type PlantFamily = {
  id: number;
  dateCreated: string;
  name: string;
};

export type CropPest = {
  id: number;
  pest: {
    id: number;
    name: string;
  };
};

export type CropProperty = {
  name: string;
  group?: string;
};

export type CropDisease = {
  id: number;
  disease: {
    id: number;
    name: string;
  };
};

export type CropNeighbour = {
  id: Crop["id"];
  name: string;
  identifier?: string;
};

export enum CropActionType {
  Düngen = "Düngen",
  Jäten = "Jäten",
  Fraßschutz = "Fraßschutz",
  Ernten = "Ernten",
  Vorziehen = "Vorziehen",
  Einpflanzen = "Einpflanzen",
  Anbinden = "Anbinden",
  Hygiene = "Hygiene",
  Pflücken = "Pflücken",
  Pikieren = "Pikieren",
  Ausgeizen = "Ausgeizen",
  Abhärten = "Abhärten",
  Abdecken = "Abdecken",
  Mulchen = "Mulchen",
  Anhäufeln = "Anhäufeln",
  Zurückschneiden = "Zurückschneiden",
  Ausdünnen = "Ausdünnen",
  Erziehen = "Erziehen",
  Hacken = "Hacken",
  Stützen = "Stützen",
  Direktsaat = "Direktsaat",
}

export type CropActionAdditionalData = {
  fertilizer?: string;
};

export type CropAction = {
  id?: number;
  cropId?: number;
  dateCreated?: string;
  title: string;
  type?: CropActionType;
  text: string;
  notificationText: string;
  daysStart: number;
  daysEnd: number;
  repeatDays: number;
  additionalData?: CropActionAdditionalData;
};

export type CompatibilityMap = {
  [key: string]: number;
};

export type Season = {
  start: number;
  end: number;
};

export enum CropType {
  PARENT_CROP = "parentCrop",
  VARIETY = "variety",
}

type Crop = {
  id: number;
  identifier?: string;
  name?: string;
  latinName?: string;
  description?: string;
  shortDescription?: string;
  origin?: string;
  growingTipps?: string[];
  germinationDuration?: object;
  germinationSeason?: Season;
  growthDuration?: object;
  growthSeason?: Season;
  harvestDuration?: number;
  harvestSeason?: Season;
  hasPrecultivation?: boolean;
  precultivationDuration?: object;
  precultivationSeason?: Season;
  harvestAmount?: number;
  nutrientDemand?: string;
  lightingDemand?: string;
  waterDemand?: string;
  groundType?: string;
  coldResistant?: boolean;
  seedingDepth?: object;
  seedingDistance?: object;
  rowDistance?: object;
  spread?: string;
  plantInRows?: boolean;
  compatibility?: CompatibilityMap;
  f1Hybrid?: boolean;
  isShared?: boolean;
  popularity?: number;
  isApproved?: boolean;
  cropDisease?: CropDisease[];
  cropPest?: CropPest[];
  cropcategory?: CropCategory;
  plantfamily?: PlantFamily;
  seasons?: string[];
  images?: object;
  imageUrl?: string;
  nonHybridSeed?: boolean;
  isSeedAvailable?: boolean;
  isSeedOrganic?: boolean;
  rating?: number;
  ratingCount?: number;
  isIncomplete?: boolean;
  canBeUsedForPlanning?: boolean;
  synonyms?: string[];
  ratedByUser?: boolean;
  editAllowed?: boolean;
  hasOpenChanges?: boolean;
  hasOpenChangesByMe?: boolean;
  isFavorite?: boolean;
  isMine?: boolean;
  forApproval?: boolean;
  isWaitingForApproval?: boolean;
  communityCropCreator?: string;
  properties?: CropProperty[];
  goodNeighbours?: CropNeighbour[];
  badNeighbours?: CropNeighbour[];
  parentCrop?: {
    name: string;
    id: number;
    dateCreated?: string;
    identifier?: string;
  };
  shop?: {
    available: boolean;
    name?: string;
    url?: string;
    price?: string;
  };
  varieties?: Crop[];
  dateCreated?: string;
  alias?: string;
  cropaction?: CropAction[];
};

export type CropWithVarieties = Crop & {
  varieties?: Crop[];
};

export default Crop;
