import React from "react";
import PropTypes from "prop-types";
import { Button, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import Theme from "../../Core/Theme";
import GlobalStyles from "../../Core/GlobalStyles";

const useStyles = makeStyles(() => ({
  root: {
    height: 40,
    borderRadius: 20,
    textTransform: "none",
    fontWeight: 700,
    minWidth: 140,
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
  },
  primary: {
    backgroundColor: Theme.colors.greenApp,
    color: Theme.colors.white,
    "&:hover": {
      opacity: 0.9,
    },
    fontFamily: Theme.fonts.karla,
    "&.Mui-disabled": {
      backgroundColor: Theme.colors.greenAppInactive,
      color: Theme.colors.white,
    },
  },
  secondary: {
    backgroundColor: Theme.colors.blueDark,
    color: Theme.white,
    "&:hover": {
      backgroundColor: Theme.colors.blueDark,
    },
  },
  alert: {
    backgroundColor: "transparent",
    borderColor: Theme.colors.red,
    borderWidth: 2,
    color: Theme.colors.red,
    borderStyle: "solid",
    "&:hover": { backgroundColor: "transparent" },
  },
  transparent: {
    backgroundColor: "transparent",
    color: Theme.colors.black,
    "&:hover": { backgroundColor: "transparent" },
  },
  magic: {
    ...GlobalStyles.magicGradient,
  },
  outline: {
    backgroundColor: "transparent",
    borderColor: Theme.colors.greenApp,
    borderWidth: 2,
    borderStyle: "solid",
    color: Theme.colors.greenApp,
    "&:hover": { backgroundColor: "transparent" },
  },
}));

const RoundedButton = ({
  title, //for backwards compatibilty
  label,
  icon,
  className,
  style,
  color,
  onClick,
  disabled,
  variant,
  testId,
}) => {
  const classes = useStyles();

  if (!variant && color) {
    variant = color;
  }
  if (!label && title) {
    label = title;
  }

  return (
    <Button
      className={clsx(
        classes.root,
        className,
        variant === "secondary"
          ? classes.secondary
          : variant === "magic"
          ? classes.magic
          : variant === "transparent"
          ? classes.transparent
          : variant === "outline"
          ? classes.outline
          : variant === "alert"
          ? classes.alert
          : classes.primary
      )}
      color={color}
      style={style}
      variant="contained"
      disabled={disabled}
      onClick={onClick}
      data-testid={testId}
    >
      {icon && <i className={icon} />}
      {label}
    </Button>
  );
};

RoundedButton.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.object,
  variant: PropTypes.oneOf([
    "primary",
    "secondary",
    "transparent",
    "magic",
    "outline",
    "alert",
  ]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

RoundedButton.defaultProps = {
  color: "primary",
  disabled: false,
};

export default RoundedButton;
