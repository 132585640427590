import React, { useState } from "react";
import { Flex } from "../../index";

type ToggleButtonGroupProps = {
  value: any;
  children: React.ReactNode;
  onChange: (value: any) => void;
  vertical?: boolean;
  className?: string;
};
const ToggleButtonGroup = ({
  value,
  children,
  onChange,
  vertical = true,
  className = "",
}: ToggleButtonGroupProps) => {
  const [val, setVal] = useState(value);
  const handleOnChange = (v: any) => {
    setVal(v);
    onChange(v);
  };
  const augmentedChildren = React.Children.map(children, (child, idx) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        active: val === child.props.value,
        onChange: () => handleOnChange(child.props.value),
        onClick: () => handleOnChange(child.props.value),
      });
    } else {
      return child;
    }
  });
  return (
    <Flex className={className} flexDirection={vertical ? "column" : "row"}>
      {augmentedChildren}
    </Flex>
  );
};
export default ToggleButtonGroup;
