import { isEqual } from "lodash";
import { useEffect, useState } from "react";

const useControlledState = <T,>(
  controlState: T,
  onChange?: (state: T) => void
) => {
  const [state, setState] = useState<T>(controlState);

  useEffect(() => {
    onChange?.(state);
  }, [state]);

  useEffect(() => {
    setState((state) => (isEqual(controlState, state) ? state : controlState));
  }, [controlState]);

  return [state, setState] as const;
};

export default useControlledState;
