import theme from "../../Theme";
import IconProps from "../../Types/IconProps";

export default ({ size = "md", color = "currentColor" }: IconProps) => (
  <svg
    width={size === "sm" ? "24px" : "32px"}
    height={size === "sm" ? "24px" : "32px"}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8644 5.71745C10.3603 3.58318 4.16809 6.31503 2.03382 11.8192C1.98873 11.9355 1.98873 12.0644 2.03382 12.1807C3.11992 14.9817 5.33457 17.1964 8.13556 18.2825C13.6397 20.4167 19.8319 17.6849 21.9662 12.1807C22.0113 12.0644 22.0113 11.9355 21.9662 11.8192C20.8801 9.01821 18.6654 6.80355 15.8644 5.71745ZM20.9617 12C18.9629 16.868 13.4257 19.2612 8.49709 17.3501C6.01868 16.3891 4.04671 14.4533 3.03835 11.9999C5.03714 7.13194 10.5743 4.73873 15.5029 6.64982C17.9813 7.61083 19.9533 9.54662 20.9617 12ZM12 16C9.79086 16 8 14.2091 8 12C8 9.79082 9.79086 7.99996 12 7.99996C14.2091 7.99996 16 9.79082 16 12C16 14.2091 14.2091 16 12 16ZM15 12C15 13.6568 13.6569 15 12 15C10.3431 15 9 13.6568 9 12C9 10.3431 10.3431 8.99996 12 8.99996C13.6569 8.99996 15 10.3431 15 12Z"
      fill={theme.colors[color]}
    />
  </svg>
);
