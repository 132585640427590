import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Theme from "../../../Core/Theme";
import { SeasonCalendar } from "./Components/SeasonCalendar";
import moment from "moment";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { usableCropsSelector } from "../../../Selectors/Crop";
import {
  filterCurrentYearPlants,
  getClosestPlantsFromDuplicates,
} from "./seasonHelpers";
import { SeasonalPlantItem } from "./Components/SeasonalPlantItem";
import { useBeforeunload } from "react-beforeunload";
import { PlanMode } from "../../Planning/PatchPlan/Consts";
import PlanControls from "../../Planning/PatchPlan/PlanControls";

const useStyles = makeStyles((theme) => ({
  root: {
    userSelect: "none",
    position: "relative",
    width: "100%",
    height: "100%",
  },
  scrollContainer: {},
}));

const SeasonalOverview = () => {
  const [filteredPatchData, setFilteredPatchData] = useState([]);
  const [cropsVarietyMap, setCropsVarietyMap] = useState([]);
  const { year, planData, planSaved } = useSelector((state) => state.Planning);
  const { crops } = useSelector(usableCropsSelector);

  const classes = useStyles();

  useBeforeunload((event) => {
    if (!planSaved) {
      event.preventDefault();
    }
  });
  //Filter all plants of currentYear
  useEffect(() => {
    if (!planData || !planData.patches || planData.patches.length < 1) {
      return;
    }

    const filteredPatches = planData.patches.map((patch) => {
      if (!patch.plants || patch.plants.length < 1) {
        return { ...patch };
      }

      const currenYearPlants = patch.plants.filter(
        filterCurrentYearPlants(year)
      );

      if (currenYearPlants.length < 1) {
        return { ...patch, plants: currenYearPlants };
      }

      const uniquePlants = [
        ...new Map(
          currenYearPlants.map((plant) => [plant["crop"]["varietyId"], plant])
        ).values(),
      ];

      //Go through all duplicates and check if those closePlants are closer
      const withUpdatedClosestPlants = uniquePlants.map(
        (refPlant, index, plants) => {
          return getClosestPlantsFromDuplicates(
            refPlant,
            index,
            currenYearPlants
          );
        }
      );

      return { ...patch, plants: withUpdatedClosestPlants };
    });

    if (cropsVarietyMap) {
      filteredPatches.forEach((patch) => {
        patch.plants.forEach((plant) => {
          plant.crop = {
            ...plant.crop,
            varietyName: cropsVarietyMap[plant.crop.varietyId],
          };
        });
      });
    }

    setFilteredPatchData(filteredPatches);
  }, [year, planData, cropsVarietyMap]);

  useEffect(() => {
    setCropsVarietyMap(
      crops.reduce((map, c) => {
        return {
          ...map,
          ...c.varieties?.reduce((vars, v) => {
            return { ...vars, [v.id]: v.name };
          }, {}),
        };
      }, {})
    );
  }, [crops]);

  return (
    (planData && (
      <div className={classes.root}>
        <PlanControls mode={PlanMode.OVERVIEW} />
        <SeasonCalendar
          fullscreen={true}
          range={{
            start: moment(year, "YYYY").startOf("year"),
            end: moment(year, "YYYY").endOf("year"),
          }}
        >
          <SeasonalPatches patches={filteredPatchData} />
        </SeasonCalendar>
      </div>
    )) ||
    null
  );
};
export default SeasonalOverview;

const SeasonalPatches = ({ patches }) => {
  return (
    <div
      className={"SeasonalPatches"}
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,

        paddingTop: 100,

        overflowY: "scroll",
      }}
    >
      <div style={{ position: "relative", height: "auto" }}>
        {patches.map((patch, i) => (
          <div
            key={"patch-" + i}
            style={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
              borderBottom: `1px solid ${Theme.colors.beigeDarkest}`,
              padding: "0 24px 0",
            }}
          >
            <p
              style={{
                userSelect: "none",
                color: Theme.colors.black,
                fontSize: Theme.sizes.h4,
                fontWeight: 700,
              }}
            >
              {patch.name}
            </p>
            {patch.plants.map((plant, i) => (
              <SeasonalPlantItem key={"plant-" + i} plant={plant} />
            ))}
            <div style={{ height: 16 }} />
            {patches.length - 1 === i && <div style={{ height: 16 }} />}
          </div>
        ))}
      </div>
    </div>
  );
};
