type CropCategory = {
  id: number;
  name: string;
  dateCreated: string;
  parent?: CropCategory;
};

export class ParentCropCategoryWithCategories {
  parent: CropCategory;
  categories: CropCategory[];
  constructor(parent: CropCategory, categories: CropCategory[]) {
    this.parent = parent;
    this.categories = categories;
  }
  push(category: CropCategory) {
    this.categories.push(category);
  }
}
export default CropCategory;
