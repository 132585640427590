import React, { useEffect, useRef, useState } from "react";

export default {
  animations: {
    createPlan: [
      "/ani/create-plan-1.png",
      "/ani/create-plan-2.png",
      "/ani/create-plan-3.png",
      "/ani/create-plan-4.png",
      "/ani/create-plan-5.png",
    ],
  },
  icons: {
    plan: {
      shape: {
        rectangle: "/img/icons/shape-rectangle.svg",
        triangle: "/img/icons/shape-triangle.svg",
        circle: "/img/icons/shape-circle.svg",
        tree: "/img/icons/shape-tree.svg",
        bush: "/img/icons/shape-bush.svg",
        path: "/img/icons/shape-path.svg",
      },
      patchAndShapeControls: {
        copy: "/img/icons/copy-black.svg",
        edit: "/img/icons/pencil-currentColor.svg",
        remove: "/img/icons/trash-black.svg",
        close: "/img/icons/checkmark-white.svg",
        minus: "/img/icons/minus-in-circle-green.svg",
        plus: "/img/icons/plus-in-circle-green.svg",
      },
    },

    filterIcons: {
      chevronDownGreen: "/img/icons/filterIcons/chevron-down-green.svg",
      close: "/img/icons/filterIcons/close.svg",
      frostProof: "/img/icons/filterIcons/frost-proof.svg",
      lightRequirements: "/img/icons/filterIcons/light-requirements.svg",
      nutrientRequirements: "/img/icons/filterIcons/nutrient-requirements.svg",
      waterRequirements: "/img/icons/filterIcons/water-requirements.svg",
    },
    social: {
      facebook: "/img/icons/social_facebook.png",
      ig: "/img/icons/social_ig.png",
      instagram: "/img/icons/social_instagram.png",
      link: "/img/icons/social_link.png",
      messages: "/img/icons/social_messages.png",
      pi: "/img/icons/social_pi.png",
      rightArrow: "/img/icons/social_right_arrow.png",
      sc: "/img/icons/social_sc.png",
      shareTeaser: "/img/icons/social_share_teaser.png",
      share: "/img/icons/social_share.png",
      tt: "/img/icons/social_tt.png",
      web: "/img/icons/social_web.png",
      whatsapp: "/img/icons/social_whatsapp.png",
      yt: "/img/icons/social_yt.png",
      signInApple: "/img/icons/social-signin-apple-48px.png",
      signInFacebook: "/img/icons//social-signin-facebook-48px.png",
      signInGoogle: "/img/icons/social-signin-google-48px.png",
    },
    paywall: {
      proAccount: "/img/pro-account.png",
      plusAccount: "/img/plus-account.png",
    },
    location: "/img/icons/location.png",
    localConditions: "/img/icons/local-conditions.png",
    ackerheldenLogo: "/img/icons/ackerhelden_logo_white.png",
    alphabeetVariety: "/img/icons/alphabeet-variety.svg",
    approvedCrop: "/img/icons/approved-crop.svg",
    attention: "/img/icons/attention.png",
    arrowLeft: "/img/icons/arrow_left.png",
    arrowRight: "/img/icons/arrow_right.png",
    betaBadge: "/img/icons/beta-badge.svg",
    book: "/img/icons/book.svg",
    bookmarkGreen: "/img/icons/bookmark-green.png",
    bookmark: "/img/icons/bookmark.png",
    bookmarked: "/img/icons/bookmarked.png",
    bush: "/img/icons/bush.png",
    buttonCheckmark: "/img/icons/button-checkmark.svg",
    camera: "/img/icons/camera.png",
    categories: "/img/icons/categories.svg",
    checkProposals: "/img/icons/check-proposals.png",
    checkSuggestionsBlue: "/img/icons/check-suggestions-blue.svg",
    checkSuggestions: "/img/icons/check-suggestions.svg",
    checkmarkBlack: "/img/icons/checkmark-black.png",
    checkmarkCircle: "/img/icons/checkmark-circle.svg",
    chevronCircle: "/img/icons/chevron-circle.svg",
    chevronDownCircleHover: "/img/icons/chevron-down-with-circle-hover.png",
    chevronDownCircle: "/img/icons/chevron-down-with-circle.png",
    chevronLeftRed: "/img/icons/chevron-left-red.svg",
    chevronRightGreen: "/img/icons/chevron-right-green.svg",
    chevronRight: "/img/icons/chevron-right.svg",
    circleButtonCloseRed: "/img/icons/circle-button-close-red.svg",
    coldResistant: "/img/icons/cold-resistant.svg",
    commentActive: "/img/icons/comment_active.png",
    comment: "/img/icons/comment.png",
    communityVarietySmall: "/img/icons/community-variety-small.svg",
    communityVariety: "/img/icons/community-variety.svg",
    community: "/img/icons/community.svg",
    crown: "/img/icons/crown.svg",
    cultivationStart: "/img/icons/cultivation-start.svg",
    dateSlider: "/img/icons/date_slider.svg",
    datePickerIcon: "/img/icons/datepicker-icon.svg",
    edit: "/img/icons/edit.png",
    f1: "/img/icons/f1.png",
    fromToday: "/img/icons/from_today.png",
    fullVarietyActive: "/img/icons/full-variety-active.svg",
    fullVariety: "/img/icons/full-variety.svg",
    germination: "/img/icons/germination.png",
    growth2: "/img/icons/growth-2.svg",
    growth: "/img/icons/growth.svg",
    harvestStart: "/img/icons/harvest-start.svg",
    heartFilled: "/img/icons/heart-filled.svg",
    heart: "/img/icons/heart.svg",
    iconBadNeighbour: "/img/icons/icon_bad_neighbour.png",
    iconGoodNeighbour: "/img/icons/icon_good_neighbour.png",
    incompleteCropAction: "/img/icons/incomplete-crop-action.png",
    incompleteCropNotice: "/img/icons/incomplete-crop-notice.png",
    incompleteCrop: "/img/icons/incomplete-crop.svg",
    info: "/img/icons/info.svg",
    libraryButtonCrops: "/img/icons/library_button_crops.png",
    libraryButtonDiseases: "/img/icons/library_button_diseases.png",
    libraryButtonPests: "/img/icons/library_button_pests.png",
    libraryPlants: "/img/icons/library_plants.png",
    lightDemandPng: "/img/icons/light_demand.png",
    lightDemand: "/img/icons/lightDemand.svg",
    magicWandCircle: "/img/icons/magic-wand-circle.svg",
    magicWand: "/img/icons/magic-wand.svg",
    mailSupportGreen: "/img/icons/mail-support-green.svg",
    menuChatQuestions: "/img/icons/menu_chat_questions.png",
    menuDiscover: "/img/icons/menu_discover.png",
    menuGrowth: "/img/icons/menu_growth.png",
    menuPlan: "/img/icons/menu_plan.png",
    menuTodo: "/img/icons/menu_todo.png",
    minusCircleGreen: "/img/icons/minus-circle-green.svg",
    moderationBadge: "/img/icons/moderation-badge.svg",
    moderator: "/img/icons/moderator.svg",
    mySeed: "/img/icons/my-seed.png",
    navCommunity: "/img/icons/nav-community.svg",
    neighboursBad: "/img/icons/neighbours_bad.svg",
    neighboursGood: "/img/icons/neighbours_good.svg",
    neighboursNeutral: "/img/icons/neighbours_neutral.svg",
    neighbours: "/img/icons/neighbours.svg",
    newPlant2: "/img/icons/new_plant-2.png",
    newPlant: "/img/icons/new_plant.png",
    nonHybridSeedPng: "/img/icons/non-hybrid_seed.png",
    nonHybridSeed: "/img/icons/non-hybrid_seed.svg",
    nutrientDemandPng: "/img/icons/nutrient_demand.png",
    nutrientDemand: "/img/icons/nutrientDemand.svg",
    paymentMethodsWoPaypal: "/img/icons/payment_methods_wo_paypal.svg",
    paymentMethods: "/img/icons/payment_methods.svg",
    plantSpacingIllustration: "/img/icons/plant-spacing-illustration.svg",
    plantingDate: "/img/icons/planting-date.svg",
    plusIcon: "/img/icons/plus_icon.svg",
    plusCircleGreen: "/img/icons/plus-circle-green.svg",
    plusCircle: "/img/icons/plus-circle.svg",
    prepone: "/img/icons/prepone.png",
    rainLarger: "/img/icons/rain-larger.png",
    rain: "/img/icons/rain.png",
    rectangular: "/img/icons/rectangular.png",
    round: "/img/icons/round.png",
    rowDistanceIllustration: "/img/icons/row-distance-illustration.svg",
    rowDistance: "/img/icons/rowDistance.svg",
    scoreMinus: "/img/icons/score-minus.svg",
    scoreNegativeSmall: "/img/icons/score-negative-small.svg",
    scoreNeutralSmall: "/img/icons/score-neutral-small.svg",
    scoreNeutral: "/img/icons/score-neutral.svg",
    scorePlus: "/img/icons/score-plus.svg",
    scorePositiveSmall: "/img/icons/score-positive-small.svg",
    scribbleBadNeighbours: "/img/icons/scribble-bad-neighbours.png",
    scribbleDiseases: "/img/icons/scribble-diseases.png",
    scribbleGoodNeighbours: "/img/icons/scribble-good-neighbours.png",
    scribbleLightDemand: "/img/icons/scribble-light-demand.png",
    scribbleNutrientDemand: "/img/icons/scribble-nutrient-demand.png",
    scribblePests: "/img/icons/scribble-pests.png",
    scribbleRowDistance: "/img/icons/scribble-row-distance.svg",
    scribbleSeedingDepth: "/img/icons/scribble-seeding-depth.svg",
    scribbleSeedingDistance: "/img/icons/scribble-seeding-distance.svg",
    scribbleWaterDemand: "/img/icons/scribble-water-demand.png",
    season: "/img/icons/season.svg",
    seedingDepth: "/img/icons/seedingDepth.svg",
    seedingDistance: "/img/icons/seedingDistance.svg",
    sendGreen: "/img/icons/send_green.svg",
    send: "/img/icons/send.png",
    shadingSemiShadedWhite: "/img/icons/shading_semi-shaded_white.png",
    shadingSemiShaded: "/img/icons/shading_semi-shaded.png",
    shadingShadedWhite: "/img/icons/shading_shaded_white.png",
    shadingShaded: "/img/icons/shading_shaded.png",
    shadingSunnyWhite: "/img/icons/shading_sunny_white.png",
    shadingSunny: "/img/icons/shading_sunny.png",
    shopPng: "/img/icons/shop.png",
    shop: "/img/icons/shop.svg",
    shoppingCart: "/img/icons/shopping-cart.svg",
    snowflake: "/img/icons/snowflake.svg",
    sortAlphabeticDown: "/img/icons/sort-alphabetic-down.svg",
    sowingDate: "/img/icons/sowing-date.svg",
    spadeLarger2: "/img/icons/spade-larger-2.png",
    spadeLarger: "/img/icons/spade-larger.png",
    spade: "/img/icons/spade.png",
    starFilled: "/img/icons/star_filled.svg",
    starGrey: "/img/icons/star_grey.svg",
    starColoredSmall: "/img/icons/star-colored-small.svg",
    starGreySmall: "/img/icons/star-grey-small.svg",
    startPage: "/img/icons/start-page.svg",
    strawberry: "/img/icons/strawberry.png",
    sunColoredFaded: "/img/icons/sun-colored-faded.png",
    sunColored: "/img/icons/sun-colored.png",
    testedVariety: "/img/icons/tested-variety.svg",
    thumbsDownLarge: "/img/icons/thumbs-down-2.svg",
    thumbsUpLarge: "/img/icons/thumbs-up-2.svg",
    thumbsUpGreen: "/img/icons/thumbs-up-green.svg",
    thumbsUp: "/img/icons/thumbs-up.svg",
    todo: "/img/icons/todo.svg",
    trash: "/img/icons/trash.svg",
    tree: "/img/icons/tree.svg",
    trustedUser: "/img/icons/trusted-user.svg",
    refreshDouble: "/img/icons/refresh-double.svg",
    checkmarkWhite: "/img/icons/checkmark-white.svg",
    menuBtnDiscover: "/img/icons/menu-btn-discover.svg",
    menuBtnGardening: "/img/icons/menu-btn-gardening.svg",
    menuBtnPlan: "/img/icons/menu-btn-plan.svg",
    menuBtnSettings: "/img/icons/menu-btn-settings.svg",
    menuBtnShop: "/img/icons/menu-btn-shop.svg",
    menuBtnCommunity: "/img/icons/community.svg",
    menuBtnUserprofile: "/img/icons/user-profile.svg",
    undo: "/img/icons/undo.svg",
    redo: "/img/icons/redo.svg",
    undoDisabled: "/img/icons/undo-disabled.svg",
    redoDisabled: "/img/icons/redo-disabled.svg",
    magnifyingGlass: "/img/icons/magnifying-glass.svg",
    chevronLeftGreen: "/img/icons/chevron-left-green.svg",
    chevronUp: "/img/icons/chevron-up.svg",
    chevronDown: "/img/icons/chevron-down.svg",
    selectedCircleGreen: "/img/icons/selected-circle-green.svg",
    crossInCircleGreySolid: "/img/icons/cross-in-circle-grey-solid.svg",
    umbrellaClosedWhite: "/img/icons/umbrella_closed_white.png",
    umbrellaClosed: "/img/icons/umbrella_closed.png",
    umbrellaHalf: "/img/icons/umbrella_half.png",
    umbrella: "/img/icons/umbrella.png",
    updateImage: "/img/icons/update-image.svg",
    upgradeTomato: "/img/icons/upgrade-tomato.png",
    userProfile: "/img/icons/user-profile.svg",
    waterDemandPng: "/img/icons/water_demand.png",
    waterDemand: "/img/icons/waterDemand.svg",
    way: "/img/icons/way.png",
    wholeYear: "/img/icons/whole_year.png",
    layerStack: "/img/icons/layer-stack.svg",
    aubergine: "/img/icons/aubergine.png",
    saladLeafs: "/img/icons/salad-leafs.png",
    redBeet: "/img/icons/red-beet.png",
  },
  images: {
    magicWarningNoFreeFields: "/img/magicWarning_freeFields.png",
    magicWarningNoPlants: "/img/magicWarning_noPlants.png",
    magician: "/img/magician.png",
    magicianWithHat: "/img/magician-with-hat.png",
    approvedCrop: "/img/approved-crop.svg",
    locationIsLoading: "/img/friendly-pumpkin-with-dots.png",
    createPlan: "/img/pumpkinHappy.png",
    tileSeason: "/img/tileSeason.png",
    tileTodos: "/img/tileTodos.png",
    drawGardenInfo: "/img/draw-garden-info.png",
    frydLogoWithClaim: "/img/fryd-claim.png",
    beeCombo: "/img/bee-combo.png",
    bugs: "/img/bugs.png",
    buttonAppStore: "/img/button_app_store.png",
    buttonGooglePlay: "/img/button_google_play.png",
    carrots: "/img/carrots.png",
    clearedPatches: "/img/cleared-patches.png",
    clouds: "/img/clouds.png",
    cloudsSad: "/img/clouds-sad.png",
    communityType: "/img/community-type.svg",
    cropCardPlaceholder: "/img/crop-card-placeholder.png",
    elementsBlue: "/img/elements-blue.png",
    emptyPatch: "/img/empty-patch.png",
    emptyPatches: "/img/empty-patches.svg",
    featureCropRotation: "/img/feature-croprotation.png",
    featureMagicWand: "/img/feature-magic.png",
    featureTodos: "/img/feature-todos.png",
    fullPatches: "/img/full-patches.png",
    gardenTypeBalcony: "/img/gardentype-balcony.png",
    gardenType: "/img/gardentype-large.png",
    gardenTypeSmall: "/img/gardentype-small.png",
    grid20: "/img/grid-20.png",
    grid40: "/img/grid-40.png",
    group: "/img/group.png",
    incompleteCrop: "/img/incomplete-crop.svg",
    itsMagic: "/img/its-magic.png",
    kidAndManWithVeggies: "/img/kid-and-man-with-veggies.png",
    line1: "/img/line1.png",
    line2: "/img/line2.png",
    line3: "/img/line3.png",
    logoHorizontal: "/img/logo_horizontal.png",
    logoWhite: "/img/logo_white.png",
    magicWand: "/img/magic-wand.png",
    betaSeasons: "/img/beta-seasons.png",
    betaDataloss: "/img/beta-dataloss.png",
    modalIllustration2: "/img/modal-illustration-2.png",
    modalIllustration3: "/img/modal-illustration-3.png",
    ownCropGroup: "/img/ownCropGroup.png",
    pattern: "/img/pattern.png",
    plantSpacing: "/img/plant-spacing.png",
    planApp: "/img/plan-app.png",
    planBrowser: "/img/plan-browser.png",
    precultivationPatch: "/img/precultivation-patch.jpg",
    profileHeaderBackground: "/img/profile_header_background.png",
    profileImagePlaceholder: "/img/profile_image_placeholder.png",
    ratingEmpty: "/img/rating-empty.svg",
    ratingFilled: "/img/rating-filled.svg",
    registerFree: "/img/register-free.png",
    registerTrial: "/img/register-trial.png",
    rowDistance: "/img/row-distance.png",
    sadVegetables: "/img/sad-vegetables.png",
    season1: "/img/season-1.png",
    season2: "/img/season-2.png",
    season3: "/img/season-3.png",
    seedAreaPattern: "/img/seed-area-pattern.png",
    socialApple: "/img/social_apple.png",
    socialFacebook: "/img/social_facebook.png",
    socialGoogle: "/img/social_google.png",
    splashLogo: "/img/splash_logo.png",
    trialWaveBg: "/img/trial-wave-bg.png",
    verticalLine: "/img/vertical-line.svg",
    warningFrost: "/img/warning-frost.png",
    wateringCan: "/img/watering-can.png",
    wateringSpilled: "/img/watering-spilled.png",
    womanCarrot: "/img/woman-carrot.png",
    x1: "/img/x1.png",
    x2: "/img/x2.png",
    x3: "/img/x3.png",
    x4: "/img/x4.png",
    nonHybridSeedInfo: "/img/non-hybrid-seed-info.png",
    f1HybridSeedInfo: "/img/f1-hybrid-seed-info.png",
    libraryCropsCloudList: "/img/library-crops-cloud.png",
    approvedVarietyInfo: "/img/approved-variety-info.png",
    incompleteVarietyInfo: "/img/incomplete-variety-info.png",
    frydOnDevices: "/img/fryd-on-devices.png",
  },
  arrows: {
    emptyPatch: "/img/arrows/empty-patch-arrow.svg",
    emptyPatchDown: "/img/arrows/empty-patch-arrow-down.svg",
  },
  todos: {
    // abdecken
    cover: "/img/icons/todo/cover.png",

    // anzucht
    propagation: "/img/icons/todo/propagation.png",

    // setzlingKaufen
    buySeedling: "/img/icons/todo/buy-seedling.png",

    // direktsaat
    directSeeding: "/img/icons/todo/direct-seeding.png",

    // anhaeufeln
    earthUp: "/img/icons/todo/earth-up.png",

    // duengen
    fertilise: "/img/icons/todo/fertilise.png",

    // abhaerten
    harden: "/img/icons/todo/harden.png",

    // ernten
    harvest: "/img/icons/todo/harvest.png",

    hygiene: "/img/icons/todo/hygiene.png",

    // erziehen
    nurture: "/img/icons/todo/nurture.png",

    // ausgeizen
    pinchOff: "/img/icons/todo/pinch-off.png",

    // einpflanzen
    plant: "/img/icons/todo/plant.png",

    // pfluecken
    pluck: "/img/icons/todo/pluck.png",

    // pikieren
    prick: "/img/icons/todo/prick.png",

    // giessen
    water: "/img/icons/todo/water.png",

    // jaeten
    weed: "/img/icons/todo/weed.png",
  },
  features: {
    features1: "/img/features/features-1.png",
    features2: "/img/features/features-2.png",
    features3: "/img/features/features-3.png",
    features4: "/img/features/features-4.png",
  },
  plans: {
    Anfänger: "/img/plans/Anfänger.jpg",
    Familie: "/img/plans/Familie.jpg",
    favorites: "/img/plans/favorites.jpg",
    Heimisch: "/img/plans/Heimisch.jpg",
    Mediterran: "/img/plans/Mediterran.jpg",
  },
  scribble: {
    root: "/img/scribble/",
    accountFree: "/img/scribble/account-free.png",
    accountPro: "/img/scribble/account-pro.png",
    badNeighbours: "/img/scribble/bad-neighbours.png",
    beeCombo: "/img/scribble/bee-combo.png",
    bee: "/img/scribble/bee.png",
    blobLarge1: "/img/scribble/blob-large-1.svg",
    blobLarge2: "/img/scribble/blob-large-2.svg",
    butterflyRed: "/img/scribble/butterfly-red.png",
    butterfly: "/img/scribble/butterfly.png",
    elementsBlue: "/img/scribble/elements-blue.png",
    elementsRose: "/img/scribble/elements-rose.png",
    emptyList: "/img/scribble/empty_list.png",
    flowerBlue: "/img/scribble/flower-blue.png",
    flowerYellow: "/img/scribble/flower-yellow.png",
    goodNeighbours: "/img/scribble/good-neighbours.png",
    groundtypeHeavyShovel: "/img/scribble/groundtype-heavy-shovel.png",
    groundtypeLightShovel: "/img/scribble/groundtype-light-shovel.png",
    groundtypeMediumShovel: "/img/scribble/groundtype-medium-shovel.png",
    groupVegWheel: "/img/scribble/group-veg-wheel.png",
    headerBlob: "/img/scribble/header-blob.svg",
    hgOnboardingWelcome: "/img/scribble/Onboarding_welcome.png",
    hgOnboardingGardenType: "/img/scribble/Onboarding_gardenType.png",
    hgOnboardingTopics: "/img/scribble/Onboarding_topics.png",
    hgOnboardingSurvey: "/img/scribble/Onboarding_survey.png",
    hgOnboardingAccountAnlegen: "/img/scribble/Onboarding_Account anlegen.png",
    hgRegistration: "/img/scribble/Registrierung.png",
    hgOnboardingNameAndVoucher: "/img/scribble/Onboarding_nameAndVoucher.png",
    highFiveVegetables: "/img/scribble/high-five-vegetables.png",
    highFiveVegetablesSolo: "/img/scribble/high-five-vegetables-solo.png",
    kiddo: "/img/scribble/kiddo.png",
    leaf: "/img/scribble/leaf.png",
    leaningCarrot: "/img/scribble/leaning-carrot.png",
    manWatering: "/img/scribble/man-watering.png",
    moderationEggplant: "/img/scribble/moderation-eggplant.png",
    planBeginner: "/img/scribble/plan-beginner.png",
    planFamily: "/img/scribble/plan-family.png",
    planMediterrean: "/img/scribble/plan-mediterrean.png",
    planNative: "/img/scribble/plan-native.png",
    rowDistance: "/img/scribble/row-distance.png",
    scribbleOwnCropsFinished: "/img/scribble/scribble-own-crops-finished.png",
    seedingDepth: "/img/scribble/seeding-depth.png",
    seedingDistance: "/img/scribble/seeding-distance.png",
    strawberryWave: "/img/scribble/strawberry-wave.png",
    tool01: "/img/scribble/tool01.png",
    tool02: "/img/scribble/tool02.png",
    tool03: "/img/scribble/tool03.png",
    tools: "/img/scribble/tools.png",
    veggieCombo1: "/img/scribble/veggie-combo1.png",
    veggieCombo2: "/img/scribble/veggie-combo2.png",
    veggiesInLove: "/img/scribble/veggies-in-love.png",
    warning: "/img/scribble/warning.png",
    wateringCombo: "/img/scribble/watering-combo.png",
    watering: "/img/scribble/watering.png",
    welcomeVeggies: "/img/scribble/welcome-veggies.png",
    wheelbarrowCombo: "/img/scribble/wheelbarrow-combo.png",
    womanAndCarrot: "/img/scribble/woman-and-carrot.png",
    womanApp: "/img/scribble/woman-app.png",
    womanHarvest: "/img/scribble/woman-harvest.png",
    womanShovel: "/img/scribble/woman-shovel.png",
    npsGardeners: "/img/scribble/nps-gardeners.png",
    veggieTrashingSatelliteView:
      "/img/scribble/veggie-trashing-satellite-view.png",
  },
};

export const Icon = ({ src, size, alt, style, ...props }) => {
  return (
    <img
      src={src}
      style={{ width: size, height: size, ...style }}
      alt={alt ? alt : ""}
      {...props}
    />
  );
};

export const Animation = ({ files, duration, style }) => {
  const [index, setIndex] = useState(0);
  const timeoutRef = useRef();
  useEffect(() => {
    let i = 0;
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setInterval(() => {
      i++;
      if (i === files.length) {
        i = 0;
      }
      setIndex(i);
    }, duration / files.length);
  }, [duration, files]);

  return (
    <div>
      <img src={files[index]} alt={""} style={style} />
    </div>
  );
};
