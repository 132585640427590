import { cloneElement, ReactElement } from "react";
import styled from "styled-components";
import IconProps from "../../../Types/IconProps";
import Box from "../../Layout/Box";
import Center from "../../Layout/Center";
import { ColorProps } from "../../Styled/color";
import { LayoutProps } from "../../Styled/layout";
import { MarginProps } from "../../Styled/margin";
import { PositionProps } from "../../Styled/position";
import { TypographyProps } from "../../Styled/typography";
import Text from "../../Typography/Text";

type StyledButtonProps = {
  inline?: boolean;
  disabled?: boolean;
};

const StyledButton = styled(Box).attrs(() => ({
  as: "button",
}))<StyledButtonProps>`
  user-select: none;
  display: ${({ inline }) => inline && "inline-block"};
  &:hover {
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    opacity: 0.75;
  }
`;

type TextButtonProps = {
  label?: string;
  fontWeight?: TypographyProps["fontWeight"];
  fontSize?: TypographyProps["fontSize"];
  iconLeft?: ReactElement<IconProps>;
  iconRight?: ReactElement<IconProps>;
  iconSize?: "lg" | "md" | "sm";
  width?: LayoutProps["width"];
  $color?: ColorProps["$color"];
  inline?: boolean;
  onClick?: (event: any) => void;
  disabled?: boolean;
  testId?: string;
} & PositionProps &
  MarginProps;

const TextButton = ({
  label,
  fontWeight = "bold",
  fontSize = "md",
  iconLeft,
  iconRight,
  iconSize = "sm",
  width = "max-content",
  inline = false,
  onClick,
  disabled = false,
  testId = "",
  ...props
}: TextButtonProps) => {
  const iconGap = iconSize === "sm" ? "4px" : "8px";
  return (
    <StyledButton
      inline={inline}
      flexShrink={0}
      as="button"
      draggable={"false"}
      overflow="hidden"
      width={width}
      onClick={onClick}
      disabled={disabled}
      data-testid={testId}
      {...props}
    >
      <Center>
        {iconLeft &&
          cloneElement(iconLeft, { ...iconLeft.props, size: iconSize })}
        <Text
          as="span"
          fontSize={fontSize}
          fontWeight={fontWeight}
          ml={iconLeft && iconGap}
          mr={iconRight && iconGap}
        >
          {label}
        </Text>
        {iconRight &&
          cloneElement(iconRight, { ...iconRight.props, size: iconSize })}
      </Center>
    </StyledButton>
  );
};

export default TextButton;
