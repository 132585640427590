import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import NavigationButton from "./NavigationButton";
import SecondaryNavigationBar from "./SecondaryNavigationBar";

import Routes from "../../Core/Routes";
import Images, { Icon } from "../../Core/Images";
import Theme from "../../Core/Theme";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    color: Theme.colors.white,
    backgroundColor: Theme.colors.aubergineDark,
  },
}));

const backgroundColor = {
  hover: Theme.colors.aubergineMedium,
  active: Theme.colors.aubergineMedium,
};

const NavigationBar = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const classes = useStyles({ matches });
  const { t } = useTranslation("navigation");
  const location = useLocation();

  const btnInfoMap = {
    root: [
      {
        title: t("menuEntry.library.root"),
        routePath: Routes.library.root,
        icon: Images.icons.menuBtnDiscover,
        testId: "menuEntry.library.root",
      },
      {
        title: t("menuEntry.plan.root"),
        routePath: Routes.plan.root,
        icon: Images.icons.menuBtnPlan,
        testId: "menuEntry.plan.root",
      },
      {
        title: t("menuEntry.gardening.root"),
        routePath: Routes.gardening.root,
        icon: Images.icons.menuBtnGardening,
        testId: "menuEntry.gardening.root",
      },
      {
        title: t("menuEntry.community.root"),
        routePath: Routes.community.root,
        icon: Images.icons.menuBtnCommunity,
        testId: "menuEntry.community.root",
      },
      /*{
        title: t("menuEntry.shop.root"),
        routePath: Routes.shop,
        icon: Images.icons.menuBtnShop,
        testId: "menuEntry.shop.root",
      },*/
    ],
    plan: [
      {
        title: t("menuEntry.plan.patches"),
        routePath: Routes.plan.patches,
        testId: "menuEntry.plan.patches",
        exactForActiveState: true,
      },
      {
        title: t("menuEntry.plan.conditions"),
        routePath: Routes.plan.conditions,
        testId: "menuEntry.plan.conditions",
        exactForActiveState: true,
      },
      {
        title: t("menuEntry.plan.planting"),
        routePath: Routes.plan.planting,
        testId: "menuEntry.plan.planting",
        exactForActiveState: false,
      },
    ],
    gardening: [
      {
        title: t("menuEntry.gardening.work"),
        routePath: Routes.gardening.work,
        testId: "menuEntry.gardening.work",
        exactForActiveState: true,
      },
      {
        title: t("menuEntry.gardening.season"),
        routePath: Routes.gardening.season,
        testId: "menuEntry.gardening.season",
        exactForActiveState: false,
      },
    ],
    /*
     * TODO add other button infos
     */
  };

  const getActiveMenuRoot = (path) => {
    if (!path || typeof path !== "string") {
      throw new TypeError(`navigation not supported ${JSON.stringify(path)}`);
    }
    const bits = path.split("/");
    if (bits.length < 2 || bits[0] !== "") {
      throw new TypeError(`navigation not supported: ${path}`);
    }
    return bits[1];
  };

  // todo: re-add this when we add the shop button
  /*const filteredButtons = matches
    ? btnInfoMap.root
    : btnInfoMap.root.slice(0, btnInfoMap.root.length - 1);*/
  const filteredButtons = btnInfoMap.root;

  return (
    <>
      <div className={classes.root}>
        {filteredButtons.map((inf, i) => (
          <NavigationButton
            key={i}
            routePath={inf.routePath}
            exactForActiveState={false}
            backgroundColor={backgroundColor}
            testId={inf.testId}
          >
            <span style={{ marginTop: -4 }}>
              <Icon src={inf.icon} size={32} style={{ alignSelf: "center" }} />
            </span>
            <span
              style={{
                marginTop: 4,
                marginLeft: 8,
                verticalAlign: "middle",
                fontWeight: "bold",
              }}
            >
              {inf.title}
            </span>
          </NavigationButton>
        ))}

        <div style={{ marginLeft: "auto" }} />

        {/*!matches && (
          <NavigationButton
            routePath={btnInfoMap.root[btnInfoMap.root.length - 1].routePath}
            exactForActiveState={false}
            backgroundColor={backgroundColor}
            testId={btnInfoMap.root[btnInfoMap.root.length - 1].testId}
          >
            <span style={{ marginTop: -4 }}>
              <Icon
                src={Images.icons.menuBtnShop}
                size={32}
                style={{ alignSelf: "center" }}
              />
            </span>
          </NavigationButton>
        )*/}

        {/*<NavigationButton
          routePath={Routes.settings}
          exactForActiveState={false}
          backgroundColor={backgroundColor}
          testId={"menuBtn.settings.root"}
        >
          <span style={{ marginTop: -4 }}>
            <Icon
              src={Images.icons.menuBtnSettings}
              size={32}
              style={{ alignSelf: "center" }}
            />
          </span>
        </NavigationButton>*/}

        <NavigationButton
          routePath={Routes.profile}
          exactForActiveState={false}
          backgroundColor={backgroundColor}
          testId={"menuBtn.profile.root"}
        >
          <span style={{ marginTop: -4 }}>
            <Icon
              src={Images.icons.menuBtnUserprofile}
              size={32}
              style={{ alignSelf: "center" }}
            />
          </span>
        </NavigationButton>
      </div>
      {btnInfoMap[getActiveMenuRoot(location.pathname)] !== undefined && (
        <SecondaryNavigationBar
          buttonList={btnInfoMap[getActiveMenuRoot(location.pathname)]}
        />
      )}
    </>
  );
};

export default NavigationBar;
