const apiVersion = "v3";

export default {
  baseURL:
    process.env.REACT_APP_STAGE === "prod"
      ? "https://api.alphabeet.org/api/" + apiVersion
      : process.env.REACT_APP_STAGE === "test"
      ? "https://test.api.alphabeet.org/api/" + apiVersion // staging
      : "https://test.api.alphabeet.org/api/" + apiVersion, // development
  imgUrl:
    process.env.REACT_APP_STAGE === "prod"
      ? "https://api.alphabeet.org"
      : process.env.REACT_APP_STAGE === "test"
      ? "https://test.api.alphabeet.org"
      : "https://test.api.alphabeet.org",
  appUrl:
    process.env.REACT_APP_STAGE === "prod"
      ? "https://login.fryd.app"
      : process.env.REACT_APP_STAGE === "test"
      ? "https://test.login.fryd.app"
      : "http://localhost:3000",
  timeout: 60000,
};
