import { Box, Modal } from "@material-ui/core";
import RoundedButton from "../../Common/RoundedButton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Theme from "../../Core/Theme";
import React, { useEffect, useState } from "react";
import { ACCOUNT_TYPE_PLUS, ACCOUNT_TYPE_PRO } from "../../Core/Constants";
import { useTranslation } from "react-i18next";
import { Add as AddIcon, Done as DoneIcon } from "@material-ui/icons";
import PaypalPayment from "./PaypalPayment";
import { useDispatch, useSelector } from "react-redux";
import {
  loadCurrentUser,
  requestPaypalPlanId,
} from "../../Reducers/User/Actions";
import PaypalConfirm from "./PaypalConfirm";
import IconButton from "@material-ui/core/IconButton";
import IconClose from "@material-ui/icons/Close";
import Images, { Icon } from "../../Core/Images";
import VoucherInput from "../VoucherInput";
import Api from "../../Core/Api";
import { VoucherContext } from "../../Types/API/Voucher";

const prices = {
  [ACCOUNT_TYPE_PLUS]: 19,
  [ACCOUNT_TYPE_PRO]: 29,
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: 16,
    width: 360,
    height: 640,
    backgroundColor: Theme.colors.beigeLight,
    display: "flex",
    flexDirection: "column",
  },
  content: {
    paddingTop: 24,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  features: { flex: 1 },
  featureRow: {
    display: "flex",
    flexDirection: "row",
  },
  featureHeadline: {
    fontWeight: "bold",
    margin: 0,
  },
  featureText: {
    margin: 0,
  },
  price: { textAlign: "center", fontWeight: "bold" },
  productTile: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 32,
    paddingLeft: 16,
    paddingRight: 16,
  },
  productPrice: {
    ...Theme.text.p,
  },
  productPricePerYear: {
    ...Theme.text.small,
    marginLeft: 8,
  },
  productFeatureLabel: {
    ...Theme.text.p,
    margin: 0,
  },
}));

const features = {
  plus: [
    {
      active: true,
      name: "features.todos.name",
      description: "features.todos.description",
    },
    {
      active: true,
      name: "features.precultures.name",
      description: "features.precultures.description",
    },
    {
      active: true,
      name: "features.magicwand.name",
      description: "features.magicwand.description",
    },
  ],
  pro: [
    {
      active: true,
      additional: true,
      name: "features.croprotation.name",
      description: "features.croprotation.description",
    },
    {
      active: true,
      additional: true,
      name: "features.multiyear.name",
      description: "features.multiyear.description",
    },
  ],
};

const FeatureList = ({ features, compact, onlyCheckmarks }) => {
  const classes = useStyles();
  const { t } = useTranslation("paywall");

  return (
    <div className={classes.features}>
      {features.map((feature) => (
        <div className={classes.featureRow}>
          {feature.additional ? <AddIcon /> : <DoneIcon />}
          <div style={{ marginLeft: 8 }}>
            <p className={classes.featureHeadline}>{t(feature.name)}</p>
            {!compact && (
              <p className={classes.featureText}>{t(feature.description)}</p>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

const ProductTile = ({
  name,
  price,
  features,
  onSelect,
  accentColor,
  buttonLabel,
  featuresLabel,
  imageSrc,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("paywall");
  return (
    <div className={classes.productTile}>
      <div className={classes.featureRow}>
        <h3 style={{ flex: 1 }}>{name}</h3>
        <Icon src={imageSrc} size={48} />
      </div>
      {featuresLabel && (
        <p className={classes.productFeatureLabel}>{featuresLabel}</p>
      )}
      <FeatureList compact={true} features={features} />
      <div style={{ textAlign: "center", marginTop: 8 }}>
        <span className={classes.productPrice}>{t("price", { price })}</span>
        <span className={classes.productPricePerYear}>
          {t("pricePerYearLabel")}
        </span>
      </div>
      <RoundedButton
        variant={"primary"}
        style={{ backgroundColor: accentColor, marginTop: 16 }}
        onClick={onSelect}
        label={buttonLabel}
      />
    </div>
  );
};

const Products = ({ onSelect }) => {
  const { t } = useTranslation("paywall");
  return (
    <div>
      <h2>{t("product.allProducts")}</h2>
      <ProductTile
        name={t("product." + ACCOUNT_TYPE_PLUS)}
        price={prices[ACCOUNT_TYPE_PLUS]}
        buttonLabel={t("button.selectPlus")}
        onSelect={() => onSelect(ACCOUNT_TYPE_PLUS)}
        accentColor={Theme.colors.blueDark}
        features={features[ACCOUNT_TYPE_PLUS]}
        imageSrc={Images.icons.paywall.plusAccount}
      />
      <ProductTile
        name={t("product." + ACCOUNT_TYPE_PRO)}
        price={prices[ACCOUNT_TYPE_PRO]}
        buttonLabel={t("button.selectPro")}
        onSelect={() => onSelect(ACCOUNT_TYPE_PRO)}
        accentColor={Theme.colors.orangeMedium}
        featuresLabel={t("product.everythingFromPlus")}
        features={features[ACCOUNT_TYPE_PRO]}
        imageSrc={Images.icons.paywall.proAccount}
      />
    </div>
  );
};

const VoucherView = ({ onContinue }) => {
  const { t } = useTranslation("paywall");
  const classes = useStyles();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.User);
  const [voucher, setVoucher] = useState(undefined);

  return (
    <div className={classes.content} style={{ flex: 1 }}>
      <div style={{ flex: 1 }}>
        <h1>{t("voucher.headline")}</h1>
        <p>{t("voucher.text")}</p>
        <VoucherInput
          setVoucher={setVoucher}
          context={VoucherContext.UPGRADE}
          presetCode={user?.settings?.register_voucher}
        />
      </div>
      <div>
        <RoundedButton
          disabled={!voucher || !voucher.valid}
          label={t("common:actions.continue")}
          onClick={() => onContinue(voucher)}
        />
      </div>
    </div>
  );
};

const STEP_VOUCHER = "voucher";
const STEP_DETAILS = "details";
const STEP_PRODUCTS = "products";
const STEP_PAYMENT = "payment";
const STEP_PAYMENT_CONFIRMED = "payment_confirmed";
const STEP_VOUCHER_CONFIRMED = "voucher_confirmed";

// TODO: add check for small devices (based on height) and change styles for small devices
const Paywall = ({ open, onDismiss, context }) => {
  const classes = useStyles();
  const { t } = useTranslation("paywall");
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.User);

  const [step, setStep] = useState(STEP_DETAILS);
  const [selectedType, setSelectedType] = useState("pro");
  const [voucher, setVoucher] = useState(undefined);

  useEffect(() => {
    const type =
      context === "account"
        ? ACCOUNT_TYPE_PRO
        : context === "year"
        ? ACCOUNT_TYPE_PRO
        : ACCOUNT_TYPE_PLUS;
    setSelectedType(type);
    setStep(
      context === "voucher"
        ? STEP_VOUCHER
        : context === "account"
        ? STEP_PRODUCTS
        : STEP_DETAILS
    );
  }, [context]);

  const accentColor =
    selectedType === ACCOUNT_TYPE_PLUS
      ? Theme.colors.blueDark
      : Theme.colors.orangeMedium;

  const onStartPayment = async () => {
    if (voucher?.type === "one-year-pro" || voucher?.type === "one-year-plus") {
      await Api.request({
        url: "/voucher/redeem",
        method: "post",
        data: { code: voucher.code },
      });
      dispatch(loadCurrentUser());
      setStep(STEP_VOUCHER_CONFIRMED);
    } else {
      dispatch(
        requestPaypalPlanId({
          type: selectedType,
          // todo: re-enable vouchers
          /*...(voucher &&
              voucher.valid &&
              voucher.type === "percent-discount" &&
              paymentType === "yearly" &&
              this.isYearlySubscriptionDiscounted() && {
                voucher_code: voucher.code,
              }),*/
        })
      );
      setStep("payment");
    }
  };

  const onDismissInternal = () => {
    onDismiss();
    setStep("details");
  };

  return (
    <Modal open={open}>
      <div className={classes.root}>
        <div className="button-bar close-button">
          <IconButton onClick={onDismissInternal}>
            <IconClose />
          </IconButton>
        </div>
        {step === STEP_VOUCHER && (
          <VoucherView
            onContinue={(v) => {
              setVoucher(v);
              // depending on voucher show products with reduced prices or details for plus/pro
              if (v.type === "one-year-pro") {
                setSelectedType(ACCOUNT_TYPE_PRO);
                setStep(STEP_DETAILS);
              } else if (v.type === "one-year-plus") {
                setSelectedType(ACCOUNT_TYPE_PLUS);
                setStep(STEP_DETAILS);
              } else {
                setStep(STEP_PRODUCTS);
              }
            }}
          />
        )}
        {step === STEP_DETAILS && (
          <>
            <div className={classes.content}>
              <Icon
                src={
                  selectedType === ACCOUNT_TYPE_PLUS
                    ? Images.icons.paywall.plusAccount
                    : Images.icons.paywall.proAccount
                }
                size={80}
                style={{ justifySelf: "center" }}
              />
              <h1>{t("product." + selectedType)}</h1>
              <p>{t("text." + selectedType)}</p>
            </div>
            <FeatureList
              features={
                selectedType === ACCOUNT_TYPE_PLUS
                  ? features[ACCOUNT_TYPE_PLUS]
                  : [
                      ...features[ACCOUNT_TYPE_PLUS],
                      ...features[ACCOUNT_TYPE_PRO],
                    ]
              }
            />
            <p className={classes.price}>
              {voucher?.type === "one-year-plus" ||
              voucher?.type === "one-year-pro"
                ? ""
                : t("pricePerYear", { price: prices[selectedType] })}
            </p>
            <RoundedButton
              style={{
                backgroundColor: accentColor,
              }}
              label={t("button." + selectedType)}
              onClick={onStartPayment}
            />
            {(!voucher ||
              (voucher?.type !== "one-year-pro" &&
                voucher?.type !== "one-year-plus")) && (
              <RoundedButton
                label={t("button.allProducts")}
                variant={"transparent"}
                onClick={() => {
                  setStep(STEP_PRODUCTS);
                }}
              />
            )}
            {(voucher?.type === "one-year-pro" ||
              voucher?.type === "one-year-plus") && (
              <RoundedButton
                label={t("common:actions.back")}
                variant={"transparent"}
                onClick={() => {
                  setStep(STEP_VOUCHER);
                }}
              />
            )}
          </>
        )}
        {step === STEP_PRODUCTS && (
          <Products
            onSelect={(type) => {
              setSelectedType(type);
              setStep(STEP_DETAILS);
            }}
          />
        )}
        {step === STEP_PAYMENT && (
          <PaypalPayment
            type={selectedType}
            onPaypalConfirm={() => setStep(STEP_PAYMENT_CONFIRMED)}
          />
        )}
        {step === STEP_PAYMENT_CONFIRMED && (
          <PaypalConfirm
            onClose={onDismissInternal}
            onContinue={onDismissInternal}
          />
        )}
        {step === STEP_VOUCHER_CONFIRMED && (
          <div className={classes.content}>
            <div style={{ flex: 1 }}>
              <h1>{t("voucher.headline")}</h1>
              <p>
                {t("voucher.confirmed", {
                  accountType: selectedType,
                })}
              </p>
            </div>
            <RoundedButton
              label={t("common:actions.continue")}
              onClick={onDismiss}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default Paywall;
