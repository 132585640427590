import { cloneElement, ReactElement } from "react";
import IconProps from "../../../Types/IconProps";
import Box from "../../Layout/Box";
import Center from "../../Layout/Center";
import { LayoutProps } from "../../Styled/layout";
import { MarginProps } from "../../Styled/margin";
import { PositionProps } from "../../Styled/position";
import Text from "../../Typography/Text";
import styled, { css } from "styled-components";
import { TypographyProps } from "../../Styled/typography";
import { PaddingProps } from "../../Styled/padding";
import { FlexProps } from "../../Styled/flex";

type ButtonProps = {
  label?: string;
  iconLeft?: ReactElement<IconProps>;
  iconRight?: ReactElement<IconProps>;
  size?: "lg" | "md" | "sm";
  variant?: "primary" | "secondary" | "tertiary" | "white" | "transparent";
  width?: LayoutProps["width"];
  disabled?: boolean;
  onClick?: () => void;
  testId?: string;
} & PositionProps &
  MarginProps &
  PaddingProps &
  TypographyProps &
  Pick<FlexProps, "gap">;

const Button = ({
  label,
  iconLeft,
  iconRight,
  size = "md",
  variant = "primary",
  width = "max-content",
  onClick,
  disabled,
  testId = "",
  fontWeight = "bold",
  fontSize = "md",
  ...props
}: ButtonProps) => {
  const handleClick = () => {
    if (disabled || !onClick) {
      return;
    }
    onClick();
  };

  return (
    <StyledButtonBox
      as="button"
      draggable={"false"}
      variant={variant}
      flexShrink={0}
      width={width}
      height={
        (size === "lg" && "48px") ||
        (size === "md" && "40px") ||
        (size === "sm" && "32px") ||
        undefined
      }
      overflow="hidden"
      borderRadius="full"
      border={variant === "secondary" ? "2pxSolid" : undefined}
      disabled={disabled}
      onClick={handleClick}
      data-testid={testId}
      {...props}
    >
      <Center
        position="relative"
        borderRadius="full"
        size="100%"
        pl={props.pl ? props.pl : iconLeft ? "24px" : "36px"}
        pr={props.pr ? props.pr : iconRight ? "24px" : "36px"}
        gap={props.gap ?? "8px"}
      >
        {iconLeft &&
          cloneElement(iconLeft, {
            size: size === "sm" ? "sm" : "md",
            ...iconLeft.props,
          })}
        <Text fontWeight={fontWeight} fontSize={fontSize}>
          {label}
        </Text>
        {iconRight &&
          cloneElement(iconRight, {
            size: size === "sm" ? "sm" : "md",
            ...iconRight.props,
          })}
      </Center>
    </StyledButtonBox>
  );
};

export default Button;

type StyledButtonBoxProps = {
  variant?: "primary" | "secondary" | "tertiary" | "white" | "transparent";
};

const StyledButtonBox = styled(Box)<StyledButtonBoxProps>(
  ({ theme, variant = "primary" }) => {
    switch (variant) {
      case "primary":
        return css`
          user-select: none;
          color: ${theme.colors.white};
          background-color: ${theme.colors.greenApp};
          &:hover {
            background-color: ${theme.colors.greenAppHover};
          }
          &:disabled {
            background-color: ${theme.colors.greenAppInactive};
          }
        `;
      case "secondary":
        return css`
          user-select: none;
          color: ${theme.colors.greenApp};
          border-color: ${theme.colors.greenApp};
          &:hover {
            color: ${theme.colors.greenAppHover};
            border-color: ${theme.colors.greenAppHover};
          }
          &:disabled {
            color: ${theme.colors.greenAppInactive};
            border-color: ${theme.colors.greenAppInactive};
          }
        `;
      case "tertiary":
        return css`
          user-select: none;
          color: ${theme.colors.greenApp};
          &:hover {
            color: ${theme.colors.greenAppHover};
          }
          &:disabled {
            color: ${theme.colors.greenAppInactive};
          }
        `;
      case "white":
        return css`
          user-select: none;
          color: ${theme.colors.currentColor};
          background-color: ${theme.colors.white};
          &:hover {
            background-color: ${theme.colors.greyMedium};
          }
          &:disabled {
            color: ${theme.colors.greyMidi};
            background-color: ${theme.colors.greyDark};
          }
        `;
      case "transparent":
        return css`
          user-select: none;
        `;
    }
  }
);
