import {} from "styled-components/macro";

import { useEffect, useRef, useState } from "react";
import { css } from "styled-components";
import { LayoutProps } from "../../Styled/layout";
import { MarginProps } from "../../Styled/margin";
import { PositionProps } from "../../Styled/position";
import Text from "../../Typography/Text";
import Row from "../../Layout/Row";
import Box from "../../Layout/Box";
import { FlexItemProps } from "../../Styled/flexItem";
import Column from "../../Layout/Column";
import ChevronDown from "../../../Resources/SVG/ChevronDown";
import ChevronUp from "../../../Resources/SVG/ChevronUp";
import { useTranslation } from "react-i18next";
import Checkmark from "../../../Resources/SVG/Checkmark";
import Center from "../../Layout/Center";

type Option<T> = {
  label: string;
  value?: T;
};

type OptionProps<T> = {
  clearOption?: boolean;
  selected?: boolean;
  option: Option<T>;
  onClick?: (option: Option<T>) => void;
};

const Option = <T,>({
  clearOption = false,
  selected = false,
  option,
  onClick,
}: OptionProps<T>) => (
  <Column
    css={
      css(
        ({ theme }) =>
          css`
            &:hover {
              background-color: ${theme.colors.greyLight};
            }
          `
      ) as any
    }
    as="button"
    onClick={() => {
      onClick?.(option);
    }}
    alignItems="stretch"
    mt="-1px"
    width="100%"
  >
    <Box zIndex={1} mx="16px" height="1px" backgroundColor="greyLight" />
    <Row px="16px" alignItems="center">
      <Text
        my="8px"
        flexGrow={1}
        noselect
        noBreak
        textAlign={clearOption ? "center" : undefined}
        $color={clearOption ? "greyDark" : undefined}
        fontWeight={selected ? "bold" : undefined}
      >
        {option.label}
      </Text>
      {selected && (
        <Center mr="-4px">
          <Checkmark />
        </Center>
      )}
    </Row>
  </Column>
);

type SelectProps<T> = {
  options: Option<T>[];
  canClear?: boolean;
  disabled?: boolean;
  error?: boolean;
  label?: string;
  placeholder?: string;
  helpText?: string;
  errorText?: string;
  outline?: boolean;
  autoFocus?: boolean;
  value?: string;
  onChange?: (value?: T) => void;
  onFocus?: () => void;
  onBlur?: () => void;
} & PositionProps &
  Pick<LayoutProps, "width" | "minWidth" | "maxWidth"> &
  MarginProps &
  FlexItemProps;

const Select = <T,>({
  options,
  canClear = false,
  disabled = false,
  error = false,
  label,
  placeholder,
  helpText,
  errorText,
  outline = false,
  autoFocus = false,
  value: controlledValue,
  onChange,
  onFocus,
  onBlur,
  ...props
}: SelectProps<T>) => {
  const { t } = useTranslation("common");

  const [selctedOptionIndex, setSelctedOptionIndex] = useState<number>();
  const [isFocused, setIsFocused] = useState<boolean>(autoFocus);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    const index = options.findIndex(
      (option) => option.value === controlledValue
    );
    if (index === -1) {
      setSelctedOptionIndex(undefined);
    } else {
      setSelctedOptionIndex(index);
    }
  }, [controlledValue]);

  useEffect(() => {
    if (selctedOptionIndex == null) {
      onChange?.(undefined);
      return;
    }
    onChange?.(options[selctedOptionIndex].value);
  }, [selctedOptionIndex]);

  return (
    <Column gap="8px">
      <Box
        height="56px"
        borderRadiusTop="md"
        borderRadiusBottom={isOpen ? "none" : "md"}
        backgroundColor="white"
        border={outline || error ? "1pxSolid" : "none"}
        borderColor={error ? "red" : "greyMedium"}
        {...props}
      >
        <Row
          m={outline || error ? "-1px" : undefined}
          size={outline || error ? "100%+2px" : "100%"}
          position="relative"
          alignItems="center"
          px="16px"
          gap="8px"
          css={`
            cursor: ${disabled ? "default" : "pointer"};
          `}
          tabIndex={0}
          onFocus={() => {
            setIsFocused(true);
            onFocus?.();
          }}
          onBlur={(e) => {
            setIsFocused(false);
            onBlur?.();

            const currentTarget = e.currentTarget;
            requestAnimationFrame(() => {
              if (!currentTarget.contains(document.activeElement)) {
                setIsOpen(false);
              }
            });
          }}
          onClick={() => {
            if (isOpen) {
              setIsOpen(false);
            } else {
              setIsOpen(true);
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              if (isOpen) {
                setIsOpen(false);
              } else {
                setIsOpen(true);
              }
            }
          }}
        >
          {label && (
            <Text
              as="span"
              $color={error ? "red" : "greyDark"}
              position="absolute"
              left="16px"
              top={selctedOptionIndex != null || placeholder ? "8px" : "auto"}
              fontSize={selctedOptionIndex != null || placeholder ? "sm" : "md"}
            >
              {label}
            </Text>
          )}
          <Text
            noselect
            mt={
              label && (isOpen || selctedOptionIndex != null || placeholder)
                ? "16px"
                : undefined
            }
            flexGrow={1}
            $color={selctedOptionIndex != null ? "black" : "greyDark"}
          >
            {(selctedOptionIndex != null &&
              options[selctedOptionIndex].label) ||
              placeholder}
          </Text>
          {isOpen ? <ChevronUp size="sm" /> : <ChevronDown size="sm" />}
          {isOpen && (
            <Column
              minWidth="100%"
              overflow="hidden"
              boxShadow="lg"
              zIndex={100}
              position="absolute"
              top="56px"
              left="0px"
              mt="-1px"
              backgroundColor="white"
              borderRadiusBottom="md"
              border={outline || error ? "1pxSolid" : "none"}
              borderColor={error ? "red" : "greyMedium"}
            >
              <Box
                m={outline || error ? "-1px" : undefined}
                size={outline || error ? "100%+2px" : "100%"}
              >
                {options.map((option, index) => (
                  <Option
                    key={option.label}
                    selected={index === selctedOptionIndex}
                    option={option}
                    onClick={() => setSelctedOptionIndex(index)}
                  />
                ))}
                {canClear && selctedOptionIndex != null && (
                  <Option
                    clearOption={true}
                    option={{
                      label: t("actions.clearSelection"),
                      value: undefined,
                    }}
                    onClick={(option) => setSelctedOptionIndex(undefined)}
                  />
                )}
              </Box>
            </Column>
          )}
        </Row>
      </Box>
      {(error && errorText && (
        <Text px="16px" fontSize="sm" $color="red">
          {errorText}
        </Text>
      )) ||
        (helpText && (
          <Text px="16px" fontSize="sm" $color="greyDark">
            {helpText}
          </Text>
        ))}
    </Column>
  );
};

export default Select;
