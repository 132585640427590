import theme from "../../Theme";
import IconProps from "../../Types/IconProps";

export default ({ size = "md", color = "currentColor" }: IconProps) => (
  <svg
    width={size === "lg" ? "48px" : size === "sm" ? "24px" : "32px"}
    height={size === "lg" ? "48px" : size === "sm" ? "24px" : "32px"}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.5 3.5C4.11929 3.5 3 4.61929 3 6V19C3 20.3807 4.11929 21.5 5.5 21.5H18.5C19.8807 21.5 21 20.3807 21 19V6C21 4.61929 19.8807 3.5 18.5 3.5H5.5ZM20 15.7929V6C20 5.17157 19.3284 4.5 18.5 4.5H5.5C4.67157 4.5 4 5.17157 4 6V12.7929L7.14645 9.64645C7.34171 9.45118 7.65829 9.45118 7.85355 9.64645L13.5 15.2929L16.1464 12.6464C16.3417 12.4512 16.6583 12.4512 16.8536 12.6464L20 15.7929ZM20 17.2071L16.5 13.7071L13.8536 16.3536C13.6583 16.5488 13.3417 16.5488 13.1464 16.3536L7.5 10.7071L4 14.2071V19C4 19.8284 4.67157 20.5 5.5 20.5H18.5C19.3284 20.5 20 19.8284 20 19V17.2071ZM15 6.5H17C17.5523 6.5 18 6.94772 18 7.5V9.5C18 10.0523 17.5523 10.5 17 10.5H15C14.4477 10.5 14 10.0523 14 9.5V7.5C14 6.94772 14.4477 6.5 15 6.5ZM15 9.5V7.5H17V9.5H15Z"
      fill={theme.colors[color]}
    />
  </svg>
);
