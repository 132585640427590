import theme from "../../Theme";
import IconProps from "../../Types/IconProps";

export default ({ size = "md" }: IconProps) => (
  <svg
    width={size === "xs" ? "16px" : size === "sm" ? "24px" : "32px"}
    height={size === "xs" ? "16px" : size === "sm" ? "24px" : "32px"}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="13" fill={theme.colors.red} />
    <mask id="path-2-inside-1_4416_413" fill={theme.colors.white}>
      <path d="M28 16C28 18.3734 27.2962 20.6935 25.9776 22.6668C24.6591 24.6402 22.7849 26.1783 20.5922 27.0866C18.3995 27.9948 15.9867 28.2324 13.6589 27.7694C11.3311 27.3064 9.19295 26.1635 7.51472 24.4853C5.83649 22.8071 4.6936 20.6689 4.23058 18.3411C3.76755 16.0133 4.00519 13.6005 4.91345 11.4078C5.8217 9.21509 7.35977 7.34094 9.33316 6.02236C11.3065 4.70379 13.6266 4 16 4V16H28Z" />
    </mask>
    <path
      d="M28 16C28 18.3734 27.2962 20.6935 25.9776 22.6668C24.6591 24.6402 22.7849 26.1783 20.5922 27.0866C18.3995 27.9948 15.9867 28.2324 13.6589 27.7694C11.3311 27.3064 9.19295 26.1635 7.51472 24.4853C5.83649 22.8071 4.6936 20.6689 4.23058 18.3411C3.76755 16.0133 4.00519 13.6005 4.91345 11.4078C5.8217 9.21509 7.35977 7.34094 9.33316 6.02236C11.3065 4.70379 13.6266 4 16 4V16H28Z"
      fill={theme.colors.white}
      stroke={theme.colors.red}
      mask="url(#path-2-inside-1_4416_413)"
    />
  </svg>
);
