import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import Moment from "moment";
import { extendMoment } from "moment-range";

import {
  DATE_FORMAT_API,
  DATE_FORMAT_FRONTEND,
} from "../../../../Core/Constants";

import Theme from "../../../../Core/Theme";
import Images from "../../../../Core/Images";

import CultivationStart from "../../../../Resources/SVG/CultivationStart";
import SowingDate from "../../../../Resources/SVG/SowingDate";
import PlantingDate from "../../../../Resources/SVG/PlantingDate";
import HarvestDate from "../../../../Resources/SVG/HarvestDate";
import DatePickerIcon from "../../../../Resources/SVG/DatePickerIcon";
import CustomCalendar from "../../../../Components/CustomCalendar";
import isEmpty from "lodash/isEmpty";

export const EditDate = ({
  onDismiss,
  date,
  onDaySelected,
  range,
  closestPlants,
}) => {
  const [contentData, setContentData] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const [minDate, setMinDate] = useState(undefined);
  const [maxDate, setMaxDate] = useState(undefined);
  const [visibleRange, setVisibleRange] = useState(null);
  const { t } = useTranslation("season");
  const moment = extendMoment(Moment);
  const initialWarning = { date: undefined, plant: undefined };
  const [intersectionWarning, setIntersectionWarning] =
    useState(initialWarning);

  const screenData = {
    precultivation: {
      title: t("dates.precultivation.label"),
      text: t("dates.precultivation.text"),
      icon: CultivationStart,
    },
    sowing: {
      title: t("dates.sowing.label"),
      text: t("dates.sowing.text"),
      icon: SowingDate,
    },
    planted: {
      title: t("dates.planted.label"),
      text: t("dates.planted.text"),
      icon: PlantingDate,
    },
    harvestStart: {
      title: t("dates.harvestStart.label"),
      text: t("dates.harvestStart.text"),
      icon: HarvestDate,
    },
    harvestEnd: {
      title: t("dates.harvestEnd.label"),
      text: t("dates.harvestEnd.text"),
      icon: HarvestDate,
    },
  };

  const checkForIntersectionWarning = () => {
    setIntersectionWarning(initialWarning);

    if (!visibleRange) {
      return;
    }

    if (!range) {
      return;
    }

    if (!["planted", "sowing", "harvestEnd"].includes(date.id)) {
      return;
    }

    if (
      ["planted", "sowing"].includes(date.id) &&
      range.min &&
      visibleRange.contains(moment(range.min))
    ) {
      const limit = moment(range.min).add(1, "days");
      setMinDate(limit);
      setIntersectionWarning({
        date: limit.format(DATE_FORMAT_API),
        plant: closestPlants.previous,
      });
      return;
    }

    if (
      date.id === "harvestEnd" &&
      range.max &&
      visibleRange.contains(moment(range.max))
    ) {
      const limit = moment(range.max).subtract(1, "days");
      setMaxDate(limit);
      setIntersectionWarning({
        date: limit.format(DATE_FORMAT_API),
        plant: closestPlants.next,
      });
    }
  };

  useEffect(() => {
    if (!date) {
      return;
    }

    setContentData(screenData[date.id]);
    setSelectedDate(
      moment(date.value, DATE_FORMAT_FRONTEND).format(DATE_FORMAT_API)
    );
    setMinDate(moment(date.min, DATE_FORMAT_API) || undefined);
    setMaxDate(moment(date.max, DATE_FORMAT_API) || undefined);
    checkForIntersectionWarning();
  }, [date]);

  useEffect(() => {
    checkForIntersectionWarning();
  }, [visibleRange]);

  useEffect(() => {
    if (!selectedDate) {
      return;
    }

    setVisibleRange(moment(selectedDate).range("month"));
  }, [selectedDate]);

  return (
    !isEmpty(contentData) &&
    minDate &&
    maxDate && (
      <div data-testid="EditDate" style={{ padding: 16 }}>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "16px 0",
            }}
          >
            {<contentData.icon />}
            <h2 style={{ marginLeft: 8 }}>{contentData.title}</h2>
          </div>
          <p>{contentData.text}</p>
        </div>
        <div
          style={{
            margin: "16px 0",
            backgroundColor: Theme.colors.white,
            borderRadius: 5,
            padding: 16,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              borderBottom: `1px solid ${Theme.colors.greyDark}`,
              paddingBottom: 12,
              marginBottom: 12,
            }}
          >
            <span style={{ color: Theme.colors.greenApp }}>
              <DatePickerIcon />
            </span>
            <div
              style={{
                marginLeft: 8,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <b>{t("selectDate")}</b>
              <span
                style={{
                  fontSize: Theme.sizes.small,
                  lineHeight: 1.2,
                  color: Theme.colors.greenApp,
                }}
              >
                {moment(selectedDate).format("LL")}
              </span>
            </div>
          </div>
          <CustomCalendar
            currentDate={moment(selectedDate).toDate()}
            onChange={(day) => {
              setSelectedDate(moment(day).format(DATE_FORMAT_API));
              onDaySelected(day);
            }}
            showWeekNumbers={true}
            minDate={minDate.toDate()}
            maxDate={maxDate.toDate()}
          />
          {intersectionWarning.date && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                borderTop: `1px solid ${Theme.colors.greyDark}`,
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  flexDirection: "row",
                  margin: "12px 0",
                  alignItems: "center",
                }}
              >
                <img
                  src={Images.scribble.warning}
                  style={{
                    width: 32,
                    height: 32,
                  }}
                />
                <b style={{ marginLeft: 4 }}>{t("warningHeadline")}</b>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <p style={{ fontSize: Theme.sizes.small, lineHeight: 1.2 }}>
                  <Trans
                    t={t}
                    i18nKey={`warning.${date.id}`}
                    values={{
                      date: moment(intersectionWarning.date).format("LL"),
                    }}
                  >
                    <span
                      style={{
                        fontSize: Theme.sizes.small,
                        lineHeight: 1.2,
                        fontWeight: 700,
                      }}
                    >
                      date
                    </span>
                  </Trans>
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  );
};
