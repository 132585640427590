import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ImageIcon from "../../../Resources/SVG/Image";
import Center from "../../Layout/Center";
import Column from "../../Layout/Column";
import Row from "../../Layout/Row";
import Text from "../../Typography/Text";
import ImageInputList from "./ImageInputList";
import { FlexItemProps } from "../../Styled/flexItem";
import { LayoutProps } from "../../Styled/layout";
import { MarginProps } from "../../Styled/margin";
import { PositionProps } from "../../Styled/position";
import useControlledState from "../../../Helpers/useControlledState";
import ImageInputArea from "../ImageInputArea";

type ImageSelectProps = {
  backgroundColor?: "greyLight" | "greyMedium";
  label?: string;
  variant?: "area" | "list";
  colCount?: number;
  sortable?: boolean;
  maxImageCount?: number;
  images?: string[];
  onChangeImages?: (images: string[]) => void;
} & PositionProps &
  LayoutProps &
  MarginProps &
  FlexItemProps;

const ImageSelect = ({
  backgroundColor = "greyLight",
  label,
  variant = "area",
  colCount = 4,
  sortable = true,
  maxImageCount = 10,
  images: controlImages,
  onChangeImages,
  ...props
}: ImageSelectProps) => {
  const { t } = useTranslation("community");

  const [images, setImages] = useControlledState(controlImages, (images) => {
    onChangeImages?.(images ?? []);
  });

  return (
    <Column gap="16px" {...props}>
      {label && (
        <Row justifyContent="space-between" mb="-4px">
          <Text fontWeight="bold">{label}</Text>
          <ImageInputArea
            maxImageCount={maxImageCount}
            images={images}
            onChangeImages={setImages}
          >
            <Text $color="greenApp" fontWeight="bold">
              {t("profile:userProfile.addPicture")}
            </Text>
          </ImageInputArea>
        </Row>
      )}

      {((images && images.length > 0) || variant === "list") && (
        <ImageInputList
          sortable={sortable}
          width="100%"
          colCount={colCount}
          withAddItem={variant === "list"}
          maxImageCount={maxImageCount}
          images={images}
          onChangeImages={setImages}
        />
      )}

      {variant === "area" &&
        (images && images.length === maxImageCount ? (
          <Center
            backgroundColor={backgroundColor}
            borderRadius="md"
            flexGrow={1}
          >
            <Text $color="greyDark">{t("postCreation.maxImageNotice")}</Text>
          </Center>
        ) : (
          <ImageInputArea
            backgroundColor={backgroundColor}
            overflow="hidden"
            borderRadius="md"
            flexGrow={1}
            maxImageCount={maxImageCount}
            images={images}
            onChangeImages={setImages}
          >
            <Center size="100%">
              <Column alignItems="center" gap="12px">
                <Text $color="greyDark">
                  {t("postCreation.selectImageHelp")}
                </Text>
                <Row alignItems="center" gap="4px">
                  <ImageIcon color="greenApp" />
                  <Text fontWeight="bold" $color="greenApp">
                    {t("postCreation.selectImage")}
                  </Text>
                </Row>
              </Column>
            </Center>
          </ImageInputArea>
        ))}
    </Column>
  );
};

export default ImageSelect;
