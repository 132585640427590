import Theme from "../Core/Theme";
export default {
  hideScrollbar: {
    //Hide scrollbar for Chrome, Safari and Opera
    "&::-webkit-scrollbar": {
      display: "none",
    },
    // Hide scrollbar for IE, Edge and Firefox
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
  },
  ellipsis: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  magicGradient: {
    background:
      "linear-gradient(36deg, " +
      Theme.aubergine +
      " 37.25%, " +
      Theme.lightRed +
      " 80.75%)",
  },
};
