import { LayoutProps } from "../../Base/Styled/layout";

type BadgeProps = {
  size: string;
};

export default ({ size }: BadgeProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 26C5.8203 26 0 20.1797 0 13C0 5.8203 5.8203 0 13 0C20.1797 0 26 5.8203 26 13C26 20.1797 20.1797 26 13 26Z"
      fill="url(#paint0_linear_1701_12186)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.0585 7.69618C19.4784 8.01667 19.5591 8.61691 19.2386 9.03686L12.3038 18.1238C12.1365 18.3431 11.8825 18.4795 11.6073 18.4979C11.332 18.5163 11.0622 18.4149 10.8671 18.2198L7.28016 14.6329C6.90661 14.2594 6.90661 13.6537 7.28016 13.2802C7.6537 12.9066 8.25933 12.9066 8.63288 13.2802L11.4465 16.0938L17.7178 7.87627C18.0383 7.45633 18.6386 7.3757 19.0585 7.69618Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1701_12186"
        x1="3.34849"
        y1="30.3333"
        x2="19.1061"
        y2="1.77273"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.175171" stopColor="#6F1A66" />
        <stop offset="1" stopColor="#F9BDB8" />
      </linearGradient>
    </defs>
  </svg>
);
