import styled, { css } from "styled-components";
import {
  ReactNode,
  RefObject,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import Box from "../Box";
import { LayoutProps } from "../../Styled/layout";
import { SpaceProps } from "../../Styled/space";
import { FlexItemProps } from "../../Styled/flexItem";
import { PositionProps } from "../../Styled/position";

const ScrollContainer = styled(Box)<{
  scrollbarWidth?: "md" | "sm";
  colorTheme?: "grey" | "beige";
}>(
  ({ theme, colorTheme = "grey", scrollbarWidth = "md" }) => css`
    // Firefox
    scrollbar-color: ${theme.colors.greyMedium} transparent;
    // All other browsers
    &::-webkit-scrollbar {
      width: ${theme.sizes[scrollbarWidth === "sm" ? "16px" : "20px"]};
    }
    &::-webkit-scrollbar-track {
      background: none;
    }
    &::-webkit-scrollbar-corner {
      background: none;
    }
    &::-webkit-scrollbar-thumb {
      border: 4px solid transparent;
      background-clip: padding-box;
      border-radius: ${theme.radii.full};
      background-color: ${colorTheme === "grey"
        ? theme.colors.greyMedium
        : theme.colors.beigeMidi};
      &:hover {
        background-color: ${colorTheme === "grey"
          ? theme.colors.greyMidi
          : theme.colors.beigeDark};
      }
      &:active {
        background-color: ${colorTheme === "grey"
          ? theme.colors.greyDark
          : theme.colors.beigeDarkest};
      }
    }
  `
);

const ScrollContent = styled(Box)<{
  contentWidth: "offset" | "full" | "contain";
  contentOffsetWidth: number;
}>(({ contentWidth, contentOffsetWidth }) => {
  switch (contentWidth) {
    case "offset":
      return;
    case "full":
      return css`
        margin-right: -${contentOffsetWidth}px;
      `;
    case "contain":
      return css`
        margin-left: ${contentOffsetWidth}px;
      `;
  }
});

type ScrollBoxProps = {
  colorTheme?: "grey" | "beige";
  scrollbarWidth?: "md" | "sm";
  overflowY?: "scroll" | "auto";
  contentWidth?: "offset" | "full" | "contain";
  scrollContainerRef?: RefObject<HTMLDivElement>;
  children?: ReactNode;
  className?: any;
} & Omit<LayoutProps, "overflow" | "overflowX" | "overflowY"> &
  SpaceProps &
  FlexItemProps &
  PositionProps;

const ScrollBox = ({
  colorTheme = "grey",
  scrollbarWidth = "md",
  overflowY = "scroll",
  contentWidth = "offset",
  scrollContainerRef: externRef,
  children,
  className,
  ...props
}: ScrollBoxProps) => {
  const defaultRef = useRef<HTMLDivElement>(null);
  const [ref, setRef] = useState(defaultRef);
  useEffect(() => {
    if (externRef) {
      setRef(externRef);
    }
  }, [externRef]);

  const [contentOffsetWidth, setContentOffsetWidth] = useState(0);
  useLayoutEffect(() => {
    if (!ref || !ref.current) return;
    setContentOffsetWidth(ref.current.offsetWidth - ref.current.clientWidth);
  }, [ref, ref.current, ref.current?.offsetWidth, ref.current?.clientWidth]);

  return (
    <ScrollContainer
      colorTheme={colorTheme}
      ref={ref}
      scrollbarWidth={scrollbarWidth}
      overflowY={overflowY}
      overflowX="hidden"
      {...props}
    >
      <ScrollContent
        contentWidth={contentWidth}
        contentOffsetWidth={contentOffsetWidth}
        className={className}
        position="relative"
        height="100%"
      >
        {children}
      </ScrollContent>
    </ScrollContainer>
  );
};

export default ScrollBox;
