import React, { useState } from "react";
import PasswordResetRequest from "./index";
import * as ApiActions from "../../Reducers/Api/Actions";
import * as UserActions from "../../Reducers/User/Actions";
import { useDispatch, useSelector } from "react-redux";
import Routes from "../../Core/Routes";
import { useNavigate } from "react-router-dom";

const PasswordResetRequestContainer = () => {
  const [resetMailSent, setResetMailSent] = useState(false);
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { successfulLoginReset } = useSelector((state: any) => state.User);
  const { isFetching } = useSelector((state: any) => state.Api);

  const onClickSend = () => {
    dispatch(
      ApiActions.postData(
        Routes.API_ROUTE_USER_RECOVER_LOGIN,
        { email },
        UserActions.receiveRecoverLogin
      )
    );
    setResetMailSent(true);
  };

  const onClickToLogin = () => {
    navigate(Routes.login);
  };

  return (
    <PasswordResetRequest
      isFetching={isFetching}
      onClickSend={onClickSend}
      resetMailSent={resetMailSent}
      email={email}
      setEmail={(value) => setEmail(value)}
      onClickToLogin={onClickToLogin}
      successfulLoginReset={!!successfulLoginReset}
    />
  );
};

export default PasswordResetRequestContainer;
