import {} from "styled-components/macro";

import { forwardRef, SyntheticEvent, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import Box from "../../Layout/Box";
import Column from "../../Layout/Column";
import Row from "../../Layout/Row";
import { LayoutProps } from "../../Styled/layout";
import space, { SpaceProps } from "../../Styled/space";
import typography, { TypographyProps } from "../../Styled/typography";
import Text from "../../Typography/Text";
import { PositionProps } from "../../Styled/position";

type TextAreaProps = {
  spellCheck?: boolean;
  label?: string;
  placeholder?: string;
  helpText?: string;
  errorText?: string;
  error?: boolean;
  outline?: boolean;
  transparent?: boolean;
  disabled?: boolean;
  autoResize?: boolean;
  rows?: number;
  autoFocus?: boolean;
  value?: string;
  onChange?: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onSelect?: (event: SyntheticEvent<HTMLTextAreaElement, Event>) => void;
} & Pick<TypographyProps, "fontSize"> &
  Pick<
    LayoutProps,
    "height" | "width" | "minWidth" | "maxWidth" | "minHeight"
  > &
  Pick<PositionProps, "position">;

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      spellCheck = false,
      label,
      placeholder,
      helpText,
      errorText,
      error,
      outline,
      transparent,
      disabled,
      autoResize,
      rows = 1,
      autoFocus = false,
      value: controlledValue,
      onChange,
      onFocus,
      onBlur,
      onSelect,

      position,
      fontSize,
      minHeight,
      ...props
    },
    ref
  ) => {
    const [value, setValue] = useState<string>(controlledValue ?? "");
    useEffect(() => {
      setValue(controlledValue ?? "");
    }, [controlledValue]);
    useEffect(() => {
      onChange?.(value);
    }, [value]);

    const [isFocused, setIsFocused] = useState<boolean>(autoFocus);

    return (
      <Column gap="8px" minHeight={minHeight} position={position}>
        <Box
          overflow="hidden"
          borderRadius="md"
          backgroundColor={transparent ? "transparent" : "white"}
          border={outline || error ? "1pxSolid" : "none"}
          borderColor={error ? "red" : "greyMedium"}
          flexGrow={1}
          as="label"
          css={`
            cursor: ${disabled ? "default" : "text"};
          `}
          {...props}
        >
          <Row
            m={outline || error ? "-1px" : undefined}
            size={outline || error ? "100%+2px" : "100%"}
            position="relative"
            alignItems="center"
          >
            {label && (
              <Text
                as="span"
                $color={error ? "red" : "greyDark"}
                position="absolute"
                left="16px"
                top={isFocused || value || placeholder ? "8px" : "auto"}
                fontSize={isFocused || value || placeholder ? "sm" : "md"}
              >
                {label}
              </Text>
            )}

            <StyledTextArea
              ref={ref}
              mx="16px"
              mt={
                label && (isFocused || value || placeholder) ? "24px" : "16px"
              }
              mb={label && (isFocused || value || placeholder) ? "8px" : "16px"}
              spellCheck={spellCheck}
              fontSize={fontSize}
              autoResize={autoResize}
              transparent={transparent}
              placeholder={placeholder}
              disabled={disabled}
              autoFocus={autoFocus}
              rows={rows}
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
              }}
              onFocus={() => {
                setIsFocused(true);
                onFocus?.();
              }}
              onBlur={() => {
                setIsFocused(false);
                onBlur?.();
              }}
              onSelect={onSelect}
            />
          </Row>
        </Box>
        {(error && errorText && (
          <Text px="16px" fontSize="sm" $color="red">
            {errorText}
          </Text>
        )) ||
          (helpText && (
            <Text px="16px" fontSize="sm" $color="greyDark">
              {helpText}
            </Text>
          ))}
      </Column>
    );
  }
);

type StyledTextAreaProps = {
  transparent?: boolean;
  autoResize?: boolean;
} & TypographyProps &
  SpaceProps;

const StyledTextArea = styled.textarea.attrs<StyledTextAreaProps>(
  ({ autoResize = false }) => ({
    onInput: (e: any) => {
      if (!autoResize) return;
      e.target.style.height = "auto";
      e.target.style.height = `${e.target.scrollHeight}px`;
    },
  })
)<StyledTextAreaProps>(
  ({ theme, transparent = false, autoResize = false }) => css`
    display: block;
    box-sizing: border-box;
    background-color: transparent;
    border: none;
    resize: none;
    margin: 0;
    padding: 0;
    width: 100%;
    line-height: ${theme.lineHeights["1.5"]};

    &:focus {
      outline: none;
    }
    &::placeholder {
      color: ${theme.colors.greyDark};
    }

    ${transparent &&
    css`
      background-color: ${theme.colors.transparent};
      color: ${theme.colors.transparent};
      caret-color: ${theme.colors.black};
    `}
    ${autoResize &&
    css`
      overflow: hidden;
    `}

    ${typography}
    ${space}
  `
);

export default TextArea;
