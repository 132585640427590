import * as ApiActions from "../Api/Actions";

export const RECEIVE_TODO_LIST = "RECEIVE_TODO_LIST";
export const RECEIVE_DELETE_TODO = "RECEIVE_DELETE_TODO";
export const RECEIVE_TODO = "RECEIVE_TODO";
export const RESET_TODOS = "WORK_RESET_TODOS";

export const receiveTodoList = (data, status) => ({
  type: RECEIVE_TODO_LIST,
  data: data,
  status: status,
});

export const receiveTodo = (id) => {
  return (data, status) => ({
    type: RECEIVE_TODO,
    data: { ...data, ...(id ? { id } : {}) },
    status: status,
  });
};

export const receiveDeleteTodo = (todoId) => ({
  type: RECEIVE_DELETE_TODO,
  id: todoId,
});

export const resetTodos = () => ({
  type: RESET_TODOS,
});

export const completeTodo = (id, getNext) => async (dispatch) => {
  if (getNext) {
    await dispatch(
      ApiActions.postData("/todos/" + id + "/complete", {}, receiveTodo())
    );
    dispatch(getNextTodo());
  } else {
    dispatch(
      ApiActions.postData("/todos/" + id + "/complete", {}, receiveTodo())
    );
  }
};

export const reopenTodo = (id) => (dispatch) => {
  dispatch(ApiActions.postData("/todos/" + id + "/reopen", {}, receiveTodo()));
};

export const snoozeTodo = (id, getNext) => async (dispatch) => {
  if (getNext) {
    await dispatch(
      ApiActions.postData(
        "/todos/" + id + "/snooze",
        { days: "7" },
        receiveTodo(id)
      )
    );
    dispatch(getNextTodo());
  } else {
    dispatch(
      ApiActions.postData(
        "/todos/" + id + "/snooze",
        { days: "7" },
        receiveTodo(id)
      )
    );
  }
};

export const createCustomTodo = (todo) => (dispatch) => {
  dispatch(ApiActions.postData("/todos", todo, receiveTodo()));
};

export const updateTodo = (todo) => (dispatch) => {
  dispatch(ApiActions.postData("/todos/" + todo.id, todo, receiveTodo()));
};

export const deleteTodo = (id) => (dispatch) => {
  dispatch(
    ApiActions.postData(
      "/todos/" + id,
      {},
      () => receiveDeleteTodo(id),
      "delete"
    )
  );
};

export const getNextTodo = () => (dispatch) => {
  dispatch(ApiActions.fetchData("/todos/next", receiveTodo()));
};

export const fetchTodos = () => async (dispatch) => {
  dispatch(ApiActions.fetchData("/todos", receiveTodoList));
};
