import mixpanel from "mixpanel-browser";
import TagManager from "react-gtm-module";
import AnalyticsEvents from "./AnalyticsEvents";

import moment from "moment";
import Api from "./Api";

const isStaging = process.env.REACT_APP_STAGE !== "prod";

const loggedEvents = {};

const mixpanelToken = isStaging
  ? "63879899a18b68e91effd2f4ba879429" // staging token
  : "552d65f17dccc36e455e1c5a41d5792b";

const gtmArgs = {
  gtmId: "GTM-KXD4SVN",
  auth: !isStaging ? "_f1w7JMme_zdc4nvWlWMMw" : "w13hxW9wvR2dgrdWgqoOIg",
  preview: !isStaging ? "env-2" : "env-13",
};

const trackEventInternally = (eventName, data) => {
  const apiData = {
    events: [
      {
        eventName,
        eventSource: "app",
        eventValue: data ? data : null,
        timestamp: moment().unix(),
      },
    ],
  };
  Api.request({ url: "/tracking/events", method: "POST", data: apiData });
};

const logEvent = (name, payload) => {
  if (isStaging) {
    console.log("Analytics Event: " + name);
  }

  TagManager.dataLayer({
    dataLayer: {
      event: name,
      ...payload,
    },
  });
  trackEventInternally(name, payload);

  mixpanel.track(name, payload);
};

// logs an event only once per session
const logEventOnce = (name, payload) => {
  if (!loggedEvents[name]) {
    loggedEvents[name] = true;
    logEvent(name, payload);
  } else {
    if (isStaging) {
      console.log("Analytics Event already logged: " + name);
    }
  }
};

const identifyUser = (userId) => {
  mixpanel.identify("" + userId); // cast to string
};

const logPurchase = (value, product, currency = "eur") => {
  try {
    TagManager.dataLayer({
      dataLayer: {
        product: product,
        transactionTotal: value,
        transactionCurrency: currency,
      },
    });

    if (mixpanel.people) {
      mixpanel.people.track_charge(value, { product });
    } else {
      console.warn("user not identified, cannot track purchase");
    }
  } catch (e) {
    console.warn("error logging purchase");
  }
};

console.log("initialize mixpanel staging? " + isStaging);
mixpanel.init(mixpanelToken, {
  debug: isStaging,
  loaded: function (mixpanel) {
    logEventOnce(AnalyticsEvents.Common.Init);
  },
});
TagManager.initialize(gtmArgs);

export default {
  identifyUser,
  logEvent,
  logEventOnce,
  logPurchase,
  // reset event that should be only logged once to log it again
  resetLoggedEvent: (name) => {
    delete loggedEvents[name];
  },
};
