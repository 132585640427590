import { ReactNode } from "react";
import { BoxProps } from "../../Layout/Box";
import { TypographyProps } from "../../Styled/typography";
import Text from "../Text";

type HeadingProps = {
  as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  children?: ReactNode;
} & TypographyProps &
  BoxProps;

const Heading = ({
  as = "h1",
  fontWeight = "bold",
  fontSize,
  children,
  ...props
}: HeadingProps) => {
  return (
    <Text
      as={as}
      fontWeight={fontWeight}
      fontSize={
        fontSize ||
        ((): TypographyProps["fontSize"] => {
          switch (as) {
            case "h1":
              return "4xl";
            case "h2":
              return "3xl";
            case "h3":
              return "2xl";
            case "h4":
              return { base: "md", md: "xl" };
            case "h5":
              return "lg";
            case "h6":
              return "md";
          }
        })()
      }
      {...props}
    >
      {children}
    </Text>
  );
};

export default Heading;
