import { PlantFamily } from "../../Types/API/Crop";

const seasonSelector = (state : any) => state.Planning.season;
const isPlusAccountSelector = (state : any) => state.Account.isPlusAccount;

const usableCropsSelector = (state: any) => {
  const { crops } = state.CropData;

  const usableComparator = (c: any) =>
    c.isApproved || c.isMine || (c.isFavorite && c.canBeUsedForPlanning);
  if (!crops) {
    return { crops: [] };
  }
  const filteredCrops = crops.filter(
    (c: any) =>
      c.varieties &&
      (usableComparator(c) || c.varieties.some((v: any) => usableComparator(v)))
  );

  return {
    crops: filteredCrops,
  };
};

const usableParentCropsSelector = (state: any) => {
  const { crops: usableCrops } = usableCropsSelector(state);
  return {
    crops: usableCrops.filter((c: any) => c.parentCrop === undefined),
  };
};

const seasonalParentCropsSelector = (state: any) => {
  const {crops: usableCrops} = usableParentCropsSelector(state)
  const season = seasonSelector(state);
  const isPlusAccount = isPlusAccountSelector(state)
  return {
    crops: usableCrops.filter((crop: any) => {
      if (!isPlusAccount) {
        return true;
      }
      if (crop.seasons && crop.seasons.length > 0) {
        return crop.seasons.indexOf(season) > -1;
      }
      return false;
    })
  };
}

const allCropsWithOwnVarietiesSelector = (state: any) => {
  const { crops } = state.CropData;
  return {
    crops: crops.filter((c: any) => c.isMine && c.parentCrop),
  };
};

const allCropsWithFavoriteVarietiesSelector = (state: any) => {
  const { crops } = state.CropData;
  return {
    crops: crops.filter((c: any) => c.isFavorite && c.parentCrop),
  };
};

const selectVarietiesFromParents = (
  parentCrops: any[],
  filterFn: (variety: any) => boolean
) => {
  return parentCrops.reduce((varieties: any[], crop: any) => {
    varieties.push(...(crop.varieties ? crop.varieties.filter(filterFn) : []));
    return varieties;
  }, []);
};

const favoriteParentCropsSelector = (state: any) => {
  const { crops: usableParentCrops } = usableParentCropsSelector(state);
  return {
    crops: usableParentCrops.filter(
      (c: any) =>
        c.isFavorite ||
        (c.varieties && c.varieties.find((v: any) => v.isFavorite))
    ),
  };
};

const favoriteCropsSelector = (state: any) => {
  const { crops: usableCrops } = favoriteParentCropsSelector(state);
  return {
    crops: selectVarietiesFromParents(usableCrops, (c: any) => c.isFavorite),
  };
};

const parentCropsWithMyVarietiesSelector = (state: any) => {
  const { crops: usableParentCrops } = usableParentCropsSelector(state);
  return {
    crops: usableParentCrops.filter((c: any) =>
      c.varieties.find((v: any) => v.isMine)
    ),
  };
};

const myVarietiesCropsSelector = (state: any) => {
  const { crops: usableCrops } = parentCropsWithMyVarietiesSelector(state);
  return {
    crops: selectVarietiesFromParents(usableCrops, (v: any) => v.isMine),
  };
};

const plantFamiliesSelector = (
  state: any
): { plantFamilies: PlantFamily[] } => {
  return { plantFamilies: state.CropData.plantFamilies };
};

export {
  usableCropsSelector,
  usableParentCropsSelector,
  seasonalParentCropsSelector,
  allCropsWithOwnVarietiesSelector,
  allCropsWithFavoriteVarietiesSelector,
  favoriteParentCropsSelector,
  favoriteCropsSelector,
  myVarietiesCropsSelector,
  parentCropsWithMyVarietiesSelector,
  plantFamiliesSelector,
};
