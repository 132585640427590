import { css } from "styled-components";
import theme from "../../Theme";
import {
  createResponisiveCSSProp,
  createResponisiveThemeProp,
  ResponsiveValue,
} from "./Core";

export type FlexContainerProps = {
  flexDirection?: ResponsiveValue<
    "column" | "column-reverse" | "row" | "row-reverse"
  >;
  flexWrap?: ResponsiveValue<"nowrap" | "wrap" | "wrap-reverse">;
  alignItems?: ResponsiveValue<
    "normal" | "flex-start" | "flex-end" | "center" | "stretch"
  >;
  justifyContent?: ResponsiveValue<
    | "normal"
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-around"
    | "space-between"
    | "space-evenly"
  >;
  gap?: ResponsiveValue<keyof Omit<typeof theme.spaces, "auto">>;
};

export const flexContainerDefaultProps: Required<FlexContainerProps> = {
  flexDirection: "row",
  flexWrap: "nowrap",
  alignItems: "normal",
  justifyContent: "normal",
  gap: "0px",
};

const flexContainer = css<FlexContainerProps>(
  ({ flexDirection, flexWrap, alignItems, justifyContent, gap }) => css`
    display: flex;
    ${createResponisiveCSSProp({
      prop: flexDirection,
      property: "flex-direction",
    })}
    ${createResponisiveCSSProp({
      prop: flexWrap,
      property: "flex-wrap",
    })}
    ${createResponisiveCSSProp({
      prop: alignItems,
      property: "align-items",
    })}
    ${createResponisiveCSSProp({
      prop: justifyContent,
      property: "justify-content",
    })}
    ${createResponisiveThemeProp({
      prop: gap,
      property: "gap",
      scale: "spaces",
    })}
  `
);

export default flexContainer;
