import moment from "moment";

import { DATE_FORMAT_API } from "../../../Core/Constants";
import {
  getPlantsDateInPatch,
  getSeasonByMonth,
  getStartAndEnd,
} from "../../../Reducers/Planning/PlanningUtils";

export const getMonthValues = (range) => {
  return getMonthValuesBetweenDates(moment(range.start), moment(range.end));
};

export const getCalendarSeasons = (range) => {
  const minDate = moment(range.start).startOf("month");
  const maxDate = moment(range.end).endOf("month");

  const yearValues = getYearValuesBetweenDates(
    moment(range.start),
    moment(range.end)
  );
  const calendarSeasons = [];
  yearValues.forEach((year) => {
    ["pre", "main", "post"].forEach((season) => {
      const seasonRange = getStartAndEnd(season, year);

      if (seasonRange.start.isAfter(maxDate, "day")) {
        seasonRange.start = moment(maxDate);
      }

      if (seasonRange.start.isBefore(minDate, "day")) {
        seasonRange.start = moment(minDate);
      }

      if (seasonRange.end.isAfter(maxDate, "day")) {
        seasonRange.end = moment(maxDate);
      }

      if (seasonRange.end.isBefore(minDate, "day")) {
        seasonRange.end = moment(minDate);
      }

      const duration = seasonRange.end.diff(seasonRange.start, "days");

      calendarSeasons.push({
        key: season,
        duration: duration ? duration + 1 : duration,
      });
    });
  });
  return calendarSeasons;
};

export const getDaysInYear = (year) => {
  return moment([year + 1, 0, 1]).diff(moment([year, 0, 1]), "days");
};

export const getDaysInCalendarData = (calendarData) => {
  return calendarData.reduce((sum, month) => sum + month.days, 0);
};

export const getDaysUntilDateInCalendarData = (calendarData, dt) => {
  const m = dt.get("month");
  const y = dt.get("year");
  return calendarData
    .filter(
      (cd) => cd.monthIndex <= m && parseInt(cd.value.split("-")[0], 10) <= y
    )
    .reduce((sum, cd) => {
      if (cd.monthIndex === m && parseInt(cd.value.split("-")[0], 10) === y) {
        return sum + dt.get("date");
      } else {
        return sum + cd.days;
      }
    }, 0);
};

export const filterCurrentYearPlants = (year) => (plant) => {
  if (!plant) {
    return;
  }
  //datePlanted is in a future year
  if (
    plant.datePlanted &&
    moment(plant.datePlanted, DATE_FORMAT_API).year() > year
  ) {
    return false;
  }

  //datePlanted is not set and dateInPatch is in a future year
  if (
    !plant.datePlanted &&
    plant.dateInPatch &&
    moment(plant.dateInPatch, DATE_FORMAT_API).year() > year
  ) {
    return false;
  }

  //dateHarvestEnd is in a past year
  if (
    plant.dateHarvestEnd &&
    moment(plant.dateHarvestEnd, DATE_FORMAT_API).year() < year
  ) {
    return false;
  }

  return true;
};

export const getClosestPlantsFromDuplicates = (refPlant, index, plants) => {
  const duplicatePlants = plants.filter(
    (plant) => plant.crop.varietyId === refPlant.crop.varietyId
  );

  duplicatePlants.forEach((plant) => {
    if (plant.uuid === refPlant.uuid) {
      return;
    }

    if (
      !plant.nextPlant &&
      !plant.previousPlant &&
      !refPlant.nextPlant &&
      !refPlant.previousPlant
    ) {
      return;
    }

    if (!refPlant.nextPlant && plant.nextPlant) {
      refPlant.nextPlant = plant.nextPlant;
    }

    if (
      refPlant.nextPlant &&
      plant.nextPlant &&
      moment(getPlantsDateInPatch(refPlant), DATE_FORMAT_API).isAfter(
        moment(getPlantsDateInPatch(plant), DATE_FORMAT_API)
      )
    ) {
      refPlant.nextPlant = plant.nextPlant;
    }

    if (!refPlant.previousPlant && plant.previousPlant) {
      refPlant.previousPlant = plant.previousPlant;
    }

    if (
      refPlant.previousPlant &&
      plant.previousPlant &&
      moment(refPlant.previousPlant.dateHarvestEnd, DATE_FORMAT_API).isAfter(
        moment(plant.previousPlant.dateHarvestEnd, DATE_FORMAT_API)
      )
    ) {
      refPlant.previousPlant = plant.previousPlant;
    }
  });

  return { ...refPlant };
};

export const getYearValuesBetweenDates = (min, max) => {
  const values = [];
  while (max > min || min.format("Y") === max.format("Y")) {
    values.push(min.year());
    min.add(1, "year");
  }

  return values;
};

export const getMonthValuesBetweenDates = (min, max) => {
  const values = [];

  while (max > min || min.format("M") === max.format("M")) {
    values.push({
      monthIndex: min.month(),
      label: min.format("MMM"),
      value: min.format("YYYY-MM"),
      days: min.daysInMonth(),
      isMainSeason: getSeasonByMonth(min.month(), min.year()) === "main",
    });
    min.add(1, "month");
  }

  return values;
};
