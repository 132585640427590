import styled from "styled-components";
import Box, { BoxProps } from "../../Layout/Box";
import { useEffect, useRef } from "react";

type ImageProps = {
  objectFit?: "fill" | "contain" | "cover" | "none" | "scale-down";
  src: string;
  lazy?: boolean;
  alt?: string;
  onLoad?: (event?: any) => void;
};

const StyledImg = styled(Box).attrs(({ lazy }: ImageProps) => ({
  as: "img",
  draggable: "false",
  loading: lazy ? "lazy" : "eager",
}))<ImageProps>`
  object-fit: ${({ objectFit = "fill" }) => objectFit};
`;

const Image = ({
  src,
  alt,
  lazy,
  objectFit,
  ...props
}: ImageProps & BoxProps) => {
  const ref = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.src = src;
    }
    return () => {
      if (ref.current) {
        ref.current.src = "";
      }
    };
  }, [src]);

  return (
    <StyledImg
      ref={ref}
      src={""}
      alt={alt}
      lazy={lazy}
      objectFit={objectFit}
      {...props}
    />
  );
};

export default Image;
