import React, { Suspense, useEffect, useState } from "react";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useParams,
  generatePath,
} from "react-router-dom";

import { Provider, useSelector } from "react-redux";
import { I18nextProvider } from "react-i18next";
import { useCookies, withCookies } from "react-cookie";
import i18n from "./i18n";
import moment from "moment";
import "moment/locale/de";
import "./Setup";
import { createBrowserHistory } from "history";

import * as ApiActions from "../Reducers/Api/Actions";
import * as UserActions from "../Reducers/User/Actions";
import * as AppActions from "../Reducers/Application/Actions";

import Store from "./Store";
import * as CropDataActions from "../Reducers/CropData/Actions";
import { receiveCropcategories } from "../Reducers/CropData/Actions";
import CoreRoutes from "./Routes";
import ConnectionErrorModal from "../Components/ConnectionErrorModal";
import { createTheme, Hidden, withWidth } from "@material-ui/core";
//loading polyfill which add Object.entries and Object.values if not available
import "es7-object-polyfill";

import { ThemeProvider } from "@material-ui/core/styles";
import Theme from "./Theme";
import styledTheme from "../Theme";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import GlobalStyles from "../Base/GlobalStyle";
import { setDeviceDetails } from "../Reducers/GardenLock/Actions";
import { getOrCreateDeviceIdFromLocalStorage } from "../Helpers/UuidHelper";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Screens from "../Screens";
import { DownloadApp } from "../Screens/DownloadApp";
import Api from "./Api";
import ArticleRedirect from "../Screens/Content/ArticleRedirect";
import Urls from "./Urls";
import Images from "./Images";

// importing the Analytics module here to force mixpanel
// initialization on app startup. the call to logEventOnce
// below prevents inadvertent, automatic cleanup of the
// otherwise "unused" import of the Analytics module.
import Analytics from "./Analytics";
import AnalyticsEvents from "./AnalyticsEvents";
Analytics.logEventOnce(AnalyticsEvents.Common.Init);

const theme = createTheme({
  palette: {
    primary: {
      main: "#13905b",
    },
    secondary: {
      main: "#13905b",
    },
  },
  typography: {
    fontFamily: "Karla, sans-serif",
  },
  color: {
    ...Theme.colors,
  },
});

const appInitializationActions = [
  ApiActions.fetchData(
    CoreRoutes.API_ROUTE_DISEASES,
    CropDataActions.receiveDiseases
  ),
  ApiActions.fetchData(
    CoreRoutes.API_ROUTE_PESTS,
    CropDataActions.receivePests
  ),
  ApiActions.fetchData(
    CoreRoutes.API_ROUTE_PLANS,
    CropDataActions.receivePlans
  ),
  ApiActions.fetchData(
    CoreRoutes.API_ROUTE_CROP_CATEGORIES,
    receiveCropcategories
  ),
  CropDataActions.loadPlantFamilies(),
];

export const history = createBrowserHistory();

const WebsiteRedirect = ({ to }) => {
  const location = useLocation();
  const { varietyParam, cropParam } = useParams();
  const navigationRoute = generatePath(to, {
    cropParam,
    varietyParam,
  });
  return <Navigate to={navigationRoute} state={{ from: location }} replace />;
};

const RequireAuth = ({ children }) => {
  const location = useLocation();
  const { isLoggedIn, onboardingFinished } = useSelector((state) => state.User);

  if (!isLoggedIn) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return (
      <Navigate to={CoreRoutes.login} state={{ from: location }} replace />
    );
  } else if (!onboardingFinished) {
    return (
      <Navigate to={CoreRoutes.onboarding} state={{ from: location }} replace />
    );
  }

  return children ? children : <Outlet />;
};

const RedirectFromAppLibraryToWebsiteLibraryIfNotLoggedIn = ({
  appInitialized,
  children,
}) => {
  useEffect(() => {
    const doRedirect = appInitialized && !isWebsite() && Api.getToken() === "";
    if (
      doRedirect &&
      (window.location.pathname === CoreRoutes.library.root ||
        window.location.pathname.startsWith(CoreRoutes.library.cropRoot))
    ) {
      window.location.replace(
        Urls.WEBSITE_URL +
          window.location.pathname.substring(CoreRoutes.library.root.length)
      );
    }
  }, [appInitialized]);

  return <>{children}</>;
};

const WebsiteContent = () => {
  return null;
};

export const isWebsite = () => {
  return process.env.REACT_APP_ENVIRONMENT === "website";
};

const preloadedImages = [];

const Main = () => {
  const [userAccountValidity, setUserAccountValidity] = useState(null);
  const [appInitialized, setAppInitialized] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies();

  const isUnAuthorizedPage = (pathname) =>
    /\/register|password-reset|login|terms|privacy|onboarding|work\/cards|profile\/public/.test(
      pathname
    );

  const isMobileAllowedRoute = () => {
    return /\/password-reset|register\/confirm|buy/.test(
      window.location.pathname
    );
  };

  useEffect(() => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    //setting moment locale de
    moment.localeData("de").weekdays(true);

    // preload some images
    const cloudsSad = new Image();
    cloudsSad.src = Images.images.cloudsSad;
    const clouds = new Image();
    clouds.src = Images.images.clouds;
    const refreshDouble = new Image();
    refreshDouble.src = Images.icons.refreshDouble;
    preloadedImages.push(cloudsSad);
    preloadedImages.push(clouds);
    preloadedImages.push(refreshDouble);

    let deviceId = cookies.deviceId;
    if (!deviceId) {
      deviceId = getOrCreateDeviceIdFromLocalStorage();
      setCookie("deviceId", deviceId, { path: "/" });
    }

    const token = cookies.token;

    Store.dispatch(setDeviceDetails(deviceId));
    Store.subscribe(handleStoreChange);

    //@todo add !isWebsiteRoute() if extra parameter is added
    if (!/\/register|password-reset/.test(window.location.pathname) && token) {
      Api.setToken(token);
      Store.dispatch(
        ApiActions.fetchData("/users/current", UserActions.receiveUser)
      ).then((res) => {
        setUserAccountValidity(UserActions.getUserAccountValidity(res.data));
      });
    }
    if (
      !/\/register|login|article|password-reset/.test(
        window.location.pathname
      ) &&
      !isWebsite()
    ) {
      appInitializationActions.forEach((action) => Store.dispatch(action));
      setAppInitialized(true);
    }
    if (isWebsite()) {
      Store.dispatch(AppActions.setIsWebsite());
    }
  }, []);

  useEffect(() => {
    if (appInitialized) {
      return;
    }
    if (
      !/\/register|login|article|password-reset/.test(
        window.location.pathname
      ) &&
      !isWebsite()
    ) {
      appInitializationActions.forEach((action) => Store.dispatch(action));
      setAppInitialized(true);
    }
  }, [window.location.pathname]);

  const handleStoreChange = () => {
    const state = Store.getState();
    if (state.Application) {
      const color = state.Application.bodyBackgroundColor;
      document.body.style.backgroundColor = color;
    }

    if (state.User && state.User.goToLogout) {
      if (
        window.location.pathname !== CoreRoutes.logout &&
        window.location.pathname !== CoreRoutes.login &&
        window.location.pathname !== CoreRoutes.register.root &&
        window.location.pathname !== CoreRoutes.root
      ) {
        window.location.replace(CoreRoutes.logout);
      }
    }
  };

  // redirects for firebase dynamic linking:
  // the from part is parsed in the native app too, so we have the need to have identical routes
  // for both apps; thats why we redirect here
  const redirects = [
    { from: "/planning", to: "/plan" },
    { from: "/planning/crops", to: "/plan/planting" },
    { from: "/discover/profile", to: "/profile" }, // old profile deep link
    { from: "/community/profile", to: "/profile" }, // new profile deep link
  ];

  const websiteRedirects = [
    {
      from: CoreRoutes.library.root,
      to: CoreRoutes.websiteLibrary.root,
    },
    {
      from: CoreRoutes.library.cropDetails,
      to: CoreRoutes.websiteLibrary.cropDetails,
    },
    {
      from: CoreRoutes.library.varietyDetails,
      to: CoreRoutes.websiteLibrary.varietyDetails,
    },
    {
      from: CoreRoutes.library.varieties,
      to: CoreRoutes.websiteLibrary.varieties,
    },
  ];

  return (
    <I18nextProvider i18n={i18n}>
      <Provider store={Store}>
        {/* TODO: remove MUI ThemeProvider when possible */}
        <ThemeProvider theme={theme}>
          <StyledThemeProvider theme={styledTheme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <GlobalStyles />
              <BrowserRouter basename={process.env.REACT_APP_ROUTING_BASENAME}>
                <RedirectFromAppLibraryToWebsiteLibraryIfNotLoggedIn
                  appInitialized={appInitialized}
                >
                  <Suspense fallback={<div></div>}>
                    <Routes>
                      {isWebsite() &&
                        websiteRedirects.map((r) => (
                          <Route
                            key={"route-" + r.from}
                            path={r.from}
                            element={<WebsiteRedirect to={r.to} />}
                          />
                        ))}
                      {/* website routes */}
                      <Route
                        path={
                          isWebsite()
                            ? CoreRoutes.websiteLibrary.root
                            : CoreRoutes.library.root
                        }
                        element={<Screens.Library />}
                      />
                      <Route
                        path={
                          isWebsite()
                            ? CoreRoutes.websiteLibrary.cropDetails
                            : CoreRoutes.library.cropDetails
                        }
                        element={<Screens.CropDetails />}
                      />
                      <Route
                        path={
                          isWebsite()
                            ? CoreRoutes.websiteLibrary.varieties
                            : CoreRoutes.library.varieties
                        }
                        element={<Screens.VarietyList />}
                      />
                      <Route
                        path={
                          isWebsite()
                            ? CoreRoutes.websiteLibrary.varietyDetails
                            : CoreRoutes.library.varietyDetails
                        }
                        element={<Screens.VarietyDetails />}
                      />
                      <Route
                        path={CoreRoutes.content.article}
                        element={<ArticleRedirect />}
                      />
                      {!isWebsite() && (
                        <>
                          <Route element={<RequireAuth />}>
                            <Route
                              path={CoreRoutes.library.favorites}
                              element={<Screens.FavoritesList />}
                            />
                            <Route
                              path={CoreRoutes.library.isMine}
                              element={<Screens.MyVarietiesList />}
                            />
                            <Route
                              path={CoreRoutes.library.createCrop}
                              element={<Screens.CreateCrop />}
                            />
                            <Route
                              path={CoreRoutes.library.createVariety}
                              element={<Screens.CreateVariety />}
                            />
                            <Route
                              path={CoreRoutes.library.moderation}
                              element={<Screens.Moderation />}
                            />
                            <Route
                              path={CoreRoutes.library.changes}
                              element={<Screens.CropChanges />}
                            />
                          </Route>
                          <Route
                            path={CoreRoutes.library.noMatch}
                            element={<Screens.NoMatch />}
                          />
                        </>
                      )}
                    </Routes>
                    <Hidden smUp>
                      {isMobileAllowedRoute() ? (
                        <Routes>
                          <Route
                            exact
                            path={CoreRoutes.register.confirm}
                            element={<Screens.RegisterConfirm />}
                          />
                          <Route exact path="/" element={<Screens.Login />} />
                          <Route
                            exact
                            path={CoreRoutes.passwordReset.newPassword}
                            element={<Screens.PasswordResetNewPassword />}
                          />
                          <Route
                            exact
                            path={CoreRoutes.passwordReset.root}
                            element={<Screens.PasswordResetRequest />}
                          />
                          <Route
                            exact
                            path={CoreRoutes.buy}
                            element={<Screens.Buy />}
                          />
                        </Routes>
                      ) : (
                        <DownloadApp />
                      )}
                    </Hidden>
                    <Hidden xsDown xsUp={isWebsite()}>
                      <Routes>
                        {/* create redirects for dynamic links */}
                        {redirects.map((r) => (
                          <Route
                            key={"route-" + r.from}
                            path={r.from}
                            element={<Navigate to={r.to} />}
                          />
                        ))}

                        <Route
                          exact
                          path={CoreRoutes.register.root}
                          element={<Screens.Login />}
                        />
                        <Route
                          exact
                          path={CoreRoutes.register.confirm}
                          element={<Screens.RegisterConfirm />}
                        />
                        <Route
                          exact
                          path={CoreRoutes.root}
                          element={<Screens.Login />}
                        />
                        <Route
                          exact
                          path={CoreRoutes.passwordReset.newPassword}
                          element={<Screens.PasswordResetNewPassword />}
                        />
                        <Route
                          exact
                          path={CoreRoutes.passwordReset.root}
                          element={<Screens.PasswordResetRequest />}
                        />
                        <Route
                          exact
                          path={CoreRoutes.login}
                          element={<Screens.Login />}
                        />
                        <Route
                          path={CoreRoutes.logout}
                          element={<Screens.Login />}
                        />
                        <Route
                          path="/library/cropcreator"
                          element={<Screens.CropCreator />}
                        />
                        <Route
                          path={CoreRoutes.terms}
                          element={<Screens.Terms />}
                        />
                        <Route
                          path={CoreRoutes.privacy}
                          element={<Screens.Privacy />}
                        />
                        <Route
                          path={CoreRoutes.buy}
                          element={<Screens.Buy />}
                        />
                        <Route
                          path={CoreRoutes.onboarding}
                          element={<Screens.Onboarding />}
                        />
                        <Route element={<RequireAuth />}>
                          <Route
                            path={CoreRoutes.profile}
                            element={<Screens.UserPage />}
                          />
                          <Route
                            path={CoreRoutes.account.root}
                            element={<Screens.Account />}
                          />
                          <Route
                            path={CoreRoutes.library.cropCreatorNewCrop}
                            element={<Screens.CropCreator />}
                          />
                          <Route
                            path={CoreRoutes.library.cropCreatorEditCrop}
                            element={<Screens.CropCreator />}
                          />
                          <Route
                            path={CoreRoutes.plan.root}
                            element={<Screens.Planning />}
                          />
                          <Route
                            path={CoreRoutes.plan.root + "/*"}
                            element={<Screens.Planning />}
                          />
                          <Route
                            path={CoreRoutes.shop.root}
                            element={<Screens.Shop />}
                          />
                          <Route
                            path={CoreRoutes.shop.payment.confirm}
                            element={<Screens.Shop />}
                          />
                          <Route
                            path={CoreRoutes.shop.payment.cancel}
                            element={<Screens.Shop />}
                          />
                          <Route
                            path={CoreRoutes.gardening.root}
                            element={<Screens.Gardening.GardeningPage />}
                          >
                            <Route
                              index
                              element={<Screens.Gardening.SeasonalOverview />}
                            />
                            <Route
                              path="work"
                              element={<Screens.Gardening.Work />}
                            />
                            <Route
                              path="season"
                              element={<Screens.Gardening.SeasonalOverview />}
                            />
                          </Route>
                          <Route
                            path={CoreRoutes.growth.season}
                            element={<Screens.Growth />}
                          />
                          <Route
                            path={CoreRoutes.growth.forecast}
                            element={<Screens.Growth />}
                          />
                          <Route
                            path={CoreRoutes.community.root}
                            element={<Screens.Community />}
                          />
                          <Route
                            path={CoreRoutes.community.bookmarks}
                            element={<Screens.Community />}
                          />
                          <Route
                            path={CoreRoutes.community.post}
                            element={<Screens.Community />}
                          />
                          <Route
                            path={CoreRoutes.community.newPost}
                            element={<Screens.Community />}
                          />
                          <Route
                            path={CoreRoutes.community.userProfile}
                            element={<Screens.Community />}
                          />
                          <Route
                            path={CoreRoutes.community.notifications}
                            element={<Screens.Community />}
                          />
                          <Route
                            path={CoreRoutes.community.trends}
                            element={<Screens.Community />}
                          />
                        </Route>
                      </Routes>
                    </Hidden>
                  </Suspense>
                  <ConnectionErrorModal />
                </RedirectFromAppLibraryToWebsiteLibraryIfNotLoggedIn>
              </BrowserRouter>
            </MuiPickersUtilsProvider>
          </StyledThemeProvider>
        </ThemeProvider>
      </Provider>
    </I18nextProvider>
  );
};

export default withWidth()(withCookies(Main));
