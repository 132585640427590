import {
  MagicWandMenuEntryType,
  MagicWandOptions,
  MagicWandWarnings,
  WaterAccessType,
} from "../Screens/Planning/PatchPlan/Consts";
import {
  ACCOUNT_TYPE_FREE,
  ACCOUNT_TYPE_PAID,
  ACCOUNT_TYPE_PLUS,
  ACCOUNT_TYPE_PRO,
  PAYMENT_INTERVAL_MONTHLY,
  PAYMENT_INTERVAL_QUARTERLY,
  PAYMENT_INTERVAL_YEARLY,
} from "../Core/Constants";
import { SurveyChoice, TopicOfInterest } from "../Screens/Onboarding/Consts";
import { CategoryType } from "../Screens/Library/CropFilters";
import { CropActionType } from "../Types/API/Crop";
import { VoucherStatusMessageType } from "../Types/API/Voucher";

// for test environment
if (typeof GeolocationPositionError === "undefined") {
  var GeolocationPositionError = {
    PERMISSION_DENIED: 1,
    POSITION_UNAVAILABLE: 2,
    TIMEOUT: 3,
  };
}

export default {
  common: {
    loading: "Lade...",
    numbers: {
      1: "einen",
      2: "zwei",
      3: "drei",
      4: "vier",
      5: "fünf",
      6: "sechs",
      7: "sieben",
      8: "acht",
      9: "neun",
      10: "zehn",
      11: "elf",
      12: "zwölf",
    },
    numeral: {
      1: "erste",
      2: "zweite",
      3: "dritte",
      4: "vierte",
      5: "fünfte",
      6: "sechste",
      7: "siebte",
      8: "achte",
      9: "neunte",
      10: "zehnte",
      11: "elfte",
      12: "zwölfte",
    },
    actions: {
      back: "Zurück",
      cancel: "Abbrechen",
      confirm: "Bestätigen",
      delete: "Löschen",
      discardAll: "Alles verwerfen",
      edit: "Bearbeiten",
      reset: "Zurücksetzen",
      save: "Speichern",
      done: "Fertig",
      continue: "Weiter",
      begin: "Start",
      ok: "Ok",
      go: "Los geht's!",
      skip: "Überspringen",
      send: "Absenden",
      apply: "Anwenden",
      registerForFree: "Kostenlos anmelden",
      clearSelection: "Auswahl löschen",
      showAll: "Alle anzeigen",
      showLess: "Weniger anzeigen",
    },
    placeholder: {
      email: "E-Mail",
      firstName: "Vorname",
      password: "Passwort",
    },
    units: {
      cm: "cm",
      m: "m",
      kg: "kg",
      euro: "€",
    },
  },
  passwordReset: {
    resetPassword: "Passwort zurücksetzen",
    enterEmail:
      "Bitte gib deine E-Mail-Adresse ein, damit wir dir einen Link zum\n" +
      "Zurücksetzen deines Passwortes zusenden können.",
    enterPassword: "Bitte gib ein neues Passwort ein.",
    repeatPassword: "Passwort wiederholen",
    savePassword: "Passwort speichern",
    passwordRequired: "Bitte gib ein Passwort ein.",
    inputsMustBeEqual: "Die Passworte stimmen nicht überein.",
    emailSent:
      "Wir haben dir eine E-Mail zum Zurücksetzen deines Passwortes\n" +
      "gesendet. Bitte prüfe deinen Posteingang.",
    passwordIsReset:
      "Dein Passwort wurde zurückgesetzt. Du kannst dich ab sofort\n" +
      "damit einloggen.",
    button: {
      sendRequest: "E-Mail anfordern",
      backToLogin: "Zurück zum Login",
    },
    retry:
      "Es gab leider ein Problem beim Zurücksetzen deines Passwortes.\n" +
      "Bitte fordere <0>hier</0> einen neuen Link zum Zurücksetzen an.",
    errorMessage:
      "Beim Senden der E-Mail zum Zurücksetzen des Kennworts ist ein\n" +
      "Problem aufgetreten.",
  },
  login: {
    signIn: "Anmelden",
    login: "Einloggen",
    loggedInAs: "Angemeldet als",
    register: "Registrieren",
    resetPassword: "Passwort zurücksetzen",
    changeAccount: "Konto wechseln",
    haveAnAccount: "Du hast bereits einen Account?",
    or: "oder",
    termsInfo:
      "Mit Klick auf “{{button}}” stimmst du den <0>Datenschutzbestimmungen</0> und den <1>Nutzungsbedingungen</1> von Fryd zu.",
    terms: "Nutzungsbedingungen",
    privacy: "Datenschutzbestimmungen",
    newsletter:
      "Ich möchte regelmäßig Tipps und interessante Themen per Mail bekommen.",
    oldVersionText:
      "Auf der Suche nach Alphabeet? Die alte Version unseres Beetplaners ",
    oldVersionLink: "findest du hier",
    orLoginWith: "oder logge dich ein mit:",

    error: {
      wrong_credentials:
        "E-Mail oder Passwort falsch. Bitte versuche es erneut.",
      not_active:
        "Dein Account wurde noch nicht aktiviert. Bitte prüfe deine E-Mails und klicke auf den Aktivierungslink in der E-Mail. Falls du deine Aktivierungsmail noch einmal bekommen möchtest <0>klicke bitte hier.</0>",
      opt_in_send:
        "Wir haben dir deine Aktivierungsmail erneut zugesendet. Bitte prüfe dein E-Mail-Postfach und klicke auf den Link in der E-Mail.",
      social_login_error: "Fehler beim Anmelden. Bitte versuche es erneut.",
      social_user_not_found:
        "Dieses Konto existiert bisher nicht. Bitte registriere dich zunächst.",
    },
  },
  badges: {
    short: {
      isApproved: "geprüft",
      isIncomplete: "unvollständig",
      communityVariety: "Community",
      userVariety: "Eigene",
    },
    isApproved: "geprüfte Daten",
    isIncomplete: "unvollständige Sorte",
    communityVariety: "Community Sorte",
    userVariety: "Eigene Sorte",
    isAvailable: "lieferbar",
    isNotAvailable: "nicht lieferbar",
    comingSoon: "coming soon",
    plus: "plus",
    pro: "pro",
    verySpecial: "very special",
  },
  cropDetails: {
    cropSuggession: {
      suggest: "Vorschläge prüfen",
      headline: "Deine Sorten kommen an!",
      text: "Yeah, du hast {{number}} Vorschläge für Sorten, die du angelegt hast. Prüfe sie jetzt gleich!",
    },
    rateModal: {
      headline: "Bewerten",
      text: "Gib dieser Sorte deine Bewertung. Was du bewertest, bleibt dir überlassen: Geschmack, Ertrag, Pflegeleichtigkeit, etc.",
      sendRating: "Bewertung abschicken",
    },
    communityEditBox: {
      editVariety: "Sorte bearbeiten",
      editVarietyText: "Du kannst für diese Sorte Änderungen vorschlagen.",
      editCrop: "Art bearbeiten",
      suggestEdits: "Änderungen vorschlagen",
      changesLocked: "Änderungen werden geprüft",
      changesLockedText:
        "Du hast Änderungen vorgeschlagen. Diese werden gerade geprüft. Wir benachrichtigen dich!",
      changesBeingChecked: "Änderungen werden geprüft",
      changesBeingCheckedText:
        "Für diese Sorte liegen Änderungsvorschläge vor, die wir gerade prüfen. Du kannst sie erst bearbeiten, wenn die Sorte wieder freigeben ist.",
    },
    incompleteCropBox: {
      headline: "Diese Sorte ist nicht vollständig",
      text: "Diese Sorte ist nicht komplett, es fehlen Daten. Du kannst jetzt dazu beitragen, die Sorte zu vervollständigen.",
      completeData: "Daten vervollständigen",
    },
    editCropsBox: {
      headline: "Eigene Arten und Sorten",
      text: "Aktuell kannst du nur eigene Sorten direkt bearbeiten. Wenn du die darüber liegende Art bearbeiten möchtest, dann kontaktiere uns bitte per Mail.",
      exampleCrop: "Beispiel für eine Art:",
      exampleVariety: "Beispiel für eine Sorte:",
      contactSupport: "Support kontaktieren",
      tomato: "Stabtomate",
      tomatoVariety: "Harzfeuer",
    },
    moderation: {
      changeByFryd: "Änderung durch Fryd",
      changeByFollowingUser: "Änderung vorgeschlagen von ",
      communityVariety: "Community Sorte",
      isVariety: "Sorte: ",
      isCrop: "Art: ",
    },
    headline: "Detailansicht",
    headlineMobile: "Allgemeine Arteninfo",
    parent: "Art: {{name}}",
    parentCrop: "Art",
    parentCrops: "Arten",
    variety: "Sorte",
    varieties: "Sorten",
    details: "Details",
    seed: "Saatgut",
    plantFamily: "Pflanzenfamilie",
    alias: "Auch bekannt als",
    backToVarietyOverview: "zurück zur Sortenübersicht",
    errorLoadingCrop: "Daten konnten nicht geladen werden",
    varietyOverview: "Sortenübersicht",
    varietySearchPlaceholder: "Suche nach Sorten",
    showAllVarieties: "Alle Sorten anzeigen",
    varietyResults: "{{amount}} Ergebnisse",
    filterAll: "Filter",
    noVarietiesFound:
      "Wir konnten keine Sorte mit diesem Namen finden. Wenn du eine bisher nicht vorhandene Sorte anlegen möchtest, kannst du das unter dem Menüpunkt <0>Neue Sorte anlegen</0> selbst tun.",
    Overview: "Übersicht",
    backToOverview: "zurück zur Übersicht",
    orderSeedNow: "Jetzt Saatgut bestellen",
    cropImage: "Bild der Pflanze",
    previewImage: "Bild der Pflanze",
    seedPackageImage: "Bild der Saatgutpackung",
    cropDescription: "Beschreibung",
    noOriginInfo: "Keine für diese Pflanze vorhanden",
    sowing: "Aussaat",
    growingTips: "Anbautipps",
    noGrowingTips: "Keine Anbautipps für diese Pflanze vorhanden.",
    varietyGrowingTips: "Sortenspezifische Anbautipps",
    conditions: "Bedingungen",
    goodNeighbours: "Gute Nachbarn",
    badNeighbours: "Schlechte Nachbarn",
    noGoodNeighbours: "Keine guten Nachbarn vorhanden.",
    noBadNeighbours: "Keine schlechten Nachbarn vorhanden.",
    plants: "Pflanzen",
    diseases: "Krankheiten",
    pests: "Schädlinge",
    varietyDescription: "Sortenbeschreibung: {{description}}",
    origin: "Herkunft:",
    selectDifferentVariety: "Andere Sorte wählen",
    noDiseasesAvailable: "Keine Krankheiten vorhanden.",
    noPestsAvailable: "Keine Schädlinge vorhanden.",
    lightingDemand: "Lichtbedarf",
    nutrientDemand: "Nährstoffbedarf",
    seedingDistance: "Pflanzabstand",
    seedingDepth: "Pflanztiefe",
    rowDistance: "Reihenabstand",
    lightDemand: "Lichtbedarf",
    waterDemand: "Wasserbedarf",
    groundType: "Boden",
    raising: "Anzucht",
    precultivation: "Vorziehen",
    directSeeding: "Direktsaat",
    centimeter: "cm",
    noRows: "Keine Reihen",
    createdAt: "angelegt am {{date}}",
    communityVarietyCreatedBy: "angelegt von {{creator}} am {{date}}",
    myVariety: "angelegt von Dir am {{date}}",
    seedPackage: "Saatgutpackung",
    editName: "Name ändern",
    addMorePhotos: "Weitere Fotos hinzufügen",
    category: "Kategorie",
    editPlantFamily: "Pflanzenfamilie ändern",
    editCategory: "Kategorie ändern",
    editCropActions: "Anleitungen ändern",
    properties: "Eigenschaften",
    editProperties: "Eigenschaften anpassen",
    seasons: "Saisonübersicht",
    editSeasons: "Zeiträume bearbeiten",
    additionalAttributes: "Weitere Eigenschaften",
    seedingDistanceHint: "innerhalb einer Reihe",
    rowDistanceHint: "zwischen den Reihen",
    seedingDepthHint: "Wie weit in die Erde",
    editVariety: "Sorte bearbeiten",
    editCrop: "Art bearbeiten",
    lockCrop: "Art sperren",
    lockVariety: "Sorte sperren",
    cropIsShared: "Sorte ist freigegeben",
    suggestEdits: "Änderungen vorschlagen",
    incomplete: "Daten vervollständigen",
    isApproved: "geprüfte Sorte",
    isApprovedCrop: "geprüfte Art",
    markApproved: "Sorte als geprüft markieren",
    markUnapproved: "Sorte als nicht geprüft markieren",
    intoCart: "in den Warenkorb",
    removeFromCart: "aus dem Warenkorb",
    editRating: "Sorte bewerten",
    alreadyRated: "Du hast die Sorte bewertet",
    inYourFavorites: "Gehört zu deinen Favoriten",
    addToFavorites: "Zu Favoriten hinzufügen",
    nonHybridSeed: "Samenfest",
    hybridSeed: "F1-Hybrid",
    coldResistant: "Frostsicher",
    notColdResistant: "Nicht frostsicher",
    nameOfCrop: "Name der Art",
    nameOfCropPlaceholder: "z.B. Stabtomate",
    nameOfVariety: "Name der Sorte",
    nameOfVarietyPlaceholder: "z.B. Harzfeuer",
    varietyExistsNotice:
      "Diese Sorte existiert bereits. Bitte vergebe einen anderen Namen, wenn du sie dennoch anlegen möchtest",
    cropNameEditForbidden: "Du kannst den Namen der Art nicht verändern.",
    deleteCrop: "Art löschen",
    deleteVariety: "Sorte löschen",
    deleteVarietyText:
      "Möchtest du diese Sorte wirklich löschen? Sie verschwindet dabei sowohl aus deiner Bibliothek, als auch aus dem Plan und den Aufgaben. Du kannst diese Aktion nicht rückgängig machen.",
    deleteCropText:
      "Möchtest du diese Art wirklich löschen? Du kannst diese Aktion nicht rückgängig machen.",
    addMissingValue: "Fehlende Information ergänzen",
    approvedVarietyModal: {
      headline: "Geprüfte Daten",
      content:
        "Um die Richtigkeit der Angaben zu gewährleisten, prüft unser Biologie-Team nach und nach alle Daten. Der Haken zeigt dir, welche Sortenübersichten bereits kontrolliert und für gut befunden wurden.",
    },
    incompleteInfoModal: {
      headline: "Unvollständig",
      content:
        "Die Angaben für diese Sorte sind unvollständig. Hier können Saisondaten, Pflanz- und Reihenabstände oder ähnliches fehlen.",
    },
    hybridSeedModal: {
      headline: "Was bedeutet F1-Hybrid?",
      content:
        "Viele Konzerne produzieren und verkaufen sogenannte F1-Sorten. F1-Pflanzen bringen entweder mehr Ertrag oder haben besonders gleichmäßig geformte Früchte beziehungsweise ausgeprägte Resistenzen gegen Krankheiten. Auf den ersten Blick erscheinen diese Züchtungen vorteilhaft, allerdings bringen sie den/die Käufer:in in eine Abhängigkeit. F1-Sorten sind Hybridzüchtungen, das heißt, es werden zwei verschiedene Sorten miteinander gekreuzt. Dafür werden gewöhnlich künstliche Inzuchtlinien erzeugt. Die Nachkommen dieser reinerbigen Eltern werden in der ersten (F1) Generation alle die gleichen Eigenschaften haben. Nimmt man aber von diesen Pflanzen eigenes Saatgut und sät es wieder aus, so wird die nächste Generation (F2) absolut unterschiedlich.",
    },
    nonHybridSeedModal: {
      headline: "Was bedeutet samenfest?",
      content:
        "Eine Pflanzensorte ist immer dann samenfest, wenn aus ihrem Saatgut wieder eine „gleiche“, Pflanze nachwächst. Setzt man beispielsweise samenfeste Tomatensamen in den Boden, wächst daraus eine Tomatenpflanze mit ähnlicher Gestalt und Eigenschaften wie die Elternpflanze.",
    },
    shareCropsBox: {
      headline: "Willst du die Sorte für die Community freigeben?",
      headlineWaitingForApproval: "Community-Freigabe wird geprüft",
      text: "Diese Sorte ist noch nicht in der Pflanzenbibliothek vorhanden.",
      textWaitingForApproval:
        "Diese Sorte wird von unserem Moderationsteam geprüft und dann freigegeben.",
      shareCrop: "Sorte freigeben",
      successHeadline: "Du bist super!",
      successText:
        "Yeah, großartig! Vielen Dank, dass du deine Sorten mit der Community teilst. So trägst du einen großen Teil dazu bei, dass die Sortenvielfalt immer weiter wächst.",
    },
    refuseCropOptions: {
      varietyExists: "Sorte schon vorhanden",
      varietyExistsText:
        "Deine Sorte wurde nicht freigegeben, weil diese Sorte in der Datenbank schon existiert.",
      wrongInformation: "Falsche Information",
      wrongInformationText:
        "Deine Sorte wurde nicht freigegeben, weil unser Moderationsteam falsche Informationen festgestellt hat.",
      dataMissing: "Daten nicht vorhanden",
      dataMissingText:
        "Deine Sorte wurde nicht freigegeben, weil wichtige Daten fehlen.",
      copyrightInfringement: "Copyright-Verletzung",
      copyrightInfringementText:
        "Deine Sorte wurde nicht freigegeben, weil unser Moderationsteam eine Copyright-Verletzung vermutet.",
      other: "Sonstiges",
    },
    approveVariety: "Sorte freigeben",
    refuseVariety: "Freigabe ablehnen",
    approveCrop: "Art freigeben",
    refuseCrop: "Freigabe ablehnen",
    cropActionsEditor: {
      title: "Anleitungen",
      label: {
        type: "Aktion",
        title: "Titel",
        text: "Text",
        notificationText: "Notification-Text",
        daysStart: "Nach x Tagen",
        doesRepeat: "Wiederholt sich?",
        repeatDays: "Wiederholt sich nach x Tagen",
        daysEnd: "Ende nach x Tagen",
        additionalData: {
          title: "Zusatzdaten",
          fertilizer: "Dünger",
        },
      },
      error: {
        crudError: "Fehler beim Speichern",
        type: {
          required: "Der Anleitungstyp muss angegeben werden.",
        },
        title: {
          required: "Der Titel muss angegeben werden.",
        },
        text: {
          required: "Der Text muss angegeben werden.",
        },
        notificationText: {
          required: "Der Notification Text muss angegeben werden.",
        },
        daysStart: {
          required: "Der Start in x Tagen muss angegeben werden.",
        },
        daysEnd: {
          required:
            "Das Ende nach x Tagen muss angegeben werden, wenn 'Wiederholt sich' angegeben ist.",
        },
      },

      [CropActionType.Düngen]: "Düngen",
      [CropActionType.Jäten]: "Jäten",
      [CropActionType.Fraßschutz]: "Fraßschutz",
      [CropActionType.Ernten]: "Ernten",
      [CropActionType.Vorziehen]: "Vorziehen",
      [CropActionType.Einpflanzen]: "Einpflanzen",
      [CropActionType.Anbinden]: "Anbinden",
      [CropActionType.Hygiene]: "Hygiene",
      [CropActionType.Pflücken]: "Pflücken",
      [CropActionType.Pikieren]: "Pikieren",
      [CropActionType.Ausgeizen]: "Ausgeizen",
      [CropActionType.Abhärten]: "Abhärten",
      [CropActionType.Abdecken]: "Abdecken",
      [CropActionType.Mulchen]: "Mulchen",
      [CropActionType.Anhäufeln]: "Anhäufeln",
      [CropActionType.Zurückschneiden]: "Zurückschneiden",
      [CropActionType.Ausdünnen]: "Ausdünnen",
      [CropActionType.Erziehen]: "Erziehen",
      [CropActionType.Hacken]: "Hacken",
      [CropActionType.Stützen]: "Stützen",
      [CropActionType.Direktsaat]: "Direktsaat",
    },
    articles: {
      title: "Mehr zum Thema {{topic}}",
      readingTime: "Lesezeit: {{readingTime}} Minuten",
    },
  },
  moderationLockModal: {
    headline: "Sorte sperren",
    lockNow: "Jetzt sperren",
    selectAlternativeVarieties: "Alternative Sorten wählen",
    selectAlternativeInfo:
      "Die Sorte {{cropName}} wird von {{usage}} Nutzer(n) außer dem Ersteller verwendet. Bitte wähle eine alternative Sorte aus. Diese Sorte wird in den Beeten der Nutzer anstelle von {{cropName}} eingesetzt.",
    unusedCropInfo:
      "Diese Sorte wird von keinem anderen Nutzer außer dem Ersteller genutzt. Du kannst sie jetzt sperren.",
  },
  cropList: {
    lightingDemand: {
      sunny: "sonniger Standort",
      halfShady: "halbschattiger Standort",
      shady: "schattiger Standort",
    },
    nutrientDemand: {
      high: "hoher Nährstoffbedarf",
      medium: "mittlerer Nährstoffbedarf",
      low: "niedriger Nährstoffbedarf",
    },
    waterDemand: {
      dry: "niedriger Wasserbedarf",
      wet: "mittlerer Wasserbedarf",
      veryWet: "hoher Wasserbedarf",
    },
  },
  patchPlan: {
    announcementModal: {
      alrightUnderstood: "Alles klar, verstanden",
      descriptionText:
        "Wir haben vor kurzem ein grundlegendes Update der Smartphone-App gemacht, die Desktop-Version ist allerdings noch auf dem alten Stand. Wir werden ca. Ende Februar auch diese Version updaten.",
      headline: "Ein großes Desktop-Update steht kurz bevor",
      subHeadline: "Alphabeet 2.0 auch am Desktop",
      warningHeadline: "Nicht rückwärtskompatibel",
      warningText:
        "Bitte Vorsicht: deine Pläne, die du jetzt noch in der alten App machst, können nicht automatisch übertragen werden. Die alte Version bleibt jedoch weiterhin erreichbar. Es gehen keine Daten verloren.",
    },
  },
  onboarding: {
    accountCreation: {
      headline: ["Nur noch einen Moment, wir", "legen deinen Account an..."],
    },
    gardenType: {
      headline: ["Welches Bild kommt deinem", "Garten am Nächsten?"],
      large: "Großer Gemüsegarten",
      balcony: "Balkon",
      small: "Kleines Beet",
    },
    signUp: {
      newsletter:
        "Ich möchte regelmäßig Tipps und interessante Themen per Mail bekommen.",
      headline: ["Großartig! Jetzt nur noch schnell", "registrieren"],
      paragraph: [
        "So kannst du Fryd auf mehreren Geräten",
        "nutzen ‒ als App und im Browser.",
      ],
      social: "oder melde dich an mit:",
      redeemVoucher: "Gutscheincode einlösen?",
    },
    survey: {
      headline: ["Wie bist du auf uns aufmerksam", "geworden?"],
      choice: {
        [SurveyChoice.SEARCH]: {
          label: "Ich habe gezielt gesucht",
          subLabel: "z.B. auf Google",
        },
        [SurveyChoice.RECOMMENDATION]: {
          label: "Ihr wurdet mir empfohlen",
          subLabel: "z.B. von einer anderen Person",
        },
        [SurveyChoice.READ]: {
          label: "Ich habe von euch gehört",
          subLabel: "z.B. Podcast, Magazin",
        },
        [SurveyChoice.ADS]: {
          label: "Ich habe eure Werbung gesehen",
          subLabel: "z.B. Anzeige auf Instagram",
        },
        [SurveyChoice.OTHER]: {
          label: "Sonstiges",
          placeholder: "Bitte gib hier deine Antwort ein",
        },
      },
    },
    topicsOfInterest: {
      headline: "Welche Themen interessieren dich?",
      topic: {
        [TopicOfInterest.OWN_CULTIVATION]: "Eigene Anzucht",
        [TopicOfInterest.SEED_PROOF]: "samenfestes Saatgut",
        [TopicOfInterest.OLD_VARIETIES]: "Alte Sorten",
        [TopicOfInterest.ECOLOGICAL_GARDENING]: "Ökologisch Gärtnern",
        [TopicOfInterest.RAISED_BEDS]: "Hochbeete",
        [TopicOfInterest.PERMA_CULTURE]: "Permakultur",
        [TopicOfInterest.MIXED_CULTURE]: "Mischkultur",
        [TopicOfInterest.PRESERVE_HARVEST]: "Ernte haltbar machen",
        [TopicOfInterest.GARDENING_WITH_KIDS]: "Gärtnern mit Kids",
        [TopicOfInterest.SOIL_IMPROVEMENT]: "Bodenverbesserung",
        [TopicOfInterest.WINTER_VEGETABLES]: "Wintergemüse",
        [TopicOfInterest.GREEN_FERTILIZATION]: "Gründüngung",
        [TopicOfInterest.GREEN_HOUSE]: "Gewächshaus",
        [TopicOfInterest.PEST_PREVENTION]: "Schädlingen vorbeugen",
        [TopicOfInterest.BENEFICIAL_INSECTS]: "Nützlinge",
        [TopicOfInterest.BALCONY_GARDENING]: "Balkongärtnern",
        [TopicOfInterest.TREES_AND_SHRUBS]: "Bäume und Büsche",
        [TopicOfInterest.HERBS]: "Kräuter",
        [TopicOfInterest.FRUITS]: "Obst",
        [TopicOfInterest.VEGETABLES]: "Gemüse",
      },
    },
    welcome: {
      headline: "Hallo, schön, dass du da bist!",
      paragraph:
        "Für deinen persönlichen Saisonplan möchten wir dich besser kennenlernen.",
      finePrint: [
        "Mit Klick auf “Weiter” stimmst du den ",
        "Datenschutzbestimmungen",
        " und den ",
        "Nutzungsbedingungen",
        " von Fryd zu.",
      ],
    },
    welcoming: {
      headline: ["Herzlich Willkommen bei", "Fryd {{type}}!"],
      feature: {
        headlineFree: ["Du hast jetzt dauerhaft", "kostenlosen Zugriff auf:"],
        headline: ["Du hast jetzt dauerhaft", "Zugriff auf:"],
        mixedCulturePlanner: "Den Mischkultur-Beetplaner",
        library: ["Die Pflanzenbibliothek mit über", "3.000 Sorten Gemüse"],
        community: "Die Fryd-Community",
        inspiration: "Inspiration, Podcasts & Artikel",
      },
    },
    error: {
      userExists:
        "Ein Benutzer mit dieser E-Mail-Adresse existiert bereits. Bitte wähle eine andere E-Mail Adresse.",
      unknown:
        "Bei der Registrierung ist leider ein Fehler aufgetreten. Bitte versuche es erneut.",
      emailInvalid: "Bitte gib eine gültige E-Mail-Adresse ein.",
      passwordLength: "Das Passwort muss aus mindestens 6 Zeichen bestehen.",
      firstNameRequired: "Bitte gib deinen Vornamen an.",
    },
  },
  cropAssistant: {
    cropDetails: {
      species: "Art",
      variety: "Sorte",
      scientificName: "Wissenschaftlicher Name",
    },
    intro: {
      headline: "Deine erste eigene Sorte",
      text:
        "Wir begleiten dich nun dabei, eine eigene Sorte anzulegen. Falls du eine Angabe nicht weißt, kannst du den Punkt ggf. überspringen. Aber: je mehr Daten du eingibst, desto besser.\n" +
        "\n" +
        "Wenn du oben rechts auf das x klickst, kannst du den Fortschritt bis dahin entweder verwerfen, oder speichern und später weiter machen.",
    },
    cropName: {
      createNewCrop: 'Neue Art "{{label}}" anlegen',
      headline: "Wähle einen Namen für Art und Sorte",
      createCropHeadline: "Wähle einen Namen für die Art",
      description:
        "Bitte achte darauf, dass der Name korrekt geschrieben ist, wie du ihn auf der Saatgut-Tüte liest, damit andere Nutzer:innen deine Sorte finden und nutzen können.",
      createCropDescription:
        "Bitte achte darauf, dass der Name korrekt geschrieben ist, damit andere Nutzer:innen deine Art finden und nutzen können.",
      nameOfCrop: "Name der Art",
      nameOfCropPlaceholder: "z.B. Stabtomate",
      nameOfVariety: "Name der Sorte",
      nameOfVarietyPlaceholder: "z.B. Harzfeuer",
      noCropFoundText: "Für diesen Suchbegriff konnten wir keine Art finden.",
      cropExistsNotice:
        "Diese Name wird bereits für eine Art oder Sorte verwendet. Bitte vergebe einen anderen Namen.",
      varietyExistsNotice:
        "Diese Sorte existiert bereits. Bitte vergebe einen anderen Namen, wenn du sie dennoch anlegen möchtest",
    },
    category: {
      headline: "In welche Kategorie gehört \n die Pflanze?",
      pleaseSelect: "Bitte wähle ",
      one: "eine",
      category: " Kategorie",
    },
    plantFamily: {
      headline: "Welcher Pflanzenfamilie gehört \n die Pflanze an?",
      pleaseSelect: "Bitte wähle ",
      one: "eine",
      category: " Pflanzenfamilie",
    },
    images: {
      headline: "Möchtest du ein Bild hinzufügen?",
      pictureOfPlant: "Foto von der Pflanze",
      pictureOfPlantText:
        "Dieses Foto wird später im Plan und in der Bibliothek zu sehen sein.",
      pictureOfPackage: "Foto von der Samentüte",
      pictureOfPackageText:
        "Dieses Foto wird in das Profil der Pflanze eingefügt, um später Infos nachschauen zu können.",
    },
    tags: {
      add: "hinzufügen",
      noTagsForSearchTerm: "Bisher gibt es zu diesem Begriff keinen Tag.",
      wuchsform: {
        headline: "Wie ist die Wuchsform deiner {{cropType}}?",
        infoText:
          "So machst du es anderen Gärtner:innen viel leichter, die richtige {{cropType}} zu finden.",
        placeholderText: "Wuchsform deiner {{cropType}}",
      },
      fruchtform: {
        headline: "Wie ist die Fruchtform deiner {{cropType}}?",
        infoText:
          "Die Früchte verschiedener {{cropTypeMultiples}} können ganz unterschiedlich aussehen. Erzähl’ uns mehr über deine Sorte.",
        placeholderText: "Fruchtform deiner {{cropType}}",
      },
      standort: {
        headline: "Für welchen Standort ist deine {{cropType}} geeignet?",
        infoText:
          "Nicht jede {{cropType}} ist für jeden Standort geeignet. Verrate anderen, wo deine {{cropType}} gerne wächst.",
        placeholderText: "Standort deiner {{cropType}}",
      },
      farbe: {
        headline: "Welche Farbe hat deine {{cropType}}?",
        infoText:
          "Auch die Farben verschiedener {{cropTypeMultiples}} unterscheiden sich. ",
        placeholderText: "Farbe deiner {{cropType}}",
      },
      sonstiges: {
        headline: "Und sonstige Eigenschaften deiner {{cropType}}?",
        infoText:
          "Hat deine {{cropType}} noch weitere wichtige Eigenschaften? ",
        placeholderText: "Eigenschaft deiner {{cropType}}",
      },
    },
    seedingDistance: {
      headline: "Pflanzabstand",
      notice:
        "Wenn auf deiner Saatgutpackung zwei Werte angegeben sind (z.B. 15-20 cm), gib bitte den Mittelwert an.",
      enterDistanceInCm: "Abstand in cm eintragen",
    },
    rowDistance: {
      headline: "Reihenabstand",
      notice:
        "Wenn auf deiner Saatgutpackung zwei Werte angegeben sind (z.B. 15-20 cm), gib bitte den Mittelwert an.",
      enterDistanceInCm: "Abstand in cm eintragen",
    },
    seedingDepth: {
      headline: "Pflanztiefe",
      notice:
        "Wenn auf deiner Saatgutpackung zwei Werte angegeben sind (z.B. 15-20 cm), gib bitte den Mittelwert an.",
      enterDepthInCm: "Tiefe in cm eintragen",
    },
    description: {
      headline: "Beschreibung",
      enterText: "Beschreibungstext eingeben",
      text: "Hier kannst du den Beschreibungstext von der Samentüte eintragen.",
    },
    goodNeighbours: {
      headline: "Gute Nachbarn",
      text: "Falls du weißt, mit welchen anderen Pflanze sich deine neue Pflanze gut verträgt, trage es hier ein.",
    },
    neighbours: {
      cropName: "Pflanze",
      searchCropName: "Suche nach Pflanzen",
    },
    badNeighbours: {
      headline: "Schlechte Nachbarn",
      text: "Falls du weißt, mit welchen anderen Pflanze sich deine neue Pflanze nicht gut verträgt, trage es hier ein.",
    },
    diseases: {
      headline: "Krankheiten",
      text: "Falls du weißt, welche Krankheiten für deine neue Pflanze typisch sind, trage sie hier ein.",
      disease: "Krankheit",
      searchDisease: "Suche nach Krankheit",
    },
    pests: {
      headline: "Schädlinge",
      text: "Falls du weißt, welche Schädlinge für deine neue Pflanze typisch sind, trage sie hier ein.",
      pest: "Schädling",
      searchPest: "Suche nach Schädling",
    },
    seedType: {
      headline: "Ist die {{cropType}} samenfest?",
      text: "{{cropTypeMultiples}}, die dieses Symbol tragen, können  für die Gewinnung von eigenem Saatgut verwendet werden.",
      nonHybrid: "samenfest",
      hybrid: "F1/Hybrid",
    },
    coldResistant: {
      headline: "Ist die {{cropType}} frostsicher?",
      text: "{{cropTypeMultiples}}, die dieses Symbol tragen, können  schon vor den Eisheiligen ausgepflanzt werden.",
      yes: "Ja",
      no: "Nein",
    },
    growingTips: {
      headline: "Anbautipps",
      enterText: "Anbautipps",
      text: "Mit diesen Tipps kannst du anderen helfen, erfolgreicher zu Gärtnern. Sie werden es dir danken.",
    },
    shareScreen: {
      headline: "Freigeben",
      text: "Möchtest du deine {{cropType}} für die Community freigeben? Dann können andere Gärtner:innen von deiner Arbeit profitieren, so wie du auch die {{cropTypeMultiples}} anderer nutzen kannst. Power to the Gemüse!",
      textMustBeApproved:
        "Bevor die {{cropType}} für andere Gärtner:innen zur Verfügung steht, wird sie von unserem Moderationsteam überprüft.",
      share: "{{cropType}} freigeben",
      dontShare: "lieber nicht",
    },
    finalScreen: {
      saving: "Deine {{cropType}} wird gespeichert...",
      headline: "Yay!",
      text: "Du hast deine {{label}} Pflanze angelegt!",
      confirm: "Und Tschüss!",
    },
    iDontKnow: "Ich weiß es nicht",
    saveChanges: "Fortschritt speichern",
    save: "Speichern",
    discard: "Verwerfen",
    add: "hinzufügen",
    saveChangesText:
      "Möchtest du deinen Fortschritt bis hierhin speichern, um später weiter zu machen?",
  },
  lightDemand: {
    headline: "Lichtbedarf",
    text: "Welchen Standort bevorzugt deine Pflanze?",
    sunny: "sonnig",
    halfShady: "halbschattig",
    shady: "schattig",
  },
  nutrientDemand: {
    headline: "Nährstoffbedarf",
    text: "Welchen Standort bevorzugt deine Pflanze?",
    high: "hoch",
    medium: "mittel",
    low: "niedrig",
  },
  waterDemand: {
    headline: "Wasserbedarf",
    text: "Welchen Standort bevorzugt deine Pflanze?",
    dry: "trocken",
    wet: "feucht",
    veryWet: "sehr feucht",
  },
  groundType: {
    headline: "Boden",
    text: "Welchen Standort bevorzugt deine Pflanze?",
    light: "leicht (sandig)",
    medium: "mittelschwer",
    heavy: "schwer (lehmig)",
    modal: {
      light: {
        headline: "Leicht (sandig)",
        paragraph: [
          "Leichter Boden hat eine hohe Wasserdurchlässigkeit, eine gute Durchlüftung, kann dafür jedoch kaum Nährstoffe speichern.",
          "Man erkennt ihn an der groben Körnung und daran, dass er durch den geringen Tongehalt nicht klebrig genug ist, um ihn zwischen den Händen zu einer Kugel oder Wurst zu formen.",
        ],
      },
      medium: {
        headline: "Mittelschwer (lehmig)",
        paragraph: [
          "Mittelschwerer Boden hat eine gute Durchlüftung und ein hohes Wasserhaltevermögen. Dadurch können die Nährstoffe gut pflanzenverfügbar gehalten werden.",
          "Man erkennt ihn an der mittelgroßen, mehlähnlichen Körnung und daran, dass er zwar in den Händen formbar, aber etwas brüchig ist. ",
        ],
      },
      heavy: {
        headline: "Schwer (Ton)",
        paragraph: [
          "Schwerer Boden hat eine geringe Wasserdurchlässigkeit und Durchlüftung, kann dafür aber viele Nährstoffe speichern. Diese sind jedoch meist schwer pflanzenverfügbar.",
          "Man erkennt ihn an der sehr feinen Körnung. Zwischen den Händen fühlt er sich klebrig an und lässt sich sehr gut zu einer Kugel oder Wurst formen.",
        ],
      },
    },
  },
  imageUploadWarning: {
    headline: "Urheberrechte von Fotos",
    notice:
      "Lade nur Bilder hoch, deren Nutzung erlaubt ist. Sie ist z.B. gestattet, wenn:",
    text1: "- Du das Bild selbst gemacht hast",
    text2: "- Die Einwilligung der Person vorliegt, die das Bild gemacht hat",
    confirm: "Ok, ich hab´s kapiert",
  },
  account: {
    version: "Version",
    noBillingAddressNotice:
      "Um deine Rechnung korrekt zu stellen benötigen wir von dir noch eine Adresse. Bitte gib diese jetzt ein.",
    accountUpgrade: {
      heading: "Dein Account",
      upgradeSubscription: "Jetzt upgraden",
      cancelSubscription: "Abo kündigen",
      extendSubscription: "Abo fortsetzen",
      dateOfExpiry: "Ende des Abos",
      dateOfNextPayment: "Nächste Zahlung",

      payedInApp:
        "Du hast dein Abo per App gekauft. Um dein Abo zu kündigen, besuche bitte den App Store.",

      accountType: {
        [ACCOUNT_TYPE_FREE]: "Fryd free",
        [ACCOUNT_TYPE_PLUS]: "Fryd plus",
        [ACCOUNT_TYPE_PRO]: "Fryd pro",
        [ACCOUNT_TYPE_PAID]: "Fryd pro",
      },
      paymentInterval: {
        [PAYMENT_INTERVAL_MONTHLY]: "Monats-Abo",
        [PAYMENT_INTERVAL_QUARTERLY]: "Quartals-Abo",
        [PAYMENT_INTERVAL_YEARLY]: "Jahres-Abo, {{price}} €/Jahr",
        ["freetest"]: "Jahres-Abo, {{price}} €/Jahr",
      },

      userIsTester: {
        heading:
          "Als Fryd Tester kannst du deine Subscription beliebig ändern:",
      },
    },
    redeemVoucher: {
      heading: "Gutschein einlösen",
      code: "Gutscheincode eingeben",
      redeem: "Einlösen",
      info: "Bitte gib deinen Gutscheincode ein",
      confirmed:
        "Dein {{accountType}} Account ist jetzt aktiv, viel Spaß beim Gärtnern.",
    },
    subscriptionCancel: {
      heading: "Fryd Abo kündigen",
      info: "Schade, dass du uns verlassen möchtest. Um mit der Kündigung fortzufahren, bestätige dies bitte durch Klick auf den Button.",
      cancelSubscription: "Zum {{date}} kündigen",
      keepSubscription: "Abo behalten",
    },
    subscriptionPayment: {
      heading: "Die Produkte im Überblick",
      payment: {
        heading: "Fryd pro abonnieren",
        info: "Bitte klicke auf den Button um die Zahlung bei PayPal fortzusetzen.",
      },
      price: "1 Jahr für {{price}} €",
      tryFree: "7 Tage kostenlos testen",
      showAllProducts: "Alle Produkte anzeigen",
      plus: {
        heading: "Fryd plus",
        info: "Vergiss nie mehr wichtige Aufgaben in deinem Garten. Wir sagen dir immer, was gerade zu tun ist.",
        buy: "Plus kaufen",
      },
      pro: {
        heading: "Fryd pro",
        info: "Bessere Ernten, nachhaltige Planung und weniger Sorgen. Ernähre dich und deine Liebsten mit Fryd pro.",
        buy: "Pro kaufen",
        including: "Alles aus Plus und zusätzlich:",
      },
    },
    features: {
      magicwand: {
        name: "Zauberstab",
        description: "Der perfekte Plan in wenigen Klicks.",
      },
      todos: {
        name: "Automatische Aufgaben",
        description: "Schritt für Schritt zur reichen Ernte",
      },
      precultures: {
        name: "Vor- und Nachkulturen",
        description: "Plane dein ganzes Gartenjahr",
      },
      croprotation: {
        name: "Fruchtwechsel (coming soon)",
        description: "Optimiere deine Bodenqualität",
      },
      multiyear: {
        name: "Mehrjährige Planung",
        description: "Ernte jedes Jahr mehr",
      },
    },
    baseData: {
      heading: "Deine Stammdaten",
      firstname: {
        label: "Vorname",
      },
      lastname: {
        label: "Nachname",
      },
      email: {
        label: "E-Mail",
      },
      street: {
        label: "Straße",
      },
      zipCode: {
        label: "PLZ",
      },
      city: {
        label: "Stadt",
      },
    },
    support: {
      heading: "Support kontaktieren",
      info: "Du hast einen Bug gefunden, willst deinem Ärger Luft machen oder Luftküsse verschicken?",
      label: "Schicke uns eine E-Mail",
      mailSubject: "Ich habe ein Problem",
    },
    gardenDelete: {
      heading: "Garten löschen",
      label: "Kompletten Garten löschen",
      info: "Du kannst hier deinen Standort, deinen eingezeichneten Garten sowie all deine Beete löschen, um mit der Planung komplett neu zu beginnen.",
      noGarden: "Du hast noch keinen Garten angelegt.",
      deleted: "Dein Garten wurde gelöscht.",
      modal:
        "Wenn du den Vorgang bestätigst, wird dein hinterlegter Standort inklusive aller von dir angelegten Beete gelöscht. Dies kann nicht rückgängig gemacht werden.",
    },
    patchesDelete: {
      heading: "Beete löschen",
      label: "Alle Beete dauerhaft löschen",
      info: "Du kannst hier all deine Beete löschen.", // TODO
      noPatches: "Du hast noch keine Beete angelegt.",
      deleted: "All deine Beete wurden gelöscht.",
      modal: null, // TODO
    },
    invoices: {
      heading: "Deine Rechnungen",
      itemLabel: "Rechnung {{formattedDate}}",
      noInvoices: "Derzeit gibt es keine Rechnungen",
    },
    termsOfUse: {
      heading: "Nutzungsbedingungen",
      label: "AGBs einsehen",
    },
    accountDelete: {
      heading: "Account löschen",
      label: "Account dauerhaft löschen",
      modal:
        "Wenn du deinen Fryd Account löschst, hast du keinen Zugriff mehr auf deine Beete. Möchtest du mit dem Löschen fortfahren?",
    },
    logout: {
      heading: "Logout",
      label: "Logout",
    },
  },
  profile: {
    tabProfile: "Community-Profil",
    tabAccount: "Account",
    userProfile: {
      profile: "Profil",
      editProfile: "Profil bearbeiten",
      editProfilePicture: "Profilbild bearbeiten",
      username: "Nutzername",
      usernameExists:
        "Dieser Nutzername wird bereits von einer anderen Nutzer:in verwendet, bitte wähle einen andern.",
      description: "Beschreibung",
      gardenPictures: "Gartenfotos",
      addPicture: "Foto hinzufügen",
    },
    baseData: {
      heading: "Deine Stammdaten",
      gardenType: {
        label: "Gartentyp",
        large: "Großer Gemüsegarten",
        small: "Kleines Beet",
        balcony: "Balkon",
      },
      region: {
        label: "Region",
      },
      experience: {
        label: "Erfahrung",
        newbie: "Neuling",
        intermediate: "Gartenfan",
        expert: "Experte ",
      },
    },
    interests: {
      heading: "Interessen",
      info: "Du kannst hier deine Interessen angeben, um passende Inhalte, wie zum Beispiel Artikel, zu erhalten.",
      addInterests: "Interessen hinzufügen",
    },
    socialMedia: {
      heading: "Social Media",
      info: "Du kannst hier deine anderen Social Media Profile verlinken, um diese mit anderen Nutzer:innen zu teilen.",
      placeholder: "Füge hier deinen Link ein",
      fb: "Facebook",
      yt: "YouTube",
      ig: "Instagram",
      pi: "Pinterest",
      tt: "TikTok",
    },
    privacy: {
      heading: "Datenschutz",
      info: "Du kannst folgende Informationen für andere Nutzer sichtbar machen, wenn du das möchtest.",
      virtualGarden: "Virtueller Gartenbesuch",
      postOverview: "Übersicht deiner Beiträge",
      yourTopics: "Themen, denen du folgst",
    },
    following: {
      headingSingular: "1 Person, der du folgst",
      headingPlural: "{{count}} Personen, denen du folgst",
      info: "Du wirst benachrichtigt, wenn Personen, denen du folgst, etwas Neues in der Community posten.",
      noFollowing:
        "Du folgst noch niemandem. Besuche das Profil anderer Personen um ihnen zu folgen.",
    },
    follower: {
      headingSingular: "1 Person, die dir folgt",
      headingPlural: "{{count}} Personen, die dir folgen",
      noFollower: "Dir folgt noch niemand.",
    },
  },
  community: {
    title: "Community",
    newPost: "Neuer Beitrag",
    createPostTitle: "Wie können wir dir helfen?",
    askDescription:
      "Hast du technische Schwierigkeiten, oder möchtest du etwas in die Community posten?",
    askSupport: "Support kontaktieren",
    askCommunity: "Beitrag in der Community",
    createPost: "Beitrag erstellen",
    addPhoto: "Foto hinzufügen",
    nickname:
      "Im Profil kannst du deinen Nickname eingeben, der anstatt deines Namens angezeigt wird.",
    feed: {
      postSearchPlaceholder: "Suche nach Beiträgen",
      hashagSearchPlaceholder: "Suche nach Themen",
      recentHashags: "Aktuelle Themen",
      showAll: "Alle anzeigen",
    },
    post: {
      reply: "Antworten",
      iLike: "Gefällt mir",
      liked: "Gefällt {{count}} Mal",
      showReply: "1 Antwort anzeigen",
      showReplies: "{{count}} Antworten anzeigen",
      hideReply: "1 Antwort ausblenden",
      hideReplies: "{{count}} Antworten ausblenden",
      replying: "du antwortest",
      comments: "Kommentare",
      postError: "Dieser Post existiert nicht.",
    },
    postCreation: {
      input: "Was möchtest du teilen?",
      newPost: "Neuer Beitrag",
      continueWithoutImage: "Weiter ohne Bild",
      selectImage: "Foto auswählen",
      selectImageHelp: "Platziere das Bild hier per Drag und Drop",
      maxImageNotice: "Du hast die maximale Anzahl an Bildern erreicht.",
      sendPost: "Beitrag posten",
      inputPlaceholder: "Was möchtest du mit der Community teilen?",
      replyPlaceholder: "Deine Antwort",
      addMentionTags: "Markiere Pflanzen und Personen mit @ im Text.",
      addHashTags: "Markiere Themen mit # im Text.",
      tag: {
        crop: "Art",
        variety: "Sorte",
        user: "Person",
        hash: "Hastag",
      },
    },
    userProfile: {
      postsOfThisUser: "Beiträge dieser Person",
      goBack: "Zurück zum Community Feed",
      editProfile: "Profil bearbeiten",
      follow: "Folgen",
      unfollow: "Nicht mehr folgen",
      memberSince: "Mitglied seit {{date}}",
      userHasNoDescription:
        "Dieses Mitglied hat noch keine Beschreibung über sich verfasst.",
      follower: "Follower",
      follows: "Gefolgt",
      posts: "Beiträge",
      sharedPlants: "Gemeinsame Pflanzen",
      noSharedPlants: "Ihr habt keine gemeinsamen Pflanzen.",
      interests: "Interessen",
      noInterestsSelected: "{{user}} hat keine Interessen ausgewählt.",
      badgeExplanation: {
        moderator: {
          headline: "Moderator:innen",
          text: "Die Moderator:innen sind ein festes Team aus User:innen, die uns bei der Moderation der Bibliothek unterstützen. Dort haben sie erweiterte Rechte wie beispielsweise das Prüfen von neuen Sorten. Du kannst dich über die E-Mail support@fryd.app als Moderator:in bei uns bewerben.",
        },
        employee: {
          headline: "Mitarbeiter:innen",
          text: "Damit du erkennen kannst, wer zum Fryd Team gehört, haben alle unsere Mitarbeiter:innen dieses Zeichen an der unteren Ecke ihres Bildes.",
        },
        creator: {
          headline: "Zaunkönig:innen",
          text: "Zu den Zaunkönig:innen gehören besonders aktive und kompetente User:innen. Diese können besondere Funktionen, zum Beispiel das Schreiben von Artikeln, nutzen. Diese Auszeichnung wird aktuell auf Basis der Community-Aktivität und der Qualität der Beiträge manuell verliehen.",
        },
      },
    },
    sharedInterestsInfoModal: {
      heading: "Gemeinsames Interesse",
      description:
        "Ihr habt beide angegeben, dass ihr euch für dieses Thema interessiert. Vielleicht interessieren dich ja auch die Beiträge diese:r Nutzer:in. ",
    },
    profileTypeModal: {
      trustedUser: {
        heading: "Mitarbeiter:innen ",
        description:
          "Damit du erkennen kannst, wer zum Fryd Team gehört, haben alle unsere Mitarbeiter:innen dieses Zeichen an der unteren Ecke ihres Bildes.",
      },
      moderator: {
        heading: "Moderator:innen",
        description:
          "Die Moderator:innen sind ein festes Team aus User:innen, die uns bei der Moderation der Bibliothek unterstützen. Dort haben sie erweiterte Rechte wie beispielsweise das Prüfen von neuen Sorten. Du kannst dich über die E-Mail support@fryd.app als Moderator:in bei uns bewerben.",
      },
      creator: {
        heading: "Zaunkönig:innen",
        description:
          "Zu den Zaunkönig:innen gehören besonders aktive und kompetente User:innen. Diese können besondere Funktionen, zum Beispiel das Schreiben von Artikeln, nutzen. Diese Auszeichnung wird aktuell auf Basis der Community-Aktivität und der Qualität der Beiträge manuell verliehen.",
      },
    },
    notificationCentre: {
      sectionNew: "Neu",
      sectionThisWeek: "Diese Woche",
      sectionOthers: "Frühere Aktivitäten",
      emptyNotificationsInfoText:
        "Poste eine Frage in die Community oder kommentiere die Posts anderer, dann findest du hier deine Benachrichtigungen über Likes, Antworten und wenn du von anderen markiert wirst.",
      post_like: "<0>{{userName}}</0> gefällt dein Beitrag.",
      comment_like: "<0>{{userName}}</0> gefällt dein Kommentar.",
      comment_mention:
        "<0>{{userName}}</0> hat dich in einem Kommentar erwähnt:",
      post_mention: "<0>{{userName}}</0> hat dich in einem Beitrag erwähnt:",
      crop_mention: "<0>{{userName}}</0> hat eine deiner Sorten erwähnt:",
      post_comment: "<0>{{userName}}</0> hat deinen Beitrag kommentiert:",
      post_following:
        "<0>{{userName}}</0> hat einen Beitrag kommentiert, dem du folgst",
      comment_comment:
        "<0>{{userName}}</0> hat einen deiner Kommentare kommentiert:",
      new_follower: "<0>{{userName}}</0> folgt dir jetzt.",
      new_post_for_followers:
        "<0>{{userName}}</0> hat einen neuen Beitrag veröffentlicht.",
    },
    shortly: "vor Kurzem",
    loadMorePosts: "Mehr Beiträge laden",
    noNickNameNotice:
      "Du hast noch keinen Nickname ausgesucht. Das kannst du jederzeit in deinem Profil machen. <0>Hier geht’s zum Profil.</0>",
    noBookmarksNotice:
      "Du hast noch keine Beiträge gespeichert. Sobald du Beiträge gespeichert hast, erscheinen diese hier.",
  },
  trial: {
    title: "Fryd pro testen",
    modalText:
      "Findest du es auch mühsam, alle Pflanzen einzeln in dein Beet zu setzen?",
    tryText1: "Teste jetzt den Zauberstab und weitere coole Features!",
    modalTextBold: "Teste jetzt Fryd pro ",
    modalTextEnd:
      "und lasse deine Beete wie von Zauberhand in Sekundenschnelle mit deinen Favoriten befüllen.",
    description:
      "Fryd pro ist ab 3,33 € pro Monat inkl. MwSt erhältlich. Für den Testzeitraum sind keine Zahlungsinformationen notwendig und es entsteht keine Zahlungsverpflichtung.",
    testNow: "14 Tage kostenlos testen",
    modalText2: "Verlierst du auch immer den ",
    modalTextBold2: "Überblick über die Aufgaben, ",
    modalTextEnd2:
      "die in deinem Garten zu erledigen sind? Wir helfen dir dabei und sagen dir immer, was wann zu tun ist!",
    tryText2: "Teste jetzt die Aufgaben und weitere coole Features! ",
    modalText3: "Du möchtest das ganze Jahr über ",
    modalTextBold3: "kontinuierlich ernten",
    modalTextEnd3:
      "? Plane jetzt mit Vor- und Nachkulturen und nutze deine Beetfläche über die ganze Saison perfekt aus!",
    tryText3: "Teste jetzt die Fruchtfolgen und weitere coole Features! ",
  },
  waterAccess: {
    headline: "Wasseranschluss",
    [WaterAccessType.AVAILABLE]: "vorhanden",
    [WaterAccessType.UNAVAILABLE]: "nicht vorhanden",
  },
  navigation: {
    menuEntry: {
      library: {
        root: "Lexikon",
        library: "Pflanzen-Lexikon",
        favorites: "Favoriten",
        createCrop: "Neue Sorte anlegen",
        isMine: "Meine Sorten",
        moderation: "Moderation",
        changes: "Änderungen",
      },
      plan: {
        root: "Planen",
        location: "Standort",
        patches: "Beete",
        conditions: "Bedingungen",
        planting: "Bepflanzung",
      },
      gardening: {
        root: "Gärtnern",
        work: "Aufgaben",
        season: "Saisonübersicht",
      },
      community: {
        root: "Community",
      },
      shop: {
        root: "Shop",
      },
    },
    savedAtFormat: "[zuletzt gespeichert am ]DD.MM.YYYY[ um ]HH.mm[ Uhr]",
  },
  season: {
    editSeasons: "Zeiträume bearbeiten",
    selectDate: "Datum wählen",
    plantingTypes: {
      precultivation: "Eigene Anzucht",
      direct: "Direktsaat ins Beet",
      seedlings: "Gekaufte Setzlinge",
    },
    editSeasonsHeadline: "Aktuelle Saisondaten",
    warningHeadline: "Ausgegraute Daten",
    warning: {
      planted:
        "Du kannst diese Pflanze erst ab dem <0>{{date}}</0> auspflanzen, da vorher noch eine <1>andere Pflanze</1> an dieser Stelle wächst.",
      sowing:
        "Du kannst diese Pflanze erst ab dem <0>{{date}}</0> aussäen, da vorher noch eine <1>andere Pflanze</1> an dieser Stelle wächst.",
      harvestEnd:
        "Du solltest diese Pflanze bis zum <0>{{date}}</0> geerntet haben, da danach eine <1>andere Pflanze</1> eingepflanzt wird.",
    },
    dates: {
      precultivation: {
        label: "Anzuchtbeginn",
        text: "Dies ist der Geburtstag deiner Pflanze, der Tag, an dem die Vorzucht beginnt.",
      },
      planted: {
        label: "Auspflanzdatum",
        text: "Dies ist der Geburtstag deiner Pflanze, der Tag, an dem der Samen oder das vorgezogene Pflänzchen ins Beet kommt. Für Pflanzen, die nicht frostsicher sind, sollte dieses Datum erst nach den Eisheiligen (11. bis 15. Mai) liegen.",
      },
      sowing: {
        label: "Aussaatdatum",
        text: "Das ist der Tag, an dem das vorgezogene Pflänzchen ins Beet kommt. Für Pflanzen, die nicht frostsicher sind, sollte dieses Datum erst nach den Eisheiligen (11. bis 15. Mai) liegen.",
      },
      harvestStart: {
        label: "Erntebeginn",
        text: "Wähle den Tag, an dem die Ernte der Pflanze beginnt.",
      },
      harvestEnd: {
        label: "Letzte Ernte",
        text: "Wähle den Tag, an dem die Pflanze abgeerntet werden soll, also raus aus dem Beet. Du kannst dieses Datum auch setzen, um Platz im Plan für eine Folgekultur frei zu machen.",
      },
    },
  },
  location: {
    yourAddress: "Ort oder Adresse eingeben",
    yourGarden: "Dein Garten",
    yourLocation: "Dein Standort",

    button: {
      drawGarden: "Garten einzeichnen",
      drawGardenAnew: "Neu einzeichnen",
      saveGarden: "Garten speichern",
    },
    heading: {
      drawGarden: "Standort einzeichnen",
      saveGarden: "Geschafft!",
      waitASec: "Bitte warte einen Moment",
    },
    drawGardenHint: {
      text: "Klicke im {{clockwise}} die Ecken deines Gartens an, um deinen Grundriss einzuzeichnen.",
      clockwise: "Uhrzeigersinn",
    },
    saveGarden: {
      text:
        "Das ist die Grundlage für deinen Beetplan.\n" +
        "Dein Garten hat {{m²}} Fläche.",
      "m²": "{{value}} qm",
    },
    isLoading:
      "Wir suchen gerade deinen Standort. Das kann ein paar Sekunden dauern.",
    GeolocationPositionError: {
      [GeolocationPositionError.PERMISSION_DENIED]:
        "Der Zugriff auf den Standort wurde verweigert.",
      [GeolocationPositionError.POSITION_UNAVAILABLE]:
        "Standort konnte nicht ermittelt werden.",
      [GeolocationPositionError.TIMEOUT]:
        "Standort konnte nicht ermittelt werden.",
      [GeolocationPositionError.UNKNOWN_ERROR]:
        "Standort konnte nicht ermittelt werden.",
    },
  },
  conditions: {
    heading: {
      conditions: "Bedingungen",
      sunAndShadow: "Sonne & Schatten",
    },
    paragraph: {
      sunAndShadow:
        "Wähle die Punkte einzeln an und zeichne die sonnigen oder schattigen Stellen mit der Maus ein.",
    },
    button: {
      sun: "Sonne",
      penumbra: "Halbschatten",
      shadow: "Schatten",
      eraser: "Radiergummi",
    },
  },
  gardening: {
    CreatePlanModal: {
      headline: "Erstelle zuerst deinen Plan",
      description:
        "Wenn du deinen Plan erstellt hast, findest du hier die Saisonübersicht und deine Aufgabenliste.",
      todoList: "Aufgaben",
      seasonOverview: "Saison",
      button: "Plan erstellen",
    },
  },
  plan: {
    tutorial: {
      button: "Hinweis schließen",
      patches: {
        heading: "Lege deine Beete an",
        paragraph:
          "Ziehe die geometrischen Formen aus dem Menü rechts per Drag and Drop in deinen Garten. Du kannst die Formen auch kombinieren.",
      },
      gotoConditions: {
        heading: "Sonne oder Schatten?",
        paragraph:
          "Wenn du deine Beete angelegt hast, kannst du im oberen Menü den Punkt Bedingungen auswählen. Dort kannst du die Licht- und Bodenverhältnisse in deinem Garten anpassen. Du kannst jederzeit hierher zurückkehren, um weitere Beete hinzuzufügen.",
      },
      conditions: {
        heading: "Wie sonnig ist dein Garten?",
        paragraph:
          "Wähle hier eines der Werkzeuge aus und zeichne dann die sehr sonnigen oder besonders schattigen Bereiche deines Gartens direkt auf der Karte ein. Diese Information nutzten wir an verschiedenen Stellen, z.B. beim Zauberstab und anderen Hilfsfunktionen.",
      },
      gotoPlanting: {
        heading: "Bist du bereit für Pflanzen?",
        paragraph:
          "Wenn du bereit bist, kannst du im oberen Menü den Punkt Pflanzen auswählen. Dort kannst du deine Beete mit zahlreichen Gemüse-, Obst- und Kräutersorten füllen. Du kannst jederzeit hierher zurückkehren, um deine lokalen Bedingungen anzupassen.",
      },
      plantingPatch: {
        heading: "Bepflanze deine Beete",
        paragraph:
          "Ziehe die Pflanzen aus dem Menü rechts per Drag and Drop in deinen Garten. Sind bereits Pflanzen im Beet, zeigen wir dir, welche anderen Pflanzen besonders gut zu diesen passen würden.",
      },
      plantingFavorites: {
        heading: "Hast du Favoriten?",
        paragraph:
          "Wenn du bestimmte Arten besonders gerne isst oder anpflanzt, kannst du sie in der rechten Leiste als Favoriten hinzufügen. Klicke dafür einfach auf das \n" +
          "„+ Favoriten”. Viel Spaß beim Garten planen!",
      },
    },
    BetaHint: {
      title: "Nutzung auf eigene Gefahr",
      paragraph:
        "Hallo, schön, dass du da bist. Du nutzt eine Beta-Version unserer App. Das bedeutet, dass die noch Fehler enthalten kann.\n" +
        "Folgende Funktionen fehlen noch:",
      toOldVersion: "Zur alten Version wechseln",
      items: {
        magicWand: {
          title: "Zauberstab",
          paragraph:
            "Der Zauberstab ist aktuell nur in der Mobile-Beta-App verfügbar, hier noch nicht.",
        },
        seasons: {
          title: "Saisonübersicht",
          paragraph:
            "Die Bearbeitung von Saisondaten ist ebenfalls noch nicht möglich, kann aber am Smartphone bearbeitet werden.",
        },
        dataloss: {
          title: "Datenverlust möglich",
          paragraph:
            "Beim gleichzeitigen Öffnen des Plans in App und Browser-Anwendung kann es zu Datenverlust kommen. Bitte nacheinander öffnen.",
        },
      },
    },
    LockModal: {
      headline: "Dein Plan ist auf einem anderen Gerät geöffnet",
      yourPlanIsOpenedOn: "Dein Plan ist auf folgendem Gerät geöffnet:",
      doYouWantToEdit: "Möchtest du den Plan auf diesem Gerät bearbeiten?",
      buttonYes: "Ja, Plan auf aktuellem Gerät bearbeiten",
      buttonNo: "Nein, Plan jetzt nicht bearbeiten",
      unsavedChangesWarning:
        "Wenn du den Plan auf deinem aktuellen Gerät bearbeitest, gehen ungespeicherte Änderungen verloren, die du auf dem anderen Gerät ({{deviceName}}) gemacht hast.",
      closeWarning:
        "Durch Klick auf den grünen Button schließen wir die Fryd-App hier im Browser, damit es keinen Datenverlust gibt.",
      isLocked: {
        headline: "Plan gesperrt",
        text: "Du hast den Plan gerade auf einem anderen Gerät geöffnet, daher ist er hier gesperrt. Schließe den Plan auf deinem anderen Gerät, dann wird er hier wieder frei geschaltet.",
        forceUnlock: "Entsperren erzwingen",
      },
    },
    DeletePatchDialog: {
      title: "Beet löschen",
      paragraph: {
        question: "Möchtest du das Beet wirklich löschen?",
        hint: "Die Pflanzen an dieser Stelle bleiben erhalten und werden nicht automatisch gelöscht.",
      },
    },
    CropItemList: {
      header: {
        favorites: "Meine Pflanzen",
        bestRated: "Beliebteste",
      },
    },
    CropDrag: {
      button: {
        editFavorites: "Favoriten",
      },
      placeholder: {
        search: "Suche",
      },
    },
    ShapeDrag: {
      header: {
        patches: {
          default: "Beete",
          mdUp: "Beetformen",
        },
        decorations: {
          default: "sonstige",
          mdUp: "Sonstiges",
        },
      },
      items: {
        label: {
          addRect: {
            default: "Rechteck",
            mdUp: "rechteckig",
          },
          addTriangle: {
            default: "Dreieck",
            mdUp: "dreieckig",
          },
          addCircle: {
            default: "Kreis",
            mdUp: "rund",
          },
          addTree: {
            default: "Baum",
            mdUp: "Baum",
          },
          addBush: {
            default: "Busch",
            mdUp: "Busch",
          },
          addPath: {
            default: "Weg",
            mdUp: "Weg",
          },
        },
      },
    },
    DimensionEditor: {
      height: "Länge",
      width: "Breite",
    },
    PatchDetail: {
      label: {
        roof: "Überdachung",
        groundType: "Boden",
      },
      option: {
        groundType: {
          light: "Leicht",
          medium: "Mittelschwer",
          heavy: "Schwer",
        },
      },
      button: {
        removePatch: "Beet löschen",
      },
    },
    PlanControls: {
      satellite: "Satellit",
      outline: "Umriss",
      undo: "rückgängig",
      redo: "wiederholen",
      header: {
        zoom: "Zoom",
      },
      label: {
        season: "Saisonabschnitt",
      },
      season: {
        pre: "Januar ‒ April",
        main: "Mai ‒ September",
        post: "Oktober ‒ Dezember",
      },
    },
    PlantControls: {
      plantDescription: {
        amountPlants: "{{amount}} Pflanzen",
        dimensions: "{{w}} cm × {{h}} cm",
      },
      VarietySelector: {
        title: "Sorten",
        isCheckedVariety: "geprüfte Sorte",
        filters: {
          button: {
            all: "Alle Filter",
          },
          heading: "Beliebteste Filter",
          showNResults: "{{nResults}} Ergebnisse anzeigen",
          VarietyFilterSeason: {
            title: "Saison",
            value: {
              pre: "Vorkulturen",
              main: "Hauptkulturen",
              post: "Nachkulturen",
            },
          },
          VarietyFilterDeliverable: {
            title: "Lieferbare Sorten",
            button: "Nur lieferbare Sorten",
            value: {
              true: "Lieferbare Sorten",
            },
            paragraph:
              "Wir zeigen dir nur Sorten an, die zur Zeit über unseren Shop lieferbar sind",
            label: "Nur lieferbare Sorten anzeigen",
          },
          VarietyFilterIsApproved: {
            title: "Geprüfte Sorten",
            button: "Nur geprüfte Sorten",
            value: {
              true: "Geprüfte Sorten",
            },
            paragraph:
              "Wir zeigen dir nur die Sorten an, die durch unsere Expert:innen geprüft wurden",
            label: "Nur geprüfte Sorten anzeigen",
          },
          VarietyFilterNonHybridSeed: {
            title: "Samenfeste Sorten",
            button: "Samenfest",
            value: {
              true: "Samenfeste Sorten",
            },
            paragraph: "Wir zeigen dir nur Sorten an, die samenfest sind.",
            label: "Nur samenfeste Sorten anzeigen",
          },
          VarietyFilterAll: {
            title: "Filter",
          },
        },
        sortBy: {
          title: "Sortierung ändern",
          value: {
            mostPopular: "Beliebteste Sorten zuerst",
            latest: "Neueste Sorten zuerst",
            abc: "alphabetisch",
          },
        },
      },
      SeasonInfo: {
        title: "Was wächst wann?",
        paragraph:
          "Um deine Beetfläche über die ganze Saison zu nutzen, kannst du neben den Hauptkulturen auch Vor- und Nachkulturen pflanzen.",
        pre: {
          title: "Vorkultur",
          dateHint: "bis 15.05. im Beet",
          imgAlt: "Bild einer roten Beete",
        },
        main: {
          title: "Hauptkultur",
          dateHint: "ab 15.05. im Beet",
          imgAlt: "Bild einer Aubergine",
        },
        post: {
          title: "Nachkultur",
          dateHint: "ab 15.09.",
          imgAlt: "Bild von Salatblättern",
        },
      },
      SeasonCalendarHint: {
        title: "Möchtest du die Zeiträume anpassen?",
        paragraph:
          "Wenn du die Saisondaten deiner Pflanze verändern möchtest, dann mach das bitte im Menüpunkt Gärtnern. Dort siehst du die Saisondaten aller Pflanzen im Überblick und kannst Änderungen vornehmen.",
      },
      PlantDetail: {
        title: "Details",
        heading: {
          season: "Saisondaten",
          plantCount: "Anzahl Pflanzen",
          attention: "Achtung!",
          species: "Art",
          variety: "Sorte",
        },
        paragraph: {
          attention:
            "Eine geringere Anzahl auf dieser Fläche wäre besser für den Ertrag und die Gesundheit deiner Pflanzen.",
        },
        label: {
          defaultVariety: "Standardsorte",
          plantArea: "Fläche",
          amountRows: "Anzahl der Reihen",
          plantsPerRow: "Pflanzen pro Reihe",
          communityCropCreator: "Sorte von {{communityCropCreator}}",
        },
        button: {
          changeVariety: "Sorte ändern",
        },
        mixedCultureScore: {
          headline: "Mischkultur-Score",
          total: "gesamt",
          neighbours: "Pflanznachbarschaft",
          rotation: "Fruchtfolge",
          ground: "Bodenbeschaffenheit",
          light: "Lichtbedarf",
          area: "Fläche",
          season: "Saison",
          legend: {
            ideal: "ideal",
            suitable: "geeignet",
            unsuitable: "ungeeignet",
          },
        },
      },
    },
    FavoritesBar: {
      header: "Meine Pflanzen",
      description:
        "Wähle nun deine Lieblingspflanzen aus, um sie als Favoriten zu speichern.",
      count: "{{count}} Arten",
      chosen: "ausgewählt",
    },
    LocationFeatureRemovedModal: {
      headline: "Adieu, du pixeliger Satellit",
      text: "Das Satellitenbild hat leider viele Probleme verursacht und ist für die Beetplanung auch nicht zwingend notwendig, daher haben wir uns entschieden, die Funktion aus der App zu nehmen. Bei Fragen oder Feedback dazu freuen wir uns über deine Nachricht an support@fryd.app.",
      button: {
        continue: "Weiter zum Plan",
        contactSupport: "Support kontaktieren",
      },
      emailSubject: "Frage zur Satellitenansicht",
    },
    sortBy: {
      abc: "alphabetisch A-Z",
      mostPopular: "Beliebteste Arten",
    },
  },
  PlantModal: {
    title: "Zeiträume bearbeiten",
  },
  MagicWand: {
    button: "Zauberstab",
  },
  MagicWandHint: {
    button: "Vorschlag erstellen",
    [MagicWandOptions.AUTO_FILL]: {
      heading: "Lücken auffüllen",
      paragraph:
        "Wir füllen leere Flächen in deinen Beeten mit optimal geeigneten Pflanzen.",
    },
    [MagicWandOptions.OPTIMIZATION]: {
      heading: "Anordung optimieren",
      paragraph:
        "Wir suchen jetzt für jede Pflanze den optimalen Platz in deinen Beeten.",
    },
  },
  MagicWandWarningOverlay: {
    [MagicWandWarnings.NO_FREE_FIELDS]: {
      headline: "Keine freien Flächen",
      text: "Wir konnten leider keine freien Flächen in deinen Beeten finden.",
    },
    [MagicWandWarnings.NO_PLANTS_FOUND]: {
      headline: "Nicht alle Lücken wurden gefüllt",
      text: "Für manche Beete konnten wir keine passenden Pflanzen für die freien Flächen finden. Es wurden also nicht alle Lücken befüllt.",
    },
    [MagicWandWarnings.NO_PLANTS_FOUND_FOR_SOME]: {
      headline: "Nicht alle Lücken wurden gefüllt",
      text: "Für manche Beete konnten wir keine passenden Pflanzen für die freien Flächen finden. Es wurden also nicht alle Lücken befüllt.",
    },
  },
  MagicApproval: {
    [MagicWandOptions.AUTO_FILL]: {
      heading: "Hier ist ein Vorschlag für die leeren Flächen:",
      button: "Neuer Vorschlag",
    },
    [MagicWandOptions.OPTIMIZATION]: {
      heading: "Hier ist ein Vorschlag für eine neue Anordnung:",
      button: "Neu anordnen",
    },
  },
  MagicWandControls: {
    button: "Plus",
    [MagicWandMenuEntryType.AUTO_FILL]: {
      heading: "Auffüllen",
      paragraph: "Der perfekte Plan auf Knopfdruck",
    },
    [MagicWandMenuEntryType.AUTO_FILL_FAVORITES]: {
      heading: "Favoriten",
      paragraph: "Auffüllen mit deinen Lieblingsarten",
    },
    [MagicWandMenuEntryType.AUTO_FILL_SURPRISE]: {
      heading: "neue Arten",
      paragraph: "Auffüllen mit überraschenden neuen Arten",
    },
    [MagicWandMenuEntryType.OPTIMIZATION]: {
      heading: "Optimieren",
      paragraph: "Jede Pflanze am perfekten Ort",
    },
  },
  cropVarietiesList: {
    addVarieties: "Sorten wählen",
  },
  npsSurvey: {
    headline: "Eine kurze Frage",
    text: "Wie wahrscheinlich ist es, dass du uns weiterempfiehlst?",
    notLikely: "nicht wahrscheinlich",
    veryLikely: "sehr \nwahrscheinlich",
    acknowledgements: {
      ratingHeadline: "Vielen Dank für deine Hilfe!",
      ratingText: "Wärst du so nett, uns auch im App Store zu bewerten?",
      notNow: "Jetzt nicht",
      yesSure: "Na klar!",
      headline: "Vielen Dank",
      text: "Hast du Wünsche oder Feedback? Wir freuen uns jederzeit über Nachrichten an: support@fryd.app",
      feedbackHeadline: "Schade! Was können wir verbessern?",
      feedbackPlaceholder: "Was hast du für Wünsche und Kritik?",
    },
  },
  library: {
    title: "Fryd Lexikon",
    breadcrumbs: {
      overview: "Übersicht",
      varietyOverview: "Sortenübersicht",
      label: "Du befindest dich hier:",
    },
    appInfoModal: {
      headline: "Desktop App oder Web?",
      content:
        "Du bist bereits in der Fryd Desktop App eingeloggt. Möchtest du die Bibliothek stattdessen dort öffnen?",
      bulletList: {
        label: "Dich erwarten viele weitere Funktionen wie zum Beispiel:",
        points: [
          "Arten und Sorten favorisieren",
          "Favoriten gesammelt verwalten",
          "Eigene Sorten anlegen",
        ],
      },
    },
    registerModal: {
      headline: "Kostenlos anmelden",
      content:
        "Du kannst dich schnell und kostenlos in unserer Desktop App anmelden und viele weiter Funktionen nutzen.",
      bulletList: {
        label: "Dazu gehört zum Beispiel:",
        points: [
          "Arten und Sorten favorisieren",
          "Favoriten gesammelt verwalten",
          "Eigene Sorten anlegen",
        ],
      },
    },
    varietyList: {
      amountOfVarieties: "{{filterAmount}} von {{totalAmount}} Sorten",
      search: {
        placeholder: "Suche nach Sorten",
      },
      backToParent: "Zurück zur Art",
    },
    favorites: {
      usabilityTip:
        "Über das Herz in der oberen rechten Ecke der Kachel kannst du deine Favoriten verwalten.",
      noFavorites:
        "Du hast noch keine Pflanzen zu deinen Favoriten hinzugefügt.",
      toggleModal: {
        headline: "{{cropType}} aus Favoriten entfernen",
        text: "Bist du sicher, dass du die folgende {{cropType}} aus deinen Favoriten entfernen möchtest?",
        actionBtn: "Aus Favoriten entfernen",
      },
    },
    myVarieties: {
      createNewCrop: "Neue Art hinzufügen",
      createNewVariety: "Neue Sorte hinzufügen",
      noOwnVarieties:
        "Du hast noch keine eigene Sorte angelegt. Wenn du eine bisher nicht vorhandene Sorte anlegen möchtest, kannst du das unter dem Menüpunkt <0>Neue Sorte anlegen</0> selbst tun.",
    },
    filters: {
      label: "Filter",
      search: {
        favoritesPlaceholder: "Sorten suchen und favorisieren",
        parentCropPlaceholder: "Suche nach Pflanzen und Sorten",
        varietyPlaceholder: "Suche nach Sorten",
      },
      resetLabel: "Filter zurücksetzen",
      filterResults: {
        compact: "{{amount}} Ergebnisse",
        default: "{{filterAmount}} von {{totalAmount}} Sorten",
      },
      showResults: "{{amount}} Ergebnisse anzeigen",
      noResults:
        "Wir konnten keine Sorte mit diesem Namen oder diesen Eigenschaften finden. Wenn du eine bisher nicht vorhandene Sorte anlegen möchtest, kannst du das unter dem Menüpunkt <0>Neue Sorte anlegen</0> selbst tun.",
      category: {
        title: "Kategorie",
        [CategoryType.VEGETABLES]: { title: "Salate & Blattgemüse" },
        [CategoryType.FRUITS]: { title: "Früchte" },
        [CategoryType.CEREALS]: { title: "Getreide" },
        [CategoryType.HERBS]: { title: "Kräuter" },
        [CategoryType.FLOWERING_PLANTS]: { title: "Blühpflanzen" },
      },
      sortBy: {
        title: "Sortierung",
        value: {
          mostPopular: "Beliebteste zuerst",
          latest: "Neueste zuerst",
          abc: "Alphabetisch",
        },
      },
      favorites: {
        title: "Favoriten",
      },
      vegetables: {
        label: {
          selectAll: "Alle Gemüse",
        },
      },
      lightingDemand: {
        title: "Licht",
        value: {
          heavy: "Sonnig",
          medium: "Halbschatten",
          light: "Schatten",
        },
      },
      waterDemand: {
        title: "Wasser",
        value: { light: "Trocken", medium: "Feucht", heavy: "Sehr feucht" },
      },
      nutrientDemand: {
        title: "Nährstoffe",
        value: { light: "Niedrig", medium: "Mittel", heavy: "Hoch" },
      },

      others: { title: "Sonstige", value: {} },
      reset: "Filter zurücksetzen",
    },
    usabilityTip:
      "Klicke auf eine Pflanze, um eine Sortenübersicht und wertvolle Tipps zum Anbau zu erhalten.",
    createCropInfo: {
      heading: "Kostenlos anmelden",
      paragraph:
        "Du kannst dich schnell und kostenlos in unserer Desktop App anmelden und viele weitere Funktionen nutzen.\n" +
        "\n" +
        "Dazu gehört zum Beispiel:\n",
      examples: [
        "Arten und Sorten favorisieren",
        "Favoriten gesammelt verwalten",
        "Eigene Sorten anlegen",
      ],
    },
  },
  paywall: {
    product: {
      plus: "Fryd plus",
      pro: "Fryd pro",
      allProducts: "Die Produkte im Überblick",
      everythingFromPlus: "Alles aus Fryd plus",
    },
    text: {
      // TODO: set context texts if needed
      plus: "Erstelle den perfekten Plan in nur wenigen Klicks und wir führen dich Schritt-für-Schritt zur reichen Ernte.",
      pro: "Erstelle einen mehrjährigen Plan unter Berücksichtigung der Fruchtwechsel-Regeln. ",
    },
    button: {
      plus: "Plus freischalten",
      pro: "Pro freischalten",
      allProducts: "Alle Produkte anzeigen",
      selectPlus: "Plus freischalten",
      selectPro: "Pro freischalten",
    },
    features: {
      magicwand: {
        name: "Zauberstab (coming soon)",
        description: "Der perfekte Plan in wenigen Klicks.",
      },
      todos: {
        name: "Aufgaben",
        description: "Wir führen dich zur perfekten Ernte",
      },
      precultures: {
        name: "Vor- und Nachkulturen",
        description: "Plane dein ganzes Gartenjahr",
      },
      croprotation: {
        name: "Fruchtwechsel (coming soon)",
        description: "Optimiere deine Bodenqualität",
      },
      multiyear: {
        name: "Mehrjährige Planung",
        description: "Jahr für Jahr zur Selbstversorgung",
      },
    },
    pricePerYear: "1 Jahr für {{price}}€",
    price: "{{price}}€",
    pricePerYearLabel: "Preis pro Jahr",
    payment: {
      headline: {
        plus: "Fryd plus abonnieren",
        pro: "Fryd pro abonnieren",
      },
    },
    voucher: {
      headline: "Gutschein einlösen",
      text: "Bitte gib deinen Gutscheincode ein",
      confirmed:
        "Dein {{accountType}} Account ist jetzt aktiv, viel Spaß beim Gärtnern.",
      statusMessage: {
        [VoucherStatusMessageType.NONE]: "",
        [VoucherStatusMessageType.SUCCESS]: "Gültiger Gutscheincode",
        [VoucherStatusMessageType.INVALID]: "Gutscheincode ist ungültig",
        [VoucherStatusMessageType.WRONG_FORMAT]:
          "Gutscheincode ist im falschen Format",
        [VoucherStatusMessageType.VALIDATION_IN_PROGRESS]:
          "Gutscheincode-Validierung wird durchgeführt",
      },
    },
  },
  cancellation: {
    headline: "Fryd Abo kündigen",
    text: "Schade, dass du uns verlassen möchtest. Um mit der Kündigung fortzufahren, bestätige dies bitte durch Klick auf den Button",
    confirmButton: "Jetzt kündigen",
  },
  payment: {
    step: {
      account: "Account",
      payment: "Zahlung",
    },
    login: {
      enterEmail: "E-Mail-Adresse eingeben",
      enterPassword: "Kennwort eingeben",
      info: "(neue und bestehende Benutzer)",
      help: "Neue Benutzer legen nach dem Kauf ein Kennwort fest.",
      registerNow: "Registriere dich jetzt",
    },
    subscription: {
      type: {
        [ACCOUNT_TYPE_PLUS]: "Fryd plus",
        [ACCOUNT_TYPE_PRO]: "Fryd pro",
      },
      onYear: "Ein Jahr ",
      pricePerYear: "{{price}} €/Jahr",
      annualSubscription: "Jahres-Abo",
      yourOrder: "Deine Bestellung",
      invoiceAmount: "Rechnungsbetrag",
      invoiceInfo:
        "Kündige bis zum {{date}}, damit deine Kosten vollständig erstattet werden, ohne dass Gebühren anfallen.",
    },
    payment: {
      heading: "Weiterleitung zur Zahlung",
      info: "Wir leiten dich jetzt weiter zu einem unserer beiden Zahlungsdienstleister.",
      payPalButton: "Jetzt kaufen über PayPal",
      otherPaymentButton: "Andere Zahlungsart wählen",
    },
    paymentSuccess: {
      heading: "Super, dein Kauf hat geklappt!",
      signIn: "Jetzt planen",
      signUp: "Jetzt Profil vervollständigen",
    },
    hasAccount: {
      headline: "Du hast bereits ein Abo",
      text: "Bitte melde dich jetzt an, um dein Abo zu nutzen. Solltest du an deinem Abo eine Änderung vornehmen wollen, dann wende dich bitte an support@fryd.app - wir helfen dir gerne.",
      gotoLogin: "Jetzt anmelden",
    },
  },
  seasonRow: {
    precultivation: "Vorziehen",
    greenHouse: "Gewächshaus",
    germination: "Auspflanzen",
    harvest: "Ernte",
  },
  downloadApp: {
    heading: "Optimiere deine Beetfläche",
    claims: [
      "Werde Teil der Fryd-Familie aus über 100.000 Gärtner:innen",
      "Erstelle deinen Mischkultur-Plan und steigere deinen Ertrag",
      "Lerne, wie man richtig ökologisch gärtnert",
    ],
    button: "Jetzt losgärtnern",
    loadFromAppStore: "Laden im App Store",
    loadFromPlayStore: "Jetzt bei Google Play",
  },
  connectionErrorModal: {
    errorMessage: "Die Daten konnten nicht geladen werden",
    tryAgain: "Erneut versuchen",
    reload: "Neu laden",
  },
};
