import "overlayscrollbars/css/OverlayScrollbars.css";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

import { forwardRef, ReactNode } from "react";

import styled, { css } from "styled-components";
import layout, { LayoutProps } from "../../Styled/layout";
import flexItem, { FlexItemProps } from "../../Styled/flexItem";
import margin, { MarginProps } from "../../Styled/margin";
import OverlayScrollbars from "overlayscrollbars";

export type ScrollElement = OverlayScrollbarsComponent;

type OverlayScrollbarProps = {
  autoHide?: "never" | "scroll" | "leave" | "move";
  dragScrolling?: boolean;
  clickScrolling?: boolean;
  className?: string;
  callbacks?: OverlayScrollbars.Options["callbacks"];
  children?: ReactNode;
};

const OverlayScrollbar = forwardRef<ScrollElement, OverlayScrollbarProps>(
  (
    {
      autoHide = "never",
      dragScrolling = true,
      clickScrolling = true,
      className,
      callbacks,
      children,
    },
    ref
  ) => {
    return (
      <OverlayScrollbarsComponent
        ref={ref}
        options={{
          className: className,
          scrollbars: { autoHide, dragScrolling, clickScrolling },
          callbacks,
        }}
      >
        {children}
      </OverlayScrollbarsComponent>
    );
  }
);

type StyledScrollBarProps = {
  scrollbarThickness?: "md" | "sm";
  colorVariant?: "grey" | "beige";
  trackVisible?: boolean;
  trackRounded?: boolean;
} & LayoutProps &
  FlexItemProps &
  MarginProps;

const StyledScrollBar = styled(OverlayScrollbar)<StyledScrollBarProps>(
  ({
    theme,
    scrollbarThickness = "md",
    colorVariant = "grey",
    trackVisible = false,
    trackRounded = false,
  }) => {
    const thickness =
      (scrollbarThickness === "md" && theme.sizes["20px"]) ||
      (scrollbarThickness === "sm" && theme.sizes["16px"]);

    return css`
      &,
      * {
        box-sizing: border-box;
      }

      .os-scrollbar {
        border-radius: ${trackRounded && theme.radii.full};
        padding: ${theme.sizes["4px"]};
        background-color: ${trackVisible &&
        ((colorVariant === "grey" && theme.colors.greyLight) ||
          (colorVariant === "beige" && theme.colors.beigeMedium))};
      }
      .os-scrollbar-vertical {
        width: ${thickness};
      }
      .os-scrollbar-horizontal {
        height: ${thickness};
      }

      .os-scrollbar-track {
        //
      }

      .os-scrollbar-handle {
        border-radius: ${theme.radii.full};

        background-color: ${false ||
        (colorVariant === "grey" && theme.colors.greyMedium) ||
        (colorVariant === "beige" && theme.colors.beigeMidi)};
        &:hover {
          background-color: ${false ||
          (colorVariant === "grey" && theme.colors.greyMidi) ||
          (colorVariant === "beige" && theme.colors.beigeDark)};
        }
        &.active {
          background-color: ${false ||
          (colorVariant === "grey" && theme.colors.greyDark) ||
          (colorVariant === "beige" && theme.colors.beigeDarkest)};
        }
      }

      .os-scrollbar-corner {
        //
      }

      ${layout}
      ${flexItem}
      ${margin}
    `;
  }
);

type ScrollBoxProps = {
  children?: ReactNode;
} & OverlayScrollbarProps &
  StyledScrollBarProps;

const ScrollBox = forwardRef<ScrollElement, ScrollBoxProps>(
  ({ children, ...props }, ref) => {
    return (
      <StyledScrollBar ref={ref} {...props}>
        {children}
      </StyledScrollBar>
    );
  }
);

export default ScrollBox;
