import { css } from "styled-components";
import { createResponisiveCSSProp, ResponsiveValue } from "./Core";

type Cursors =
  | "auto"
  | "default"
  | "none"
  | "context-menu"
  | "help"
  | "pointer"
  | "progress"
  | "wait"
  | "cell"
  | "crosshair"
  | "text"
  | "vertical-text"
  | "alias"
  | "copy"
  | "move"
  | "no-drop"
  | "not-allowed"
  | "grab"
  | "grabbing"
  | "e-resize"
  | "n-resize"
  | "ne-resize"
  | "nw-resize"
  | "s-resize"
  | "se-resize"
  | "sw-resize"
  | "w-resize"
  | "ew-resize"
  | "ns-resize"
  | "nesw-resize"
  | "nwse-resize"
  | "col-resize"
  | "row-resize"
  | "all-scroll"
  | "zoom-in"
  | "zoom-out";

export type CursorProps = {
  cursor?: ResponsiveValue<Cursors>;
};

export const cursorDefaultProps: CursorProps = {
  cursor: "auto",
};

const cursor = css<CursorProps>(
  ({ cursor }) => css`
    ${createResponisiveCSSProp({
      prop: cursor,
      property: "cursor",
    })}
  `
);

export default cursor;
