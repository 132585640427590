import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Text from "../../Typography/Text";
import ChevronUp from "../../../Resources/SVG/ChevronUp";
import Column from "../../Layout/Column";
import Checkmark from "../../../Resources/SVG/Checkmark";
import { LayoutProps } from "../../Styled/layout";
import ChevronDown from "../../../Resources/SVG/ChevronDown";
import styled, { css } from "styled-components/macro";
import { useOnClickOutside } from "../../../Core/CustomHooks";
import Close from "../../../Resources/SVG/Close";
import theme from "../../../Theme";
import TextButton from "../TextButton";
import { useTranslation } from "react-i18next";
import { setEquals } from "../../../Utils/setUtils";
import Row from "../../Layout/Row";
import Center from "../../Layout/Center";

const ALL_SELECTED = "__all_selected__";

export enum ChoiceType {
  SINGLE,
  MULTIPLE,
}

export type OptionType = {
  value: string;
  label: string;
  selected?: boolean;
};

type DropdownItemProps = {
  option: OptionType;
  onClick?: (option: OptionType) => void;
};

const CroppedText = styled(Text)<{ numberOfLines: number }>(
  ({ numberOfLines }) => css`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: ${numberOfLines}; /* number of lines to show */
    line-clamp: ${numberOfLines};
    -webkit-box-orient: vertical;
  `
);

const EmptyDropdownItem = styled(Row)`
  top: 100%;
  width: 100%;
  position: absolute;
`;

const DropdownItem = ({ option, onClick }: DropdownItemProps) => {
  const handleOnClick = () => {
    if (onClick) {
      onClick(option);
    }
  };

  return (
    <Row
      justifyContent={"space-between"}
      alignItems={"center"}
      backgroundColor={"white"}
      px={"16px"}
      py={"8px"}
      gap={"16px"}
      width={"100%"}
      onClick={handleOnClick}
      role={"option"}
      aria-selected={option.selected}
      className={option.selected ? "drop-down-item-selected" : "drop-down-item"}
    >
      <CroppedText
        fontWeight={option.selected ? "bold" : "normal"}
        numberOfLines={1}
      >
        {option.label}
      </CroppedText>
      {option.selected && <Checkmark data-testid="checkmark" size={"sm"} />}
    </Row>
  );
};

type DropdownItemListProps = {
  options: OptionType[];
  type: ChoiceType;
  onSelectItem?: (option: OptionType) => void;
  footer?: ReactNode;
  compactDisplay?: boolean;
};
const DropdownItemList = ({
  options,
  onSelectItem,
  footer,
  type,
  compactDisplay,
}: DropdownItemListProps) => {
  return (
    <ListContainer
      className={type === ChoiceType.MULTIPLE ? "multiSelect" : "singleSelect"}
      position={"absolute"}
      backgroundColor={"white"}
      borderRadius={"md"}
      width={"max-content"}
      minWidth={"100%"}
      overflow={"hidden"}
      role={"listbox"}
      compactDisplay={!!compactDisplay}
      aria-multiselectable={type === ChoiceType.MULTIPLE}
    >
      {options.map((option) => (
        <DropdownItem
          key={option.value}
          option={option}
          onClick={onSelectItem}
        />
      ))}
      {footer}
    </ListContainer>
  );
};

const HeaderContainer = styled(Row).attrs(
  ({ open, hasError }: { open: boolean; hasError?: boolean }) => ({
    open: open,
    hasError: hasError,
  })
)`
  > * {
    user-select: none;
  }
  z-index: 2;
  border-bottom-right-radius: ${(props) =>
    props.open ? "0px" : theme.radii.md};
  border-bottom-left-radius: ${(props) =>
    props.open ? "0px" : theme.radii.md};
  border-color: ${(props) =>
    props.hasError ? props.theme.colors.red : undefined};
  color: ${(props) => (props.hasError ? props.theme.colors.red : undefined)};
`;

const Counter = ({ count }: { count: number }) => {
  return (
    <Center
      borderRadius={"full"}
      backgroundColor={"beigeDark"}
      width={"20px"}
      height={"20px"}
    >
      <Text $color={"white"} fontWeight={"bold"}>
        {count}
      </Text>
    </Center>
  );
};

type ListContainerProps = { compactDisplay: boolean };
const ListContainer = styled(Column)<ListContainerProps>(
  ({ theme, compactDisplay }) => css`
    z-index: 1;
    cursor: pointer;
    user-select: none;
    top: 100%;
    border-top-left-radius: 0;
    &.singleSelect {
      border-top-right-radius: 0;
    }
    &.multiSelect {
      border-top-right-radius: ${compactDisplay ? 0 : undefined};
    }

    box-shadow: ${theme.shadows.lg};

    & > * {
      word-break: break-all;
      user-select: none;
    }

    & > *:not(.footer):hover {
      background-color: ${theme.colors.greyLight};
    }
    &.multiSelect > :nth-last-child(n + 2) {
      border-radius: ${theme.radii.none};
      border-bottom: ${theme.borders["1pxSolid"]} ${theme.colors.greyMedium};
    }
  `
);

type DropdownProps = {
  label: string;
  resetLabel?: string;
  selectAllLabel?: string;
  type?: ChoiceType;
  options: OptionType[];
  values?: OptionType["value"][];
  resetValues?: boolean;
  open?: boolean;
  compactDisplay?: boolean;
  onChange: (values: OptionType["value"][]) => void;
  onReset?: () => void;
  hasError?: boolean;
} & Pick<LayoutProps, "width" | "maxWidth" | "minWidth">;

const Dropdown = ({
  label,
  resetLabel,
  selectAllLabel,
  options,
  values,
  onChange,
  onReset,
  width,
  minWidth,
  maxWidth,
  compactDisplay,
  type = ChoiceType.SINGLE,
  open = false,
  resetValues = false,
  hasError = false,
}: DropdownProps) => {
  const [isListOpen, setIsListOpen] = useState(open);
  const [headerTitle, setHeaderTitle] = useState(label);
  const [selectedValues, setSelectedValues] = useState<
    OptionType["value"][] | undefined
  >(values);

  const wrapper = useRef();
  const { t } = useTranslation("common");

  useOnClickOutside(wrapper, () => {
    if (isListOpen) {
      setIsListOpen(false);
    }
  });

  useEffect(() => {
    if (
      type === ChoiceType.MULTIPLE &&
      !setEquals(
        new Set<OptionType["value"]>(selectedValues),
        new Set<OptionType["value"]>(values)
      )
    ) {
      setSelectedValues(values);
    }
  }, [values]);

  useEffect(() => {
    if (resetValues) {
      setSelectedValues(undefined);
    }
  }, [resetValues]);

  useEffect(() => {
    if (type === ChoiceType.SINGLE) {
      if (!selectedValues || selectedValues.length === 0) {
        setHeaderTitle(label);
      } else {
        const selectedOption = options.find(
          (o) => o.value === selectedValues[0]
        );
        if (selectedOption) {
          setHeaderTitle(selectedOption.label);
        } else {
          console.warn(`Unknown dropdown option value ${selectedValues[0]}`);
        }
      }
    }
  }, [selectedValues]);

  const resetOptions = () => {
    setSelectedValues(undefined);
    setIsListOpen(false);
    setHeaderTitle(label);
    !!onReset && onReset();
    !!onChange && onChange([]);
  };

  const onClickDropDown = () => {
    if (
      type === ChoiceType.SINGLE &&
      selectedValues &&
      selectedValues.length === 1
    ) {
      resetOptions();
    } else {
      setIsListOpen(!isListOpen);
    }
  };

  const onSelectListItem = (option: OptionType) => {
    setIsListOpen(false);

    let updatedValues = selectedValues || [];

    if (type === ChoiceType.SINGLE) {
      if (updatedValues.length === 1) {
        if (option.value === updatedValues[0]) {
          updatedValues = [];
          setHeaderTitle(label);
        } else {
          updatedValues = [option.value];
          setHeaderTitle(option.label);
        }
      } else {
        updatedValues = [option.value];
        setHeaderTitle(option.label);
      }
    } else {
      const allSelected = setEquals(
        new Set<OptionType["value"]>(selectedValues),
        new Set<OptionType["value"]>(options.map((o) => o.value))
      );
      if (selectAllLabel && option.value === ALL_SELECTED) {
        if (allSelected) {
          updatedValues = [];
        } else {
          updatedValues = options.map((o) => o.value);
        }
      } else {
        if (selectAllLabel && allSelected) {
          updatedValues = [option.value];
        } else {
          if ((selectedValues || []).indexOf(option.value) < 0) {
            updatedValues.push(option.value);
          } else {
            updatedValues.splice(
              (selectedValues || []).indexOf(option.value),
              1
            );
          }
        }
      }
    }

    setSelectedValues(updatedValues);
    onChange(updatedValues);
  };

  const composeOptions = useCallback(
    (options: OptionType[]) => {
      const allSelected = setEquals(
        new Set<OptionType["value"]>(selectedValues || []),
        new Set<OptionType["value"]>(options.map((o) => o.value))
      );
      const opts = options.map((i) => {
        const opt = { ...i };
        if (selectAllLabel) {
          opt.selected =
            !allSelected && (selectedValues || []).indexOf(i.value) >= 0;
        } else {
          opt.selected = (selectedValues || []).indexOf(i.value) >= 0;
        }
        return opt;
      });
      if (selectAllLabel) {
        return [
          {
            value: ALL_SELECTED,
            label: selectAllLabel,
            selected: allSelected,
          },
          ...opts,
        ];
      } else {
        return opts;
      }
    },
    [options, selectedValues]
  );

  return (
    <Column
      position={"relative"}
      ref={wrapper}
      width={width}
      minWidth={minWidth}
      maxWidth={maxWidth}
      borderRadius={"md"}
      boxShadow={isListOpen ? "lg" : "none"}
      className={"dropdown"}
      zIndex={isListOpen ? 10 : 1}
      role={"combobox"}
      aria-expanded={isListOpen}
    >
      <HeaderContainer
        hasError={hasError}
        width={"100%"}
        justifyContent={"space-between"}
        alignItems={"center"}
        border={
          type === ChoiceType.SINGLE && (selectedValues || []).length > 0
            ? "1pxSolid"
            : "none"
        }
        borderColor={
          type === ChoiceType.SINGLE && (selectedValues || []).length > 0
            ? "beigeDarkest"
            : isListOpen
            ? "greyMedium"
            : "transparent"
        }
        backgroundColor={
          type === ChoiceType.SINGLE && (selectedValues || []).length > 0
            ? "beigeMidi"
            : "white"
        }
        borderRadius={"md"}
        px={"16px"}
        py={"8px"}
        gap={"16px"}
        onClick={onClickDropDown}
        open={isListOpen}
        borderBottom={"1pxSolid"}
      >
        <Row gap={"8px"}>
          <Text fontWeight={"bold"}>{headerTitle}</Text>
          {type === ChoiceType.MULTIPLE &&
            (selectedValues || []).length > 0 && (
              <Counter count={(selectedValues || []).length} />
            )}
        </Row>
        {type === ChoiceType.SINGLE && (selectedValues || []).length > 0 ? (
          <Close size={"sm"} color={"beigeDarkest"} />
        ) : isListOpen ? (
          <ChevronUp size={"sm"} />
        ) : (
          <ChevronDown size={"sm"} />
        )}
      </HeaderContainer>
      {isListOpen && options.length === 0 && (
        <EmptyDropdownItem
          p={"16px"}
          backgroundColor={"white"}
          className={"footer"}
          justifyContent={"center"}
        />
      )}
      {isListOpen && (
        <DropdownItemList
          options={composeOptions(options)}
          type={type}
          onSelectItem={onSelectListItem}
          compactDisplay={compactDisplay}
          footer={
            selectedValues &&
            selectedValues.length > 0 && (
              <Row
                p={"16px"}
                borderRadius={"md"}
                backgroundColor={"white"}
                className={"footer"}
                justifyContent={"center"}
              >
                <TextButton
                  label={resetLabel || t("common:actions.reset")}
                  onClick={resetOptions}
                  $color={"greenApp"}
                />
              </Row>
            )
          }
        />
      )}
    </Column>
  );
};

export default Dropdown;
