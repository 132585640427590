import React, { useEffect, useState } from "react";
import PasswordResetNewPassword from "./index";
import { useDispatch, useSelector } from "react-redux";
import * as ApiActions from "../../Reducers/Api/Actions";
import * as UserActions from "../../Reducers/User/Actions";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PasswordResetNewPasswordContainer = () => {
  const { t } = useTranslation("passwordReset");
  const dispatch = useDispatch();

  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [passwordRepeatTouched, setPasswordRepeatTouched] = useState(false);
  const [newPasswordRequested, setNewPasswordRequested] = useState(false);

  const { successfulLoginReset } = useSelector((state: any) => state.User);
  const { isFetching } = useSelector((state: any) => state.Api);

  const { hash } = useParams();

  useEffect(() => {
    if (passwordTouched && passwordRepeatTouched) {
      if (password === "" || passwordRepeat === "") {
        setPasswordErrorMessage(t("passwordRequired"));
      } else if (password !== passwordRepeat) {
        setPasswordErrorMessage(t("inputsMustBeEqual"));
      } else {
        setPasswordErrorMessage("");
      }
    } else {
      setPasswordErrorMessage("");
    }
  }, [passwordTouched, passwordRepeatTouched, password, passwordRepeat]);

  return (
    <PasswordResetNewPassword
      isFetching={isFetching}
      password={password}
      setPassword={(value) => setPassword(value)}
      setPasswordTouched={() => setPasswordTouched(true)}
      setPasswordRepeatTouched={() => setPasswordRepeatTouched(true)}
      passwordRepeat={passwordRepeat}
      setPasswordRepeat={(value) => setPasswordRepeat(value)}
      passwordError={!!passwordErrorMessage}
      passwordErrorMessage={passwordErrorMessage}
      newPasswordRequested={newPasswordRequested}
      successfulLoginReset={!!successfulLoginReset}
      onClickReset={() => {
        setNewPasswordRequested(true);
        dispatch(
          ApiActions.postData(
            "/users/recoverlogin",
            {
              resetHash: hash,
              password,
            },
            UserActions.receiveRecoverLogin
          )
        );
      }}
    />
  );
};

export default PasswordResetNewPasswordContainer;
