import moment from "moment";
import _, { merge } from "lodash";
import * as ApiActions from "../Api/Actions";
import { postData } from "../Api/Actions";
import {
  setIsPlusAccount,
  setIsProAccount,
  setPlusExpired,
  setProExpired,
} from "../Account/Actions";
import { setUseSeasons } from "../Planning/Actions";
import Api from "../../Core/Api";
import { ACCOUNT_TYPE_PLUS, ACCOUNT_TYPE_PRO } from "../../Core/Constants";
import Routes from "../../Core/Routes";
import { mergeObject } from "../../Helpers/UtilityHelpers";

export const START_LOADING = "LOGIN_START_LOADING";
export const RECEIVE_CHECK_EMAIL = "USER_RECEIVE_CHECK_EMAIL";
export const RECEIVE_CHECK_EMAIL_ERROR = "RECEIVE_CHECK_EMAIL_ERROR";
export const RESET_CHECK_EMAIL = "RESET_CHECK_EMAIL";
export const RECEIVE_REGISTER = "RECEIVE_REGISTER";
export const RECEIVE_REGISTER_DEVICE = "RECEIVE_REGISTER_DEVICE";
export const RESET_REGISTER = "RESET_REGISTER";
export const RECEIVE_UNREGISTER = "RECEIVE_UNREGISTER";
export const RECEIVE_RESEND_OPTIN = "RECEIVE_RESEND_OPTIN";
export const RECEIVE_OPT_IN_USER = "RECEIVE_OPT_IN_USER";
export const RECEIVE_USER = "RECEIVE_USER";
export const RECEIVE_LOGIN = "RECEIVE_LOGIN";
export const RECEIVE_RECOVER_LOGIN = "RECEIVE_RECOVER_LOGIN";
export const RESET_TOKEN = "RESET_TOKEN";
export const GO_TO_LOGOUT = "GO_TO_LOGOUT";
export const LOGOUT = "USER_LOGOUT";
export const RESET_GO_TO_LOGOUT = "RESET_GO_TO_LOGOUT";
export const RECEIVE_LOCATIONS = "RECEIVE_LOCATIONS";
export const SELECT_LOCATION = "SELECT_LOCATION";
export const REGISTER_WEBPUSH_NOTIFICATIONS = "REGISTER_WEBPUSH_NOTIFICATIONS";
export const SET_REGISTER_WITH_PATCH = "SET_REGISTER_WITH_PATCH";
export const RECEIVE_SUBSCRIPTION = "RECEIVE_SUBSCRIPTION";
export const RECEIVE_SUBSCRIPTION_CONFIRM = "RECEIVE_SUBSCRIPTION_CONFIRM";
export const RECEIVE_INVOICES = "RECEIVE_INVOICES";
export const EDIT_USER = "EDIT_USER";
export const RESET_USER = "RESET_USER";
export const SET_SHOW_ONBOARDING = "SET_SHOW_ONBOARDING";
export const RECEIVE_CANCEL_SUBSCRIPTION = "RECEIVE_CANCEL_SUBSCRIPTION";
export const SET_APP_VERSION_INFO = "SET_APP_VERSION_INFO";
export const BEGIN_NEW_RESET_PROCESS = "BEGIN_NEW_RESET_PROCESS";
export const SET_REGISTER_CONTEXT = "SET_REGISTER_CONTEXT";
export const SET_PUSH_NOTIFICATIONS_ASKED = "SET_PUSH_NOTIFICATIONS_ASKED";
export const SET_NOTIFICATIONS_SETTING = "SET_NOTIFICATIONS_SETTING";
export const SET_ONBOARDING_STEP = "SET_ONBOARDING_STEP";
export const RECEIVE_PAYPAL_PLAN_ID = "RECEIVE_PAYPAL_PLAN_ID";
export const RECEIVE_NICKNAME_AVAILABILITY = "RECEIVE_NICKNAME_AVAILABILITY";
export const RESET_NICKNAME_AVAILABILITY = "RESET_NICKNAME_AVAILABILITY";
export const SET_IMAGE_COPYRIGHT_SETTING = "SET_IMAGE_COPYRIGHT_SETTING";
export const SET_PRECULTIVATION_PATCH_POSITION =
  "SET_PRECULTIVATION_PATCH_POSITION";
export const RECEIVE_ACTIVATE_TRIAL = "RECEIVE_ACTIVATE_TRIAL";
export const SET_SOCIAL_USER = "USER_SET_SOCIAL_USER";
export const RESET_SOCIAL_LOGIN = "USER_RESET_SOCIAL_LOGIN";
export const RECEIVE_FOLLOWING = "USER_RECEIVE_FOLLOWING";
export const RECEIVE_FOLLOWERS = "USER_RECEIVE_FOLLOWERS";
export const RECEIVE_FOLLOW_USER = "FEED_RECEIVE_FOLLOW_USER";
export const RECEIVE_UNFOLLOW_USER = "FEED_RECEIVE_UNFOLLOW_USER";
export const RECEIVE_OWN_USER_PROFILE = "FEED_RECEIVE_OWN_USER_PROFILE";
export const SET_USER_EDIT = "SET_USER_EDIT";
export const UPDATE_USER_EDIT = "UPDATE_USER_EDIT";

export const beginNewResetProcess = () => ({
  type: BEGIN_NEW_RESET_PROCESS,
});

// USER PAYMENT INTERVAL TYPES
export const FREE_TEST = "FREE_TEST";
export const MONTHLY = "MONTHLY";
export const YEARLY = "YEARLY";
export const QUARTERLY = "QUARTERLY";
export const VOUCHER = "VOUCHER";

export const paymentIntervalTypes = {
  freetest: FREE_TEST,
  monthly: MONTHLY,
  yearly: YEARLY,
  quarterly: QUARTERLY,
  voucher: VOUCHER,
};

export const startLoading = () => ({
  type: START_LOADING,
});

export const receiveRegister = (data, status) => (dispatch) => {
  if (status === 200) {
    Api.setToken(data.token);
    processUser(dispatch, data.user);
  }
  dispatch({
    type: RECEIVE_REGISTER,
    data: data,
    status: status,
  });
};

export const register = (data) => (dispatch) => {
  dispatch(postData("/users/register", data, receiveRegister));
};

export const setRegisterContext = (context) => ({
  type: SET_REGISTER_CONTEXT,
  context,
});

export const setRegisterWithPatch = () => ({
  type: SET_REGISTER_WITH_PATCH,
});

export const receiveOptInUser = (data, status) => ({
  type: RECEIVE_OPT_IN_USER,
  data,
  status,
});

export const editUser = (data) => ({
  type: EDIT_USER,
  data,
});

export const resetUser = () => ({
  type: RESET_USER,
});

export const receiveUnregister = (data, status) => ({
  type: RECEIVE_UNREGISTER,
  status: status,
});

export const unregister = () => (dispatch) =>
  dispatch(ApiActions.postData("/users/unregister", {}, receiveUnregister));

export const receiveRegisterDevice = (data, status) => (dispatch) => {
  if (status === 200) {
    Api.setToken(data.token);
    processUser(dispatch, data.user);
  }
  dispatch({
    type: RECEIVE_REGISTER_DEVICE,
    status: status,
    data: data,
  });
};

export const registerDevice = (deviceId) => (dispatch) =>
  dispatch(
    ApiActions.postData(
      "/users/registerdevice",
      { deviceId },
      receiveRegisterDevice
    )
  );

export const resetRegister = () => ({
  type: RESET_REGISTER,
});

export const receiveResendOptin = (data, status) => ({
  type: RECEIVE_RESEND_OPTIN,
  status: status,
});

export const resendOptinMail = (email) => (dispatch) =>
  dispatch(
    ApiActions.postData("/users/register/resend", { email }, receiveResendOptin)
  );

export const setSocialUser = (socialUser) => ({
  type: SET_SOCIAL_USER,
  socialUser,
});

export const resetSocialLogin = () => ({
  type: RESET_SOCIAL_LOGIN,
});

export const socialLogin = (email, idToken) => (dispatch) => {
  dispatch(startLoading());
  dispatch(
    postData(
      Routes.API_ROUTE_SOCIAL_LOGIN,
      { email, idToken },
      receiveLogin
      /* TODO implement: receiveLoginError*/
    )
  );
};

export const checkNickName = (nickname) => (dispatch) => {
  if (nickname) {
    dispatch(
      ApiActions.postData(
        "/users/checknickname",
        { nickname },
        receiveNickNameAvailabilty
      )
    );
  } else {
    dispatch(resetNickNameAvailabilty());
  }
};

export const receiveNickNameAvailabilty = (data) => ({
  type: RECEIVE_NICKNAME_AVAILABILITY,
  data: data,
});

export const resetNickNameAvailabilty = () => ({
  type: RESET_NICKNAME_AVAILABILITY,
});

export const receiveLogin = (data, status) => (dispatch) => {
  if (status === 200) {
    Api.setToken(data.token);
    processUser(dispatch, data.user);
  }
  dispatch({
    type: RECEIVE_LOGIN,
    data: data,
    status: status,
  });
};

export const login = (data) => (dispatch) => {
  dispatch(postData("/users/login", data, receiveLogin));
};

export const resetToken = () => (dispatch) => {
  Api.setToken("");
  dispatch({ type: RESET_TOKEN });
};

export const goToLogout = () => ({
  type: GO_TO_LOGOUT,
});

export const resetGoToLogout = () => ({
  type: RESET_GO_TO_LOGOUT,
});

export const receiveUser = (data, status) => (dispatch) => {
  if (status !== 200) {
    dispatch(resetToken());
  } else {
    processUser(dispatch, data);
    dispatch({
      type: RECEIVE_USER,
      data,
    });
  }
};

const processUser = (dispatch, user) => {
  if (user) {
    const paidAccountExpired =
      user.payment !== undefined &&
      user.payment !== null &&
      user.payment.interval !== "" &&
      user.payment.interval !== "freetest" &&
      getUserAccountValidity(user) < moment();
    if (paidAccountExpired) {
      dispatch(setPlusExpired());
      dispatch(setProExpired());
      dispatch(setUseSeasons(false));
    } else {
      const accountType = getAccountType(user);
      if (accountType === ACCOUNT_TYPE_PRO) {
        dispatch(setIsProAccount());
        dispatch(setUseSeasons(true));
      } else if (accountType === ACCOUNT_TYPE_PLUS) {
        dispatch(setIsPlusAccount());
        dispatch(setUseSeasons(true));
      } else {
        dispatch(setUseSeasons(false));
      }
    }
  }
};

export const getAccountType = (user) => {
  let accountType = "free";
  if (user && getUserAccountValidity(user) > moment()) {
    if (user.accountType === "paid" || user.accountType === "pro") {
      accountType = ACCOUNT_TYPE_PRO;
    } else if (user.accountType === "plus") {
      accountType = ACCOUNT_TYPE_PLUS;
    }
  }
  return accountType;
};

export const receiveLocations = (data) => ({
  type: RECEIVE_LOCATIONS,
  data: data,
});

export const selectLocation = (location) => ({
  type: SELECT_LOCATION,
  location: location,
});

export const receiveInvoices = (data) => ({
  type: RECEIVE_INVOICES,
  data: data,
});

export const loadInvoices = () => (dispatch) => {
  dispatch(ApiActions.fetchData("/invoices", receiveInvoices));
};

export const receiveSubscriptionRequest = (data) => ({
  type: RECEIVE_SUBSCRIPTION,
  data: data,
});

export const receiveSubscriptionConfirm = (data, status) => (dispatch) => {
  if (status === 200) {
    processUser(dispatch, data.user);
  }
  dispatch({
    type: RECEIVE_SUBSCRIPTION_CONFIRM,
    data,
    status,
  });
};

export const receiveRecoverLogin = (data, status) => ({
  type: RECEIVE_RECOVER_LOGIN,
  data,
  status,
});

export const showOnboarding = () => ({
  type: SET_SHOW_ONBOARDING,
  showOnboarding: true,
});

export const hideOnboarding = () => ({
  type: SET_SHOW_ONBOARDING,
  showOnboarding: false,
});

export const receiveCancelSubscription = (data, status) => (dispatch) => {
  dispatch(ApiActions.fetchData("/users/current", receiveUser));
  dispatch({
    type: RECEIVE_CANCEL_SUBSCRIPTION,
  });
};

export const cancelSubscription = () => (dispatch) =>
  dispatch(
    ApiActions.postData(
      "/users/subscription/cancel",
      {},
      receiveCancelSubscription
    )
  );

export const setAppVersionInfo = (appVersion, appSdkVersion) => ({
  type: SET_APP_VERSION_INFO,
  appVersion,
  appSdkVersion,
});

export const setPushNotificationsAsked = () => ({
  type: SET_PUSH_NOTIFICATIONS_ASKED,
});

export const setNotificationsSetting = (setting, value) => ({
  type: SET_NOTIFICATIONS_SETTING,
  setting,
  value,
});

export const setImageCopyrightAccepted = (setting, value) => ({
  type: SET_IMAGE_COPYRIGHT_SETTING,
  setting,
  value,
});

export const setOnboardingStep = (step) => ({
  type: SET_ONBOARDING_STEP,
  step,
});

export const receiveCheckEmail = (data) => ({
  type: RECEIVE_CHECK_EMAIL,
  data,
});

export const receiveCheckEmailError = (data, status) => ({
  type: RECEIVE_CHECK_EMAIL_ERROR,
  data,
  status,
});

export const addAllSeedsToCart = (patchId) => (dispatch) => {
  dispatch(
    ApiActions.postData(`/shop/cart/patch/${patchId}`, {}, () => ({ type: "" }))
  );
};

export const setPrecultivationPatchPosition =
  (position, isTemporary = false) =>
  async (dispatch, getState) => {
    await dispatch({
      type: SET_PRECULTIVATION_PATCH_POSITION,
      position,
      isTemporary,
    });
    const state = getState();
    !isTemporary && dispatch(saveUser(state.User.user));
  };

export const receivePaypalPlanId = (data) => ({
  type: RECEIVE_PAYPAL_PLAN_ID,
  data,
});

export const saveUser = (user) => (dispatch) => {
  dispatch(ApiActions.postData("/users", user, receiveUser));
};

export const requestPaypalPlanId = (params) => (dispatch) => {
  dispatch(
    ApiActions.postData(
      "/paypal/subscription/create",
      params,
      receivePaypalPlanId
    )
  );
};

export const confirmPaypalSubscription =
  (subscriptionId, payerId) => (dispatch) => {
    dispatch(
      ApiActions.postData(
        "/paypal/subscription/confirm",
        { subscriptionId, payerId },
        receiveSubscriptionConfirm
      )
    );
  };

export const getUserAccountValidity = (user) => {
  return user === undefined ||
    user.accountType === null ||
    user.payment === undefined ||
    _.trim(user.payment.validUntil) === ""
    ? moment().subtract(1, "day") // If validUntil not specified force account as invalid
    : moment(user.payment.validUntil).hour(23).minute(59);
};

export const updateUserSetting =
  (settingKey, settingValue, isObj = false) =>
  (dispatch, getState) => {
    const { user } = getState().User;
    console.log(user);
    const data = user;
    if (!data.settings) {
      data.settings = {};
    }

    if (isObj) {
      data[settingKey] = settingValue;
    } else {
      data.settings[settingKey] = settingValue;
    }

    return dispatch(postData("/users", data, receiveUser));
  };

export const checkEmail = (email) => (dispatch) => {
  dispatch(postData("/users/checkemail", { email }, receiveCheckEmail));
};

export const resetCheckEmail = () => ({
  type: RESET_CHECK_EMAIL,
});

export const receiveActivateTrial = (data) => ({
  type: RECEIVE_ACTIVATE_TRIAL,
  data,
});

export const activateTrial = () => async (dispatch) => {
  await dispatch(ApiActions.postData("/users/trial", {}, receiveActivateTrial));
  dispatch(ApiActions.fetchData("/users/current", receiveUser));
};

export const setTrialExpirationShown = () => (dispatch, getState) => {
  const user = getState().User.user;
  user.settings.trialExpirationShown = true;
  dispatch(saveUser(user));
};

export const setPaidExpirationShown = () => (dispatch, getState) => {
  const user = getState().User.user;
  user.settings.paidExpirationShown = true;
  dispatch(saveUser(user));
};

export const setShareDialogShown = () => (dispatch, getState) => {
  const user = getState().User.user;
  user.settings.shareDialogShown = true;
  dispatch(saveUser(user));
};

export const loadCurrentUser = () => (dispatch) => {
  dispatch(ApiActions.fetchData("/users/current", receiveUser));
};

export const logoutUser = () => ({
  type: LOGOUT,
});

export const receiveFollowing = (data) => ({
  type: RECEIVE_FOLLOWING,
  data: data.following,
});

export const loadFollowingUsers = () => (dispatch) => {
  dispatch(startLoading());
  dispatch(
    ApiActions.fetchData(Routes.API_ROUTE_USERS_FOLLOWING, receiveFollowing)
  );
};

export const receiveFollowers = (data) => ({
  type: RECEIVE_FOLLOWERS,
  data: data.followers,
});

export const loadFollowers = () => (dispatch) => {
  dispatch(startLoading());
  dispatch(
    ApiActions.fetchData(Routes.API_ROUTE_USERS_FOLLOWERS, receiveFollowers)
  );
};

export const receiveFollowUser = (data) => ({
  type: RECEIVE_FOLLOW_USER,
  hash: data.hash,
});

export const followUser = (hash) => (dispatch) => {
  dispatch(
    ApiActions.postData(
      Routes.API_ROUTE_USER_FOLLOW.replace("{hash}", hash),
      {},
      receiveFollowUser
    )
  );
};

export const receiveUnfollowUser = (data) => ({
  type: RECEIVE_UNFOLLOW_USER,
  hash: data.hash,
});

export const unfollowUser = (hash) => (dispatch) => {
  dispatch(
    ApiActions.postData(
      Routes.API_ROUTE_USER_UNFOLLOW.replace("{hash}", hash),
      {},
      receiveUnfollowUser
    )
  );
};

export const receiveOwnUserProfile = (data) => ({
  type: RECEIVE_OWN_USER_PROFILE,
  data: data,
});

export const fetchOwnUserProfile = (profileHash) => (dispatch) => {
  dispatch(
    ApiActions.fetchData(
      Routes.API_ROUTE_FEED_USER_PROFILE.replace("{hash}", profileHash),
      receiveOwnUserProfile
    )
  );
};

export const updateUser = (userData) => (dispatch, getState) => {
  const { user } = getState().User;
  const links = userData?.profile?.links;
  const data = mergeObject(user, userData);
  if (links && data) {
    data.profile = { ...data.profile, links };
  }

  dispatch(setUserEdit(undefined));
  return dispatch(postData(Routes.API_ROUTE_USERS, data, receiveUser));
};

export const setUserEdit = (userEdit) => ({
  type: SET_USER_EDIT,
  data: userEdit,
});

export const resetUserEdit = () => ({
  type: SET_USER_EDIT,
  data: undefined,
});

export const updateUserEdit = (userEdit) => ({
  type: UPDATE_USER_EDIT,
  data: userEdit,
});
