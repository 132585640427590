import React from "react";
import styled, { css } from "styled-components";
import { Box, Button, Column, Flex, Image, Text } from "../../../../Base";
import { TypographyProps } from "../../../../Base/Styled/typography";
import { SpaceProps } from "../../../../Base/Styled/space";
import Ellipse from "../../../../Resources/SVG/Ellipse";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Images from "../../../../Core/Images";
import Routes from "../../../../Core/Routes";

const FlexZ10 = styled(Flex)`
  z-index: 10;
`;

export const ClaimsList = styled.ul`
  padding-left: 12px;
`;

const RelativeSpan = styled.span`
  position: relative;
`;

const OptimizeHeading = styled(Box)<{
  textAlign: TypographyProps["textAlign"];
}>(
  ({ theme, textAlign = "center" }) => css`
    & > * {
      font-family: ${theme.fonts.vollkorn};
      font-weight: ${theme.fontWeights.bold};
      font-size: ${theme.fontSizes["2xl"]};
    }
    padding-top: 48px;
    position: relative;
    text-align: ${textAlign ? textAlign : undefined};
  `
);

export const DownloadAppHeader = ({
  text,
  textAlign = "center",
  ...props
}: { text: string } & {
  textAlign: TypographyProps["textAlign"];
} & SpaceProps) => {
  return (
    <OptimizeHeading textAlign={textAlign} {...props}>
      <RelativeSpan>
        {text}
        <EllipseBox top={-10} left={-38}>
          <Ellipse color={"orangeLight"} />
        </EllipseBox>
      </RelativeSpan>
    </OptimizeHeading>
  );
};

const EllipseBox = styled(Box)<{ top: number; left: number }>(
  ({ top, left }) => css`
    position: absolute;
    z-index: -1;
    top: ${top}px;
    left: ${left}px;
  `
);

export const DownloadAppComponent = () => {
  const { t } = useTranslation("downloadApp");
  const navigate = useNavigate();
  const claims = [0, 1, 2].map((i) => t(`claims.${i}`));
  return (
    <FlexZ10
      flexDirection={{ base: "column", md: "row" }}
      justifyContent={{ md: "center" }}
      px={{ base: "48px", md: "48px" }}
      position={"relative"}
      gap={{ base: "0px", md: "80px" }}
      backgroundColor={"beigeMedium"}
      mb={"32px"}
      pb={"32px"}
      width={"100%"}
    >
      <Image
        src={Images.images.frydOnDevices}
        alt={t("heading")}
        objectFit={"contain"}
        pt={"60px"}
      />
      <Column>
        <DownloadAppHeader
          textAlign={"start"}
          text={t("heading")}
          pb={"16px"}
        />
        <ClaimsList>
          {claims.map((claim, idx) => (
            <li key={idx}>
              <Text as={"span"} fontSize={"lg"}>
                {claim}
              </Text>
            </li>
          ))}
        </ClaimsList>
        <Column alignItems={{ base: "center", md: "flex-start" }}>
          <Button
            label={t("button")}
            onClick={() => navigate(Routes.login)}
            testId={"button.login"}
          />
        </Column>
      </Column>
    </FlexZ10>
  );
};
