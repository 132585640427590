import React, { ReactNode } from "react";
import styled, { css } from "styled-components";
import Box, { BoxProps } from "../../Layout/Box";
import typography, { TypographyProps } from "../../Styled/typography";

const StyledText = styled(Box)<TextProps>(
  ({ inline, noBreak, noselect, lineClamp }) => css`
    display: ${inline && (lineClamp ? "-webkit-inline-box" : "inline")};
    white-space: ${noBreak && "nowrap"};
    ${noselect &&
    css`
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    `}

    ${typography};
  `
);

export type TextProps = {
  as?: "p" | "span" | "label" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  inline?: boolean;
  children?: ReactNode;
  noBreak?: boolean;
  noselect?: boolean;
  className?: string;
} & TypographyProps &
  BoxProps;

const Text = React.forwardRef<HTMLDivElement, TextProps>(
  ({ as = "p", inline = false, children, className, ...props }, ref) => {
    return (
      <StyledText
        className={className}
        ref={ref}
        as={as}
        inline={inline}
        {...props}
      >
        {children}
      </StyledText>
    );
  }
);

export default Text;
