import { cloneElement, ReactElement } from "react";
import Center from "../../Layout/Center/index";
import Row from "../../Layout/Row/index";
import Text from "../../Typography/Text/index";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { ColorProps } from "../../Styled/color";
import IconProps from "../../../Types/IconProps";
import Flower from "../../../Resources/SVG/Flower";
import Checkmark from "../../../Resources/SVG/Checkmark";
import IsIncomplete from "../../../Resources/SVG/IsIncomplete";
import IsApproved from "../../../Resources/SVG/IsApproved";
import Close from "../../../Resources/SVG/Close";
import Lock from "../../../Resources/SVG/Lock";
import { TypographyProps } from "../../Styled/typography";
import theme from "../../../Theme";

type BadgeType =
  | "isApproved"
  | "isIncomplete"
  | "communityVariety"
  | "userVariety"
  | "isAvailable"
  | "isNotAvailable"
  | "pro"
  | "plus"
  | "comingSoon";

type Color = keyof typeof theme.colors;

type BadgeProps = {
  type: BadgeType;
  size?: Exclude<IconProps["size"], "lg">;
  color?: Color;
  labelLength?: "short" | "default";
  label?: string;
  withLabel?: boolean;
  fontSize?: TypographyProps["fontSize"];
  onClick?: (e?: any) => void;
};

const Badge = ({
  type,
  withLabel = true,
  size = "md",
  labelLength = "default",
  label,
  color = "currentColor",
  onClick,
}: BadgeProps) => {
  const { t } = useTranslation("badges");

  const badgeTypeProps: {
    [key in BadgeType]: {
      withOutline?: boolean;
      backgroundColor?: ColorProps["backgroundColor"];
      iconSize?: IconProps["size"];
      icon?: ReactElement<IconProps>;
      iconColor?: Color;
    };
  } = {
    isAvailable: {
      withOutline: true,
      backgroundColor: "aubergineApp",
      icon: <Checkmark />,
      iconSize: "sm",
      iconColor: "white",
    },
    isNotAvailable: {
      withOutline: true,
      backgroundColor: "aubergineAppInactive",
      icon: <Close />,
      iconSize: "xs",
      iconColor: "white",
    },
    pro: {
      withOutline: true,
      backgroundColor: "orangeMedium",
      icon: <Lock />,
      iconSize: "xs",
      iconColor: "white",
    },
    plus: {
      withOutline: true,
      backgroundColor: "blueDark",
      icon: <Lock />,
      iconSize: "xs",
      iconColor: "white",
    },
    comingSoon: {
      withOutline: true,
      backgroundColor: "aubergineApp",
    },
    isApproved: {
      withOutline: false,
      icon: <IsApproved />,
    },
    isIncomplete: {
      withOutline: false,
      icon: <IsIncomplete />,
    },
    communityVariety: {
      withOutline: false,
      icon: <Flower />,
      iconColor: "blueDark",
    },
    userVariety: {
      withOutline: false,
      icon: <Flower />,
      iconColor: "aubergineApp",
    },
  };

  const icon = badgeTypeProps[type].icon || <></>;

  return (
    <Row
      backgroundColor={
        badgeTypeProps[type].withOutline && badgeTypeProps[type].backgroundColor
          ? badgeTypeProps[type].backgroundColor
          : "transparent"
      }
      borderRadius={"full"}
      gap={
        badgeTypeProps[type].withOutline
          ? badgeTypeProps[type].iconSize !== "sm"
            ? "4px"
            : "0px"
          : size === "md"
          ? "8px"
          : "4px"
      }
      pl={
        badgeTypeProps[type].withOutline
          ? badgeTypeProps[type].iconSize !== "sm"
            ? "8px"
            : "0px"
          : "0px"
      }
      pr={badgeTypeProps[type].withOutline ? "8px" : "0px"}
      alignItems={"center"}
      justifyContent={"center"}
      $color={badgeTypeProps[type].withOutline ? "white" : color}
      onClick={onClick}
    >
      {badgeTypeProps[type].icon &&
        cloneElement(icon, {
          size: badgeTypeProps[type].iconSize || size,
          color: badgeTypeProps[type].iconColor,
        })}
      {(label || withLabel) && (
        <Text
          css={`
            font-size: ${badgeTypeProps[type].withOutline
              ? "10px"
              : size === "xs" || size === "sm"
              ? "13px"
              : "16px"};
            line-height: 24px;
            text-transform: ${badgeTypeProps[type].withOutline
              ? "uppercase"
              : "none"};
            @media screen and (max-width: ${theme.breakpoints.md}) {
              font-size: ${badgeTypeProps[type].withOutline ? "10px" : "13px"};
            }
          `}
        >
          {!!label
            ? label
            : labelLength === "short"
            ? t(`short.${type}`)
            : t(type)}
        </Text>
      )}
    </Row>
  );
};

export default Badge;
