import { combineReducers } from "redux";

import Account from "./Account";
import Api from "./Api";
import Application from "./Application";
import Content from "./Content";
import CropData from "./CropData";
import Discover from "./Discover";
import GardenLock from "./GardenLock";
import Location from "./Location";
import Planning from "./Planning";
import User from "./User";
import Work from "./Work";
import Feed from "./Feed";
import NpsSurvey from "./NpsSurvey";
import { LOGOUT } from "./User/Actions";

const appReducer = combineReducers({
  Account,
  Api,
  Application,
  Content,
  CropData,
  Discover,
  GardenLock,
  Location,
  NpsSurvey,
  User,
  Work,
  Feed,
  Planning,
});

export const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};
