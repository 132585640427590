import theme from "../../Theme";
import IconProps from "../../Types/IconProps";

export default ({ size = "md", color = "blueDark" }: IconProps) => (
  <svg
    width={size === "xs" ? "16px" : size === "sm" ? "24px" : "32px"}
    height={size === "xs" ? "16px" : size === "sm" ? "24px" : "32px"}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="13" fill={theme.colors[color]} />
    <circle cx="16" cy="16" r="2.5" stroke={theme.colors.white} />
    <path
      d="M14.9341 13.7519C14.2824 12.6114 13.6076 10.9519 13.6 9.62214C13.5962 8.96707 13.7541 8.45705 14.0841 8.11031C14.41 7.76787 14.9847 7.5 16 7.5V6.5C14.8153 6.5 13.9359 6.81547 13.3597 7.42094C12.7875 8.02212 12.5954 8.8246 12.6 9.62786C12.6091 11.2148 13.3843 13.0553 14.0659 14.2481L14.9341 13.7519ZM16 7.5C17.0153 7.5 17.59 7.76787 17.9159 8.11031C18.2459 8.45705 18.4037 8.96706 18.4 9.62214C18.3924 10.9519 17.7176 12.6114 17.0659 13.7519L17.9341 14.2481C18.6157 13.0553 19.3909 11.2148 19.4 9.62786C19.4046 8.8246 19.2124 8.02212 18.6403 7.42094C18.0641 6.81547 17.1847 6.5 16 6.5V7.5Z"
      fill={theme.colors.white}
    />
    <path
      d="M18.0778 13.8309C18.9611 12.8586 20.3309 11.704 21.5932 11.2859C22.2151 11.0799 22.7489 11.0724 23.1806 11.2791C23.607 11.4832 24.0394 11.9471 24.3531 12.9126L25.3042 12.6036C24.9381 11.4769 24.3663 10.738 23.6124 10.3771C22.8639 10.0188 22.0413 10.084 21.2788 10.3366C19.7723 10.8356 18.2615 12.1416 17.3377 13.1585L18.0778 13.8309ZM24.3531 12.9126C24.6669 13.8782 24.5897 14.5076 24.3647 14.9234C24.1369 15.3444 23.7007 15.6521 23.0765 15.851C21.8095 16.2547 20.0227 16.1257 18.7366 15.8583L18.533 16.8374C19.878 17.117 21.868 17.2855 23.3801 16.8038C24.1454 16.5599 24.8493 16.1292 25.2442 15.3993C25.642 14.6642 25.6703 13.7304 25.3042 12.6036L24.3531 12.9126Z"
      fill={theme.colors.white}
    />
    <path
      d="M18.3543 17.2788C19.552 17.8184 21.0733 18.7643 21.8611 19.8357C22.2491 20.3634 22.4212 20.8688 22.3581 21.3433C22.2957 21.8119 21.9882 22.3664 21.1668 22.9632L21.7546 23.7722C22.713 23.0759 23.2391 22.3037 23.3493 21.4752C23.4588 20.6526 23.1426 19.8904 22.6667 19.2433C21.7266 17.9647 20.0176 16.9314 18.7651 16.367L18.3543 17.2788ZM21.1668 22.9632C20.3454 23.56 19.723 23.6811 19.258 23.5956C18.7873 23.509 18.3598 23.1892 17.9778 22.657C17.2023 21.5768 16.7728 19.8376 16.6297 18.5318L15.6356 18.6407C15.7853 20.0064 16.24 21.951 17.1654 23.2402C17.6338 23.8927 18.261 24.429 19.0772 24.5791C19.8992 24.7303 20.7961 24.4686 21.7546 23.7722L21.1668 22.9632Z"
      fill={theme.colors.white}
    />
    <path
      d="M15.1772 18.5318C15.034 19.8377 14.6045 21.5768 13.8291 22.6571C13.4471 23.1893 13.0196 23.5091 12.5488 23.5957C12.0839 23.6811 11.4614 23.56 10.6401 22.9633L10.0523 23.7723C11.0107 24.4686 11.9076 24.7303 12.7297 24.5792C13.5459 24.4291 14.173 23.8928 14.6414 23.2403C15.5669 21.9511 16.0215 20.0064 16.1712 18.6408L15.1772 18.5318ZM10.6401 22.9633C9.81869 22.3665 9.51115 21.812 9.44879 21.3434C9.38564 20.8689 9.55771 20.3635 9.94578 19.8357C10.7335 18.7644 12.2549 17.8185 13.4525 17.2788L13.0417 16.3671C11.7892 16.9315 10.0802 17.9648 9.14014 19.2433C8.66428 19.8905 8.34804 20.6526 8.45753 21.4753C8.56779 22.3038 9.09382 23.0759 10.0523 23.7723L10.6401 22.9633Z"
      fill={theme.colors.white}
    />
    <path
      d="M12.9883 15.8583C11.7021 16.1257 9.91535 16.2546 8.64833 15.8509C8.02416 15.6521 7.58789 15.3443 7.36009 14.9234C7.13511 14.5076 7.05796 13.8782 7.3717 12.9126L6.42064 12.6036C6.05454 13.7303 6.08282 14.6642 6.4806 15.3993C6.87556 16.1292 7.5794 16.5599 8.34476 16.8037C9.8568 17.2855 11.8468 17.117 13.1918 16.8373L12.9883 15.8583ZM7.3717 12.9126C7.68024 11.963 8.12748 11.5424 8.57728 11.376C9.04971 11.2011 9.6509 11.2586 10.3492 11.5299C11.7496 12.0742 13.2484 13.3659 14.1299 14.3362L14.8701 13.6638C13.9445 12.6449 12.3212 11.2234 10.7114 10.5978C9.90459 10.2843 9.02863 10.1427 8.23024 10.4381C7.40921 10.7419 6.79195 11.4608 6.42064 12.6036L7.3717 12.9126Z"
      fill={theme.colors.white}
    />
  </svg>
);
