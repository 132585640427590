import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import "./Styles.scss";
import TodoGroupList from "../TodoGroupList";
import CustomTodoItem from "../CustomTodoItem";

function TodoListWeek(props) {
  const { startWeekLabel, endWeekLabel, label, selectedWeek } = props;

  let todos = _.sortBy(props.todos, "priority");

  return (
    <div className="todo-list-week">
      <div className="headline">
        <p>{label}</p>
        <p>
          {startWeekLabel} - {endWeekLabel}
        </p>
      </div>
      {todos.map((group, i) => (
        <TodoGroupList
          key={i}
          snoozeTodo={props.snoozeTodo}
          deleteTodo={props.deleteTodo}
          onUpdate={props.updateTodoList}
          addCustomTodo={props.addCustomTodo}
          onCheckTodo={props.onCheckTodo}
          selectedWeek={selectedWeek}
          group={group}
        />
      ))}
      <CustomTodoItem
        onUpdate={props.updateTodoList}
        selectedWeek={selectedWeek}
        addCustomTodo={props.addCustomTodo}
        startWeekLabel={startWeekLabel}
      ></CustomTodoItem>
    </div>
  );
}
TodoListWeek.propTypes = {
  onCheckTodo: PropTypes.func.isRequired,
  todos: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
};

export default TodoListWeek;
