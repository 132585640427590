import { css } from "styled-components";
import theme from "../../Theme";
import { createResponisiveThemeProp, ResponsiveValue } from "./Core";

export type PaddingProps = {
  p?: ResponsiveValue<keyof typeof theme.spaces>;
  py?: ResponsiveValue<keyof typeof theme.spaces>;
  px?: ResponsiveValue<keyof typeof theme.spaces>;
  pt?: ResponsiveValue<keyof typeof theme.spaces>;
  pb?: ResponsiveValue<keyof typeof theme.spaces>;
  pl?: ResponsiveValue<keyof typeof theme.spaces>;
  pr?: ResponsiveValue<keyof typeof theme.spaces>;
};

export const paddingDefaultProps: PaddingProps = {
  p: "0px",
  px: "0px",
  py: "0px",
  pt: "0px",
  pr: "0px",
  pb: "0px",
  pl: "0px",
};

const padding = css<PaddingProps>(
  ({ p, px, py, pt, pr, pb, pl }) => css`
    ${createResponisiveThemeProp({
      prop: p,
      property: [
        "padding-top",
        "padding-bottom",
        "padding-left",
        "padding-right",
      ],
      scale: "spaces",
    })}
    ${createResponisiveThemeProp({
      prop: py,
      property: ["padding-top", "padding-bottom"],
      scale: "spaces",
    })}
    ${createResponisiveThemeProp({
      prop: px,
      property: ["padding-left", "padding-right"],
      scale: "spaces",
    })}
    ${createResponisiveThemeProp({
      prop: pt,
      property: "padding-top",
      scale: "spaces",
    })}
    ${createResponisiveThemeProp({
      prop: pb,
      property: "padding-bottom",
      scale: "spaces",
    })}
    ${createResponisiveThemeProp({
      prop: pl,
      property: "padding-left",
      scale: "spaces",
    })}
    ${createResponisiveThemeProp({
      prop: pr,
      property: "padding-right",
      scale: "spaces",
    })}
  `
);

export default padding;
