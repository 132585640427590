import {} from "styled-components/macro";

import { useEffect, useState } from "react";
import Plus from "../../../../Resources/SVG/Plus";
import Box from "../../../Layout/Box";
import Center from "../../../Layout/Center";
import ImageInputArea from "../../ImageInputArea";
import useControlledState from "../../../../Helpers/useControlledState";

type ImageAddItemProps = {
  width: string;
  maxImageCount?: number;
  images?: string[];
  onChangeImages?: (images?: string[]) => void;
};

const ImageAddItem = ({
  width,
  maxImageCount,
  images: ImagesExtern,
  onChangeImages,
}: ImageAddItemProps) => {
  const [images, setImages] = useControlledState(ImagesExtern, onChangeImages);

  return (
    <ImageInputArea
      position="relative"
      borderRadius="md"
      maxImageCount={maxImageCount}
      images={images}
      onChangeImages={setImages}
    >
      <Box
        borderColor="beigeDarkest"
        border="2pxDashed"
        borderRadius="md"
        aspectRatio="1/1"
        css={`
          width: ${width};
        `}
      >
        <Center
          position="absolute"
          top="-12px"
          right="-12px"
          borderRadius="full"
          backgroundColor="beigeDarkest"
        >
          <Plus color="white" size="sm" />
        </Center>
      </Box>
    </ImageInputArea>
  );
};

export default ImageAddItem;
