import theme from "../../Theme";
import IconProps from "../../Types/IconProps";

export default ({ size = "md", color = "currentColor" }: IconProps) => (
  <svg
    width={size === "xs" ? "16px" : size === "sm" ? "24px" : "32px"}
    height={size === "xs" ? "16px" : size === "sm" ? "24px" : "32px"}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 12C19 10.7905 18.1411 9.78164 17 9.55001V8.5C17 5.73858 14.7614 3.5 12 3.5C9.23858 3.5 7 5.73858 7 8.5V9.55001C5.85888 9.78164 5 10.7905 5 12V19C5 20.3807 6.11929 21.5 7.5 21.5H16.5C17.8807 21.5 19 20.3807 19 19V12ZM16 9.5V8.5C16 6.29086 14.2091 4.5 12 4.5C9.79086 4.5 8 6.29086 8 8.5V9.5H16ZM16.6157 10.5044C16.5785 10.5132 16.5398 10.5178 16.5 10.5178C16.454 10.5178 16.4095 10.5116 16.3672 10.5H7.5C6.67157 10.5 6 11.1716 6 12V19C6 19.8284 6.67157 20.5 7.5 20.5H16.5C17.3284 20.5 18 19.8284 18 19V12C18 11.2105 17.39 10.5634 16.6157 10.5044ZM11 14.5V14.882C11 15.5396 11.1531 16.1882 11.4472 16.7764L11.7236 17.3292C11.776 17.4339 11.883 17.5 12 17.5C12.117 17.5 12.224 17.4339 12.2764 17.3292L12.5528 16.7764C12.8469 16.1882 13 15.5396 13 14.882V14.5C13 13.9477 12.5523 13.5 12 13.5C11.4477 13.5 11 13.9477 11 14.5ZM12 12.5C10.8954 12.5 10 13.3954 10 14.5V14.882C10 15.6948 10.1893 16.4965 10.5528 17.2236L10.8292 17.7764C11.0509 18.2199 11.5042 18.5 12 18.5C12.4958 18.5 12.9491 18.2199 13.1708 17.7764L13.4472 17.2236C13.8107 16.4965 14 15.6948 14 14.882V14.5C14 13.3954 13.1046 12.5 12 12.5Z"
      fill={theme.colors[color]}
    />
  </svg>
);
