import { css } from "styled-components";
import theme from "../../Theme";
import { createResponisiveThemeProp, ResponsiveValue } from "./Core";

export type ShadowProps = {
  boxShadow?: ResponsiveValue<keyof typeof theme.shadows>;
};

export const shadowDefaultProps: ShadowProps = {
  boxShadow: "none",
};

const shadow = css<ShadowProps>(
  ({ boxShadow }) => css`
    ${createResponisiveThemeProp({
      prop: boxShadow,
      property: "box-shadow",
      scale: "shadows",
    })}
  `
);

export default shadow;
