import React, { useLayoutEffect, useState } from "react";
import PasswordResetScreenWrapper from "../../ScreenWrappers/PasswordReset";
import { validateEmail } from "../../Helpers/LoginHelper";
import { Box, Button, Text, TextInput } from "../../Base";

import { useTranslation } from "react-i18next";
import CircularProgress from "../../Base/Progress/CircularProgress";

interface PasswordResetRequestProps {
  isFetching: boolean;
  onClickSend: () => void;
  onClickToLogin: () => void;
  resetMailSent: boolean;
  email: string;
  setEmail: (value: string) => void;
  successfulLoginReset: boolean;
}

const PasswordResetRequest = ({
  isFetching,
  onClickSend,
  onClickToLogin,
  resetMailSent,
  email,
  setEmail,
  successfulLoginReset,
}: PasswordResetRequestProps) => {
  const { t } = useTranslation("passwordReset");
  const [progress, setProgress] = useState(0);
  useLayoutEffect(() => {
    let loadingTimeout: any;
    if (isFetching) {
      loadingTimeout = setTimeout(() => {
        setProgress((progress % 100) + 2);
      }, 30);
    }
    return () => {
      clearTimeout(loadingTimeout);
    };
  }, [isFetching, progress]);
  return (
    <PasswordResetScreenWrapper>
      {isFetching ? (
        <CircularProgress
          size={40}
          trackWidth={4}
          indicatorWidth={4}
          label={""}
          progress={progress}
        />
      ) : !resetMailSent ? (
        <Box>
          <Text as={"p"}>{t("enterEmail")}</Text>

          <TextInput
            my={"20px"}
            placeholder={t("common:placeholder.email")}
            value={email}
            onChange={setEmail}
          />

          <Button
            label={t("button.sendRequest")}
            onClick={onClickSend}
            disabled={!validateEmail(email)}
          />
        </Box>
      ) : (
        <>
          {successfulLoginReset ? (
            <Text as={"p"} my={"20px"}>
              {t("emailSent")}
            </Text>
          ) : (
            <Text as={"p"} my={"20px"}>
              {t("errorMessage")}
            </Text>
          )}
          <Button label={t("button.backToLogin")} onClick={onClickToLogin} />
        </>
      )}
    </PasswordResetScreenWrapper>
  );
};

export default PasswordResetRequest;
