import { ReactNode } from "react";
import styled, { css } from "styled-components";
import { LayoutProps } from "../../Styled/layout";
import { MarginProps } from "../../Styled/margin";
import { PositionProps } from "../../Styled/position";
import typography, { TypographyProps } from "../../Styled/typography";
import Text from "../../Typography/Text";
import theme from "../../../Theme";

const StyledLink = styled(Text)<LinkProps>(
  ({ color, theme }) => css`
    color: ${color && theme.colors[color]};
    font-family: ${theme.fonts.karla};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
    ${typography}
  `
);

type LinkProps = {
  children?: ReactNode;
  href: string;
  width?: LayoutProps["width"];
  inline?: boolean;
  color?: keyof typeof theme.colors;
} & PositionProps &
  MarginProps &
  TypographyProps;

const Link = ({
  children,
  width = "max-content",
  inline = true,
  color = "blueDark",
  ...props
}: LinkProps) => {
  return (
    <StyledLink
      as="a"
      target="_blank"
      color={color}
      width={width}
      inline={inline}
      {...props}
    >
      {children}
    </StyledLink>
  );
};

export default Link;
