import IconProps from "../../Types/IconProps";
import theme from "../../Theme";

export default ({ size = "md", color = "currentColor" }: IconProps) => (
  <svg
    width={
      (size === "xl" && "48px") ||
      (size === "lg" && "40px") ||
      (size === "md" && "32px") ||
      (size === "sm" && "24px") ||
      (size === "xs" && "16px") ||
      undefined
    }
    height={
      (size === "xl" && "48px") ||
      (size === "lg" && "40px") ||
      (size === "md" && "32px") ||
      (size === "sm" && "24px") ||
      (size === "xs" && "16px") ||
      undefined
    }
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26 16.3775C26.4142 16.3775 26.75 16.0417 26.75 15.6275C26.75 15.2132 26.4142 14.8775 26 14.8775V16.3775ZM6 15.6275L5.45748 15.1096C5.18084 15.3994 5.18084 15.8555 5.45748 16.1453L6 15.6275ZM14.7778 7.51786C15.0638 7.21823 15.0528 6.74349 14.7532 6.45748C14.4535 6.17148 13.9788 6.18252 13.6928 6.48214L14.7778 7.51786ZM13.6928 24.7728C13.9788 25.0724 14.4535 25.0834 14.7532 24.7974C15.0528 24.5114 15.0638 24.0367 14.7778 23.737L13.6928 24.7728ZM26 14.8775H6V16.3775H26V14.8775ZM6.54252 16.1453L14.7778 7.51786L13.6928 6.48214L5.45748 15.1096L6.54252 16.1453ZM5.45748 16.1453L13.6928 24.7728L14.7778 23.737L6.54252 15.1096L5.45748 16.1453Z"
      fill={theme.colors[color]}
    />
  </svg>
);
