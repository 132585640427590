import * as actions from "./Actions";

export const initialState = {
  weeklyTopic: undefined,
  recommendedCrops: undefined,
  tutorials: undefined,
  cropcategories: undefined,
};

export default function DiscoverReducer(state = initialState, action = {}) {
  switch (action.type) {
    case actions.RECEIVE_CONTENT:
      state = {
        ...state,
        weeklyTopic: action.data.weeklyTopic,
        recommendedCrops: action.data.recommendedCrops,
        tutorials: action.data.tutorials,
        cropcategories: action.data.cropcategories,
      };
      return state;

    default:
      return state;
  }
}
