import styled from "styled-components/macro";
import { css } from "styled-components";
import typography, { TypographyProps } from "../../Styled/typography";
import SearchIcon from "../../../Resources/SVG/Search";
import color, { ColorProps } from "../../Styled/color";
import theme from "../../../Theme";
import space, { SpaceProps } from "../../Styled/space";
import Close from "../../../Resources/SVG/Close";
import { useEffect, useRef, useState } from "react";
import layout, { LayoutProps } from "../../Styled/layout";
import { MarginProps } from "../../Styled/margin";
import Center from "../../Layout/Center";
import Row from "../../Layout/Row";

type Color = keyof typeof theme.colors;
type InputProps = {
  placeholderColor?: Color;
} & TypographyProps &
  ColorProps &
  SpaceProps &
  LayoutProps;

const Input = styled.input<InputProps>(
  ({ theme, placeholderColor = "currentColor" }) => css`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    cursor: text;
    outline: none;
    background-color: transparent;
    border: none;
    box-shadow: none;
    flex: 1;
    &::-webkit-search-decoration,
    &::-webkit-search-results-button,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-decoration {
      display: none;
    }

    &::placeholder {
      color: ${theme.colors[placeholderColor]};
    }

    ${layout}
    ${typography};
    ${color};
    ${space};
  `
);

const InputContainer = styled(Row).attrs({ cursor: "text" })``;

type SearchBarProps = {
  size?: "lg" | "md";
  colorVariant?: "white" | "beige" | "aubergine" | "grey";
  placeholder?: string;
  value?: string;
  onChange?: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onEnter?: (value: string) => void;
  onClear?: () => void;
  autoFocus?: boolean;
  disabled?: boolean;
  width?: LayoutProps["width"];
  maxWidth?: LayoutProps["maxWidth"];
  minWidth?: LayoutProps["minWidth"];
  backgroundColor?: Color;
} & MarginProps;

const SearchBar = ({
  size = "lg",
  colorVariant = "white",
  placeholder,
  value,
  onChange,
  onFocus,
  onBlur,
  onEnter,
  onClear,
  autoFocus = false,
  disabled = false,
  ...props
}: SearchBarProps) => {
  const [inputValue, setInputValue] = useState(value ?? "");
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setInputValue(value ?? "");
  }, [value]);

  const handleFocus = (e: any) => {
    if (!onFocus) {
      return;
    }
    e.preventDefault();
    onFocus();
  };

  const handleClear = () => {
    setInputValue("");
    onChange?.("");
    onClear?.();
  };

  const handleChange = (e: any) => {
    setInputValue(e.target.value);
    if (!onChange) {
      return;
    }
    e.preventDefault();
    onChange(e.target.value);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      onEnter?.(e.target.value);
      ref.current?.blur();
    }
  };

  return (
    <InputContainer
      height={size === "md" ? "40px" : "56px"}
      $color={
        (colorVariant === "white" && "black") ||
        (colorVariant === "beige" && "black") ||
        (colorVariant === "aubergine" && "white") ||
        (colorVariant === "grey" && "greyDark") ||
        undefined
      }
      backgroundColor={
        (colorVariant === "white" && "white") ||
        (colorVariant === "beige" && "beigeMedium") ||
        (colorVariant === "aubergine" && "aubergineDark") ||
        (colorVariant === "grey" && "transparent") ||
        undefined
      }
      borderRadius={"full"}
      alignItems={"center"}
      onClick={() => {
        if (ref.current) {
          ref.current.focus();
        }
      }}
      overflow="hidden"
      {...props}
    >
      <Center flexShrink={0} width="56px" height="100%">
        <SearchIcon />
      </Center>
      <Input
        $color="currentColor"
        placeholderColor={
          (colorVariant === "white" && "greyDark") ||
          (colorVariant === "beige" && "greyDark") ||
          (colorVariant === "aubergine" && "aubergineLight") ||
          undefined
        }
        id={"search-input"}
        ref={ref}
        type={"search"}
        placeholder={placeholder}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={onBlur}
        onKeyDown={handleKeyDown}
        value={inputValue}
        autoFocus={autoFocus}
        disabled={disabled}
        autoComplete={"off"}
        minWidth={"0px"}
      />
      {inputValue && (
        <Center
          flexShrink={0}
          width="56px"
          height="100%"
          as="button"
          borderRadiusTopRight="full"
          borderRadiusBottomRight="full"
          onClick={handleClear}
        >
          <Close />
        </Center>
      )}
    </InputContainer>
  );
};

export default SearchBar;
