import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
  withWidth,
} from "@material-ui/core";
import { TimePicker } from "@material-ui/pickers";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import "./Styles.scss";

function TodoOptions(props) {
  const { options } = props;
  const [strategy, setStrategy] = useState(options.strategy || "weed");
  const [view] = useState(options.workView || "list");
  const [showToWater, setShowToWater] = useState(
    options.activateTodos ? options.activateTodos.toWater : false
  );
  const [showFertilization, setShowFertilization] = useState(
    options.activateTodos ? options.activateTodos.toFertilize : false
  );
  const [notifyWeather, setNotifyWeather] = useState(
    options.notifications ? options.notifications.weather : false
  );
  const [notifyWatering, setNotifyWatering] = useState(
    options.notifications ? options.notifications.watering : false
  );
  const [notifyDailyTodo, setNotifyDailyTodo] = useState(
    options.notifications ? options.notifications.dailyTodo : false
  );
  let wateringTimeMoment = moment();
  if (options.notifications && options.notifications.wateringTime) {
    let time = options.notifications.wateringTime.split(":");
    wateringTimeMoment.hour(time[0]);
    wateringTimeMoment.minute(time[1]);
  }
  const [wateringTime, setWateringTime] = useState(wateringTimeMoment);
  const [notifyWeekPlan, setNotifyWeekPlan] = useState(
    options.notifications ? options.notifications.weeklyDigest : false
  );

  const onSave = () => {
    const todoOptions = {
      strategy,
      workView: view,
      activateTodos: {
        toWater: showToWater,
        toFertilize: showFertilization,
      },
      notifications: {
        weather: notifyWeather,
        weeklyDigest: notifyWeekPlan,
        watering: notifyWatering,
        wateringTime:
          wateringTime && notifyWatering ? wateringTime.format("HH:mm") : null,
        work: props.options.notifications
          ? props.options.notifications.work
          : false,
        dailyTodo: notifyDailyTodo,
      },
    };
    props.onSave(todoOptions);
  };
  const onClose = () => {
    props.onClose();
  };
  return (
    <div className="content-wrapper">
      <div className="todo-options">
        <h1>Aufgaben-Optionen</h1>
        <div className="container-scroll">
          <div className="option-container">
            <h3>Jäten oder Mulchen?</h3>
            <p>
              Welche Strategie zur Unkrautbekämpfung und Bodenpflege möchtest du
              verfolgen?
            </p>
            <RadioGroup
              aria-label="strategy"
              name="strategy"
              value={strategy}
              onChange={(e) => {
                setStrategy(e.target.value);
              }}
            >
              <FormControlLabel
                value="weed"
                control={<Radio />}
                label="Ich jäte und hacke"
              />
              <FormControlLabel
                value="mulch"
                control={<Radio />}
                label="Ich mulche"
              />
              <FormControlLabel
                value="none"
                control={<Radio />}
                label="Ich brauche keine Hinweise dazu"
              />
            </RadioGroup>
          </div>
          <div className="option-container">
            <h3>Aktive Aufgaben</h3>
            <p>
              Wichtige Aufgaben zeigen wir immer, weniger wichtige kannst du
              abschalten.
            </p>
            <div className="switch-with-label">
              Gießen
              <Switch
                checked={showToWater}
                onChange={(e, v) => setShowToWater(v)}
              />
            </div>
            <div className="switch-with-label">
              Düngen
              <Switch
                checked={showFertilization}
                onChange={(e, v) => setShowFertilization(v)}
              />
            </div>
          </div>
          <div className="option-container">
            <h3>Notifications</h3>
            <p>
              Hier kannst du einstellen, zu welchen Themen wir dich
              benachrichtigen.{" "}
            </p>
            <div className="switch-with-label">
              Wochenplan per E-Mail
              <Switch
                checked={notifyWeekPlan}
                onChange={(e, v) => setNotifyWeekPlan(v)}
              />
            </div>
            <div className="switch-with-label">
              Wetter-Warnungen
              <Switch
                checked={notifyWeather}
                onChange={(e, v) => setNotifyWeather(v)}
              />
            </div>
            <div className="switch-with-label">
              Gieß-Erinnerungen
              <Switch
                checked={notifyWatering}
                onChange={(e, v) => setNotifyWatering(v)}
              />
            </div>
            {notifyWatering && (
              <div className="watering-panel">
                <p>Um wieviel Uhr möchtest du ans Gießen erinnert werden?</p>
                <TimePicker
                  style={{ width: 50 }}
                  value={wateringTime}
                  views={["hours", "minutes"]}
                  minutesStep={5}
                  onChange={(v) => setWateringTime(v)}
                  ampm={false}
                />
                <div className="time-label">Uhr</div>
              </div>
            )}
            <div className="switch-with-label">
              Tägliche Aufgaben
              <Switch
                checked={notifyDailyTodo}
                onChange={(e, v) => setNotifyDailyTodo(v)}
              />
            </div>
          </div>
          <div className="options-footer">
            <div className="col cancel" onClick={onClose}>
              <i className="zwicon-arrow-circle-left" />
              <span>abbrechen</span>
            </div>

            <div className="col save" onClick={onSave}>
              <i className="zwicon-checkmark" />
              <span>speichern</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
TodoOptions.propTypes = {
  options: PropTypes.object,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default withWidth()(TodoOptions);
