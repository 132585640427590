import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  DATE_FORMAT_API,
  DATE_FORMAT_FRONTEND,
} from "../../../../Core/Constants";
import { PlantingType } from "../../Consts";
import { useTranslation } from "react-i18next";
import { ButtonBase } from "@material-ui/core";
import Theme from "../../../../Core/Theme";
import { SeasonCalendar } from "../Components/SeasonCalendar";
import { PlantPeriodsBar } from "../Components/PlantPeriodsBar";
import { PlantDatesList } from "../Components/PlantDatesList";
import Container from "../../Components/Container";
import { getPlantHasPrecultivation } from "../../../../Reducers/Planning/PlanningUtils";
import { updatePlant } from "../../../../Reducers/Planning/Actions";
import { lockGarden } from "../../../../Reducers/GardenLock/Actions";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  getDaysInCalendarData,
  getDaysInYear,
  getMonthValues,
} from "../seasonHelpers";
import PlantingTypeChoice from "../../../../Components/PlantingTypeChoice";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    display: "flex",
    flexDirection: ({ matches }) => (matches ? "column" : "row"),
    "& > *": {
      flex: 1,
    },
  },
  ItemContainer: {
    backgroundColor: Theme.colors.beigeMedium,
    border: `1px solid ${Theme.colors.beigeDarkest}`,
    borderRadius: 8,
    padding: 4,
    height: 54,
    zIndex: 1,
    marginTop: 8,
  },
  ImageContainer: {
    height: 60,
    width: 60,
    backgroundColor: Theme.colors.white,
    borderRadius: 30,
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
  Image: { height: 60, width: 60 },
  PlanTitleContainer: { marginLeft: 16, flex: 1, "& p": { margin: 0 } },
  SeasonsBarContainer: {
    display: "flex",
    flexDirection: "row",
    height: 10,
    borderRadius: 5,
    overflow: "hidden",
    marginTop: 4,
  },
  SeasonsBarItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export const PlantDetails = ({
  visible,
  onSave,
  onDismiss,
  plant,
  openCalendar,
}) => {
  const { t } = useTranslation("season");
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const classes = useStyles({ matches });

  const { year, garden } = useSelector((state) => state.Planning);

  const [plantPeriodsDates, setPlantPeriodsDates] = useState([]);
  const [plantTotalDuration, setPlantTotalDuration] = useState(
    plant.plantDuration
  );
  const [plantStartDayOfYear, setPlantStartDayOfYear] = useState(
    plant.plantStart
  );
  const [seasonViewRange, setSeasonViewRange] = useState({
    start: undefined,
    end: undefined,
  });
  const [calendarData, setCalendarData] = useState([]);
  const [plantingType, setPlantingType] = useState(null);
  const [datePlanted, setDatePlanted] = useState(null);
  const [dateInPatch, setDateInPatch] = useState(null);
  const [dateHarvestStart, setDateHarvestStart] = useState(null);
  const [dateHarvestEnd, setDateHarvestEnd] = useState(null);
  const [datePreviousPlant, setDatePreviousPlant] = useState(undefined);
  const [dateNextPlant, setDateNextPlant] = useState(undefined);
  const [preCultivationDuration, setPreCultivationDuration] = useState(null);
  const [continuousPlantLife, setContinuousPlantLife] = useState(false);
  const [datesChanged, setDatesChanged] = useState(false);

  const hasPrecultivation = getPlantHasPrecultivation(plant);

  //initialize date values
  useEffect(() => {
    if (!plant) {
      return;
    }

    if (!datePlanted && plant.datePlanted) {
      setDatePlanted(plant.datePlanted);
    }

    if (!dateInPatch && plant.dateInPatch) {
      setDateInPatch(plant.dateInPatch);
    }

    if (!dateHarvestStart && plant.dateHarvestStart) {
      setDateHarvestStart(plant.dateHarvestStart);
    }

    if (!dateHarvestEnd && plant.dateHarvestEnd) {
      setDateHarvestEnd(plant.dateHarvestEnd);
    }

    if (!plantingType) {
      setPlantingType(
        plant.plantingType
          ? plant.plantingType
          : hasPrecultivation
          ? PlantingType.PRECULTIVATION
          : PlantingType.DIRECT
      );
    }

    if (!continuousPlantLife && plant.continuous) {
      setContinuousPlantLife(true);
    }

    if (!dateNextPlant && plant.nextPlant) {
      setDateNextPlant(
        plant.nextPlant.dateInPatch || plant.nextPlant.datePlanted
      );
    }

    if (!datePreviousPlant && plant.previousPlant) {
      setDatePreviousPlant(plant.previousPlant.dateHarvestEnd);
    }

    if (!preCultivationDuration && plant.datePlanted !== plant.dateInPatch) {
      setPreCultivationDuration(
        moment(plant.dateInPatch).diff(moment(plant.datePlanted), "days")
      );
    }
  }, [plant]);

  useEffect(() => {
    if (!datePlanted || !dateHarvestEnd) {
      return;
    }

    let start = moment(datePlanted).startOf("month");
    let end = moment(dateHarvestEnd).endOf("month");
    const duration = Math.round(
      moment(end).diff(moment(start), "months", true)
    );

    if (duration < 9) {
      start = moment(start).subtract(Math.round((9 - duration) / 2), "months");
      end = moment(end).add(parseInt((9 - duration) / 2), "months");
    }

    if (continuousPlantLife) {
      end = moment(end).add(1, "years").endOf("year");
    }

    setSeasonViewRange({
      start: start.format(DATE_FORMAT_API),
      end: end.format(DATE_FORMAT_API),
    });
  }, [datePlanted, dateHarvestEnd]);

  useEffect(() => {
    if (!plantPeriodsDates || plantPeriodsDates.length < 1) {
      return;
    }

    const totalDuration = Math.abs(
      moment(datePlanted).diff(moment(dateHarvestEnd), "days")
    );

    setPlantTotalDuration(totalDuration);
  });

  const onChangeDatePlanted = (date) => {
    let daysDiff = moment(date).diff(datePlanted, "days");
    setDatePlanted(date);

    let newDateInPatch = moment(dateInPatch).add(daysDiff, "days");
    if (
      datePreviousPlant &&
      newDateInPatch.isBefore(moment(datePreviousPlant))
    ) {
      daysDiff = moment(datePreviousPlant).diff(dateInPatch, "days");
    }

    let newDateHarvestEnd = moment(dateHarvestEnd).add(daysDiff, "days");
    if (dateNextPlant && newDateHarvestEnd.isAfter(moment(dateNextPlant))) {
      daysDiff = moment(dateNextPlant).diff(dateHarvestEnd, "days");
    }

    setDateInPatch(
      moment(dateInPatch).add(daysDiff, "days").format(DATE_FORMAT_API)
    );
    setDateHarvestStart(
      moment(dateHarvestStart).add(daysDiff, "days").format(DATE_FORMAT_API)
    );
    setDateHarvestEnd(
      moment(dateHarvestEnd).add(daysDiff, "days").format(DATE_FORMAT_API)
    );
  };

  const saveDates = async () => {
    dispatch(
      updatePlant(plant, {
        datePlanted: moment(datePlanted).format(DATE_FORMAT_API),
        dateInPatch: moment(dateInPatch).format(DATE_FORMAT_API),
        dateHarvestStart: moment(dateHarvestStart).format(DATE_FORMAT_API),
        dateHarvestEnd: moment(dateHarvestEnd).format(DATE_FORMAT_API),
        plantingType: plantingType,
      })
    );
    setDatesChanged(false);
  };

  const onPressSave = async () => {
    if (datesChanged) {
      await saveDates();
    }
    onSave();
  };

  const checkForLockedGarden = () => {
    if (garden) {
      dispatch(lockGarden(garden.id));
    }
  };

  /**
   * Handles changing the planting type
   *
   * @param {keyof typeof PlantingType} newPlantingType - The newly selected planting type
   */
  const onChangePlantingType = (newPlantingType) => {
    const newPlantingTypeLCase = newPlantingType.toLowerCase();
    const oldPlantingType = plantingType;
    setPlantingType(newPlantingTypeLCase);

    if (newPlantingTypeLCase === PlantingType.PRECULTIVATION) {
      setDatePlanted(
        moment(datePlanted).subtract(preCultivationDuration, "days")
      );
      return;
    }

    if (
      ![newPlantingTypeLCase, oldPlantingType].includes(
        PlantingType.PRECULTIVATION
      )
    ) {
      return;
    }

    setDatePlanted(moment(datePlanted).add(preCultivationDuration, "days"));
  };

  useEffect(() => {
    if (!datePlanted || !dateInPatch || !dateHarvestStart || !dateHarvestEnd) {
      return;
    }

    const precultivationData = {
      id: "precultivation",
      color: Theme.colors.greenLight,
      label: t("dates.precultivation.label"),
      value: moment(datePlanted).format(DATE_FORMAT_FRONTEND),
      set: (date) => {
        onChangeDatePlanted(date);
        setPreCultivationDuration(
          moment(dateInPatch).diff(moment(date), "days")
        );
      },
    };

    const growingData = {
      id: "planted",
      color: Theme.colors.greenMedium,
      label: t("dates.planted.label"),
      value: moment(datePlanted).format(DATE_FORMAT_FRONTEND),
      set: (date) => {
        onChangeDatePlanted(date);
      },
    };

    const harvestStartData = {
      id: "harvestStart",
      color: Theme.colors.greenDark,
      label: t("dates.harvestStart.label"),
      value: moment(dateHarvestStart).format(DATE_FORMAT_FRONTEND),
      min: moment(datePlanted).format(DATE_FORMAT_API),
      max: moment(dateHarvestEnd).format(DATE_FORMAT_API),
      set: (date) => {
        setDateHarvestStart(date);
      },
    };

    const harvestEndData = {
      id: "harvestEnd",
      color: Theme.colors.greenDark,
      label: t("dates.harvestEnd.label"),
      value: moment(dateHarvestEnd).format(DATE_FORMAT_FRONTEND),
      min: moment(dateHarvestStart).format(DATE_FORMAT_API),
      set: (date) => {
        setDateHarvestEnd(date);
      },
    };

    const plantPeriodDatesByPlantingType = {
      [PlantingType.PRECULTIVATION]: [
        precultivationData,
        {
          ...growingData,
          value: moment(dateInPatch).format(DATE_FORMAT_FRONTEND),
          min: moment(datePlanted).format(DATE_FORMAT_API),
          max: moment(dateHarvestStart).format(DATE_FORMAT_API),
          set: (date) => {
            setDateInPatch(date);
            setPreCultivationDuration(
              moment(date).diff(moment(datePlanted), "days")
            );
          },
        },
        {
          ...harvestStartData,
          min: moment(dateInPatch).format(DATE_FORMAT_API),
        },
        harvestEndData,
      ],
      [PlantingType.DIRECT]: [
        { ...growingData, id: "sowing", label: t("dates.sowing.label") },
        harvestStartData,
        harvestEndData,
      ],
      [PlantingType.SEEDLINGS]: [growingData, harvestStartData, harvestEndData],
    };

    setPlantPeriodsDates(plantPeriodDatesByPlantingType[plantingType]);
    setDatesChanged(true);
  }, [
    plantingType,
    datePlanted,
    dateInPatch,
    dateHarvestStart,
    dateHarvestEnd,
    visible,
  ]);

  useEffect(() => {
    if (!seasonViewRange || !seasonViewRange.start || !seasonViewRange.end) {
      return;
    }

    setCalendarData(getMonthValues(seasonViewRange));
  }, [seasonViewRange]);

  const daysInCalendarData =
    calendarData.length !== 0
      ? getDaysInCalendarData(calendarData)
      : getDaysInYear(year);

  return (
    <Container
      visible={visible}
      menuButtons={[
        <ButtonBase
          key={0}
          onClick={(event) => {
            event.stopPropagation();
            onDismiss();
          }}
        >
          <span>{t("common:actions.cancel")}</span>
        </ButtonBase>,
        <span key={1}>{t("editSeasons")}</span>,
        <ButtonBase
          key={2}
          style={{ color: Theme.colors.greenApp }}
          onClick={() => {
            onPressSave();
          }}
        >
          <span>{t("common:actions.done")}</span>
        </ButtonBase>,
      ]}
    >
      <div
        className={classes.root}
        data-testid={`PlantDetails.${plant.crop.name}`}
      >
        <div
          style={{
            padding: "0 16px",
            borderRight: matches
              ? undefined
              : `1px solid ${Theme.colors.greenAppInactive}`,
          }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "nowrap",
              flexDirection: "row",
              padding: "16px 0",
            }}
          >
            <div className={classes.ImageContainer}>
              <img className={classes.Image} src={plant.crop.imageUrl} />
            </div>
            <div className={classes.PlanTitleContainer}>
              <h2>{plant.crop.name}</h2>
              <p>{plant.crop.varietyName || plant.crop.latinName}</p>
            </div>
          </div>
          {/* Season Periods Row */}
          <div style={{ padding: "16px 0" }}>
            <span style={{ fontWeight: 700 }}>{t("editSeasonsHeadline")}</span>

            <div style={{ height: 60, marginTop: 8 }}>
              <div
                style={{
                  height: "100%",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <SeasonCalendar fullscreen={false} range={seasonViewRange} />

                  <PlantPeriodsBar
                    style={{
                      position: "absolute",
                      zIndex: 5,
                      width: `${
                        (plantTotalDuration / daysInCalendarData) * 100
                      }%`,
                      left: `${
                        (Math.abs(
                          moment(seasonViewRange.start).diff(
                            moment(datePlanted),
                            "days"
                          )
                        ) /
                          daysInCalendarData) *
                        100
                      }%`,
                      bottom: 8,
                    }}
                    periodDates={plantPeriodsDates}
                    range={seasonViewRange}
                    continuous={continuousPlantLife}
                    withPeriodMarks={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          {/* Season Periods Dates */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: 16,
              flex: 1,
            }}
          >
            <PlantDatesList
              openCalendar={(params) => {
                checkForLockedGarden();
                openCalendar(params);
              }}
              plantPeriodsDates={plantPeriodsDates}
              calendarRange={{ min: datePreviousPlant, max: dateNextPlant }}
            />
          </div>

          {/* Season Plantingtypes selector*/}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "16px 22px 32px 16px",
              flex: 1,
            }}
          >
            {plantingType && (
              <PlantingTypeChoice
                value={plantingType ? plantingType.toUpperCase() : undefined}
                onChange={onChangePlantingType}
              />
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};
