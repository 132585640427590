export const ACCOUNT_TYPE_FREE = "free";
export const ACCOUNT_TYPE_PRO = "pro";
export const ACCOUNT_TYPE_PLUS = "plus";
export const ACCOUNT_TYPE_PAID = "paid"; // old subscriptions before switch to plus/pro

export const PAYMENT_INTERVAL_MONTHLY = "monthly";
export const PAYMENT_INTERVAL_QUARTERLY = "quarterly";
export const PAYMENT_INTERVAL_YEARLY = "yearly";

export const ACCOUNT_TYPE_PLUS_PRICE = 19;
export const ACCOUNT_TYPE_PRO_PRICE = 29;

export const DATE_FORMAT_API = "YYYY-MM-DD";
export const DATE_FORMAT_FRONTEND = "DD.MM.YYYY";

//light types
export const LIGHT_TYPE_SUN = "sun";
export const LIGHT_TYPE_NEUTRAL = "neutral";
export const LIGHT_TYPE_SHADOW = "shadow";

//ground types
export const GROUND_TYPE_LIGHT = "light";
export const GROUND_TYPE_MEDIUM = "medium";
export const GROUND_TYPE_HEAVY = "heavy";
