import theme from "../../Theme";
import IconProps from "../../Types/IconProps";

export default ({ size = "md", color = "currentColor" }: IconProps) => (
  <svg
    width={
      (size === "lg" && "40px") ||
      (size === "md" && "32px") ||
      (size === "sm" && "24px") ||
      (size === "xs" && "16px") ||
      undefined
    }
    height={
      (size === "lg" && "40px") ||
      (size === "md" && "32px") ||
      (size === "sm" && "24px") ||
      (size === "xs" && "16px") ||
      undefined
    }
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12.5C2 18.0228 6.47715 22.5 12 22.5C17.5228 22.5 22 18.0228 22 12.5C22 6.97715 17.5228 2.5 12 2.5C6.47715 2.5 2 6.97715 2 12.5ZM14.5 16.5H13V11C13 10.7239 12.7761 10.5 12.5 10.5H10.5C10.2239 10.5 10 10.7239 10 11C10 11.2761 10.2239 11.5 10.5 11.5H12V16.5H10.5C10.2239 16.5 10 16.7239 10 17C10 17.2761 10.2239 17.5 10.5 17.5H14.5C14.7761 17.5 15 17.2761 15 17C15 16.7239 14.7761 16.5 14.5 16.5ZM12 21.5C16.9706 21.5 21 17.4706 21 12.5C21 7.52944 16.9706 3.5 12 3.5C7.02944 3.5 3 7.52944 3 12.5C3 17.4706 7.02944 21.5 12 21.5ZM13 8C13 7.72386 12.7761 7.5 12.5 7.5H11.5C11.2239 7.5 11 7.72386 11 8V9C11 9.27614 11.2239 9.5 11.5 9.5H12.5C12.7761 9.5 13 9.27614 13 9V8Z"
      fill={theme.colors[color]}
    />
  </svg>
);
