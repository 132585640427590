export enum VoucherContext {
  REGISTER = "register",
  UPGRADE = "upgrade",
}
export enum VoucherStatusMessageTypeType {
  NONE = "",
  ERROR = "error",
  SUCCESS = "success",
}

export enum VoucherStatusMessageType {
  NONE,
  SUCCESS,
  INVALID,
  WRONG_FORMAT,
  VALIDATION_IN_PROGRESS,
}

export type VoucherStatusType =
  | "empty"
  | "localInvalid"
  | "remoteValidationInProgress"
  | "remoteInvalid"
  | "remoteValid";

export type Voucher = {
  message: VoucherStatusMessageType;
  messageType: VoucherStatusMessageTypeType;
  valid?: boolean;
  type?: any;
  parameters?: any;
  code?: string;
};
export default Voucher;
