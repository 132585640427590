import theme from "../../Theme";
import IconProps from "../../Types/IconProps";

export default ({ size = "md", color = "currentColor" }: IconProps) => (
  <svg
    width={
      (size === "lg" && "40px") ||
      (size === "md" && "32px") ||
      (size === "sm" && "24px") ||
      (size === "xs" && "16px") ||
      undefined
    }
    height={
      (size === "lg" && "40px") ||
      (size === "md" && "32px") ||
      (size === "sm" && "24px") ||
      (size === "xs" && "16px") ||
      undefined
    }
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Shape"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0581 3.76606C12.2459 3.41131 12.7541 3.41131 12.9419 3.76606L21.9419 20.7661C22.1182 21.0991 21.8768 21.5 21.5 21.5H3.50001C3.12323 21.5 2.88183 21.0991 3.05812 20.7661L12.0581 3.76606ZM12.5 14.5C12.7762 14.5 13 14.2761 13 14V10C13 9.72386 12.7762 9.5 12.5 9.5C12.2239 9.5 12 9.72386 12 10V14C12 14.2761 12.2239 14.5 12.5 14.5ZM13 17.5V16.5H12V17.5H13ZM20.6696 20.5H4.33047L12.5 5.06863L20.6696 20.5Z"
      fill={theme.colors[color]}
    />
  </svg>
);
