import React, { useEffect, useState } from "react";
import moment from "moment";
import Theme from "../../../../../Core/Theme";
import {
  getCalendarSeasons,
  getDaysInCalendarData,
  getDaysUntilDateInCalendarData,
  getMonthValues,
} from "../../seasonHelpers";
import SnowFlake from "../../../../../Resources/SVG/SnowFlake";

export const SeasonCalendar = ({ fullscreen, range, children }) => {
  const [calendarData, setCalendarData] = useState([]);
  const [seasonsData, setSeasonsData] = useState([]);

  useEffect(() => {
    if (!range || !range.start || !range.end) {
      return;
    }

    const calendarSeasons = getCalendarSeasons(range);
    setSeasonsData(calendarSeasons);
  }, [range]);

  useEffect(() => {
    if (!range || !range.start || !range.end) {
      return;
    }

    setCalendarData(getMonthValues(range));
  }, [range]);

  const daysInCalendarData = getDaysInCalendarData(calendarData);
  const today = moment();
  const daysUntilTodayInCalendarData = getDaysUntilDateInCalendarData(
    calendarData,
    today
  );
  const todayPct = (daysUntilTodayInCalendarData / daysInCalendarData) * 100;

  return (
    <div
      className="SeasonCalendar"
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        backgroundColor: "white",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,

          height: "100%",

          display: "flex",
          flexDirection: "row",
        }}
      >
        {seasonsData.length > 0 &&
          seasonsData.map((season, i) => (
            <div
              key={`season-${season.key}-${i}`}
              style={{
                position: "relative",

                width: `${Math.ceil(
                  (season.duration / daysInCalendarData) * 100
                )}%`,
                backgroundColor:
                  season.key === "main"
                    ? "transparent"
                    : Theme.colors.blueLight,
              }}
            >
              {season.key === "pre" && season.duration > 1 && (
                <div
                  style={{
                    position: "absolute",
                    right: -8,
                    width: 16,
                    height: 16,
                    backgroundColor: fullscreen
                      ? Theme.colors.beigeLight
                      : Theme.colors.white,
                    borderRadius: 8,
                    top: 4,
                    alignItems: "center",
                    color: Theme.colors.blueDark,
                  }}
                >
                  <SnowFlake width={16} height={16} />
                </div>
              )}
            </div>
          ))}
      </div>
      <div
        style={{
          position: "absolute",
          left: `${todayPct}%`,
          width: 1,
          borderLeft: `1px solid ${Theme.colors.red}`,
          height: 80,
          maxHeight: "100%",
        }}
      />
      {fullscreen && (
        <div
          style={{
            position: "absolute",
            top: 97,
            left: `${todayPct}%`,
            width: 1,
            borderLeft: `1px solid ${Theme.colors.red}`,
            height: "100%",
          }}
        />
      )}
      {fullscreen && (
        <div
          style={{
            position: "absolute",
            top: 80,
            left: `${todayPct}%`,
            transform: "translateX(-50%)",
            height: 17,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <span
            style={{
              fontSize: 13,

              color: Theme.colors.red,
            }}
          >
            {today.format("DD.MM")}
          </span>
        </div>
      )}
      <div
        style={{
          position: "absolute",
          display: "flex",
          width: "100%",
          height: "100%",

          flexDirection: "row",
        }}
      >
        {calendarData.length > 0 &&
          calendarData.map((month, i) => (
            <div
              className={"calendarData-" + i}
              key={"month-" + i}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "flex-start",
                width: `${Math.ceil((month.days / daysInCalendarData) * 100)}%`,
                borderLeft: `1px solid ${
                  i === 0
                    ? Theme.colors.blueLight
                    : month.isMainSeason
                    ? Theme.colors.greyMedium
                    : Theme.colors.blueDark
                }`,
              }}
            >
              <span
                style={{
                  textAlign: "center",
                  fontSize: Theme.sizes.small,
                  lineHeight: 1.2,
                  paddingLeft: "45%",
                  height: 24,
                  display: "inline-block",
                  color: month.isMainSeason
                    ? Theme.colors.greyDark
                    : Theme.colors.blueDark,
                  paddingTop: 4,
                  marginBottom: "auto",
                }}
              >
                {month.monthIndex !== 4 && month.label.substring(0, 1)}
              </span>
            </div>
          ))}
      </div>

      {children}
    </div>
  );
};
