import { CropAction, CropWithVarieties } from "../../../Types/API/Crop";

const cropActionsDiffer = (a: CropAction, b: CropAction) => {
  return (
    a.text !== b.text ||
    a.type !== b.type ||
    (a.additionalData && !b.additionalData) ||
    (!a.additionalData && b.additionalData) ||
    a.additionalData?.fertilizer !== b.additionalData?.fertilizer ||
    a.daysEnd !== b.daysEnd ||
    a.repeatDays !== b.repeatDays ||
    a.daysStart !== b.daysStart ||
    a.notificationText !== b.notificationText ||
    a.title !== b.title
  );
};

export const processCropActionsForSaving = (
  crop: CropWithVarieties,
  oldCropActions: CropAction[],
  newCropActions: CropAction[]
) => {
  if (!crop) {
    throw new Error("no crop given");
  }
  const upsertCropActions = newCropActions.reduce(
    (upsertThese: CropAction[], ca) => {
      ca.cropId = crop.id;
      if (ca.id && ca.id < 0) {
        delete ca.id;
        upsertThese.push(ca);
      } else {
        const existingCropAction = oldCropActions.find(
          (existing) => existing.id === ca.id
        );
        if (existingCropAction) {
          if (cropActionsDiffer(ca, existingCropAction)) {
            upsertThese.push(ca);
          }
        } else {
          console.warn("CropAction " + ca.id + " not found");
        }
      }
      return upsertThese;
    },
    []
  );
  const deleteCropActions = oldCropActions.filter(
    (ca) => !newCropActions.find((nca) => nca.id === ca.id)
  );
  return [upsertCropActions, deleteCropActions];
};
