import React from "react";
import PropTypes from "prop-types";

import "./Styles.scss";
import { Checkbox } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import SmallZwiconButton from "../SmallZwiconButton";
import moment from "moment";
import CustomTodoItem from "../CustomTodoItem";
import Theme from "../../Core/Theme";

const GreenCheckbox = withStyles({
  root: {
    color: Theme.mainGreen,
    "&$checked": {
      color: Theme.mainGreenHover,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

class TodoItem extends React.Component {
  state = {
    checked: false,
    open: false,
    editCustomTodo: false,
  };

  static getDerivedStateFromProps(props, state) {
    let newState = state;

    if (props.data !== state.data) {
      newState.data = props.data;
      newState.checked = props.data.dateDone !== null;
    }

    return newState;
  }

  onChange = (e, c) => {
    this.setState({ checked: c });
    this.props.onCheck(c);
  };

  onToggle = () => {
    this.setState({ open: !this.state.open });
  };

  snoozeTodo = (todo) => {
    if (todo.dateDue && todo.dateDue.date) {
      todo.dateDue.date = moment(todo.dateDue.date, "YYYY-MM-DD")
        .add(1, "weeks")
        .format("YYYY-MM-DD");
    }
    this.props.snoozeTodo(todo.id);
    this.setState({ open: false });
  };

  updateCustomTodo = () => {
    this.setState({ editCustomTodo: false, open: false });
  };

  render() {
    const {
      data,
      transparent,
      displayImage = false,
      cropImageSrc,
      selectedWeek,
      type,
      isGreen,
    } = this.props;
    const additionalClass = this.state.checked ? " done" : "";
    if (data === undefined) {
      return null;
    }
    return (
      <div className="todo-item">
        {this.state.editCustomTodo ? (
          <CustomTodoItem
            selectedWeek={this.props.selectedWeek}
            todo={data}
            editMode={true}
            onUpdate={this.updateCustomTodo}
            addCustomTodo={this.props.addCustomTodo}
          />
        ) : (
          <div style={{ width: "100%" }}>
            <div className="todo-content" onClick={this.onToggle}>
              <div className="todo-content-title">
                {displayImage && (
                  <div className="crop-image">
                    <img src={cropImageSrc} alt="" />
                  </div>
                )}
                <div className="centered-todo-items">
                  {isGreen ? (
                    <GreenCheckbox
                      checked={this.state.checked}
                      onChange={this.onChange}
                      onClick={(e) => e.stopPropagation()}
                    />
                  ) : (
                    <Checkbox
                      checked={this.state.checked}
                      onChange={this.onChange}
                      onClick={(e) => e.stopPropagation()}
                    />
                  )}
                  <p className={"todo-title" + additionalClass}>
                    {displayImage || data.type === "custom"
                      ? data.title
                      : data.title + " " + data.type}
                  </p>
                  <div className="todo-right">
                    {!this.state.open &&
                      (transparent ? (
                        // <i className="zwicon-info-circle" />
                        <i className="zwicon-chevron-down" />
                      ) : (
                        <SmallZwiconButton icon="chevron-down" />
                      ))}
                    {this.state.open &&
                      (transparent ? (
                        <i
                          className="zwicon-chevron-up"
                          onClick={this.onToggle}
                        />
                      ) : (
                        <SmallZwiconButton icon="chevron-up" />
                      ))}
                  </div>
                </div>
              </div>
            </div>
            {this.state.open && (
              <div className="todo-text">
                {displayImage && (
                  <div className="crop-image">
                    <i className="zwicon-history" />
                    <p>KW{selectedWeek + 1}</p>
                  </div>
                )}
                <div>
                  {displayImage &&
                    data.additionalInformation.map((info, index) => (
                      <div
                        key={"todo-info-" + index}
                        className="additional-information"
                      >
                        <span className="title">{info.title} :</span>
                        <span className="value">{info.value}</span>
                      </div>
                    ))}
                  {this.state.open && data.text && <p>{data.text}</p>}
                  {displayImage && (
                    <div
                      className="snooze-todo"
                      onClick={() => this.snoozeTodo(data)}
                    >
                      <i className="zwicon-bell-snooze" />
                      <p>eine Woche schieben</p>
                    </div>
                  )}
                </div>
              </div>
            )}
            {this.state.open && type && type.name === "custom" && (
              <>
                <div
                  className="snooze-todo"
                  onClick={() => this.snoozeTodo(data)}
                >
                  <i className="zwicon-bell-snooze" />
                  <p>eine Woche schieben</p>
                </div>

                <div className="custom-footer">
                  <div
                    className="icon-with-label"
                    onClick={() => this.setState({ editCustomTodo: true })}
                  >
                    <i className="zwicon-edit-circle" />
                    <span>Aufgabe anpassen</span>
                  </div>
                  <i
                    className="zwicon-trash"
                    onClick={() => {
                      this.props.deleteTodo(data.id);
                      this.updateCustomTodo();
                    }}
                  />
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

TodoItem.propTypes = {
  data: PropTypes.object.isRequired,
  transparent: PropTypes.bool,
};
TodoItem.defaultProps = {};

export default TodoItem;
