import Slider from "@material-ui/core/Slider";
import React, { useEffect, useState } from "react";
import CanvasMessages from "alphabeet-canvas/CanvasMessages";
import Images, { Icon } from "../../../../Core/Images";
import { useTranslation } from "react-i18next";
import Theme from "../../../../Core/Theme";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { PlanMode, Season } from "../Consts";
import { useDispatch, useSelector } from "react-redux";
import {
  decreaseYear,
  increaseYear,
  setSeason,
} from "../../../../Reducers/Planning/Actions";

import Paywall from "../../../../Components/Paywall";
import { postMessage } from "../util";

const useStyles = makeStyles((theme) => ({
  container: {
    ...Theme.ui.listContainerStyles,
    ...Theme.ui.shadow,
    padding: ({ mode }) => (mode === PlanMode.OVERVIEW ? 0 : "8px 0"),
  },
  iconButton: {
    ...Theme.ui.iconButtonStyles,
    margin: 0,
  },
  textButton: {
    ...Theme.ui.textButtonStyles,
    margin: "8px 0 8px 8px",
  },
  undecoratedList: {
    margin: 0,
    padding: 0,
    "& li": {
      listStyle: "none",
    },
  },
  seasonChoice: {
    display: "flex",
    flexDirection: "column",

    "& header": {
      padding: "0 16px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    "& header *": {
      fontSize: Theme.sizes.small,
      fontWeight: 700,
    },
  },
  yearChoice: {
    display: "flex",
    alignItems: "center",
    lineHeight: 1.5,
  },
  actionsGrid: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    alignItems: "center",
    gap: 8,
    paddingLeft: 16,
    paddingRight: 16,

    "& label.undo": {
      color: ({ undoEnabled }) =>
        undoEnabled ? Theme.colors.black : Theme.colors.greyMedium,
    },
    "& label.redo": {
      color: ({ redoEnabled }) =>
        redoEnabled ? Theme.colors.black : Theme.colors.greyMedium,
    },
    "& label.satellite": {
      color: Theme.colors.black,
    },
    userSelect: "none",
  },
  zoom: {
    display: "flex",
    flexDirection: "column",
    padding: 16,
    "& section": {
      display: "flex",
      justifyContent: "space-between",
      "& span": { color: Theme.colors.greyMedium },
    },
  },
}));

const Container = ({ mode, children }) => {
  const classes = useStyles({ mode });
  return <div className={classes.container}>{children}</div>;
};

export const SeasonChoice = ({ season, onChange }) => {
  const { t } = useTranslation("plan");
  const classes = useStyles({});
  const seasons = [Season.PRE, Season.MAIN, Season.POST];
  const [isExpanded, setIsExpanded] = useState(true);

  const isActive = (s) => s === season;

  return (
    <div className={classes.seasonChoice}>
      <header>
        <span>{t("PlanControls.label.season")}</span>
        <button
          data-testid={"btn.seasonChoice"}
          className={classes.iconButton}
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
        >
          <Icon
            src={isExpanded ? Images.icons.chevronUp : Images.icons.chevronDown}
            size={32}
          />
        </button>
      </header>
      {isExpanded && (
        <ul className={classes.undecoratedList} style={{ marginLeft: 8 }}>
          {seasons.map((s, idx) => (
            <li key={idx}>
              <button
                role={"option"}
                className={classes.textButton}
                style={{
                  fontSize: 14,
                  ...(isActive(s)
                    ? {
                        backgroundColor: Theme.colors.beigeMedium,
                        border: "1px solid",
                        borderColor: Theme.colors.beigeDark,
                      }
                    : {}),
                }}
                onClick={(event) => onChange(s)}
              >
                {t(`PlanControls.season.${s}`)}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export const UndoRedo = ({
  undoEnabled,
  redoEnabled,
  onUndoClick,
  onRedoClick,
}) => {
  const { t } = useTranslation("plan");
  const classes = useStyles({ undoEnabled, redoEnabled });
  return (
    <div className={classes.actionsGrid}>
      <button
        id={"btn.undo"}
        data-testid={"btn.undo"}
        className={classes.iconButton}
        onClick={onUndoClick}
      >
        <Icon
          src={undoEnabled ? Images.icons.undo : Images.icons.undoDisabled}
          size={32}
        />
      </button>

      <label className={"undo"} htmlFor={"btn.undo"}>
        {t("PlanControls.undo")}
      </label>
      <button
        id={"btn.redo"}
        data-testid={"btn.redo"}
        className={classes.iconButton}
        onClick={onRedoClick}
      >
        <Icon
          src={redoEnabled ? Images.icons.redo : Images.icons.redoDisabled}
          size={32}
        />
      </button>

      <label className={"redo"} htmlFor={"btn.redo"}>
        {t("PlanControls.redo")}
      </label>
    </div>
  );
};

export const YearChoice = ({ year, onIncrease, onDecrease }) => {
  const classes = useStyles({});
  return (
    <div className={classes.yearChoice}>
      <button
        data-testid="btn.decreaseYear"
        className={classes.iconButton}
        onClick={() => onDecrease()}
      >
        <Icon src={Images.icons.chevronLeftGreen} size={32} />
      </button>

      <b style={{ margin: "0 auto", width: 64, textAlign: "center" }}>{year}</b>

      <button
        data-testid="btn.increaseYear"
        className={classes.iconButton}
        onClick={() => onIncrease()}
      >
        <Icon src={Images.icons.chevronRightGreen} size={32} />
      </button>
    </div>
  );
};

export const Zoom = ({ level, onChange }) => {
  const { t } = useTranslation("plan");
  const classes = useStyles({});
  return (
    <div className={classes.zoom}>
      <section>
        <header>{t("PlanControls.header.zoom")}</header>
        <span>{Math.max(1, Math.ceil(level / 10.0)) + ":" + 10}</span>
      </section>
      <Slider
        data-testid={"slider.zoom"}
        min={1}
        max={100}
        defaultValue={17.5}
        value={level}
        onChange={onChange}
      />
    </div>
  );
};

const PlanControls = ({ mode }) => {
  const [zoom, setZoom] = useState(17.5);
  const [undoEnabled, setUndoEnabled] = useState(false);
  const [redoEnabled, setRedoEnabled] = useState(false);
  const [paywallOpen, setPaywallOpen] = useState(false);
  const [paywallContext, setPaywallContext] = useState(undefined);
  const dispatch = useDispatch();
  const { season, year } = useSelector((state) => state.Planning);
  const { isPlusAccount, isProAccount } = useSelector((state) => state.Account);

  const zoomHandler = (e) => {
    if (e?.detail?.zoom) {
      setZoom(e.detail.zoom);
    }
  };
  const undoRedoHandler = (e) => {
    setUndoEnabled(e.detail.undoable);
    setRedoEnabled(e.detail.redoable);
  };

  useEffect(() => {
    if (mode !== PlanMode.OVERVIEW) {
      window.addEventListener(CanvasMessages.ZOOM_LEVEL, zoomHandler);
      window.addEventListener(CanvasMessages.UNDO_REDO_STATUS, undoRedoHandler);
      return () => {
        window.removeEventListener(CanvasMessages.ZOOM_LEVEL, zoomHandler);
        window.removeEventListener(
          CanvasMessages.UNDO_REDO_STATUS,
          undoRedoHandler
        );
      };
    }
  }, []);

  const changeYear = (action) => () => {
    if (!isProAccount) {
      setPaywallContext("year");
      setPaywallOpen(true);
    } else {
      dispatch(action());
    }
  };
  const onIncreaseYear = changeYear(increaseYear);
  const onDecreaseYear = changeYear(decreaseYear);

  const onSetSeason = (season) => {
    if (!isPlusAccount && !isProAccount) {
      setPaywallContext("season");
      setPaywallOpen(true);
    } else {
      dispatch(setSeason(season));
    }
  };

  return (
    <Container mode={mode}>
      {(mode === PlanMode.CROPS || mode === PlanMode.OVERVIEW) && (
        <YearChoice
          year={year}
          onDecrease={onDecreaseYear}
          onIncrease={onIncreaseYear}
        />
      )}
      {mode === PlanMode.CROPS && (
        <SeasonChoice season={season} onChange={onSetSeason} />
      )}
      {mode !== PlanMode.OVERVIEW && (
        <UndoRedo
          undoEnabled={undoEnabled}
          redoEnabled={redoEnabled}
          onUndoClick={() => postMessage("undo", {})}
          onRedoClick={() => postMessage("redo", {})}
        />
      )}
      {mode !== PlanMode.OVERVIEW && (
        <Zoom
          level={zoom}
          onChange={(e, v) => {
            setZoom(v);
            postMessage("zoom", { zoom: v });
          }}
        />
      )}
      <Paywall
        open={paywallOpen}
        context={paywallContext}
        onDismiss={() => setPaywallOpen(false)}
      />
    </Container>
  );
};

export default PlanControls;
