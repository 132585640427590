import theme from "../../Theme";
import IconProps from "../../Types/IconProps";

export default ({ size = "md", color = "currentColor" }: IconProps) => (
  <svg
    width={
      (size === "xl" && "48px") ||
      (size === "lg" && "40px") ||
      (size === "md" && "32px") ||
      (size === "sm" && "24px") ||
      (size === "xs" && "16px") ||
      undefined
    }
    height={
      (size === "xl" && "48px") ||
      (size === "lg" && "40px") ||
      (size === "md" && "32px") ||
      (size === "sm" && "24px") ||
      (size === "xs" && "16px") ||
      undefined
    }
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.1923 7.62073C25.4462 7.36689 25.4462 6.95533 25.1923 6.70149C24.9385 6.44765 24.5269 6.44765 24.2731 6.70149L15.8939 15.0807L7.72682 6.9137C7.47298 6.65986 7.06142 6.65986 6.80758 6.9137C6.55374 7.16754 6.55374 7.57909 6.80758 7.83294L14.9746 16L6.80757 24.167C6.55373 24.4209 6.55373 24.8324 6.80757 25.0863C7.06141 25.3401 7.47297 25.3401 7.72681 25.0863L15.8939 16.9192L24.2731 25.2985C24.527 25.5523 24.9385 25.5523 25.1924 25.2985C25.4462 25.0446 25.4462 24.6331 25.1924 24.3792L16.8131 16L25.1923 7.62073Z"
      fill={theme.colors[color]}
    />
  </svg>
);
