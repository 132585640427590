import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./Styles.scss";
import StyledTextField from "../../Common/StyledTextField";
import moment from "moment";
import { withWidth } from "@material-ui/core";

function CustomTodoItem(props) {
  const deviceInnerWidth = window ? window.innerWidth : 0;
  const [id, setId] = useState(null);
  const [customTitle, setCustomTitle] = useState("");
  const [customDescription, setCustomDescription] = useState("");

  useEffect(() => {
    const { todo } = props;
    setId(todo ? todo.id : null);
    setCustomTitle(todo ? todo.title : "");
    setCustomDescription(todo ? todo.text : "");
  }, [props]);

  const addCustomTodo = () => {
    const todo = {
      id: id,
      title: customTitle,
      text: customDescription,
      dateDue: props.todo
        ? props.todo.dateDue.date
        : moment(props.startWeekLabel, "DD-MM-YYYY")
            .weekday(1)
            .format("YYYY-MM-DD"),
    };
    if (props.addCustomTodo) {
      props.addCustomTodo(todo);
    }
    if (props.onUpdate) {
      props.onUpdate();
    }
    setId(null);
    setCustomTitle("");
    setCustomDescription("");
  };

  return (
    <div className={`custom-todo ${props.editMode ? "edit" : ""}`}>
      <div className="custom-todo-title">
        <StyledTextField
          placeholder={"Titel der Aufgabe eingeben"}
          fullWidth={true}
          onChange={(e) => {
            setCustomTitle(e.target.value);
          }}
          value={customTitle}
        />
      </div>
      <div className="content-block">
        <StyledTextField
          placeholder="Optional: Beschreibungstext oder Notizen"
          multiline
          rows={props.width === "xs" && deviceInnerWidth < 390 ? 3 : 2}
          value={customDescription}
          onChange={(e) => {
            setCustomDescription(e.target.value);
          }}
        />
        <div className="col" onClick={addCustomTodo}>
          <div className="save">
            <i className="zwicon-checkmark" />
          </div>
          <span>speichern</span>
        </div>
      </div>
    </div>
  );
}
CustomTodoItem.propTypes = {
  addCustomTodo: PropTypes.func.isRequired,
  onUpdate: PropTypes.func,
};

export default withWidth()(CustomTodoItem);
