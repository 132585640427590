// adapted from https://www.fcodelabs.com/2021/01/12/React-Material-Date-Picker/
import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";

import { makeStyles } from "@material-ui/core/styles";
import Theme from "../../Core/Theme";

import "./react-calendar-hacks.scss";

export const useStyles = makeStyles(() => ({
  root: {
    "& .react-calendar__navigation": {
      display: "flex",
    },
    "& .react-calendar__navigation__prev2-button, & .react-calendar__navigation__next2-button":
      {
        visibility: "hidden",
      },
    "& .react-calendar__navigation__prev-button, & .react-calendar__navigation__next-button":
      {
        border: 0,
        color: Theme.colors.greenApp,
        backgroundColor: "transparent",
        fontSize: Theme.sizes.h2,
      },
    "& .react-calendar__navigation__label": {
      border: 0,
      backgroundColor: "transparent",
      fontFamily: Theme.fonts.karla,
    },
    "& abbr[title]": {
      textDecoration: "none",
    },
    "& .react-calendar__month-view__weekdays__weekday": {
      fontSize: Theme.sizes.small,
      textAlign: "center",
      color: Theme.colors.greyMedium,
    },
    "& .react-calendar__month-view__weekNumbers": {
      "& .react-calendar__tile": {
        paddingTop: 4,
        paddingBottom: 4,
        textAlign: "center",
        color: Theme.colors.greyMedium,
        "& span": {
          fontSize: Theme.sizes.small,
        },
      },
    },
    "& button.react-calendar__tile abbr": {
      borderRadius: "50%",
      padding: 4,
      fontFamily: Theme.fonts.karla,
      display: "inline-block",
      width: 16,
    },
    "& button.react-calendar__tile": {
      border: 0,
      backgroundColor: "transparent",
      padding: 2,
      "&:not(.react-calendar__tile--now):not(.react-calendar__tile--active)": {
        "& abbr": {
          border: "1px solid transparent",
        },
        "&:hover abbr": {
          border: `1px solid ${Theme.colors.beigeDarkest}`,
        },
      },
    },
    "& .react-calendar__tile--active abbr": {
      border: `1px solid ${Theme.colors.beigeDarkest}`,
      backgroundColor: Theme.colors.beigeMedium,
    },
    "& .react-calendar__tile--now abbr": {
      color: Theme.colors.white,
      backgroundColor: Theme.colors.greenApp,
    },
  },
}));
const CustomCalendar = ({
  currentDate,
  onChange,
  showWeekNumbers,
  minDate,
  maxDate,
}) => {
  const [selectedDate, setSelectedDate] = useState(currentDate);
  const classes = useStyles(); // import those CSS

  useEffect(() => {
    setSelectedDate(currentDate);
  }, [currentDate]);

  return (
    <Calendar
      className={classes.root}
      tileClassName={"react-calendar-tile-hack"}
      value={selectedDate}
      onChange={(date) => {
        setSelectedDate(date);
        onChange(date);
      }}
      showWeekNumbers={showWeekNumbers}
      minDate={minDate}
      maxDate={maxDate}
    />
  );
};
export default CustomCalendar;
