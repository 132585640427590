import { cloneElement, MouseEventHandler, ReactElement } from "react";
import { MarginProps } from "../../Styled/margin";
import { PositionProps } from "../../Styled/position";
import { ColorProps } from "../../Styled/color";
import IconProps from "../../../Types/IconProps";
import Box from "../../Layout/Box";
import Center from "../../Layout/Center";
import styled from "styled-components";
import ButtonEffect from "../../Display/ButtonEffect";

type IconButtonProps = {
  icon: ReactElement<IconProps>;
  size?: "lg" | "md" | "sm" | "xs";
  hoverIcon?: ReactElement<IconProps>;
  color?: ColorProps["$color"];
} & (
  | {
      as?: "button";
      onClick?: (e: any) => void;
      href?: undefined;
    }
  | {
      as?: "a";
      onClick?: undefined;
      href?: string;
    }
) &
  PositionProps &
  MarginProps &
  ColorProps;

const StyledCenter = styled(Center)`
  > svg:nth-of-type(2) {
    display: none;
  }

  &:hover {
    > svg:nth-of-type(1) {
      display: none;
    }
    > svg:last-of-type {
      display: block;
    }
  }
`;

const IconButton = ({
  as = "button",
  icon,
  hoverIcon,
  size = "md",
  color,
  onClick,
  href,
  ...props
}: IconButtonProps) => {
  return (
    <Box
      as={as}
      target={as === "a" ? "_blank" : undefined}
      href={href}
      onClick={onClick}
      overflow="hidden"
      borderRadius="full"
      size={
        (size === "lg" && "48px") ||
        (size === "md" && "40px") ||
        (size === "sm" && "32px") ||
        (size === "xs" && "24px") ||
        undefined
      }
      flexShrink={0}
      $color={color}
      {...props}
    >
      <StyledCenter position="relative" borderRadius="full" size="100%">
        {!hoverIcon && <ButtonEffect />}
        {cloneElement(icon, { size: size, ...icon.props })}
        {hoverIcon &&
          cloneElement(hoverIcon, { size: size, ...hoverIcon.props })}
      </StyledCenter>
    </Box>
  );
};

export default IconButton;
