import { uniqBy } from "lodash";

import * as actions from "./Actions";
import {
  RECEIVE_TAGS,
  RESET_USER_PROFILE,
  RECEIVE_USER_PROFILE,
  RESET_POST_SAVED,
  RESET_UNREAD_NOTIFICATIONS,
  SELECT_COMMENT,
  START_FETCHING,
  START_FETCHING_USER_PROFILE,
  RESET_FEED_FOR_USER,
  RECEIVE_FEED_FOR_USER,
  SET_FEED_RETURN_TO_POST_HTML_ID,
} from "./Actions";

export const initialState = {
  posts: [],
  currentPage: 1,
  totalPages: 1,
  userPosts: [],
  userCurrentPage: 1,
  userTotalPages: 1,
  selectedPost: undefined,
  selectedPostError: false,
  bookmarks: [],
  isLoading: false,
  postSaved: false,
  selectedComment: undefined,
  notifications: [],
  unreadNotifications: 0,
  isRefreshingNotifications: false,
  isRefreshingSelectedPost: false,
  tags: { mentiontags: [], hashtags: [] },
  selectedUserProfile: undefined,
  isFetchingUserProfile: false,
  trendingTags: [],
  myTags: [],
  returnToPostHtmlId: undefined,
};

const updatePostsAndBookmarks = (newPost, posts, userPosts, bookmarks) => {
  if (newPost && newPost.id) {
    // if post already existed, remove it
    const index = posts.findIndex((t) => t.id === newPost.id);
    if (index === -1) {
      posts.unshift(newPost);
    } else {
      posts.splice(index, 1, newPost);
    }
    const userPostIndex = userPosts.findIndex((t) => t.id === newPost.id);
    if (userPostIndex !== -1) {
      userPosts.splice(userPostIndex, 1, newPost);
    }
    const bookmarkIndex = bookmarks.findIndex((t) => t.id === newPost.id);
    if (bookmarkIndex === -1 && newPost.isBookmarked) {
      bookmarks.unshift(newPost);
    } else if (bookmarkIndex !== -1 && newPost.isBookmarked) {
      bookmarks.splice(bookmarkIndex, 1, newPost);
    } else if (bookmarkIndex !== -1 && !newPost.isBookmarked) {
      bookmarks.splice(bookmarkIndex, 1);
    }
  }
  return [posts, userPosts, bookmarks];
};

export default function FeedReducer(state = initialState, action = {}) {
  switch (action.type) {
    case START_FETCHING: {
      return { ...state, isLoading: true };
    }
    case actions.RECEIVE_FEED_LIST: {
      if (action.data.posts !== undefined) {
        state = {
          ...state,
          posts: action.data.posts,
          currentPage: Number(action.data.currentPage),
          totalPages: Number(action.data.totalPages),
          isLoading: false,
          unreadNotifications: Number(
            action.data._metadata.unreadNotifications
          ),
          trendingTags: action.data._metadata.trendingTags,
          myTags: action.data._metadata.myTags,
        };
      }
      return state;
    }
    case actions.RECEIVE_FEED_PAGE_LIST: {
      if (action.data.posts !== undefined) {
        const posts = uniqBy([...state.posts, ...action.data.posts], "id");
        state = {
          ...state,
          posts,
          currentPage: Number(action.data.currentPage),
          totalPages: Number(action.data.totalPages),
          isLoading: false,
        };
      }
      return state;
    }
    case actions.RECEIVE_SELECTED_POST: {
      if (action.status !== 200) {
        return {
          ...state,
          selectedPost: undefined,
          selectedPostError: true,
          isRefreshingSelectedPost: false,
          selectedComment: undefined,
        };
      }

      const post = action.data;
      const [posts, userPosts, bookmarks] = state.posts.some(
        (p) => p.id === post.id
      )
        ? updatePostsAndBookmarks(
            post,
            state.posts,
            state.userPosts,
            state.bookmarks
          )
        : [state.posts, state.userPosts, state.bookmarks];
      return {
        ...state,
        selectedPost: post,
        selectedPostError: false,
        isRefreshingSelectedPost: false,
        posts,
        userPosts,
        bookmarks,
        selectedComment: undefined,
      };
    }
    case actions.RECEIVE_FEED: {
      if (action.data !== undefined) {
        const post = action.data.post;
        const [posts, userPosts, bookmarks] = updatePostsAndBookmarks(
          post,
          state.posts,
          state.userPosts,
          state.bookmarks
        );

        state = {
          ...state,
          posts,
          userPosts,
          bookmarks,
          postSaved: true,
          selectedComment: undefined,
        };

        if (post && post.id && state.selectedPost && state.selectedPost.id) {
          state.selectedPost = post;
        }
      }
      return state;
    }
    case actions.RECEIVE_BOOKMARKS: {
      if (action.data.posts !== undefined) {
        state = {
          ...state,
          bookmarks: action.data.posts,
        };
      }
      return state;
    }
    case actions.SELECT_POST: {
      state = {
        ...state,
        selectedPost: action.data,
        selectedPostError: false,
        selectedComment: undefined,
      };
      return state;
    }
    case RESET_POST_SAVED: {
      return {
        ...state,
        postSaved: false,
      };
    }
    case SELECT_COMMENT: {
      return { ...state, selectedComment: { ...action.comment } };
    }
    case actions.START_FETCHING_NOTIFICATIONS: {
      return { ...state, isRefreshingNotifications: true };
    }
    case actions.RECEIVE_NOTIFICATIONS: {
      return {
        ...state,
        notifications: action.data.notifications,
        isRefreshingNotifications: false,
      };
    }
    case RECEIVE_TAGS: {
      if (action.data !== undefined) {
        state = {
          ...state,
          tags: action.data,
        };
      }
      return state;
    }
    case RESET_USER_PROFILE: {
      return {
        ...state,
        selectedUserProfile: undefined,
        isFetchingUserProfile: false,
      };
    }
    case RECEIVE_USER_PROFILE: {
      if (action.data !== undefined) {
        state = {
          ...state,
          selectedUserProfile: action.data,
          isFetchingUserProfile: false,
        };
      }
      return state;
    }
    case START_FETCHING_USER_PROFILE: {
      return { ...state, isFetchingUserProfile: true };
    }
    case actions.START_FETCHING_SELECTED_POST: {
      return { ...state, isRefreshingSelectedPost: true };
    }
    case RESET_UNREAD_NOTIFICATIONS: {
      return { ...state, unreadNotifications: 0 };
    }

    case RESET_FEED_FOR_USER: {
      return { ...state, userPosts: [], userCurrentPage: 1, userTotalPages: 1 };
    }

    case RECEIVE_FEED_FOR_USER: {
      if (action.data.posts !== undefined) {
        state = {
          ...state,
          userPosts: uniqBy(state.userPosts.concat(action.data.posts), "id"),
          userCurrentPage: Number(action.data.currentPage),
          userTotalPages: Number(action.data.totalPages),
          isLoading: false,
        };
      }
      return state;
    }

    case SET_FEED_RETURN_TO_POST_HTML_ID: {
      return { ...state, returnToPostHtmlId: action.htmlId };
    }

    default:
      return state;
  }
}
