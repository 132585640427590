export const LOCATION_FEATURE_REMOVED_DATE = "2022-09-25";

export const PlanMode = {
  CROPS: "crops",
  PATCHES: "patches",
  OVERVIEW: "overview",
  MAGIC_WAND: "magicWand",
  SUNPAINTER: "sunPainter",
};

export const ShapeControlsType = {
  PATCH: "patch",
  DECORATION: "decoration",
};

export const Season = {
  PRE: "pre",
  MAIN: "main",
  POST: "post",
};

export const GroundType = {
  LIGHT: "light",
  MEDIUM: "medium",
  HEAVY: "heavy",
};

export const RoofType = {
  NONE: "none",
  GREENHOUSE: "greenhouse",
};

export const MagicWandOptions = {
  AUTO_FILL: "autoFill",
  OPTIMIZATION: "optimization",
};

export const MagicWandWarnings = {
  NO_FREE_FIELDS: "noFreeFields",
  NO_PLANTS_FOUND: "noPlantsFound",
  NO_PLANTS_FOUND_FOR_SOME: "noPlantsFoundForSome",
};

export const AutoFillOptions = {
  FAVORITES: "favorites",
  SURPRISE: "surprise",
};

export const MagicWandMenuEntryType = {
  AUTO_FILL: MagicWandOptions.AUTO_FILL,
  AUTO_FILL_FAVORITES:
    MagicWandOptions.AUTO_FILL + "_" + AutoFillOptions.FAVORITES,
  AUTO_FILL_SURPRISE:
    MagicWandOptions.AUTO_FILL + "_" + AutoFillOptions.SURPRISE,
  OPTIMIZATION: MagicWandOptions.OPTIMIZATION,
};

export const LightingDemandType = {
  LIGHT: "light",
  MEDIUM: "medium",
  HEAVY: "heavy",
};

export const NutrientDemandType = {
  LIGHT: "light",
  MEDIUM: "medium",
  HEAVY: "heavy",
};

export const WaterDemandType = {
  LIGHT: "light",
  MEDIUM: "medium",
  HEAVY: "heavy",
};

export const WaterAccessType = {
  AVAILABLE: "yes",
  UNAVAILABLE: "no",
};

export const Side = {
  NONE: "none",
  TOP: "top",
  RIGHT: "right",
  BOTTOM: "bottom",
  LEFT: "left",
};

export const Settings = {
  PLAN_TUTORIAL: "planTutorial",
};

export const PlanTutorialStep = {
  NONE: "",
  PATCHES: "patches",
  GOTO_CONDITIONS: "gotoConditions",
  CONDITIONS: "conditions",
  GOTO_PLANTING: "gotoPlanting",
  PLANTING_PATCH: "plantingPatch",
  PLANTING_FAVORITES: "plantingFavorites",
};

export const AMOUNT_OF_HARVEST_PER_SQUAREMETRE = 7;
