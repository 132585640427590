import * as ApiActions from "../Api/Actions";
import Routes from "../../Core/Routes";

export const RECEIVE_FEED_LIST = "FEED_RECEIVE_FEED_LIST";
export const RECEIVE_FEED_PAGE_LIST = "FEED_RECEIVE_FEED_PAGE_LIST";
export const RECEIVE_FEED = "FEED_RECEIVE_FEED";
export const SELECT_POST = "FEED_SELECT_POST";
export const SELECT_COMMENT = "FEED_SELECT_COMMENT";
export const RECEIVE_BOOKMARKS = "FEED_RECEIVE_BOOKMARKS";
export const START_FETCHING = "FEED_START_FETCHING";
export const RESET_POST_SAVED = "FEED_RESET_POST_SAVED";
export const START_FETCHING_NOTIFICATIONS = "FEED_START_FETCHING_NOTIFICATIONS";
export const RECEIVE_NOTIFICATIONS = "FEED_RECEIVE_NOTIFICATIONS";
export const RESET_UNREAD_NOTIFICATIONS = "RESET_UNREAD_NOTIFICATIONS";
export const START_FETCHING_SELECTED_POST = "FEED_START_FETCHING_SELECTED_POST";
export const RECEIVE_SELECTED_POST = "FEED_RECEIVE_SELECTED_POST";
export const RECEIVE_TAGS = "FEED_RECEIVE_TAGS";
export const RESET_USER_PROFILE = "FEED_RESET_USER_PROFILE";
export const RECEIVE_USER_PROFILE = "FEED_RECEIVE_USER_PROFILE";
export const START_FETCHING_USER_PROFILE = "FEED_START_FETCHING_USER_PROFILE";
export const RECEIVE_FEED_FOR_USER = "FEED_RECEIVE_FEED_FOR_USER";
export const RESET_FEED_FOR_USER = "FEED_RESET_FEED_FOR_USER";
export const SET_FEED_RETURN_TO_POST_HTML_ID =
  "FEED_SET_FEED_RETURN_TO_POST_HTML_ID";

export const receiveFeedList = (data, status) => ({
  type: RECEIVE_FEED_LIST,
  data: data,
  status: status,
});

export const receiveFeedPageList = (data, status) => ({
  type: RECEIVE_FEED_PAGE_LIST,
  data: data,
  status: status,
});

export const receiveFeed = (data, status) => ({
  type: RECEIVE_FEED,
  data: data,
  status: status,
});

export const receiveBookmarks = (data, status) => ({
  type: RECEIVE_BOOKMARKS,
  data: data,
  status: status,
});

export const selectPost = (post) => ({
  type: SELECT_POST,
  data: post,
});

export const selectComment = (comment) => ({
  type: SELECT_COMMENT,
  comment,
});

export const startFetching = () => ({
  type: START_FETCHING,
});

export const resetPostSaved = () => ({
  type: RESET_POST_SAVED,
});

export const startFetchingNotifications = () => ({
  type: START_FETCHING_NOTIFICATIONS,
});

export const receiveNotifications = (data, status) => ({
  type: RECEIVE_NOTIFICATIONS,
  data: data,
  status: status,
});

export const startFetchingSelectedPost = () => ({
  type: START_FETCHING_SELECTED_POST,
});

export const receiveSelectedPost = (data, status) => ({
  type: RECEIVE_SELECTED_POST,
  data: data,
  status: status,
});

export const receiveTags = (data, status) => ({
  type: RECEIVE_TAGS,
  data: data,
  status: status,
});

export const receiveUserProfile = (data) => ({
  type: RECEIVE_USER_PROFILE,
  data: data,
});

export const startFetchingUserProfile = () => ({
  type: START_FETCHING_USER_PROFILE,
});

export const receiveFeedForUser = (data) => ({
  type: RECEIVE_FEED_FOR_USER,
  data: data,
});

export const sendPost = (data) => (dispatch) => {
  dispatch(ApiActions.postData(Routes.API_ROUTE_POSTS, data, receiveFeed));
};

export const fetchFeed = (search, category) => (dispatch) => {
  dispatch(startFetching());
  let url = Routes.API_ROUTE_FEED + "?page=1";
  if (search) {
    url += "&q=" + encodeURI(search).replace(/#/g, "%23");
  }
  if (category) {
    url += "&c=" + category;
  }
  dispatch(ApiActions.fetchData(url, receiveFeedList));
};

export const fetchFeedbyPage = (page, search, category) => (dispatch) => {
  dispatch(startFetching());
  let url = Routes.API_ROUTE_FEED + `?page=${page}`;
  if (search) {
    url += "&q=" + encodeURI(search).replace(/#/g, "%23");
  }
  if (category) {
    url += "&c=" + category;
  }
  dispatch(ApiActions.fetchData(url, receiveFeedPageList));
};

export const fetchBookmarks = () => (dispatch) => {
  dispatch(
    ApiActions.fetchData(Routes.API_ROUTE_FEED_BOOKMARKS, receiveBookmarks)
  );
};

export const addComment =
  (postId, text, parentCommentId, image) => (dispatch) => {
    dispatch(
      ApiActions.postData(
        Routes.API_ROUTE_POSTS_COMMENT.replace("{id}", postId),
        { text: text, parentCommentId: parentCommentId, image },
        receiveFeed
      )
    );
  };

export const addBookmark = (id) => (dispatch) => {
  dispatch(
    ApiActions.postData(
      Routes.API_ROUTE_POSTS_BOOKMARK.replace("{id}", id),
      {},
      receiveFeed
    )
  );
};

export const removeBookmark = (id) => (dispatch) => {
  dispatch(
    ApiActions.deleteData(
      Routes.API_ROUTE_POSTS_BOOKMARK.replace("{id}", id),
      {},
      receiveFeed
    )
  );
};

export const removePost = (id) => async (dispatch) => {
  await dispatch(
    ApiActions.deleteData(Routes.API_ROUTE_POSTS_ID.replace("{id}", id), {})
  );
  dispatch(fetchFeed());
};

export const editPost = (id, text) => (dispatch) => {
  dispatch(
    ApiActions.putData(
      Routes.API_ROUTE_POSTS_ID.replace("{id}", id),
      { text },
      receiveFeed
    )
  );
};

export const removeComment = (postId, commentId) => (dispatch) => {
  dispatch(
    ApiActions.deleteData(
      Routes.API_ROUTE_COMMENT_ID.replace("{postId}", postId).replace(
        "{id}",
        commentId
      ),
      {},
      receiveFeed
    )
  );
};

export const likePost = (postId) => (dispatch) => {
  dispatch(
    ApiActions.postData(
      Routes.API_ROUTE_POSTS_LIKE.replace("{id}", postId),
      {},
      receiveFeed
    )
  );
};

export const dislikePost = (postId) => (dispatch) => {
  dispatch(
    ApiActions.deleteData(
      Routes.API_ROUTE_POSTS_LIKE.replace("{id}", postId),
      {},
      receiveFeed
    )
  );
};

export const likeComment = (postId, commentId) => (dispatch) => {
  dispatch(
    ApiActions.postData(
      Routes.API_ROUTE_COMMENTS_LIKE.replace("{postId}", postId).replace(
        "{id}",
        commentId
      ),
      {},
      receiveFeed
    )
  );
};

export const dislikeComment = (postId, commentId) => (dispatch) => {
  dispatch(
    ApiActions.deleteData(
      Routes.API_ROUTE_COMMENTS_LIKE.replace("{postId}", postId).replace(
        "{id}",
        commentId
      ),
      {},
      receiveFeed
    )
  );
};

export const fetchNotifications = () => (dispatch) => {
  dispatch(startFetchingNotifications());
  dispatch(
    ApiActions.fetchData(Routes.API_ROUTE_NOTIFICATIONS, receiveNotifications)
  );
};

export const resetUnreadNotifications = () => ({
  type: RESET_UNREAD_NOTIFICATIONS,
});

export const fetchSelectedPost = (id) => (dispatch) => {
  dispatch(startFetchingSelectedPost());
  dispatch(
    ApiActions.fetchData(
      Routes.API_ROUTE_POSTS_ID.replace("{id}", id),
      receiveSelectedPost
    )
  );
};

export const fetchTags = () => (dispatch) => {
  dispatch(ApiActions.fetchData(Routes.API_ROUTE_TAGS, receiveTags));
};

export const resetUserProfile = () => ({ type: RESET_USER_PROFILE });

export const fetchUserProfile = (profileHash) => (dispatch) => {
  dispatch(startFetchingUserProfile());
  dispatch(
    ApiActions.fetchData(
      Routes.API_ROUTE_FEED_USER_PROFILE.replace("{hash}", profileHash),
      receiveUserProfile
    )
  );
};

export const reportPost = (postId, reason) => (dispatch) => {
  dispatch(
    ApiActions.postData(
      Routes.API_ROUTE_REPORT_POST.replace("{postId}", postId),
      { reason },
      undefined
    )
  );
};

export const reportComment = (postId, commentId, reason) => (dispatch) => {
  dispatch(
    ApiActions.postData(
      Routes.API_ROUTE_REPORT_COMMENT.replace("{postId}", postId).replace(
        "{id}",
        commentId
      ),
      { reason },
      undefined
    )
  );
};

export const categorizePostAsSupport = (postId) => async (dispatch) => {
  await dispatch(
    ApiActions.postData(
      Routes.API_ROUTE_CATEGORIZE_POST.replace("{postId}", postId),
      { category: "support" },
      undefined
    )
  );
  dispatch(fetchFeed());
};

export const categorizePostAsDefault = (postId) => (dispatch) => {
  dispatch(
    ApiActions.postData(
      Routes.API_ROUTE_CATEGORIZE_POST.replace("{postId}", postId),
      { category: "default" },
      undefined
    )
  );

  dispatch(fetchFeed(undefined, "support"));
};

export const resetFeedForUser = () => ({ type: RESET_FEED_FOR_USER });

export const fetchFeedForUser =
  (userHash, page = 1) =>
  (dispatch) => {
    if (page === 1) {
      dispatch(resetFeedForUser());
    }
    dispatch(startFetching());
    const url = Routes.API_ROUTE_FEED + "?u=" + userHash + "&page=" + page;
    dispatch(ApiActions.fetchData(url, receiveFeedForUser));
  };

export const setFeedReturnToPostHtmlId = (htmlId) => ({
  type: SET_FEED_RETURN_TO_POST_HTML_ID,
  htmlId,
});
