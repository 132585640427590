import { css } from "styled-components/macro";
import {
  ComponentProps,
  ReactElement,
  ReactNode,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Box, Center, Column, HR, Row, Text } from "../../../Base";
import { LayoutProps } from "../../Styled/layout";
import { PaddingProps } from "../../Styled/padding";
import ScrollBox, { ScrollElement } from "../../Layout/NewScrollBox";

type TabsProps = {
  tabWidth?: LayoutProps["width"];
  pt?: PaddingProps["pt"];
  activeTabIndex?: number;
  onChangeTab?: (index: number) => void;
  children?: ReactElement<ComponentProps<typeof Tab>>[];
} & Pick<LayoutProps, "size">;

export const Tabs = ({
  tabWidth,
  activeTabIndex: controlledActiveTabIndex,
  onChangeTab,
  children,
  size,
  pt,
  ...props
}: TabsProps) => {
  const [activeTabIndex, setActiveTabIndex] = useState(
    controlledActiveTabIndex ?? 0
  );
  const tabRefs = useRef<HTMLButtonElement[]>([]);
  const [tabIndicatorOffset, setTabIndicatorOffset] = useState<number>();
  const [tabIndicatorWidth, setTabIndicatorWidth] = useState<number>();

  useEffect(() => {
    if (controlledActiveTabIndex == null || !children) return;

    if (
      controlledActiveTabIndex >= children.length ||
      controlledActiveTabIndex < 0
    ) {
      console.warn(
        `${controlledActiveTabIndex} is not a valid index (0 - ${
          children.length - 1
        })`
      );
    } else {
      setActiveTabIndex(controlledActiveTabIndex);
    }
  }, [activeTabIndex, children]);

  useLayoutEffect(() => {
    if (!tabRefs.current) return;
    setTabIndicatorOffset(
      tabRefs.current.reduce(
        (offset, tab, index) =>
          index < activeTabIndex ? offset + tab.clientWidth : offset,
        0
      )
    );
    setTabIndicatorWidth(tabRefs.current[activeTabIndex].clientWidth);
  }, [activeTabIndex, tabRefs]);

  const scrollBoxRef = useRef<ScrollElement>(null);
  useEffect(() => {
    const instance = scrollBoxRef.current?.osInstance();
    instance?.scroll(0);
  }, [activeTabIndex, scrollBoxRef]);

  return (
    <ScrollBox ref={scrollBoxRef} size={size}>
      <Column width="100%" alignItems="center">
        <Center
          pt={pt}
          width="100%"
          zIndex={100}
          position="sticky"
          top="0px"
          backgroundColor="beigeLight"
        >
          <Column width={tabWidth} height="40px" position="relative">
            <Row zIndex={10} height="100%">
              {children?.map((child, index) => (
                <Center
                  borderRadiusTopLeft="md"
                  borderRadiusTopRight="md"
                  height="100%"
                  ref={(ref: HTMLButtonElement) =>
                    (tabRefs.current[index] = ref)
                  }
                  key={index}
                  flexGrow={1}
                  flexBasis="0px"
                  px="8px"
                  as="button"
                  onClick={() => {
                    onChangeTab?.(index);
                    if (controlledActiveTabIndex == null) {
                      setActiveTabIndex(index);
                    }
                  }}
                  css={`
                    ${css(
                      ({ theme }) => css`
                        &:hover {
                          background-color: ${theme.colors.beigeMedium};
                        }
                      `
                    )}
                  `}
                >
                  <Text
                    whiteSpace="nowrap"
                    fontWeight={index === activeTabIndex ? "bold" : "normal"}
                  >
                    {child.props.label}
                  </Text>
                </Center>
              ))}
            </Row>
            <Box
              zIndex={30}
              position="absolute"
              bottom="1px"
              backgroundColor="black"
              height="2px"
              css={`
                ${css`
                  transition: all 250ms ease;
                  left: ${tabIndicatorOffset != null &&
                  `${tabIndicatorOffset}px`};
                  width: ${tabIndicatorWidth != null &&
                  `${tabIndicatorWidth}px`};
                `}
              `}
            />
            <HR
              zIndex={20}
              position="absolute"
              bottom="0px"
              left="0px"
              color="beigeDark"
            />
          </Column>
        </Center>
        <Box width={tabWidth} minHeight="0px" flexGrow={1}>
          {children?.[activeTabIndex]}
        </Box>
      </Column>
    </ScrollBox>
  );
};

type TabProps = {
  label: string;
  children?: ReactNode;
} & PaddingProps;

export const Tab = ({ label, children, ...props }: TabProps) => {
  return (
    <Box {...props} height="100%">
      {children}
    </Box>
  );
};
