import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppBarProgress from "../../Common/AppBarProgress";
import TodoList from "../../Components/TodoList";
import TodoOptions from "../../Components/TodoOptions";
import Images from "../../Core/Images";
import { saveUser } from "../../Reducers/User/Actions";
import {
  completeTodo,
  createCustomTodo,
  deleteTodo,
  fetchTodos,
  getNextTodo,
  reopenTodo,
  resetTodos,
  snoozeTodo,
} from "../../Reducers/Work/Actions";
import ResponsiveModalWrapper from "../../ScreenWrappers/ResponsiveModalWrapper";
import "./Styles.scss";
import NavigationBar from "../../Components/NavigationBar";
import { useWidth } from "../../Core/CustomHooks";
import Paywall from "../../Components/Paywall";
import Routes from "../../Core/Routes";
import { useNavigate } from "react-router-dom";

const initialWeekStart = moment().weekday(0);
const initialWeek = {
  startWeek: initialWeekStart,
  endWeek: moment(initialWeekStart).weekday(6),
};

export const TodoGroup = [
  {
    name: "Aussäen",
    identifier: "direktsaat",
    imgSrc: Images.todos.directSeeding,
  },
  {
    name: "Vorziehen",
    identifier: "vorziehen",
    imgSrc: Images.todos.propagation,
  },
  {
    name: "Abdecken",
    identifier: "abdecken",
    imgSrc: Images.todos.cover,
  },
  {
    name: "Setzlinge Kaufen",
    identifier: "setzlingekaufen",
    imgSrc: Images.todos.buySeedling,
  },
  {
    name: "Einpflanzen",
    identifier: "einpflanzen",
    imgSrc: Images.todos.plant,
  },
  {
    name: "Pikieren",
    identifier: "pikieren",
    imgSrc: Images.todos.prick,
  },
  {
    name: "Ernten",
    identifier: "ernten",
    imgSrc: Images.todos.harvest,
  },
  {
    name: "Pflücken",
    identifier: "pflücken",
    imgSrc: Images.todos.pluck,
  },
  {
    name: "Düngen",
    identifier: "düngen",
    imgSrc: Images.todos.fertilise,
  },
  {
    name: "Zurückschneiden",
    identifier: "zurückschneiden",
    imgSrc: Images.todos.nurture,
  },
  {
    name: "Ausgeizen",
    identifier: "ausgeizen",
    imgSrc: Images.todos.pinchOff,
  },
  {
    name: "Abhärten",
    identifier: "abhärten",
    imgSrc: Images.todos.harden,
  },
  {
    name: "Anhäufeln",
    identifier: "anhäufeln",
    imgSrc: Images.todos.earthUp,
  },
  {
    name: "Mulchen",
    identifier: "mulchen",
    imgSrc: Images.todos.fertilise,
  },
  {
    name: "Erziehen",
    identifier: "erziehen",
    imgSrc: Images.todos.nurture,
  },
  {
    name: "Anbinden",
    identifier: "anbinden",
    imgSrc: Images.todos.nurture,
  },
  {
    name: "Stützen",
    identifier: "stützen",
    imgSrc: Images.todos.nurture,
  },
  {
    name: "Gießen",
    identifier: "gießen",
    imgSrc: Images.todos.water,
  },
  {
    name: "Hygiene",
    identifier: "hygiene",
    imgSrc: Images.todos.hygiene,
  },
  {
    name: "Fraßschutz",
    identifier: "fraßschutz",
    imgSrc: Images.todos.hygiene,
  },
  { name: "Jäten", identifier: "jäten", imgSrc: Images.todos.weed },
  {
    name: "Ausdünnen",
    identifier: "ausdünnen",
    imgSrc: Images.todos.weed,
  },
  {
    name: "Hacken",
    identifier: "hacken",
    imgSrc: Images.todos.weed,
  },

  {
    name: "custom",
    identifier: "custom",
    imgSrc: null,
  },
];

const initialNextWeekStart = moment().weekday(7);
const initialNextWeek = {
  startWeek: initialNextWeekStart,
  endWeek: moment(initialNextWeekStart).weekday(6),
};
const initialSecondNextWeekStart = moment(initialNextWeek.startWeek).weekday(7);
const initialSecondNextWeek = {
  startWeek: initialSecondNextWeekStart,
  endWeek: moment(initialSecondNextWeekStart).weekday(6),
};

const WorkScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const width = useWidth();

  const { isPlusAccount, isProAccount } = useSelector((state) => state.Account);
  const { todos, todosLoaded } = useSelector((state) => state.Work);
  const { user } = useSelector((state) => state.User);

  const [showOptions, setShowOptions] = useState(false);
  const [isPremiumAccount, setIsPremiumAccount] = useState(
    isPlusAccount || isProAccount
  );
  const [selectedWeek, setSelectedWeek] = useState(initialWeek);
  const [selectedAdjacantWeek, setSelectedAdjacantWeek] =
    useState(initialNextWeek);
  const [selectedNextAdjacantWeek, setSelectedNextAdjacantWeek] = useState(
    initialSecondNextWeek
  );
  const [countWeek, setCountWeek] = useState(0);

  useEffect(() => {
    setIsPremiumAccount(isPlusAccount || isProAccount);
  }, [isPlusAccount, isProAccount]);

  const renderLastWeek = () => {
    const currentWeek = selectedWeek;
    const startWeek = moment(currentWeek.startWeek).add(-7, "days");
    const lastWeek = { startWeek, endWeek: moment(startWeek).day(7) };

    const week = selectedAdjacantWeek;
    const startAdjacantWeek = moment(week.startWeek).add(-7, "days");
    const activeAdjacantWeek = {
      startWeek: startAdjacantWeek,
      endWeek: moment(startAdjacantWeek).day(7),
    };

    const nextWeek = selectedNextAdjacantWeek;
    const startNextAdjacantWeek = moment(nextWeek.startWeek).add(-7, "days");
    const activeNextAdjacantWeek = {
      startWeek: startNextAdjacantWeek,
      endWeek: moment(startNextAdjacantWeek).day(7),
    };

    setSelectedWeek(lastWeek);
    setSelectedAdjacantWeek(activeAdjacantWeek);
    setSelectedNextAdjacantWeek(activeNextAdjacantWeek);
    setCountWeek(countWeek - 1);
  };

  const renderNextWeek = () => {
    const currentWeek = selectedWeek;
    const startWeek = moment(currentWeek.startWeek).add(7, "days");
    const nextCurrentWeek = { startWeek, endWeek: moment(startWeek).day(7) };

    const week = selectedAdjacantWeek;
    const startAdjacantWeek = moment(week.startWeek).add(7, "days");
    const activeAdjacantWeek = {
      startWeek: startAdjacantWeek,
      endWeek: moment(startAdjacantWeek).day(7),
    };
    const nextWeek = selectedNextAdjacantWeek;
    const startNextAdjacantWeek = moment(nextWeek.startWeek).add(7, "days");
    const activeNextAdjacantWeek = {
      startWeek: startNextAdjacantWeek,
      endWeek: moment(startNextAdjacantWeek).day(7),
    };

    setSelectedWeek(nextCurrentWeek);
    setSelectedAdjacantWeek(activeAdjacantWeek);
    setSelectedNextAdjacantWeek(activeNextAdjacantWeek);
    setCountWeek(countWeek + 1);
  };

  useEffect(() => {
    if (!todosLoaded) {
      dispatch(fetchTodos());
    }
  }, []);

  const onCheckTodo = (id, checked) => {
    if (checked) {
      dispatch(completeTodo(id));
    } else {
      dispatch(reopenTodo(id));
    }
  };

  const saveOptions = (options) => {
    if (user.settings.workView !== options.workView) {
      dispatch(resetTodos());
    }
    const newUser = {
      ...user,
      settings: { ...user.settings, ...options },
    };
    dispatch(saveUser(newUser));
    setShowOptions(false);
    if (options.workView === "cards") {
      dispatch(getNextTodo());
    } else if (options.workView === "list") {
      dispatch(fetchTodos());
    }
  };

  const startWeek = selectedWeek.startWeek.week();
  const prev = startWeek - 1;
  const next = startWeek + 1;

  let todoGroupsMap = [];
  const todosSelectedWeek = todos.filter((t) => {
    return (
      moment(moment(t.dateDue.date).format("MM/DD/YYYY")).isSameOrAfter(
        selectedWeek.startWeek.format("MM/DD/YYYY")
      ) &&
      moment(moment(t.dateDue.date).format("MM/DD/YYYY")).isSameOrBefore(
        selectedWeek.endWeek.format("MM/DD/YYYY")
      )
    );
  });
  TodoGroup.forEach((group) => {
    const res = todosSelectedWeek.filter((todo) => {
      if (todo.type && todo.title) {
        return todo.type.toLowerCase() === group.identifier.toLowerCase();
      }
      return null;
    });
    group.todos = res;
    res.length > 0 && todoGroupsMap.push(group);
  });

  const contentClass = "container-inner";

  return (
    <div className="content-wrapper full-width">
      <Paywall
        open={!isPremiumAccount}
        context={"account"}
        onDismiss={() => navigate(Routes.gardening.season)}
      />
      <div
        className={`${contentClass} scroll height-flex work ${
          width === "xs" || width === "sm" ? "mobile" : ""
        }
      `}
      >
        <div className="work-container-header">
          <div className="todoWorkWeek">
            <div className="weeks-tab">
              <div
                className={
                  prev === moment().week() ? "week current-week" : "week"
                }
                onClick={renderLastWeek}
              >
                <div className="lastWeek">
                  <i className="zwicon-chevron-left" />
                </div>
                <span>
                  {prev === moment().week() ? "Diese Woche" : "KW" + prev}{" "}
                </span>
              </div>
              <div
                className={
                  startWeek === moment().week()
                    ? "week selected current-week"
                    : "week selected"
                }
              >
                {" "}
                <span>
                  {startWeek === moment().week()
                    ? "Diese Woche"
                    : "KW" + startWeek}
                </span>
              </div>
              <div
                className={
                  next === moment().week() ? "week current-week" : "week"
                }
                onClick={renderNextWeek}
              >
                <div className="nextWeek">
                  <i className="zwicon-chevron-right" />
                </div>
                <span>
                  {next === moment().week() ? "Diese Woche" : "KW" + next}{" "}
                </span>
              </div>
            </div>
          </div>
          <div className="icon-with-label" onClick={() => setShowOptions(true)}>
            <div className=" icon-wrapper">
              <i className="zwicon-cog" style={{ margin: 0 }} />
            </div>
            Optionen
          </div>
          <div className="ackerhelden-banner ackerhelden-desktop">
            <img
              className="ackerhelden-logo"
              src={Images.icons.ackerheldenLogo}
              alt=""
            />
            <p className="ackerhelden-banner-content">
              Wir orientieren uns an den Prinzipien des ökologischen
              <br /> Landbaus mit unserem Partner Ackerhelden.
            </p>
          </div>
        </div>
        <TodoList
          todos={todos}
          onCheckTodo={onCheckTodo}
          startWeek={selectedWeek.startWeek}
          endWeek={selectedWeek.endWeek}
          adjacantStartWeek={selectedAdjacantWeek.startWeek}
          adjacantEndWeek={selectedAdjacantWeek.endWeek}
          nextAdjacantStartWeek={selectedNextAdjacantWeek.startWeek}
          nextAdjacantEndWeek={selectedNextAdjacantWeek.endWeek}
          countWeek={countWeek}
          snoozeTodo={(id) => dispatch(snoozeTodo(id))}
          deleteTodo={(id) => dispatch(deleteTodo(id))}
          addCustomTodo={(todo) => dispatch(createCustomTodo(todo))}
        />
        {showOptions && (
          <ResponsiveModalWrapper
            open={true}
            showCloseButton={true}
            modalClassName="details-dialog"
            onClose={() => setShowOptions(false)}
          >
            <div className="todo-option-details">
              <TodoOptions
                options={user ? user.settings : {}}
                onClose={() => setShowOptions(false)}
                onSave={saveOptions}
              />
            </div>
          </ResponsiveModalWrapper>
        )}
      </div>
    </div>
  );
};

export default WorkScreen;
