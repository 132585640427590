import React from "react";
export default ({ width = 32, height = 32 }) => {
  return (
    <svg
      width={`${width}`}
      height={`${height}`}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2551 3.71724C15.2551 3.32112 15.5762 3 15.9723 3C16.3684 3 16.6895 3.32112 16.6895 3.71724V7.60611L19.0402 5.29613C19.3227 5.01849 19.7768 5.02245 20.0545 5.30498C20.3321 5.58751 20.3282 6.04163 20.0456 6.31927L16.6895 9.61729V21.5295L20.0456 24.8276C20.3282 25.1052 20.3321 25.5593 20.0545 25.8419C19.7768 26.1244 19.3227 26.1283 19.0402 25.8507L16.6895 23.5407V27.3861C16.6895 27.7822 16.3684 28.1033 15.9723 28.1033C15.5762 28.1033 15.2551 27.7822 15.2551 27.3861V23.6413L13.0068 25.8507C12.7243 26.1283 12.2701 26.1244 11.9925 25.8419C11.7149 25.5593 11.7188 25.1052 12.0014 24.8276L15.2551 21.6302V9.51668L12.0014 6.31927C11.7188 6.04163 11.7149 5.58751 11.9925 5.30498C12.2701 5.02245 12.7243 5.01849 13.0068 5.29613L15.2551 7.5055V3.71724Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.8537 9.05254C26.1968 8.85448 26.6354 8.97202 26.8335 9.31507C27.0316 9.65811 26.914 10.0968 26.571 10.2948L23.2027 12.2395L26.3792 13.1205C26.7609 13.2263 26.9845 13.6216 26.8787 14.0033C26.7728 14.385 26.3775 14.6086 25.9958 14.5028L21.4609 13.2451L11.1455 19.2007L9.96718 23.757C9.868 24.1405 9.47671 24.371 9.09321 24.2718C8.70971 24.1726 8.47922 23.7813 8.5784 23.3978L9.40377 20.2063L6.07316 22.1293C5.73011 22.3273 5.29146 22.2098 5.0934 21.8667C4.89534 21.5237 5.01287 21.085 5.35592 20.887L8.60013 19.0139L5.56075 18.171C5.17904 18.0652 4.95541 17.6699 5.06127 17.2882C5.16713 16.9065 5.56238 16.6829 5.9441 16.7887L10.3419 18.0083L20.8308 11.9525L21.9731 7.53561C22.0723 7.15211 22.4636 6.92162 22.8471 7.0208C23.2306 7.11998 23.4611 7.51127 23.3619 7.89478L22.5726 10.9469L25.8537 9.05254Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.6438 20.8872C26.9868 21.0853 27.1044 21.5239 26.9063 21.867C26.7083 22.21 26.2696 22.3275 25.9266 22.1295L22.5591 20.1853L23.3841 23.3753C23.4833 23.7588 23.2528 24.1501 22.8693 24.2493C22.4858 24.3484 22.0945 24.118 21.9953 23.7344L20.8174 19.1797L10.499 13.2224L5.96625 14.4794C5.58454 14.5853 5.18929 14.3616 5.08343 13.9799C4.97757 13.5982 5.2012 13.203 5.58291 13.0971L8.75725 12.2168L5.42874 10.2951C5.08569 10.097 4.96815 9.65835 5.16621 9.3153C5.36427 8.97225 5.80292 8.85471 6.14597 9.05277L9.38974 10.9256L8.60014 7.87241C8.50096 7.48891 8.73145 7.09762 9.11495 6.99844C9.49845 6.89926 9.88974 7.12974 9.98892 7.51325L11.1315 11.9311L21.619 17.9862L26.0175 16.7664C26.3992 16.6605 26.7945 16.8841 26.9003 17.2658C27.0062 17.6475 26.7826 18.0428 26.4008 18.1487L23.3608 18.9917L26.6438 20.8872Z"
        fill="currentColor"
      />
    </svg>
  );
};
