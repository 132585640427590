import IconProps from "../../Types/IconProps";
import theme from "../../Theme";

export default ({ size = "md", color = "currentColor" }: IconProps) => (
  <svg
    width={
      (size === "xl" && "48px") ||
      (size === "lg" && "40px") ||
      (size === "md" && "32px") ||
      (size === "sm" && "24px") ||
      (size === "xs" && "16px") ||
      undefined
    }
    height={
      (size === "xl" && "48px") ||
      (size === "lg" && "40px") ||
      (size === "md" && "32px") ||
      (size === "sm" && "24px") ||
      (size === "xs" && "16px") ||
      undefined
    }
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.5 12.4706C19.9142 12.4706 20.25 12.1348 20.25 11.7206C20.25 11.3064 19.9142 10.9706 19.5 10.9706V12.4706ZM4.5 11.7206L3.95748 11.2027C3.68084 11.4925 3.68084 11.9486 3.95748 12.2384L4.5 11.7206ZM11.219 5.76786C11.505 5.46823 11.494 4.99349 11.1943 4.70748C10.8947 4.42148 10.42 4.43252 10.134 4.73214L11.219 5.76786ZM10.134 18.709C10.42 19.0087 10.8947 19.0197 11.1943 18.7337C11.494 18.4477 11.505 17.9729 11.219 17.6733L10.134 18.709ZM19.5 10.9706H4.5V12.4706H19.5V10.9706ZM5.04252 12.2384L11.219 5.76786L10.134 4.73214L3.95748 11.2027L5.04252 12.2384ZM3.95748 12.2384L10.134 18.709L11.219 17.6733L5.04252 11.2027L3.95748 12.2384Z"
      fill={theme.colors[color]}
    />
  </svg>
);
