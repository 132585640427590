import {} from "styled-components/macro";

import { ReactNode, useEffect, useState } from "react";
import styled from "styled-components";
import Box from "../../Layout/Box";
import { fileToImageDataUrl } from "../../../Helpers/FileHelper";
import { SpaceProps } from "../../Styled/space";
import { PositionProps } from "../../Styled/position";
import { FlexItemProps } from "../../Styled/flexItem";
import { BorderProps } from "../../Styled/border";
import { LayoutProps } from "../../Styled/layout";
import { ColorProps } from "../../Styled/color";
import useControlledState from "../../../Helpers/useControlledState";
import { isEqual } from "lodash";

export const ALLOWED_FILE_TYPES = ["image/png", "image/jpg", "image/jpeg"];
export const IMAGE_TYPE = "jpeg";
export const MAX_IMAGE_SIZE = 1600;

type ImageInputAreaProps = {
  multiple?: boolean;
  maxImageCount?: number;
  images?: string[];
  onChangeImages?: (images: string[]) => void;
  children?: ReactNode;
} & SpaceProps &
  PositionProps &
  FlexItemProps &
  BorderProps &
  ColorProps &
  LayoutProps;

const ImageInputArea = ({
  multiple = true,
  maxImageCount = multiple ? 1 : undefined,
  images: ImagesExtern,
  onChangeImages,
  children,
  ...props
}: ImageInputAreaProps) => {
  const [images, setImages] = useControlledState(ImagesExtern, (images) =>
    onChangeImages?.(images ?? [])
  );

  const updateImages = async (files: File[]) => {
    if (!multiple) {
      const image = await fileToImageDataUrl(
        files[0],
        IMAGE_TYPE,
        MAX_IMAGE_SIZE
      );
      setImages([image]);
      return;
    }

    const imagesTemp = images ? [...images] : [];
    for (const file of files) {
      if (!maxImageCount || imagesTemp.length < maxImageCount) {
        const image = await fileToImageDataUrl(
          file,
          IMAGE_TYPE,
          MAX_IMAGE_SIZE
        );
        imagesTemp.push(image);
      }
    }
    setImages(imagesTemp);
  };

  return (
    <ImageInputBox
      onDragOver={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onDrop={async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const files = Array.from(e.dataTransfer.files);
        updateImages(files);
      }}
      {...props}
    >
      <ImageInput
        multiple={multiple}
        onChange={async (e) => {
          e.preventDefault();
          e.stopPropagation();
          if (e.target.files) {
            const files = Array.from(e.target.files);
            updateImages(files);
          }
        }}
      />
      {children}
    </ImageInputBox>
  );
};

export default ImageInputArea;

const ImageInputBox = styled(Box).attrs(() => ({
  as: "label",
}))`
  cursor: pointer;
`;

const ImageInput = styled.input.attrs<{ multiple: boolean }>(
  ({ multiple = true }) => ({
    type: "file",
    accept: ALLOWED_FILE_TYPES.reduce((str, value) => `${str},${value}`),
    multiple: multiple,
  })
)`
  display: none;
`;
