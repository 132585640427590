import { useState } from "react";
import { css } from "styled-components/macro";
import CloseSmall from "../../../../Resources/SVG/CloseSmall";
import Image from "../../../Display/Image";
import Box from "../../../Layout/Box";
import IconButton from "../../IconButton";

type ImagePreviewItemProps = {
  width: string;
  image: string;
  index: number;
  draggable?: boolean;
  onDelete: () => void;
  onDragStart?: (index: number) => void;
  onDragEnd?: (index: number) => void;
  onDragEnter?: (index: number) => void;
};

const ImagePreviewItem = ({
  width,
  image,
  index,
  onDelete,
  draggable,
  onDragStart,
  onDragEnd,
  onDragEnter,
}: ImagePreviewItemProps) => {
  const [isDragged, setIsDragged] = useState(false);

  return (
    <Box
      position="relative"
      draggable={draggable}
      onDragStart={(e) => {
        setIsDragged(true);
        onDragStart?.(index);
      }}
      onDragEnd={(e) => {
        setIsDragged(false);
        onDragEnd?.(index);
      }}
      onDragEnter={(e) => {
        e.preventDefault();
        onDragEnter?.(index);
      }}
      css={
        draggable
          ? (css`
              cursor: grab;
              &:active {
                cursor: grabbing;
              }
              opacity: ${isDragged ? 0.5 : 1};
            ` as any)
          : undefined
      }
    >
      <Image
        css={`
          width: ${width};
        `}
        borderRadius="md"
        aspectRatio="1/1"
        objectFit="cover"
        src={image}
      />
      <IconButton
        position="absolute"
        top="-12px"
        right="-12px"
        size="xs"
        color="white"
        backgroundColor="greyMidi"
        icon={<CloseSmall size="sm" />}
        onClick={onDelete}
      />
    </Box>
  );
};

export default ImagePreviewItem;
