import React, { ReactNode, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Flex, Row } from "../../index";
import space, { SpaceProps } from "../../Styled/space";
import layout, { LayoutProps } from "../../Styled/layout";
import { FlexContainerProps } from "../../Styled/flexContainer";
import ButtonEffect from "../../Display/ButtonEffect";

const Wrapper = styled(Flex)`
  cursor: pointer;
  height: auto;
  width: 100%;
  box-sizing: border-box;
  ${space}
  ${layout}
`;

const Item = styled(Row)`
  cursor: pointer;
  & * {
    cursor: pointer;
  }
  gap: 0;
  align-items: center;
  box-sizing: border-box;
`;

type RadioButtonLabelProps = {
  reverse?: boolean;
};

const RadioButtonLabel = styled.label<RadioButtonLabelProps>`
  width: 100%;
  display: inline-flex;
  flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "row")};
  justify-content: ${({ reverse }) =>
    reverse ? "space-between" : "flex-start"};
  align-items: center;
  gap: 8px;
`;

const RadioButton = styled.input(
  ({ theme }) => css`
    appearance: none;
    width: 20px;
    height: 20px;
    border: ${theme.borders["2pxSolid"]} ${theme.colors.greenApp};
    border-radius: ${theme.radii.full};
    background-clip: content-box;
    margin: 2.25px;
    flex-shrink: 0;
    &:checked {
      background-color: ${theme.colors.greenApp};
      padding: 0.15em;
    }
  `
);

export const TypedRadioButton = <T extends unknown>({
  value,
  checked,
  onChange,
}: {
  value: T;
  checked: boolean;
  onChange: (value: T) => void;
}) => {
  return (
    <Row
      position={"relative"}
      alignItems={"center"}
      justifyContent={"center"}
      overflow={"hidden"}
      borderRadius={"full"}
      size={"24px"}
      minWidth={"min-content"}
    >
      <ButtonEffect />
      <RadioButton
        type="radio"
        value={value as any}
        checked={checked}
        onChange={() => {}}
        onClick={() => {
          onChange?.(value);
        }}
      />
    </Row>
  );
};

type OptionType<T> = {
  value: T;
  label:
    | string
    | ReactNode
    | ((
        key: string | number,
        value: T,
        checked: boolean,
        onChange: (value: T) => void,
        radioButtonIsLeft?: boolean
      ) => void);
};

type RadioSelectProps<T> = {
  options: OptionType<T>[];
  onChange: (value: T) => void;
  value: T;
  radioButtonIsLeft?: boolean;
  className?: string;
  testId?: string;
} & SpaceProps &
  LayoutProps &
  Pick<FlexContainerProps, "flexDirection" | "alignItems">;

const RadioSelect = <T extends unknown>({
  options,
  onChange,
  value,
  className,
  testId,
  radioButtonIsLeft = true,
  flexDirection = "column",
  ...props
}: RadioSelectProps<T>) => {
  const [selected, setSelected] = useState<T | undefined>();
  useEffect(() => {
    if (selected !== value) {
      setSelected(value);
    }
  }, [value]);

  const handleSelectChange = (value: any) => {
    setSelected(value);
    onChange(value);
  };

  return (
    <Wrapper
      data-testid={testId}
      flexDirection={flexDirection ?? "column"}
      alignItems={
        props.alignItems
          ? props.alignItems
          : flexDirection === "row"
          ? "center"
          : flexDirection === "column"
          ? "stretch"
          : undefined
      }
      gap={"12px"}
      {...props}
    >
      {options.map((option, idx) => {
        return typeof option.label === "function" ? (
          option.label(
            idx,
            option.value,
            selected === option.value,
            handleSelectChange
          )
        ) : (
          <Item
            justifyContent={radioButtonIsLeft ? "flex-start" : "space-between"}
            key={idx}
            onClick={() => handleSelectChange(option.value)}
          >
            <RadioButtonLabel reverse={!radioButtonIsLeft}>
              <TypedRadioButton<T>
                value={option.value}
                checked={selected === option.value}
                onChange={() => {}}
              />
              {option.label}
            </RadioButtonLabel>
          </Item>
        );
      })}
    </Wrapper>
  );
};
export default RadioSelect;
