import { POST_DATA, RECEIVE_DATA, REQUEST_DATA } from "./Actions";

export const initialState = {
  sources: {},
  isFetching: false,
  hasAuthError: false,
  isConnected: true,
};

function getSourceState(action) {
  const ignoreFetchingState = !!action.options?.ignoreFetchingState;
  switch (action.type) {
    case REQUEST_DATA:
      return {
        isFetching: true,
        isConnected: true,
        source: action.source,
      };

    case POST_DATA:
      return {
        isFetching: !ignoreFetchingState,
        isConnected: true,
        source: action.source,
      };

    case RECEIVE_DATA:
      return {
        isFetching: false,
        isConnected: action.isConnected,
        data: action.data,
        source: action.source,
      };
    default:
      return null;
  }
}

export default function apiReducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST_DATA:
    case POST_DATA:
    case RECEIVE_DATA:
      const sources = {
        ...state.sources,
        [action.source]: getSourceState(action),
      };
      const sourcesValues = Object.keys(sources).map((key) => sources[key]);
      const isFetching = sourcesValues.reduce(
        (accumulator, { isFetching }) => accumulator || isFetching,
        false
      );
      const isConnected = sourcesValues.reduce(
        (accumulator, { isConnected }) => accumulator && isConnected,
        true
      );

      return {
        ...state,
        sources,
        isFetching,
        isConnected,
      };

    default:
      return state;
  }
}
