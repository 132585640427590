import * as actions from "./Actions";
import Theme from "../../Core/Theme";
export const initialState = {
  bodyBackgroundColor: Theme.beige,
  isWebsite: false,
};

export default function ApplicationReducer(state = initialState, action = {}) {
  switch (action.type) {
    case actions.SET_BODY_BACKGROUND_COLOR: {
      state = {
        ...state,
        bodyBackgroundColor: action.color,
      };
      return state;
    }

    case actions.SET_IS_WEBSITE: {
      state = {
        ...state,
        isWebsite: true,
      };
    }

    default:
      return state;
  }
}
