import * as Sentry from "@sentry/react";
import { cloneDeep } from "lodash";
import { mergeObject } from "../../Helpers/UtilityHelpers";
import * as actions from "./Actions";
import { getAccountType } from "./Actions";

export const initialState = {
  token: undefined,
  hasLocation: false,
  isLoggedIn: false,
  registered: false,
  registerError: false,
  registerWithPatch: false,
  registerContext: undefined,
  optInMissing: false,
  locations: undefined,
  locationSuggestions: undefined,
  webpushAllowed: false,
  paypalApprovalUrl: undefined,
  subscriptionConfirmed: false,
  showOnboarding: false,
  onboardingFinished: false,
  appVersion: undefined,
  appSdkVersion: undefined,
  successfulLoginReset: null,
  invoices: [],
  // unregistered (only deviceId)
  // -> free-trial (14 days trial, with email)
  // -> free (trial over, with email)
  // -> paid
  accountType: "free",
  pushNotificationsAsked: false,
  onboardingStep: 0,
  nickNameExists: false,
  paypalPlanId: undefined,
  isModerator: false,
  isSuperModerator: false,
  isPaidAccount: false,
  emailExists: undefined,
  checkEmailError: undefined,
  socialUser: undefined,
  user: undefined,
  userEdit: undefined,
  followingUsers: [],
  followers: [],
  reloadFollowingUsers: false,
  userProfile: undefined,
};

const isStatusOk = (action) => 200 <= action.status && action.status < 300;

const configureSentry = (user) => {
  if (user) {
    Sentry.configureScope((scope) => {
      scope.setUser({
        id: user.id,
      });
    });
  }
};

const updateStateWithUser = (state, user) => {
  console.log("updateStateWithUser", user);

  let onboardingFinished = false;
  let pushNotificationsAsked = false;
  let isModerator = false;
  let isSuperModerator = false;
  if (user && user.settings) {
    if (
      user.settings.onboardingData &&
      user.settings.onboardingData.onboardingFinished
    ) {
      onboardingFinished = true;
    }
    if (
      user.settings.notifications &&
      user.settings.notifications.dailyTodo !== undefined
    ) {
      pushNotificationsAsked = true;
    }
    if (user.settings.isModerator) {
      isModerator = true;
    }
    if (user.settings.isSuperModerator) {
      isSuperModerator = true;
    }
  }

  const accountType = getAccountType(user);

  state = {
    ...state,
    accountType,
    user: {
      ...state.user,
      ...user,
    },
    unSavedUser: {
      ...state.user,
      ...user,
      modified: false,
    },
    isLoggedIn: true,
    onboardingFinished,
    pushNotificationsAsked,
    askForSurvey: user && user.askForSurvey,
    isModerator,
    isSuperModerator,
  };
  return state;
};

export default function UserReducer(state = initialState, action = {}) {
  switch (action.type) {
    case actions.SET_APP_VERSION_INFO: {
      state = {
        ...state,
        appVersion: action.appVersion,
        appSdkVersion: action.appSdkVersion,
      };
      return state;
    }

    case actions.SET_PUSH_NOTIFICATIONS_ASKED: {
      state = {
        ...state,
        pushNotificationsAsked: true,
      };
      return state;
    }

    case actions.START_LOADING: {
      return { ...state, isLoading: true };
    }

    case actions.RESET_CHECK_EMAIL: {
      state = {
        ...state,
        emailExists: undefined,
      };
      return state;
    }

    case actions.RECEIVE_CHECK_EMAIL_ERROR: {
      state = {
        ...state,
        checkEmailError: true,
      };
    }

    case actions.RECEIVE_CHECK_EMAIL: {
      state = {
        ...state,
        emailExists: action.data.exists,
        checkEmailError: false,
      };
    }

    case actions.SET_ONBOARDING_STEP: {
      state = {
        ...state,
        onboardingStep: action.step,
      };
      return state;
    }

    case actions.RECEIVE_NICKNAME_AVAILABILITY: {
      state = {
        ...state,
        nickNameExists: !action.data.free,
      };
      return state;
    }

    case actions.RESET_NICKNAME_AVAILABILITY: {
      state = {
        ...state,
        nickNameExists: false,
      };
      return state;
    }

    case actions.RECEIVE_RECOVER_LOGIN:
      return {
        ...state,
        successfulLoginReset: isStatusOk(action),
      };

    case actions.SET_SOCIAL_USER: {
      return {
        ...state,
        socialUser: action.socialUser,
      };
    }

    case actions.BEGIN_NEW_RESET_PROCESS:
      return {
        ...state,
        successfulLoginReset: null,
      };

    case actions.SET_REGISTER_CONTEXT:
      return {
        ...state,
        registerContext: action.context,
      };

    case actions.SET_REGISTER_WITH_PATCH:
      state = {
        ...state,
        registerWithPatch: true,
      };
      return state;

    case actions.RESET_TOKEN: {
      state = {
        ...state,
        token: undefined,
        user: undefined,
        isLoggedIn: false,
      };
      return state;
    }

    case actions.GO_TO_LOGOUT: {
      state = {
        ...state,
        goToLogout: true,
      };

      return state;
    }

    case actions.RESET_GO_TO_LOGOUT: {
      state = {
        ...state,
        goToLogout: false,
      };

      return state;
    }

    case actions.RECEIVE_REGISTER: {
      if (action.status === 200) {
        configureSentry(action.data.user);

        state = {
          ...state,
          user: action.data.user,
          unSavedUser: action.data.user,
          accountType: getAccountType(action.data.user),
          registered: true,
          registerError: false,
          optInMissing: true,
          token: action.data.token,
          isLoggedIn: true,
        };
      } else {
        state = {
          ...state,
          registerError: action.data.error ? action.data.error : "unknown",
        };
      }

      return state;
    }

    case actions.RESET_REGISTER: {
      return { ...state, registered: false, registerError: false };
    }

    case actions.RECEIVE_REGISTER_DEVICE: {
      if (action.status === 200) {
        configureSentry(action.data.user);

        state = updateStateWithUser(state, action.data.user);
        state = {
          ...state,
          token: action.data.token,
        };
      }
      return state;
    }

    case actions.RECEIVE_OPT_IN_USER: {
      if (action.status !== 200) {
        return {
          ...state,
          optInUser: null,
          optInToken: null,
          user: null,
          token: null,
          isLoggedIn: false,
        };
      }

      configureSentry(action.data.user);

      return {
        ...state,
        optInUser: action.data.user,
        optInToken: action.data.token,
        optInForEmailChange: action.data.emailChanged,
        user: action.data.user,
        accountType: getAccountType(action.data.user),
        token: action.data.token,
        isLoggedIn: true,
      };
    }

    case actions.RECEIVE_LOGIN: {
      if (action.status === 401) {
        let error = "";
        if (action.data.error === "User not active") {
          error = "not_active";
        } else {
          error = "wrong_credentials";
        }

        state = {
          ...state,
          loginError: error,
        };
      } else {
        const user = action.data.user;
        const hasLocation =
          user && user.location !== undefined && user.location !== null;

        configureSentry(user);
        state = updateStateWithUser(state, user);

        state = {
          ...state,
          user,
          accountType: user && getAccountType(user),
          unSavedUser: user,
          token: action.data.token,
          isLoggedIn: true,
          hasLocation: hasLocation,
        };
      }

      return state;
    }

    case actions.RESET_SOCIAL_LOGIN: {
      return {
        ...state,
        loginError: false,
        loginErrorMessage: undefined,
        isLoading: false,
      };
    }

    case actions.RECEIVE_USER: {
      const user = action.data;
      const hasLocation =
        user && user.location !== undefined && user.location !== null;

      configureSentry(user);

      if (action.data.errorCode === 100) {
        return {
          ...state,
          saveError: action.data.errorCode,
        };
      } else {
        const emailChanged =
          user && state.unSavedUser && state.unSavedUser.email !== user.email;
        state = updateStateWithUser(state, user);
        return {
          ...state,
          hasLocation: hasLocation,
          emailChanged,
        };
      }
    }

    case actions.EDIT_USER: {
      console.log("edit user");
      return {
        ...state,
        unSavedUser: {
          ...state.unSavedUser,
          ...action.data,
          modified: true,
        },
        saveError: undefined,
      };
    }

    case actions.RESET_USER: {
      return {
        ...state,
        unSavedUser: state.user,
      };
    }

    case actions.RECEIVE_LOCATIONS: {
      state = {
        ...state,
        locations: action.data.locations,
      };

      return state;
    }

    case actions.SELECT_LOCATION: {
      state = {
        ...state,
        selectedLocation: action.location,
        hasLocation: true,
      };

      return state;
    }

    case actions.RECEIVE_SUBSCRIPTION: {
      state = {
        ...state,
        paypalApprovalUrl: action.data.approvalUrl,
      };
      return state;
    }

    case actions.RECEIVE_SUBSCRIPTION_CONFIRM: {
      if (isStatusOk(action)) {
        const user = action.data.user;
        const unSavedUser = { ...user, modified: false };
        if (action.data.payerInfo) {
          const payerInfo = action.data.payerInfo;
          if (!unSavedUser.firstname) {
            unSavedUser.firstname = payerInfo.firstName;
          }
          if (!unSavedUser.lastname) {
            unSavedUser.lastname = payerInfo.lastName;
          }
          if (unSavedUser.email.indexOf("@") === -1) {
            unSavedUser.email = payerInfo.email;
          }
        }

        return {
          ...state,
          subscriptionConfirmed: true,
          user,
          unSavedUser,
        };
      } else {
        return {
          ...state,
          subscriptionConfirmed: false,
        };
      }
    }

    case actions.RECEIVE_INVOICES: {
      state = {
        ...state,
        invoices: action.data.invoices,
      };
      return state;
    }

    case actions.SET_SHOW_ONBOARDING: {
      state = {
        ...state,
        showOnboarding: action.showOnboarding,
      };
      return state;
    }

    case actions.RECEIVE_UNREGISTER: {
      return {
        ...state,
        token: null,
        isLoggedIn: false,
        user: null,
      };
    }
    case actions.SET_IMAGE_COPYRIGHT_SETTING: {
      const user = state.user;
      let settings = user.settings;
      if (!settings) {
        settings = {};
      }

      settings[action.setting] = action.value;

      return {
        ...state,
        user: {
          ...user,
          settings,
        },
      };
    }

    case actions.SET_NOTIFICATIONS_SETTING: {
      const user = state.user;
      let settings = user.settings;
      if (!settings) {
        settings = {};
      }
      if (!settings.notifications) {
        settings.notifications = {};
      }
      settings.notifications[action.setting] = action.value;

      return {
        ...state,
        user: {
          ...user,
          settings,
        },
      };
    }

    case actions.RECEIVE_PAYPAL_PLAN_ID: {
      return { ...state, paypalPlanId: action.data.planId };
    }

    case actions.SET_PRECULTIVATION_PATCH_POSITION: {
      const user = state.user;

      return {
        ...state,
        user: {
          ...user,
          settings: {
            ...user.settings,
            precultivationPatchPosition: action.position,
          },
        },
      };
    }

    case actions.RECEIVE_FOLLOWING: {
      return {
        ...state,
        followingUsers: action.data,
        isLoading: false,
        reloadFollowingUsers: false,
      };
    }

    case actions.RECEIVE_FOLLOWERS: {
      return { ...state, followers: action.data, isLoading: false };
    }

    case actions.RECEIVE_FOLLOW_USER: {
      const newFollowingUsers = [
        ...state.followingUsers,
        { publicProfileHash: action.hash },
      ];
      return {
        ...state,
        followingUsers: newFollowingUsers,
        reloadFollowingUsers: true,
      };
    }

    case actions.RECEIVE_UNFOLLOW_USER: {
      let newFollowingUsers = [...state.followingUsers];
      const index = newFollowingUsers.findIndex(
        (u) => u.publicProfileHash === action.hash
      );
      newFollowingUsers.splice(index, 1);

      return {
        ...state,
        followingUsers: newFollowingUsers,
      };
    }

    case actions.RECEIVE_OWN_USER_PROFILE: {
      if (action.data !== undefined) {
        state = {
          ...state,
          userProfile: action.data,
          isLoading: false,
        };
      }
      return state;
    }

    case actions.SET_USER_EDIT: {
      return {
        ...state,
        userEdit: action.data,
      };
    }

    case actions.UPDATE_USER_EDIT: {
      const userEdit = { ...state.userEdit, ...action.data };
      return {
        ...state,
        userEdit,
      };
    }

    default:
      return state;
  }
}
