import React from "react";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { withStyles } from "@material-ui/core/styles";
import Theme from "../../Core/Theme";

const theme = createTheme({
  palette: {
    primary: { main: Theme.mainGreen },
  },
  typography: { useNextVariants: true },
});

const useStyles = (theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    "&label.Mui-focused": {
      color: Theme.mainGreen,
    },
    "&.Mui-focused": {
      backgroundColor: Theme.white,
    },
    "&.MuiInput-underline:after": {
      borderBottomColor: Theme.mainGreen,
    },
    // "&$focused": {
    //   borderBottomColor: Theme.mainGreen,
    // },
    "&.Mui-error.MuiInput-underline:after": {
      borderBottomColor: Theme.lightRed,
    },
    "&:not(.Mui-disabled):hover": {
      backgroundColor: Theme.white,
    },
  },
  input: {
    // the right padding is set to zero to avoid a too big gap between adornment and input text
    // we need this for our size inputs
    padding: "10px 12px",
    fontFamily: "Karla",
    textAlign: (props) => (props.center ? "center" : ""),
    paddingRight: (props) => (props.hasIcon ? "36px" : "12px"),
  },
  multiline: {
    padding: "6px 0",
  },
});

const StyledTextField = ({
  classes,
  inputRef,
  width,
  autoFocusDesktop,
  hasIcon,
  disableUnderline,
  inputStyle,
  min,
  maxLength,
  endAdornmentProp,
  onBlur,
  ...otherProps
}) => (
  <MuiThemeProvider theme={theme}>
    <TextField
      className="textfield-100"
      style={{ width: "100%" }}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        classes,
        disableUnderline,
        endAdornment: endAdornmentProp,
      }}
      inputProps={{
        min,
        maxLength,
        ...(inputStyle ? { style: inputStyle } : {}),
      }}
      autoFocus={autoFocusDesktop && isWidthUp("sm", width, false)}
      {...otherProps}
      inputRef={inputRef}
      onBlur={onBlur}
    />
  </MuiThemeProvider>
);

export default withStyles(useStyles)(withWidth()(StyledTextField));
