import {
  RECEIVE_LOCATIONS,
  RECEIVE_MAPS_IMAGE,
  RECEIVE_PLACE,
  RESET_LOCATIONS,
  SET_GEO_OUTLINE,
  START_LOAD_MAPS_IMAGE,
} from "./Actions";

export const initialState = {
  locations: [],
  place: undefined,
  mapsImage: undefined,
  gardenOutline: undefined,
};

export default function LocationReducer(state = initialState, action = {}) {
  switch (action.type) {
    case RECEIVE_LOCATIONS: {
      return {
        ...state,
        locations: action.locations,
      };
    }

    case RECEIVE_PLACE: {
      return {
        ...state,
        place: action.place,
      };
    }

    case RESET_LOCATIONS: {
      return {
        ...state,
        locations: [],
      };
    }

    case SET_GEO_OUTLINE: {
      return {
        ...state,
        geoOutline: action.outline,
      };
    }

    case START_LOAD_MAPS_IMAGE: {
      return {
        ...state,
        mapsImageLoading: true,
      };
    }

    case RECEIVE_MAPS_IMAGE: {
      return {
        ...state,
        mapsImageLoading: false,
        mapsImage: action.data.imageData,
        gardenOutline: action.data.outline,
      };
    }

    default:
      return state;
  }
}
