import React, { useLayoutEffect, useState } from "react";
import PasswordResetScreenWrapper from "../../ScreenWrappers/PasswordReset";

import { Trans, useTranslation } from "react-i18next";
import { Button, Link, PasswordInput, Text } from "../../Base";
import Routes from "../../Core/Routes";
import CircularProgress from "../../Base/Progress/CircularProgress";

interface PasswordResetNewPasswordProps {
  isFetching: boolean;
  password: string;
  passwordRepeat: string;
  setPassword: (value: string) => void;
  setPasswordRepeat: (value: string) => void;
  passwordError: boolean;
  onClickReset: () => void;
  setPasswordTouched: (value: boolean) => void;
  setPasswordRepeatTouched: (value: boolean) => void;
  passwordErrorMessage?: string;
  newPasswordRequested: boolean;
  successfulLoginReset: boolean | null;
}

const PasswordResetNewPassword = ({
  isFetching,
  password,
  passwordRepeat,
  setPassword,
  setPasswordRepeat,
  passwordError,
  onClickReset,
  setPasswordTouched,
  setPasswordRepeatTouched,
  passwordErrorMessage,
  newPasswordRequested,
  successfulLoginReset,
}: PasswordResetNewPasswordProps) => {
  const { t } = useTranslation("passwordReset");
  const [progress, setProgress] = useState(0);
  useLayoutEffect(() => {
    let loadingTimeout: any;
    if (isFetching) {
      loadingTimeout = setTimeout(() => {
        setProgress((progress % 100) + 2);
      }, 30);
    }
    return () => {
      clearTimeout(loadingTimeout);
    };
  }, [isFetching, progress]);

  return (
    <PasswordResetScreenWrapper>
      <div>
        {isFetching ? (
          <CircularProgress
            size={40}
            trackWidth={4}
            indicatorWidth={4}
            label={""}
            progress={progress}
          />
        ) : newPasswordRequested ? (
          <>
            {successfulLoginReset === true && (
              <Text as={"p"}>{t("passwordIsReset")}</Text>
            )}
            {successfulLoginReset === false && (
              <Text>
                <Trans t={t} i18nKey={"retry"}>
                  <Link to={Routes.passwordReset.root}>Link</Link>
                </Trans>
              </Text>
            )}
          </>
        ) : (
          <>
            {passwordErrorMessage && (
              <Text as={"p"}>{passwordErrorMessage}</Text>
            )}
            <Text as={"p"} my={"8px"}>
              {t("enterPassword")}
            </Text>
            <PasswordInput
              label={t("common:placeholder.password")}
              error={passwordError}
              value={password}
              onChange={(value) => {
                if (value !== password) {
                  setPassword(value);
                  setPasswordTouched(true);
                }
              }}
              mb={"20px"}
            />
            <PasswordInput
              label={t("repeatPassword")}
              error={passwordError}
              value={passwordRepeat}
              onChange={(value) => {
                if (value !== password) {
                  setPasswordRepeat(value);
                  setPasswordRepeatTouched(true);
                }
              }}
              mb={"20px"}
            />
            <Button label={t("savePassword")} onClick={onClickReset} />
          </>
        )}
      </div>
    </PasswordResetScreenWrapper>
  );
};
export default PasswordResetNewPassword;
