import React from "react";
import Images from "../../Core/Images";
import { Column, Image, Row, Text } from "../../Base";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  ClaimsList,
  DownloadAppHeader,
} from "../Library/CropDetails/StartGardening";

const DownloadAppContainer = styled(Column).attrs({
  mx: "32px",
  mt: "60px",
  mb: "32px",
})`
  height: calc(100% - 32px);
`;

const ScaledImage = styled(Image)`
  object-fit: fill;
  max-width: 100%;
  flex-grow: 1;
`;

const Anchor = styled.a`
  height: 54px;
`;

export const DownloadApp = () => {
  const { t } = useTranslation("downloadApp");
  const claims = [0, 1, 2].map((i) => t(`claims.${i}`));
  return (
    <DownloadAppContainer>
      <Image src={Images.images.frydOnDevices} alt={t("heading")} />
      <DownloadAppHeader textAlign={"center"} text={t("heading")} pb={"16px"} />

      <ClaimsList>
        {claims.map((claim, idx) => (
          <li key={idx}>
            <Text as={"span"} fontSize={"lg"}>
              {claim}
            </Text>
          </li>
        ))}
      </ClaimsList>
      <Row gap={"16px"} pt={"16px"}>
        <Anchor
          href={
            "https://play.google.com/store/apps/details?id=org.alphabeet.app"
          }
        >
          <ScaledImage
            src={Images.images.buttonGooglePlay}
            alt={"loadFromPlayStore"}
          />
        </Anchor>
        <Anchor
          href={"https://apps.apple.com/de/app/alphabeet/id1492138640?l=de"}
        >
          <ScaledImage
            src={Images.images.buttonAppStore}
            alt={t("loadFromAppStore")}
          />
        </Anchor>
      </Row>
    </DownloadAppContainer>
  );
};
