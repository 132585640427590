import styled from "styled-components";
import Flex from "../Flex";

const ScrollingFlexWithScrollBarHiddenOnTouchDevices = styled(Flex).attrs({
  overflow: "auto",
})`
  @media (hover: hover) {
    padding-bottom: 8px;
  }
  @media (hover: none) {
    /* Hide scrollbar */
    ::-webkit-scrollbar {
      display: none; /* Chrome, Safari and Opera */
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
`;

export default ScrollingFlexWithScrollBarHiddenOnTouchDevices;
