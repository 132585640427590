import React from "react";
import { withWidth } from "@material-ui/core";
import "../index.scss";
class BrowserNotSupported extends React.Component {
  render() {
    return (
      <div
        className={`browserNotSupported-page ${
          this.props.width === "xs" ? "mobile" : ""
        }`}
      >
        <b>
          Fryd funktioniert leider nicht mit deinem Browser. Bitte verwende eine
          aktuelle Version von
          <a href="https://www.mozilla.org/en-US/firefox/new/">
            {" "}
            Mozilla Firefox
          </a>
          , <a href="https://www.google.com/chrome/"> Google Chrome</a>,
          <a href="https://support.apple.com/downloads/safari">
            {" "}
            Apple Safari{" "}
          </a>
          oder die neueste Version von{" "}
          <a href="https://www.microsoft.com/en-us/edge/">Microsoft Edge</a>.
        </b>
      </div>
    );
  }
}

export default withWidth()(BrowserNotSupported);
