import React from "react";
import { useTranslation } from "react-i18next";
import Images from "../../Core/Images";
import styled, { css } from "styled-components";
import { Box, Column, Heading, Image, Row } from "../../Base";

const Root = styled(Box)(
  ({ theme }) => css`
    background-color: ${theme.colors["beigeLight"]};
    @media (min-width: ${theme.breakpoints.sm}) {
      background: ${theme.colors["greenApp"]} url(/img/login-background.jpg)
        no-repeat;
      background-size: cover;
    }
    height: 100%;
    overflow-y: auto;
  `
);

const Wrapper = styled(Row)`
  max-width: 1440px;
`;

const Content = styled(Box)(
  ({ theme }) => css`
    @media (min-width: ${theme.breakpoints.md}) {
      width: 408px;
    }
    transform: translate(-50%, -50%);
  `
);

const PasswordResetScreenWrapper = ({ children }) => {
  const { t } = useTranslation("passwordReset");
  return (
    <Root>
      <Wrapper justifyContent={"center"} m={"auto"}>
        <Content
          backgroundColor={"beigeLight"}
          borderRadius={"md"}
          p={"16px"}
          pt={"32px"}
          pb={"32px"}
          position={"absolute"}
          top={"50%"}
          left={"50%"}
        >
          <Column flexGrow={1} flexShrink={1} flexBasis={"0px"} px={"16px"}>
            <Image
              src={Images.images.frydLogoWithClaim}
              alt={""}
              style={{ height: 218, width: 312, justifySelf: "center" }}
            />
            <Heading as={"h1"} fontSize={"2xl"} fontFamily={"vollkorn"}>
              {t("resetPassword")}
            </Heading>
            <Box>{children}</Box>
          </Column>
        </Content>
      </Wrapper>
    </Root>
  );
};

export default PasswordResetScreenWrapper;
