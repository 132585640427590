import { cloneDeep, isArray, mergeWith } from "lodash";

export const mergeObject = <TObject, TSource>(
  object: TObject,
  source1: TSource,
  source2?: TSource
): TObject & TSource => {
  return mergeWith(object, source1, source2, (value, srcValue) => {
    if (isArray(value)) {
      if (isArray(srcValue)) {
        return srcValue;
      } else {
        return value;
      }
    }
    return;
  });
};
