import { css } from "styled-components";
import theme from "../../Theme";
import { createResponisiveThemeProp, ResponsiveValue } from "./Core";

export type MarginProps = {
  m?: ResponsiveValue<keyof typeof theme.spaces>;
  my?: ResponsiveValue<keyof typeof theme.spaces>;
  mx?: ResponsiveValue<keyof typeof theme.spaces>;
  mt?: ResponsiveValue<keyof typeof theme.spaces>;
  mb?: ResponsiveValue<keyof typeof theme.spaces>;
  ml?: ResponsiveValue<keyof typeof theme.spaces>;
  mr?: ResponsiveValue<keyof typeof theme.spaces>;
};

export const marginDefaultProps: MarginProps = {
  m: "0px",
  mx: "0px",
  my: "0px",
  mt: "0px",
  mr: "0px",
  mb: "0px",
  ml: "0px",
};

const margin = css<MarginProps>(
  ({ m, mx, my, mt, mr, mb, ml }) => css`
    ${createResponisiveThemeProp({
      prop: m,
      property: ["margin-top", "margin-bottom", "margin-left", "margin-right"],
      scale: "spaces",
    })}
    ${createResponisiveThemeProp({
      prop: my,
      property: ["margin-top", "margin-bottom"],
      scale: "spaces",
    })}
    ${createResponisiveThemeProp({
      prop: mx,
      property: ["margin-left", "margin-right"],
      scale: "spaces",
    })}
    ${createResponisiveThemeProp({
      prop: mt,
      property: "margin-top",
      scale: "spaces",
    })}
    ${createResponisiveThemeProp({
      prop: mb,
      property: "margin-bottom",
      scale: "spaces",
    })}
    ${createResponisiveThemeProp({
      prop: ml,
      property: "margin-left",
      scale: "spaces",
    })}
    ${createResponisiveThemeProp({
      prop: mr,
      property: "margin-right",
      scale: "spaces",
    })}
  `
);

export default margin;
