import styled from "styled-components";
import flexContainer, { FlexContainerProps } from "../../Styled/flexContainer";
import Box from "../Box";

type CenterProps = {} & Omit<
  FlexContainerProps,
  "alignItems" | "justifyContent"
>;

const Center = styled(Box)<CenterProps>`
  ${flexContainer};
  align-items: center;
  justify-content: center;
`;

export default Center;
