import * as ApiActions from "../Api/Actions";
import * as DiscoverActions from "../Discover/Actions";
import Routes from "../../Core/Routes";
import { processCropActionsForSaving } from "../../Components/CropDetailsEditDialog/CropActionsEditor/cropActionsHelpers";

export const RECEIVE_CROPS = "RECEIVE_CROPS";
export const RECEIVE_CROP = "RECEIVE_CROP";
export const RECEIVE_DELETE_CROP = "RECEIVE_DELETE_CROP";
export const RECEIVE_PLANS = "RECEIVE_PLANS";
export const CROP_SELECT_CROP = "CROP_SELECT_CROP";
export const CROP_UNSELECT_CROP_AND_VARIETY = "CROP_UNSELECT_CROP_AND_VARIETY";
export const CROP_SELECT_VARIETY = "CROP_SELECT_VARIETY";
export const CROP_SELECT_CROP_BY_IDENTIFIER = "CROP_SELECT_CROP_BY_IDENTIFIER";
export const RECEIVE_PESTS = "RECEIVE_PESTS";
export const RECEIVE_DISEASES = "RECEIVE_DISEASES";
export const LIKE_CROP = "LIKE_CROP";
export const RECEIVE_SAVE_CROP = "RECEIVE_SAVE_CROP";
export const RESET_SAVE_CROP = "RESET_SAVE_CROP";
export const RECEIVE_CROP_CATEGORIES = "RECEIVE_CROP_CATEGORIES";
export const RECEIVE_SUGGESTED_CROPS = "RECEIVE_SUGGESTED_CROPS";
export const TOGGLE_FAVORITE = "TOGGLE_FAVORITE";
export const SET_FAVORITE = "CROP_SET_FAVORITE";
export const CHANGE_EDIT_MODE = "CHANGE_EDIT_MODE";
export const ADD_VARIETY_CHANGE = "ADD_VARIETY_CHANGE";
export const DISCARD_VARIETY_CHANGES = "DISCARD_VARIETY_CHANGES";
export const RECEIVE_BEST_RATED_CROPS = "RECEIVE_BEST_RATED_CROPS";
export const RECEIVE_NEW_COMMUNITY_CROPS = "RECEIVE_NEW_COMMUNITY_CROPS";
export const SET_CROP_FILTER = "SET_CROP_FILTER";
export const SET_VARIETY_FILTER = "SET_VARIETY_FILTER";
export const SET_VARIETY_SORT = "SET_VARIETY_SORT";
export const SET_CROP_SORT = "SET_CROP_SORT";
export const RECEIVE_RATE_CROP = "RECEIVE_RATE_CROP";
export const RESET_SORT_AND_FILTERS = "RESET_SORT_AND_FILTERS";
export const RECEIVE_OPEN_CHANGES = "RECEIVE_OPEN_CHANGES";
export const START_FETCHING = "START_FETCHING";
export const START_FETCHING_CROP = "START_FETCHING_CROP";
export const RECEIVE_CHANGES_FOR_CROP = "RECEIVE_CHANGES_FOR_CROP";
export const ACCEPT_EDIT_LOCALLY = "ACCEPT_EDIT_LOCALLY";
export const RECEIVE_ACCEPT_EDIT_RESPONSE = "RECEIVE_ACCEPT_EDIT_RESPONSE";
export const REJECT_EDIT_LOCALLY = "REJECT_EDIT_LOCALLY";
export const RECEIVE_REJECT_EDIT_RESPONSE = "RECEIVE_REJECT_EDIT_RESPONSE";
export const RECEIVE_UPDATED_CROP = "RECEIVE_UPDATED_CROP";
export const RECEIVE_CREATED_CROP = "RECEIVE_CREATED_CROP";
export const RECEIVE_OPEN_APPROVALS = "RECEIVE_OPEN_APPROVALS";
export const RECEIVE_ACCEPT_APPROVAL_RESPONSE =
  "RECEIVE_ACCEPT_APPROVAL_RESPONSE";
export const RECEIVE_REJECT_VARIETY_RESPONSE =
  "RECEIVE_REJECT_VARIETY_RESPONSE";
export const RECEIVE_APPLIED_CHANGES = "RECEIVE_APPLIED_CHANGES";
export const RECEIVE_CROP_USAGE = "RECEIVE_CROP_USAGE";
export const RECEIVE_CROP_LOCK = "RECEIVE_CROP_LOCK";
export const RESET_CROP_LOCK = "RESET_CROP_LOCK";
export const SET_CROPS_FETCHING = "SET_CROPS_FETCHING";
export const RECEIVE_PLANT_FAMILIES = "RECEIVE_PLANT_FAMILIES";
export const RECEIVE_PROPERTIES = "RECEIVE_PROPERTIES";
export const RECEIVE_PROPERTIES_FOR_CROP = "RECEIVE_PROPERTIES_FOR_CROP";
export const RECEIVE_CROP_ACTIONS = "RECEIVE_CROP_ACTIONS";
export const SET_CROP_ACTIONS_FETCHED = "SET_CROP_ACTIONS_FETCHED";
export const START_CROP_ACTIONS_FETCHING = "START_CROP_ACTIONS_FETCHING";
export const RECEIVE_CROP_ACTION_UPSERT = "RECEIVE_CROP_ACTION_UPSERT";
export const RECEIVE_CROP_ACTION_DELETE = "RECEIVE_CROP_ACTION_DELETE";
export const RECEIVE_CROP_CONTENT = "RECEIVE_CROP_CONTENT";
export const SET_CROP_CONTENT_FETCHING = "SET_CROP_CONTENT_FETCHING";
export const SET_CROP_CONTENT_FETCHED = "SET_CROP_CONTENT_FETCHED";

export const loadDiscoverPageData = () => async (dispatch) => {
  await dispatch(loadCrops());
  dispatch(ApiActions.fetchData("/content", DiscoverActions.receiveContent));
  dispatch(loadNewCommunityCrops());
  dispatch(loadBestRatedCrops());
};

const receiveBestRatedCrops = (data, status) => ({
  type: RECEIVE_BEST_RATED_CROPS,
  data,
  status,
});

export const startFetching = () => ({
  type: START_FETCHING,
});

export const startFetchingCrop = (id) => ({
  type: START_FETCHING_CROP,
  cropId: id,
});

export const receiveOpenChanges = (data) => ({
  type: RECEIVE_OPEN_CHANGES,
  data,
});

export const fetchOpenChanges = () => (dispatch) => {
  dispatch(ApiActions.fetchData("/crops/changesets", receiveOpenChanges));
};

export const receiveChangesForCrop = (data) => ({
  type: RECEIVE_CHANGES_FOR_CROP,
  data,
});

export const fetchChangesForCrop = (cropId, applied) => (dispatch) => {
  dispatch(startFetchingCrop(cropId));
  let url;
  if (applied) {
    url = `/crops/${cropId}/edits?applied=true`;
  } else {
    url = `/crops/${cropId}/edits`;
  }
  dispatch(ApiActions.fetchData(url, receiveChangesForCrop));
};

export const acceptEditLocally = ({ editId }) => ({
  type: ACCEPT_EDIT_LOCALLY,
  editId,
});

export const receiveAcceptEditResponse = (cropId, editId) => (data) => ({
  type: RECEIVE_ACCEPT_EDIT_RESPONSE,
  data,
  cropId,
  editId,
});

export const acceptEdit =
  ({ cropId, editId, changeset }) =>
  async (dispatch) => {
    dispatch(acceptEditLocally({ editId }));
    await dispatch(
      ApiActions.postData(
        `/crops/${cropId}/edits/${editId}/apply`,
        changeset,
        receiveAcceptEditResponse(cropId, editId)
      )
    );
    dispatch(ApiActions.fetchData(`/crops/${cropId}`, receiveUpdatedCrop));
  };

export const receiveAcceptApprovalResponse = (cropId, editId) => (data) => ({
  type: RECEIVE_ACCEPT_APPROVAL_RESPONSE,
  data,
  cropId,
  editId,
});

export const approveVariety =
  ({ varietyId, editId, changeset }) =>
  async (dispatch) => {
    await dispatch(
      ApiActions.postData(
        `/crops/${varietyId}/edits/${editId}/apply`,
        changeset,
        receiveAcceptApprovalResponse(varietyId, editId)
      )
    );
    dispatch(fetchOpenApprovals());
  };

export const receiveRejectVarietyResponse = (cropId, editId) => (data) => ({
  type: RECEIVE_REJECT_VARIETY_RESPONSE,
  data,
  cropId,
  editId,
});

export const rejectVariety =
  ({ cropId, editId, feedback }) =>
  async (dispatch) => {
    await dispatch(
      ApiActions.postData(
        `/crops/${cropId}/edits/${editId}/decline`,
        { feedback },
        receiveRejectVarietyResponse(cropId, editId)
      )
    );
    dispatch(fetchOpenApprovals());
  };

export const receiveUpdatedCrop = (data, status) => (dispatch, getState) => {
  const isSuperModerator = getState().User.isSuperModerator;
  dispatch({
    type: RECEIVE_UPDATED_CROP,
    data: data,
    status: status,
    isSuperModerator: isSuperModerator,
  });
};

export const rejectEditLocally = ({ editId }) => ({
  type: REJECT_EDIT_LOCALLY,
  editId,
});

export const receiveRejectEditResponse = (cropId, editId) => (data) => ({
  type: RECEIVE_REJECT_EDIT_RESPONSE,
  data,
  cropId,
  editId,
});

export const rejectEdit =
  ({ cropId, editId }) =>
  (dispatch) => {
    dispatch(rejectEditLocally({ editId }));
    dispatch(
      ApiActions.postData(
        `/crops/${cropId}/edits/${editId}/decline`,
        {},
        receiveRejectEditResponse(cropId, editId)
      )
    );
  };

export const loadBestRatedCrops = () => (dispatch) =>
  dispatch(ApiActions.fetchData("/crops/bestrated", receiveBestRatedCrops));

const receiveNewCommunityCrops = (data, status) => ({
  type: RECEIVE_NEW_COMMUNITY_CROPS,
  data,
  status,
});

export const loadNewCommunityCrops = () => (dispatch) =>
  dispatch(ApiActions.fetchData("/crops/new", receiveNewCommunityCrops));

export const receiveCrops = (data, status) => (dispatch, getState) => {
  const isSuperModerator = getState().User.isSuperModerator;
  dispatch({
    type: RECEIVE_CROPS,
    data: data,
    status: status,
    isSuperModerator: isSuperModerator,
  });
};

let _cropsLoadPromise = undefined;
export const loadCrops = () => async (dispatch, getState) => {
  const { cropsFetching } = getState().CropData;
  if (!cropsFetching) {
    dispatch({ type: SET_CROPS_FETCHING, status: true });
    _cropsLoadPromise = dispatch(ApiActions.fetchData("/crops", receiveCrops));
  }
  return _cropsLoadPromise;
};

export const receiveCrop = (data, status) => (dispatch, getState) => {
  const isSuperModerator = getState().User.isSuperModerator;
  dispatch({
    type: RECEIVE_CROP,
    data: data,
    status: status,
    isSuperModerator: isSuperModerator,
  });
};

export const receiveAndSelectCrop = (data, status) => (dispatch) => {
  dispatch({
    type: RECEIVE_CROP,
    data: data,
    status: status,
    select: true,
  });
};

export const loadCrop = (cropId) => (dispatch) => {
  dispatch(startFetchingCrop(cropId));
  dispatch(
    ApiActions.fetchData(
      "/crops/" + cropId + "/varieties",
      receiveAndSelectCrop
    )
  );
};

export const receivePlans = (data, status) => ({
  type: RECEIVE_PLANS,
  data: data,
  status: status,
});

export const selectVariety = (variety) => ({
  type: CROP_SELECT_VARIETY,
  variety: variety,
});

export const toggleFavorite = (crop) => (dispatch) => {
  if (crop.isFavorite) {
    dispatch(
      ApiActions.postData(
        "/crops/" + crop.id + "/dislike",
        {},
        receiveToggleFavorite(crop)
      )
    );
  } else {
    dispatch(
      ApiActions.postData(
        "/crops/" + crop.id + "/like",
        {},
        receiveToggleFavorite(crop)
      )
    );
  }
};

export const receiveToggleFavorite = (crop) => (data, status) => (dispatch) => {
  if (status === 200 && !!data.success) {
    dispatch({
      type: SET_FAVORITE,
      cropId: crop.id,
      isFavorite: !crop.isFavorite,
    });
  }
};

export const setFavoritesList = (cropIds) => (dispatch, getState) => {
  const crops = getState().CropData.crops;
  const currentFavorites = crops
    .filter((c) => c.isFavorite)
    .map((c) => {
      if (c.parentCrop) {
        return c.parentCrop.id;
      } else if (!c.parentCrop) {
        return c.id;
      }
    });

  // TODO: replace api calls with single api call, if api is changed and can handle this
  cropIds.forEach((id) => {
    if (currentFavorites.indexOf(id) === -1) {
      dispatch(
        ApiActions.postData(
          Routes.API_ROUTE_CROPS_LIKE.replace("{cropId}", id),
          {},
          receiveCrop
        )
      );
      dispatch({
        type: SET_FAVORITE,
        cropId: id,
        isFavorite: true,
      });
    }
  });
  currentFavorites.forEach((id) => {
    if (cropIds.indexOf(id) === -1) {
      console.log("remove favorite " + id);
      dispatch(
        ApiActions.postData(
          Routes.API_ROUTE_CROPS_DISLIKE.replace("{cropId}", id),
          {},
          receiveCrop
        )
      );
      dispatch({
        type: SET_FAVORITE,
        cropId: id,
        isFavorite: false,
      });
    }
  });
};

export const selectCrop = (crop) => ({
  type: CROP_SELECT_CROP,
  crop: crop,
});

export const unselectCropAndVariety = () => ({
  type: CROP_UNSELECT_CROP_AND_VARIETY,
});

export const selectCropByIdentifier = (identifier, varietyIdentifier) => ({
  type: CROP_SELECT_CROP_BY_IDENTIFIER,
  identifier: identifier,
  varietyIdentifier: varietyIdentifier,
});

export const loadPests = () => ApiActions.fetchData("/pests", receivePests);

export const receivePests = (data, status) => ({
  type: RECEIVE_PESTS,
  data: data,
  status: status,
});

export const loadDiseases = () =>
  ApiActions.fetchData("/diseases", receiveDiseases);

export const receiveDiseases = (data, status) => ({
  type: RECEIVE_DISEASES,
  data: data,
  status: status,
});

export const saveCrop = (data) => (dispatch, getState) => {
  dispatch(resetSaveCrop());
  const { selectedCrop, selectedVariety, cropActions } = getState().CropData;
  const cropActionPromises = [];
  if ((data.parentCropId && selectedVariety) || selectedCrop) {
    const oldCropActions = cropActions || [];
    const newCropActions = data.cropaction || [];
    delete data.cropaction;
    const [upsertCropActions, deleteCropActions] = processCropActionsForSaving(
      (data.parentCropId && selectedVariety) || selectedCrop,
      oldCropActions,
      newCropActions
    );
    upsertCropActions.forEach((ca) => {
      cropActionPromises.push(dispatch(upsertCropAction(ca)));
    });
    deleteCropActions.forEach((ca) => {
      cropActionPromises.push(dispatch(deleteCropAction(ca.id)));
    });
  }
  return Promise.allSettled(cropActionPromises).then(() => {
    return dispatch(ApiActions.postData("/crops", data, receiveSaveCrop));
  });
};

export const createCrop = (data) => (dispatch) => {
  dispatch(resetSaveCrop());
  dispatch(ApiActions.postData("/crops", data, receiveCreatedCrop));
};

export const receiveCreatedCrop = (data, status) => (dispatch, getState) => {
  const isSuperModerator = getState().User.isSuperModerator;
  dispatch({
    type: RECEIVE_CREATED_CROP,
    data: data,
    status: status,
    isSuperModerator: isSuperModerator,
  });
};

export const resetSaveCrop = () => ({
  type: RESET_SAVE_CROP,
});

export const receiveSaveCrop = (data, status) => (dispatch, getState) => {
  const isSuperModerator = getState().User.isSuperModerator;
  dispatch({
    type: RECEIVE_SAVE_CROP,
    data: data,
    status: status,
    isSuperModerator: isSuperModerator,
  });
};

export const loadCropcategories = () => (dispatch) =>
  dispatch(ApiActions.fetchData("/cropcategories", receiveCropcategories));

export const receiveCropcategories = (data, status) => ({
  type: RECEIVE_CROP_CATEGORIES,
  data: data,
  status: status,
});

export const loadPlantFamilies = () => (dispatch) =>
  dispatch(
    ApiActions.fetchData(Routes.API_ROUTE_PLANT_FAMILIES, receivePlantFamilies)
  );

export const receivePlantFamilies = (data, status) => ({
  type: RECEIVE_PLANT_FAMILIES,
  data: data,
  status: status,
});

export const loadProperties = () => (dispatch) => {
  dispatch(
    ApiActions.fetchData(Routes.API_ROUTE_PROPERTIES, receiveProperties)
  );
};

export const receiveProperties = (data, status) => ({
  type: RECEIVE_PROPERTIES,
  data: data,
  status: status,
});

export const loadPropertiesForCrop = (crop) => (dispatch) => {
  if (!crop) {
    return;
  }
  dispatch(selectCrop(crop));
  dispatch(
    ApiActions.fetchData(
      Routes.API_ROUTE_PROPERTIES_FOR_CROP.replace("{cropId}", crop.id),
      receivePropertiesForCrop
    )
  );
};

export const receivePropertiesForCrop = (data, status) => ({
  type: RECEIVE_PROPERTIES_FOR_CROP,
  data,
  status,
});

export const receiveDeleteCrop = (data) => ({
  type: RECEIVE_DELETE_CROP,
  cropId: data.id,
});

export const deleteCrop = (crop) => (dispatch) =>
  dispatch(
    ApiActions.postData("/crops/" + crop.id, {}, receiveDeleteCrop, "DELETE")
  );

export const changeEditMode = (editMode) => ({
  type: CHANGE_EDIT_MODE,
  isInEditMode: editMode,
});

export const addVarietyChange = (variety, change) => ({
  type: ADD_VARIETY_CHANGE,
  variety,
  change,
});

export const discardVarietyChanges = () => ({
  type: DISCARD_VARIETY_CHANGES,
});

export const setCropSort = (option) => ({
  type: SET_CROP_SORT,
  sort: option,
});
export const setVarietySort = (option) => ({
  type: SET_VARIETY_SORT,
  sort: option,
});

export const setCropFilter = ({ filterType, activeFilters }) => ({
  type: SET_CROP_FILTER,
  filterType,
  activeFilters,
});

export const setVarietyFilter = ({ filterType, activeFilters }) => ({
  type: SET_VARIETY_FILTER,
  filterType,
  activeFilters,
});

export const resetSortAndFilters = () => ({
  type: RESET_SORT_AND_FILTERS,
});

export const receiveRateCrop = (data, status) => ({
  type: RECEIVE_RATE_CROP,
  data: data,
  status: status,
});

export const rateCrop = (crop, rating) => (dispatch) =>
  dispatch(
    ApiActions.postData(
      "/crops/" + crop.id + "/rate",
      { rating },
      receiveRateCrop
    )
  );

export const receiveOpenApprovals = (data) => ({
  type: RECEIVE_OPEN_APPROVALS,
  data,
});

export const fetchOpenApprovals = () => async (dispatch) => {
  dispatch(ApiActions.fetchData("/crops/approvals", receiveOpenApprovals));
};

export const receiveAppliedChanges = (data) => ({
  type: RECEIVE_APPLIED_CHANGES,
  data,
});

export const fetchAppliedChanges = () => (dispatch) => {
  dispatch(
    ApiActions.fetchData(
      "/crops/changesets?applied=true",
      receiveAppliedChanges
    )
  );
};

export const fetchCropUsage = (cropId) => (dispatch) => {
  dispatch(
    ApiActions.fetchData("/crops/" + cropId + "/usage", receiveCropUsage)
  );
};

export const receiveCropUsage = (data) => ({
  type: RECEIVE_CROP_USAGE,
  users: data.users,
});

export const lockCrop = (cropId, newCropId) => (dispatch) => {
  const url = newCropId
    ? "/crops/" + cropId + "/lock?newCropId=" + newCropId
    : "/crops/" + cropId + "/lock";
  dispatch(resetCropLock());
  dispatch(ApiActions.postData(url, [], receiveCropLock));
};

export const receiveCropLock = () => ({
  type: RECEIVE_CROP_LOCK,
});

export const resetCropLock = () => ({
  type: RESET_CROP_LOCK,
});

export const receiveLoadCropActions = (data) => ({
  type: RECEIVE_CROP_ACTIONS,
  cropactions: data.cropactions,
});

export const loadCropActions = (cropId) => (dispatch, getState) => {
  const parsedCropId = parseInt(cropId, 10);
  if (isNaN(parsedCropId)) {
    console.warn(
      `loadCropActions: cropId must be a number, got ${typeof cropId}`
    );
  } else {
    if (!getState().CropData.cropActionsFetching) {
      dispatch({ type: START_CROP_ACTIONS_FETCHING });
      return dispatch(
        ApiActions.fetchData(
          Routes.API_ROUTE_CROP_ACTIONS_LOAD.replace("{cropId}", cropId),
          receiveLoadCropActions
        )
      );
    }
  }
};

export const setCropActionsFetched = (cropActionsFetched) => ({
  type: SET_CROP_ACTIONS_FETCHED,
  cropActionsFetched,
});

export const receiveUpsertCropAction = (data) => ({
  type: RECEIVE_CROP_ACTION_UPSERT,
  success: data.success,
});

export const upsertCropAction = (cropActionData) => (dispatch) => {
  return dispatch(
    ApiActions.postData(
      Routes.API_ROUTE_CROP_ACTION_UPSERT,
      cropActionData,
      receiveUpsertCropAction
    )
  );
};

export const receiveDeleteCropAction = (data) => ({
  type: RECEIVE_CROP_ACTION_DELETE,
  success: data.success,
});

export const deleteCropAction = (cropActionId) => (dispatch) => {
  return dispatch(
    ApiActions.deleteData(
      Routes.API_ROUTE_CROP_ACTION_DELETE.replace(
        "{cropactionId}",
        cropActionId
      ),
      null,
      receiveDeleteCropAction
    )
  );
};

export const receiveCropContent = (data, status) => ({
  type: RECEIVE_CROP_CONTENT,
  content: data.content,
  status: status,
});

export const setCropContentFetching = (cropContentFetching) => ({
  type: SET_CROP_CONTENT_FETCHING,
  cropContentFetching,
});

export const setCropContentFetched = (cropContentFetched) => ({
  type: SET_CROP_CONTENT_FETCHED,
  cropContentFetched,
});

export const loadCropContent = (cropId) => (dispatch, getState) => {
  const state = getState();
  if (state.User.isLoggedIn && !state.CropData.cropContentFetching) {
    dispatch(setCropContentFetching(true));
    dispatch(
      ApiActions.fetchData(
        Routes.API_ROUTE_CONTENT_FOR_CROP.replace("{cropId}", cropId),
        receiveCropContent
      )
    );
  }
};
