import theme from "../../Theme";
import IconProps from "../../Types/IconProps";

export default ({ size = "md", color = "currentColor" }: IconProps) => (
  <svg
    width={
      (size === "xl" && "48px") ||
      (size === "lg" && "40px") ||
      (size === "md" && "32px") ||
      (size === "sm" && "24px") ||
      (size === "xs" && "16px") ||
      undefined
    }
    height={
      (size === "xl" && "48px") ||
      (size === "lg" && "40px") ||
      (size === "md" && "32px") ||
      (size === "sm" && "24px") ||
      (size === "xs" && "16px") ||
      undefined
    }
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3333 15H23.6667C24.0349 15 24.3333 15.2985 24.3333 15.6667C24.3333 16.0349 24.0349 16.3333 23.6667 16.3333H16.3333V23.6667C16.3333 24.0349 16.0349 24.3333 15.6667 24.3333C15.2985 24.3333 15 24.0349 15 23.6667V16.3333H7.66667C7.29848 16.3333 7 16.0349 7 15.6667C7 15.2985 7.29848 15 7.66667 15H15V7.66667C15 7.29848 15.2985 7 15.6667 7C16.0349 7 16.3333 7.29848 16.3333 7.66667V15Z"
      fill={theme.colors[color]}
    />
  </svg>
);
