import React, { useState } from "react";
import PropTypes from "prop-types";
import "./Styles.scss";
import TodoItem from "../TodoItem";
import { Icon } from "../../Core/Images";

function TodoGroupList(props) {
  const [openTodoList, setOpenTodoList] = useState(false);

  const { group, selectedWeek } = props;
  return (
    <div
      className={`todos-group-list ${
        props.group.name === "custom" || openTodoList ? "open" : "hide"
      }`}
    >
      {group.name !== "custom" && (
        <div
          className="todos-group-row"
          onClick={() => setOpenTodoList(!openTodoList)}
        >
          <div
            style={{
              width: 48,
              height: 48,
              padding: 4,
              backgroundColor: "#FFFFFF",
            }}
          >
            <Icon src={group.imgSrc} size={48} />
          </div>
          <b>{group.name}</b>
          <div className="icon">
            {!openTodoList && <i className="zwicon-chevron-down" />}
            {openTodoList && (
              <i
                className="zwicon-chevron-up"
                onClick={() => setOpenTodoList(!openTodoList)}
              />
            )}
          </div>
        </div>
      )}
      {group.todos.length > 0 &&
        group.todos.map((td, i) => (
          <TodoItem
            type={group}
            snoozeTodo={props.snoozeTodo}
            addCustomTodo={props.addCustomTodo}
            onUpdate={props.updateTodoList}
            deleteTodo={props.deleteTodo}
            transparent={true}
            selectedWeek={selectedWeek}
            key={"todo-item-" + i++}
            data={td}
            displayImage={group.name !== "custom"}
            cropImageSrc={td.cropImageUrl}
            onCheck={(c) => props.onCheckTodo(td.id, c)}
          />
        ))}
    </div>
  );
}
TodoGroupList.propTypes = {
  group: PropTypes.object.isRequired,
  transparent: PropTypes.bool,
};

export default TodoGroupList;
