import styled, { css } from "styled-components";
import flexContainer, { FlexContainerProps } from "../../Styled/flexContainer";
import Box from "../Box";

type RowProps = {
  reverse?: boolean;
} & Omit<FlexContainerProps, "flexDirection">;

const Row = styled(Box)<RowProps>(
  ({ reverse = false }) => css`
    ${flexContainer}
    flex-direction: ${reverse ? "row-reverse" : "row"};
  `
);

export default Row;
