const colors = {
  aubergineMedium: "#765673",
  aubergineDark: "#593c57",
  aubergineLight: "#9a7e98",
  aubergineApp: "#6f1a66",
  aubergineAppInactive: "#a97fa5",
  greenDark: "#414b18",
  greenMedium: "#7d894e",
  greenLight: "#d0d5b3",
  greenApp: "#13905b",
  greenAppInactive: "#a6cbbb",
  greenAppHauptkultur: "#80c27e",
  blueHandle: "#24b0ff",
  blueDark: "#7198d3",
  blueLight: "#d8e2f1",
  beigeDarkest: "#c1b3a4",
  beigeDark: "#d5c6b6",
  beigeMidi: "#e9daca",
  beigeMedium: "#f1e9de",
  beigeLight: "#F6F2ED",
  orangeDark: "#db8541",
  orangeMedium: "#f2b06b",
  orangeLight: "#fbcd9d",
  pinkDark: "#a45d57",
  pinkMedium: "#eb9b95",
  pinkLight: "#f9bdb8",
  red: "#e74d32",
  yellow: "#ffb800",
  black: "#150807",
  greyDark: "#8c8c8c",
  greyMedium: "#d4d4d4",
  greyLight: "#f1f1f1",
  white: "#ffffff",
};

const fonts = {
  vollkorn: "Vollkorn, serif",
  karla: "Karla, sans-serif",
};

const fontWeights = {
  normal: 400,
  bold: 700,
};

const fontStyles = {
  normal: "normal",
  italic: "italic",
};

const sizes = {
  h1: 32,
  h2: 24,
  h3: 20,
  h4: 16,
  copy: 16,
  small: 14,
  smaller: 12,
};

const toolsBorder = {
  borderBottom: `1px solid ${colors.greyMedium}`,
};

export default {
  colors,
  fonts,
  fontWeights,
  fontStyles,
  sizes,
  text: {
    p: {
      fontFamily: fonts.karla,
      fontSize: sizes.copy,
    },
    small: {
      fontFamily: fonts.karla,
      fontSize: sizes.small,
    },
    link: {
      cursor: "pointer",
      color: colors.blueDark,
    },
  },
  ui: {
    toolsBorder,
    editOverlayStyles: {
      display: "flex",
      flexDirection: "column",
      width: 400,
      position: "absolute",
      bottom: 32,
      alignSelf: "center",
      zIndex: 100,
      backgroundColor: colors.greyLight,
      borderRadius: 24,
      padding: "12px",
      overflowX: "hidden",
      fontFamily: fonts.karla,
      lineHeight: 1.5,
      fontWeight: 400,
    },
    overlay: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      opacity: 0.5,
      backgroundColor: colors.greyLight,
    },
    inputResetStyles: {
      border: "none",
      backgroundImage: "none",
      backgroundColor: "transparent",
      boxShadow: "none",
      outline: "none",
      fontFamily: fonts.karla,
      maxWidth: "100%",
      color: colors.greyDark,
      padding: 0,
    },
    roundedButtonStyles: {
      display: "flex",
      gap: 8,
      maxWidth: "100%",
      alignItems: "center",
      justifyContent: "center",
      padding: "0 36px",
      borderRadius: 16,
      cursor: "pointer",
      border: "0px",
      fontWeight: 700,
      fontSize: sizes.small,
      fontFamily: fonts.karla,
      color: colors.white,
      backgroundColor: colors.greenApp,
    },
    listContainerStyles: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      zIndex: 100,
      backgroundColor: colors.white,
      borderRadius: 24,
      overflowX: "hidden",
      fontFamily: "Karla",
      lineHeight: 1.5,
      top: 16,
      left: 16,
      width: 166,
      padding: "8px 0",
      alignItems: "space-between",
      "& > *:nth-last-child(n+2)": {
        ...toolsBorder,
      },
      "& > *": {
        padding: "8px 0",
      },
    },
    iconButtonStyles: {
      fontSize: "100%",
      fontFamily: "inherit",
      border: 0,
      padding: 0,
      margin: 8,
      width: 32,
      height: 32,
      borderRadius: "50%",
      backgroundColor: "inherit",
      cursor: "pointer",
    },
    textButtonStyles: {
      cursor: "pointer",
      fontFamily: fonts.karla,
      border: 0,
      margin: "8px 8px",
      padding: "4px 8px",
      borderRadius: 16,
      backgroundColor: "inherit",
      textAlign: "left",
    },
    itemButtonStyles: {
      "& .MuiButton-label": {
        display: "flex",
        gap: 8,
        textTransform: "none",
        fontWeight: 700,
        fontSize: sizes.small,
      },
      "& .MuiToggleButton-label": {
        display: "flex",
        alignItems: "center",
        gap: 8,
        textTransform: "none",
        fontWeight: 700,
        fontSize: sizes.small,
        color: colors.black,
      },
    },
    scrollContainerStyles: {
      width: "100%",
      boxSizing: "content-box",
      overflowY: "scroll",
      scrollbarWidth: "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    roundedFull: {
      borderRadius: "50%",
    },
    rounded8: {
      borderRadius: 8,
    },
    rounded24: {
      borderRadius: 24,
    },
    shadow: {
      boxShadow: `2px 2px 15px 0px rgba(0,0,0,0.2)`,
    },
    forceHeightWhenPositionAbsolute: {
      height: "auto",
      margin: 0,
    },
  },

  // old theme styles
  lightBlue: "#bad8ce",
  brightBlue: "#58aad4",
  blue: "#2175a1",
  lightGreen: "#65d083",
  lightGreenHover: "#7be298",
  mainGreen: "#13905b",
  mainGreenHover: "#2da572",
  aubergine: "#56434e",
  aubergineLight: "#8c807f",
  aubergineMidi: "#796571",
  zitrone: "#e8e376",
  salat: "#6fc180",
  beige: "#f4e5d1",
  beige2: "#ccb59a",
  beige2lighter: "#e0cdb6",
  darkbeige: "#e0ccb5",
  midibeige: "#ead9c3",
  hellbeige: "#f5f0ea",
  mittelgrau: "#e4e4e4",
  black: "#150807",

  lightRed: "#e74d32",
  warningRed: "#cc0033",
  red: "#852005",
  orange: "#f8af4a",
  white: "#ffffff",
  beigetrans: "#f4e5d1f3",

  grey: "#8c8c8c",
  darkgrey: "#343434",
  lightgrey: "#999999",
  lightgrey2: "#cacaca",
  lightgrey3: "#f2f2f2",
};
