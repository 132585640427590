import React from "react";
import PropTypes from "prop-types";

import "./Styles.scss";

class SmallZwiconButton extends React.Component {
  render() {
    const {
      icon,
      label,
      right,
      smaller,
      buttonStyle,
      larger,
      transparent,
      disabled,
      size,
      className,
    } = this.props;

    let cn = right ? "zwicon-button button-right" : "zwicon-button";
    if (smaller) {
      cn += " zwicon-button-smaller";
    } else if (larger) {
      cn += " zwicon-button-56";
    } else if (size) {
      cn += " zwicon-button-" + size;
    }
    if (buttonStyle === "cancel") {
      cn += " button-cancel";
    }
    if (buttonStyle === "white") {
      cn += " button-white";
    }
    if (buttonStyle === "beige") {
      cn += " button-beige";
    }
    if (className) {
      cn += ` ${className}`;
    }

    return (
      <div className={cn}>
        <div
          className={`inner ${disabled ? "button-disabled" : ""}`}
          onClick={this.props.onClick}
        >
          {!right && (
            <div className={`icon ${transparent ? "icon-transparent" : ""}`}>
              <i className={"zwicon-" + icon} />
            </div>
          )}
          {label && <p className="label">{label}</p>}
          {right && (
            <div className="icon icon-right">
              <i className={"zwicon-" + icon} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

SmallZwiconButton.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string,
  right: PropTypes.bool,
  onClick: PropTypes.func,
  smaller: PropTypes.bool,
  larger: PropTypes.bool,
  buttonStyle: PropTypes.oneOf(["default", "cancel", "white", "beige"]),
  transparent: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default SmallZwiconButton;
