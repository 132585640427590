import { css } from "styled-components";
import theme from "../../Theme";
import {
  createResponisiveCSSProp,
  createResponisiveThemeProp,
  ResponsiveValue,
} from "./Core";

export type LayoutProps = {
  aspectRatio?: ResponsiveValue<
    "auto" | "9/16" | "3/4" | "1/1" | "4/3" | "16/9"
  >;
  size?: ResponsiveValue<keyof Omit<typeof theme.sizes, "none">>;
  width?: ResponsiveValue<keyof Omit<typeof theme.sizes, "none">>;
  height?: ResponsiveValue<keyof Omit<typeof theme.sizes, "none">>;
  minWidth?: ResponsiveValue<keyof Omit<typeof theme.sizes, "none">>;
  minHeight?: ResponsiveValue<keyof Omit<typeof theme.sizes, "none">>;
  maxWidth?: ResponsiveValue<keyof Omit<typeof theme.sizes, "auto">>;
  maxHeight?: ResponsiveValue<keyof Omit<typeof theme.sizes, "auto">>;
  overflow?: ResponsiveValue<"visible" | "hidden" | "scroll" | "auto">;
  overflowX?: ResponsiveValue<"visible" | "hidden" | "scroll" | "auto">;
  overflowY?: ResponsiveValue<"visible" | "hidden" | "scroll" | "auto">;
};

export const layoutDefaultProps: LayoutProps = {
  aspectRatio: "auto",
  size: "auto",
  width: "auto",
  height: "auto",
  minWidth: "auto",
  minHeight: "auto",
  maxWidth: "none",
  maxHeight: "none",
  overflow: "visible",
  overflowX: "visible",
  overflowY: "visible",
};

const layout = css<LayoutProps>(
  ({
    aspectRatio,
    size,
    width,
    height,
    minWidth,
    minHeight,
    maxWidth,
    maxHeight,
    overflow,
    overflowY,
    overflowX,
  }) => css`
    ${createResponisiveCSSProp({
      prop: aspectRatio,
      property: "aspect-ratio",
    })}
    ${createResponisiveThemeProp({
      prop: size,
      property: ["width", "height"],
      scale: "sizes",
    })}
    ${createResponisiveThemeProp({
      prop: width,
      property: "width",
      scale: "sizes",
    })}
    ${createResponisiveThemeProp({
      prop: height,
      property: "height",
      scale: "sizes",
    })}
    ${createResponisiveThemeProp({
      prop: minWidth,
      property: "min-width",
      scale: "sizes",
    })}
    ${createResponisiveThemeProp({
      prop: minHeight,
      property: "min-height",
      scale: "sizes",
    })}
    ${createResponisiveThemeProp({
      prop: maxWidth,
      property: "max-width",
      scale: "sizes",
    })}
    ${createResponisiveThemeProp({
      prop: maxHeight,
      property: "max-height",
      scale: "sizes",
    })}
    ${createResponisiveCSSProp({
      prop: overflow,
      property: ["overflow-y", "overflow-x"],
    })}
    ${createResponisiveCSSProp({
      prop: overflowY,
      property: "overflow-y",
    })}
    ${createResponisiveCSSProp({
      prop: overflowX,
      property: "overflow-x",
    })}
  `
);

export default layout;
