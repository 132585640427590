import React from "react";
import * as PropTypes from "prop-types";
import { withWidth, IconButton } from "@material-ui/core";
import CustomModal from "../../Common/CustomModal";
import IconClose from "@material-ui/icons/Close";

const ResponsiveModalWrapper = ({
  children,
  width,
  showCloseButton,
  modalClassName,
  onClose,
}) => (
  <>
    {width === "xs" || width === "sm" ? (
      <div className={modalClassName}>
        {showCloseButton && (
          <div className="button-bar close-button">
            <IconButton onClick={onClose}>
              <IconClose />
            </IconButton>
          </div>
        )}
        {children}
      </div>
    ) : (
      <CustomModal
        className={modalClassName}
        open={true}
        showCloseButton={showCloseButton}
        onClose={onClose}
      >
        {children}
      </CustomModal>
    )}
  </>
);

ResponsiveModalWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.string.isRequired,
};

export default withWidth()(ResponsiveModalWrapper);
