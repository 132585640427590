import {
  NpsSurveyState,
  NpsSurveyAction,
  SET_SURVEY_STARTED,
  RECEIVE_SURVEY,
  RECEIVE_SURVEY_ERROR,
  SET_SURVEY_MODAL_VISIBLE,
  SET_SCORE,
  SET_SURVEY_MODAL_HIDDEN,
} from "./Actions";

export const initialState: NpsSurveyState = {
  surveyCompleted: false,
  surveyDeclined: false,
  surveyStarted: false,
  surveyModalVisible: false,
  score: undefined,
};

export default function NpsSurveyReducer(
  state: NpsSurveyState = initialState,
  action: NpsSurveyAction
) {
  switch (action.type) {
    case SET_SURVEY_MODAL_VISIBLE: {
      return { ...state, surveyModalVisible: true };
    }
    case SET_SURVEY_MODAL_HIDDEN: {
      return { ...state, surveyModalVisible: false };
    }
    case SET_SURVEY_STARTED: {
      return { ...state, surveyStarted: true, surveyModalVisible: false };
    }
    case SET_SCORE:
      return { ...state, score: action.score };

    case RECEIVE_SURVEY: {
      return {
        ...state,
        surveyCompleted: true,
      };
    }
    case RECEIVE_SURVEY_ERROR: {
      return {
        ...state,
        surveyCompleted: true,
      };
    }
    default:
      return state;
  }
}
