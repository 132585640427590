import {
  SET_IS_PLUS_ACCOUNT,
  SET_IS_PRO_ACCOUNT,
  SET_PLUS_EXPIRED,
  SET_PRO_EXPIRED,
  SET_SHOW_ACCOUNT_UPGRADE,
  SET_SHOW_VOUCHER_REDEEMER,
  SET_VOUCHER,
} from "./Actions";

export const initialState = {
  isPlusAccount: false,
  isPlusExpired: false,
  isProAccount: false,
  isProExpired: false,
  showAccountUpgrade: false,
  isTrialExpired: false,
  isPaidExpired: false,
  isInTrial: false,
  showTrialExpiration: false,
  skipTrial: false,
  showVoucherRedeemer: false,
  voucher: undefined,
};

export default function AccountReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_SHOW_ACCOUNT_UPGRADE: {
      return {
        ...state,
        showAccountUpgrade: action.show,
        skipTrial: !!action.skipTrial,
        showVoucherRedeemer: !!action.showVoucherRedeemer,
      };
    }

    case SET_IS_PRO_ACCOUNT: {
      return {
        ...state,
        isProAccount: true,
        isPlusAccount: true,
      };
    }

    case SET_IS_PLUS_ACCOUNT: {
      return {
        ...state,
        isProAccount: false,
        isPlusAccount: true,
      };
    }

    case SET_PLUS_EXPIRED: {
      return {
        ...state,
        isPlusAccount: false,
        isProAccount: false,
        isPlusExpired: true,
      };
    }

    case SET_PRO_EXPIRED: {
      return {
        ...state,
        isPlusAccount: false,
        isProAccount: false,
        isProExpired: true,
      };
    }

    case SET_VOUCHER: {
      return { ...state, voucher: action.voucher };
    }

    case SET_SHOW_VOUCHER_REDEEMER: {
      return { ...state, showVoucherRedeemer: action.show };
    }

    default:
      return state;
  }
}
