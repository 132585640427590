import {} from "styled-components/macro";
import Voucher, {
  VoucherContext,
  VoucherStatusMessageType,
  VoucherStatusMessageTypeType,
} from "../../Types/API/Voucher";
import { useTranslation } from "react-i18next";
import { Column, Row, Text, TextInput } from "../../Base";
import React, { useEffect, useRef, useState } from "react";
import { validateVoucherCode } from "../../Utils/VoucherRedeemer/api";
import styled from "styled-components";

const voucherChunkSize = 4;
const voucherStatuses: { [key: string]: Voucher } = {
  empty: {
    message: VoucherStatusMessageType.NONE,
    messageType: VoucherStatusMessageTypeType.NONE,
  },
  localInvalid: {
    message: VoucherStatusMessageType.WRONG_FORMAT,
    messageType: VoucherStatusMessageTypeType.ERROR,
  },
  remoteValidationInProgress: {
    message: VoucherStatusMessageType.VALIDATION_IN_PROGRESS,
    messageType: VoucherStatusMessageTypeType.NONE,
  },
  remoteInvalid: {
    message: VoucherStatusMessageType.INVALID,
    messageType: VoucherStatusMessageTypeType.ERROR,
  },
  remoteValid: {
    message: VoucherStatusMessageType.SUCCESS,
    messageType: VoucherStatusMessageTypeType.SUCCESS,
  },
};

interface VoucherInputProps {
  setVoucher: (voucher: Voucher | null) => void;
  context: VoucherContext;
  presetCode?: string;
}

const makeEmptyVoucherCode = () => ["", "", "", ""];

const VoucherStatusMessage = ({ voucher }: { voucher: Voucher }) => {
  const { t } = useTranslation("paywall");
  const color =
    voucher.messageType === VoucherStatusMessageTypeType.SUCCESS
      ? "greenApp"
      : voucher.messageType === VoucherStatusMessageTypeType.ERROR
      ? "red"
      : undefined;

  return (
    <Text as={"span"} fontSize={"sm"} ml={"4px"} $color={color}>
      {t(`voucher.statusMessage.${voucher.message}`)}
    </Text>
  );
};

const VoucherInput = ({
  setVoucher,
  context,
  presetCode,
}: VoucherInputProps) => {
  const inputRefs = [1, 2, 3, 4].map(() => useRef<HTMLInputElement>(null));
  const [externalVoucherIsValid, setExternalVoucherIsValid] = useState(false);
  const codes = presetCode
    ? presetCode.match(/.{1,4}/g) ?? makeEmptyVoucherCode()
    : makeEmptyVoucherCode();
  const [voucherCodes, setVoucherCodes] = useState<string[]>(codes);
  const setVoucherCodeByIndex = (index: number, code: string) => {
    setVoucherCodes(
      voucherCodes.map((vc, i) => {
        return index === i ? code : vc;
      })
    );
  };
  const [activeTextBoxIndex, setActiveTextBoxIndex] = useState(-1);
  const [voucherStatus, setVoucherStatus] = useState<Voucher>(
    voucherStatuses.empty
  );

  const pasteVoucherCode = (pastedText: string) => {
    const rawCode = pastedText.trim().replace(/-/g, "");

    if (rawCode.length !== 16) {
      return;
    }

    setVoucherCodes(rawCode.match(/.{1,4}/g) ?? makeEmptyVoucherCode());
  };

  useEffect(() => {
    async function sendValidationRequest() {
      const response = await validateVoucherCode(
        voucherCodes.map((c) => c.toUpperCase()).join(""),
        context
      );
      if (response && response.valid === true) {
        setVoucherStatus({
          ...voucherStatuses.remoteValid,
          ...response,
          code: voucherCodes.map((c) => c.toUpperCase()).join(""),
        });
      } else {
        setVoucherStatus(voucherStatuses.remoteInvalid);
      }
    }

    if (
      activeTextBoxIndex !== -1 &&
      voucherCodes[activeTextBoxIndex] &&
      voucherCodes[activeTextBoxIndex].length === voucherChunkSize
    ) {
      inputRefs[activeTextBoxIndex + 1] &&
        inputRefs[activeTextBoxIndex + 1].current &&
        inputRefs[activeTextBoxIndex + 1].current?.focus();
    }

    if (voucherCodes.every((c) => c === "")) {
      setVoucherStatus(voucherStatuses.empty);
    } else if (voucherCodes.some((c) => !/^[a-zA-Z0-9]{4}$/.test(c))) {
      setVoucherStatus(voucherStatuses.localInvalid);
    } else if (
      voucherStatus.messageType !== voucherStatuses.remoteValid.messageType
    ) {
      setVoucherStatus(voucherStatuses.remoteValidationInProgress);
      sendValidationRequest();
    }
    // eslint-disable-next-line
  }, [voucherCodes]);

  useEffect(() => {
    if (voucherStatus.message === voucherStatuses.remoteValid.message) {
      setVoucher(voucherStatus);
      setExternalVoucherIsValid(true);
    } else {
      if (externalVoucherIsValid) {
        setExternalVoucherIsValid(false);
        setVoucher(null);
      }
    }
    // eslint-disable-next-line
  }, [voucherStatus]);

  const onFocus = (index: number) => {
    setActiveTextBoxIndex(index);
  };

  function* renderInputs() {
    for (let index = 0, len = inputRefs.length; index < len; ++index) {
      yield (
        <TextInput
          key={index}
          onChange={(value: string) => setVoucherCodeByIndex(index, value)}
          value={voucherCodes[index]}
          maxLength={voucherChunkSize}
          ref={inputRefs[index]}
          fontFamily="mono"
          css={`
            width: calc(9.6px * ${voucherChunkSize} + 32px);
          `}
          outline
          onFocus={() => onFocus(index)}
          onPaste={(event: any) => {
            pasteVoucherCode(event.clipboardData.getData("Text"));
          }}
          onInput={(e: any) => {
            e.target.value = ("" + e.target.value).toUpperCase();
          }}
        />
      );
      if (index !== len - 1) {
        yield <Spacer key={len + index}>{"-"}</Spacer>;
      }
    }
  }

  return (
    <Column>
      <Row alignItems={"center"} justifyContent={"flex-start"}>
        {Array.from(renderInputs())}
      </Row>
      <VoucherStatusMessage voucher={voucherStatus} />
    </Column>
  );
};
export default VoucherInput;

const Spacer = styled(Text).attrs(() => ({
  width: "20px",
  textAlign: "center",
  fontFamily: "mono",
  flexShrink: 0,
}))`
  cursor: default;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
