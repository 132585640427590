import React from "react";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import "./Styles.scss";
import Theme from "../../Core/Theme";

const styles = (theme) => ({
  linearColorPrimary: {
    backgroundColor: Theme.colors.lightBlue,
  },
  linearBarColorPrimary: {
    backgroundColor: Theme.colors.mainGreen,
  },
});

const AppBarProgress = withStyles(styles)(({ classes }) => (
  <div className="app-progress-bar">
    <LinearProgress
      classes={{
        colorPrimary: classes.linearColorPrimary,
        barColorPrimary: classes.linearBarColorPrimary,
      }}
    />
  </div>
));

export default AppBarProgress;
