import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { confirmPaypalSubscription } from "../../../Reducers/User/Actions";
import { useTranslation } from "react-i18next";
import moment from "moment";

const PaypalPayment = ({ type, onPaypalConfirm }) => {
  const dispatch = useDispatch();
  const planId = useRef();
  const { t } = useTranslation("paywall");
  const { paypalPlanId } = useSelector((state) => state.User);
  const height = window.innerHeight;

  // due to the paypal implementation we have to use a ref and cannot use the variable from the reducer directly
  useEffect(() => {
    planId.current = paypalPlanId;
  }, [paypalPlanId]);

  const createSubscription = (data, actions) => {
    return actions.subscription.create({
      plan_id: planId.current,
      start_time: moment().add(7, "days").format(),
    });
  };

  return (
    <>
      <h1>{t("payment.headline." + type)}</h1>
      {paypalPlanId && (
        <>
          <p>
            Bitte klicke auf den Button um die Zahlung bei PayPal fortzusetzen.
          </p>
          <div>
            <PayPalScriptProvider
              options={{
                "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
                currency: "EUR",
                vault: true,
                intent: "subscription",
              }}
            >
              <PayPalButtons
                style={{
                  layout: "horizontal",
                  color: "blue",
                  tagline: false,
                }}
                createSubscription={createSubscription}
                onApprove={(data) => {
                  dispatch(
                    confirmPaypalSubscription(data.subscriptionID, data.payerID)
                  );
                  console.log("paypal confirm");
                  onPaypalConfirm();
                }}
              />
            </PayPalScriptProvider>
          </div>
        </>
      )}
    </>
  );
};

export default PaypalPayment;
