import styled, { css } from "styled-components";
import flexContainer, { FlexContainerProps } from "../../Styled/flexContainer";
import Box from "../Box";

type ColumnProps = {
  reverse?: boolean;
} & Omit<FlexContainerProps, "flexDirection">;

const Column = styled(Box)<ColumnProps>(
  ({ reverse = false }) => css`
    ${flexContainer}
    flex-direction: ${reverse ? "column-reverse" : "column"};
  `
);

export default Column;
