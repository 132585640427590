import { css } from "styled-components";
import theme from "../../Theme";
import {
  createResponisiveCSSProp,
  createResponisiveThemeProp,
  ResponsiveValue,
} from "./Core";

export type TypographyProps = {
  fontFamily?: ResponsiveValue<keyof typeof theme.fonts>;
  fontStyle?: ResponsiveValue<"normal" | "italic">;
  fontWeight?: ResponsiveValue<keyof typeof theme.fontWeights>;
  fontSize?: ResponsiveValue<keyof typeof theme.fontSizes>;
  lineHeight?: ResponsiveValue<keyof typeof theme.lineHeights>;
  textDecoration?: ResponsiveValue<
    "none" | "underline" | "overline" | "line-through"
  >;
  textAlign?: ResponsiveValue<"start" | "end" | "center" | "justify">;
  textOverflow?: ResponsiveValue<"clip" | "ellipsis">;
  wordWrap?: ResponsiveValue<"normal" | "break-word">;
  whiteSpace?: ResponsiveValue<
    "normal" | "nowrap" | "pre" | "pre-wrap" | "pre-line" | "break-spaces"
  >;
  lineClamp?: ResponsiveValue<number>;
  hyphens?: "none" | "auto" | "manual";
  letterSpacing?: 0 | string;
};

export const typographyDefaultProps: TypographyProps = {
  fontFamily: "karla",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: "md",
  lineHeight: "1.5",
  textDecoration: "none",
  textAlign: "start",
  textOverflow: "clip",
  wordWrap: "normal",
  whiteSpace: "normal",
  hyphens: "auto",
  letterSpacing: "normal",
};

const typography = css<TypographyProps>(
  ({
    fontFamily = "karla",
    fontStyle = "normal",
    fontWeight = "normal",
    fontSize = "md",
    lineHeight = "1.5",
    textDecoration = "none",
    textAlign = "start",
    textOverflow,
    wordWrap,
    whiteSpace,
    lineClamp,
    hyphens,
    letterSpacing,
  }) => css`
    ${createResponisiveThemeProp({
      prop: fontFamily,
      property: "font-family",
      scale: "fonts",
    })}
    ${createResponisiveCSSProp({
      prop: fontStyle,
      property: "font-style",
    })}
    ${createResponisiveThemeProp({
      prop: fontWeight,
      property: "font-weight",
      scale: "fontWeights",
    })}
    ${createResponisiveThemeProp({
      prop: fontSize,
      property: "font-size",
      scale: "fontSizes",
    })}
    ${createResponisiveThemeProp({
      prop: lineHeight,
      property: "line-height",
      scale: "lineHeights",
    })}
    ${createResponisiveCSSProp({
      prop: textDecoration,
      property: "text-decoration",
    })}
    ${createResponisiveCSSProp({
      prop: textAlign,
      property: "text-align",
    })}
    ${createResponisiveCSSProp({
      prop: textOverflow,
      property: "text-overflow",
    })}
    ${createResponisiveCSSProp({
      prop: wordWrap,
      property: "word-wrap",
    })}
    ${createResponisiveCSSProp({
      prop: whiteSpace,
      property: "white-space",
    })}
    ${lineClamp != null &&
    css`
      display: -webkit-box;
      -webkit-box-orient: vertical;
    `}
    ${createResponisiveCSSProp({
      prop: lineClamp,
      property: "-webkit-line-clamp",
    })}
    ${createResponisiveCSSProp({
      prop: letterSpacing,
      property: "letter-spacing",
    })}
    ${createResponisiveCSSProp({
      prop: hyphens,
      property: "hyphens",
    })}
    hyphenate-limit-chars: ${hyphens ? "6 3 2" : undefined};
  `
);

export default typography;
