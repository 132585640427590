import Routes from "../../Core/Routes";
import { fetchData, postData } from "../Api/Actions";

export const RECEIVE_LOCATIONS = "LOCATION_RECEIVE_LOCATIONS";
export const RECEIVE_PLACE = "LOCATION_RECEIVE_PLACE";
export const SET_GEO_OUTLINE = "LOCATION_SET_GEO_OUTLINE";
export const START_LOAD_MAPS_IMAGE = "LOCATION_START_LOAD_MAPS_IMAGE";
export const RECEIVE_MAPS_IMAGE = "LOCATION_RECEIVE_MAPS_IMAGE";
export const RESET_LOCATIONS = "LOCATION_RESET_LOCATIONS";

export const receiveLocations = (data) => ({
  type: RECEIVE_LOCATIONS,
  locations: data.locations,
});

export const autocompleteLocations = (query, withAddress) => (dispatch) => {
  let url = Routes.API_ROUTE_LOCATIONS_AUTOCOMPLETE.replace("{query}", query);
  if (withAddress) {
    url += "&address=1";
  }
  dispatch(fetchData(url, receiveLocations));
};

export const getLocationsFromGeo = (lat, lon) => (dispatch) => {
  dispatch(
    fetchData(
      Routes.API_ROUTE_LOCATIONS_FROM_GEO.replace("{lat}", lat).replace(
        "{lon}",
        lon
      ),
      receiveLocations
    )
  );
};

export const receivePlace = (place) => ({
  type: RECEIVE_PLACE,
  place,
});

export const getPlace = (placeId) =>
  fetchData(
    Routes.API_ROUTE_LOCATIONS_GET_PLACE.replace("{placeId}", placeId),
    receivePlace
  );

export const resetLocations = () => ({
  type: RESET_LOCATIONS,
});

export const startLoadMapsImage = () => ({
  type: START_LOAD_MAPS_IMAGE,
});

export const setGeoOutline = (outline) => ({
  type: SET_GEO_OUTLINE,
  outline,
});

export const getMapsImage = (data) => (dispatch) => {
  dispatch(startLoadMapsImage());
  dispatch(setGeoOutline(data.path));
  dispatch(
    postData(
      Routes.API_ROUTE_LOCATIONS_GET_MAPS_IMAGE,
      data,
      receiveGetMapsImage
    )
  );
};

export const receiveGetMapsImage = (data) => ({
  type: RECEIVE_MAPS_IMAGE,
  data,
});
