import { css } from "styled-components";
import theme from "../../Theme";
import {
  createResponisiveCSSProp,
  createResponisiveThemeProp,
  ResponsiveValue,
} from "./Core";

export type ColorProps = {
  $color?: ResponsiveValue<keyof typeof theme.colors>;
  backgroundColor?: ResponsiveValue<keyof typeof theme.colors>;
  opacity?: ResponsiveValue<
    | "100%"
    | "90%"
    | "80%"
    | "70%"
    | "60%"
    | "50%"
    | "40%"
    | "30%"
    | "20%"
    | "10%"
    | "0%"
  >;
};

export const colorDefaultProps: ColorProps = {
  $color: "currentColor",
  backgroundColor: "transparent",
  opacity: "100%",
};

const color = css<ColorProps>(
  ({ $color, backgroundColor, opacity }) => css`
    ${createResponisiveThemeProp({
      prop: $color,
      property: "color",
      scale: "colors",
    })}
    ${createResponisiveThemeProp({
      prop: backgroundColor,
      property: "background-color",
      scale: "colors",
    })}
    ${createResponisiveCSSProp({
      prop: opacity,
      property: "opacity",
    })}
  `
);

export default color;
