import * as React from "react";
import * as PropTypes from "prop-types";

import "./Styles.scss";

const ModalNavigator = ({ left, center, right, fullScreen, dialog }) => {
  return (
    <div
      className={
        dialog
          ? "dialog-navigation"
          : fullScreen
          ? "secondary-navigation full-screen"
          : "secondary-navigation"
      }
    >
      <div>{left}</div>
      <div>{center}</div>
      <div>{right}</div>
    </div>
  );
};

ModalNavigator.propTypes = {
  left: PropTypes.node,
  center: PropTypes.node,
  right: PropTypes.node,
};

export default ModalNavigator;
