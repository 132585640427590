import { useEffect, useState } from "react";
import ArrowLeftSmall from "../../../Resources/SVG/ArrowLeftSmall";
import ArrowRightSmall from "../../../Resources/SVG/ArrowRightSmall";
import IconButton from "../../Input/IconButton";
import Box from "../../Layout/Box";
import Center from "../../Layout/Center";
import { LayoutProps } from "../../Styled/layout";
import { MarginProps } from "../../Styled/margin";
import { PositionProps } from "../../Styled/position";
import ImageComponent from "../Image";

type MultiImageProps = {
  imageUrls: string[];
  imageIndex?: number;
  objectFit?: "fill" | "contain" | "cover" | "none" | "scale-down";
  aspectRatio?: LayoutProps["aspectRatio"];
  onChangeImageIndex?: (imageIndex: number) => void;
} & PositionProps &
  MarginProps;

const MultiImage = ({
  imageUrls,
  imageIndex,
  onChangeImageIndex,
  aspectRatio = "1/1",
  objectFit = "contain",
  ...props
}: MultiImageProps) => {
  const [index, setIndex] = useState(0);

  // image preload
  const preloadImages: {
    [key: string]: HTMLImageElement;
  } = {};
  useEffect(() => {
    imageUrls.forEach((imageUrl) => {
      const img = new Image();
      img.src = imageUrl;
      preloadImages[imageUrl] = img;
    });
  }, []);

  useEffect(() => {
    onChangeImageIndex?.(index);
  }, [index]);

  useEffect(() => {
    if (imageIndex != null) {
      setIndex(imageIndex);
    }
  }, [imageIndex]);

  return (
    <Box {...props}>
      <Box position="relative">
        {index > 0 && (
          <Center
            position="absolute"
            top="0px"
            bottom="0px"
            left="0px"
            pl="4px"
          >
            <IconButton
              opacity="80%"
              backgroundColor="black"
              $color="white"
              size="sm"
              icon={<ArrowLeftSmall />}
              onClick={() =>
                imageIndex != null
                  ? onChangeImageIndex?.(index - 1)
                  : setIndex(index - 1)
              }
            />
          </Center>
        )}
        {index < imageUrls.length - 1 && (
          <Center
            position="absolute"
            top="0px"
            bottom="0px"
            right="0px"
            pr="4px"
          >
            <IconButton
              opacity="80%"
              backgroundColor="black"
              $color="white"
              size="sm"
              icon={<ArrowRightSmall />}
              onClick={() =>
                imageIndex != null
                  ? onChangeImageIndex?.(index + 1)
                  : setIndex(index + 1)
              }
            />
          </Center>
        )}
        <ImageComponent
          backgroundColor="beigeMidi"
          aspectRatio={aspectRatio}
          width="100%"
          objectFit={objectFit}
          src={imageUrls[index]}
        />
      </Box>
    </Box>
  );
};

export default MultiImage;
