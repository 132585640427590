import theme from "../../Theme";
import IconProps from "../../Types/IconProps";

export default ({ size = "md", color = "currentColor" }: IconProps) => (
  <svg
    width={size === "sm" ? "24px" : "32px"}
    height={size === "sm" ? "24px" : "32px"}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.85355 20.8536C3.65829 21.0488 3.34171 21.0488 3.14645 20.8536C2.95118 20.6583 2.95118 20.3417 3.14645 20.1464L20.1464 3.14645C20.3417 2.95118 20.6583 2.95118 20.8536 3.14645C21.0488 3.34171 21.0488 3.65829 20.8536 3.85355L3.85355 20.8536ZM5.56743 15.5622C5.77394 15.7455 5.79272 16.0615 5.60938 16.2681C5.42605 16.4746 5.11002 16.4933 4.90352 16.31C3.63534 15.1841 2.65019 13.7704 2.03382 12.1808C1.98873 12.0645 1.98873 11.9355 2.03382 11.8192C4.09127 6.51317 9.94271 3.75672 15.3273 5.52527C15.5897 5.61143 15.7325 5.89397 15.6463 6.15632C15.5601 6.41868 15.2776 6.5615 15.0153 6.47533C10.1969 4.89276 4.96479 7.30886 3.03835 12C3.60037 13.3673 4.46614 14.5845 5.56743 15.5622ZM12.6197 8.04779C12.8926 8.09028 13.0793 8.34591 13.0369 8.61876C12.9944 8.89162 12.7387 9.07837 12.4659 9.03589C12.3129 9.01206 12.1573 9 12 9C10.3431 9 9 10.3431 9 12C9 12.1556 9.01181 12.3096 9.03513 12.461C9.07717 12.7339 8.88999 12.9893 8.61707 13.0313C8.34414 13.0733 8.08882 12.8862 8.04678 12.6132C8.01571 12.4115 8 12.2066 8 12C8 9.79086 9.79086 8 12 8C12.2088 8 12.4159 8.01605 12.6197 8.04779ZM11.0279 15.4004C10.9907 15.674 11.1823 15.926 11.4559 15.9632C11.6353 15.9877 11.817 16 12 16C14.2091 16 16 14.2091 16 12C16 11.8387 15.9904 11.6784 15.9714 11.5198C15.9386 11.2456 15.6897 11.05 15.4155 11.0828C15.1413 11.1157 14.9457 11.3646 14.9785 11.6387C14.9928 11.7579 15 11.8784 15 12C15 13.6569 13.6569 15 12 15C11.8621 15 11.7255 14.9907 11.5908 14.9724C11.3172 14.9351 11.0652 15.1267 11.0279 15.4004ZM18.3906 7.73195C18.2073 7.93845 18.2261 8.25448 18.4326 8.43781C19.5339 9.41555 20.3996 10.6327 20.9616 12C19.0352 16.6911 13.8031 19.1072 8.98475 17.5247C8.72239 17.4385 8.43986 17.5813 8.35369 17.8437C8.26752 18.106 8.41035 18.3886 8.6727 18.4747C14.0573 20.2433 19.9087 17.4868 21.9662 12.1808C22.0113 12.0645 22.0113 11.9355 21.9662 11.8192C21.3498 10.2296 20.3647 8.8159 19.0965 7.69C18.89 7.50667 18.5739 7.52545 18.3906 7.73195Z"
      fill={theme.colors[color]}
    />
  </svg>
);
