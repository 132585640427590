import {} from "styled-components/macro";
import {
  ClipboardEventHandler,
  FormEventHandler,
  forwardRef,
  KeyboardEventHandler,
  ReactElement,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import styled, { css } from "styled-components";
import { LayoutProps } from "../../Styled/layout";
import { MarginProps } from "../../Styled/margin";
import { PositionProps } from "../../Styled/position";
import space, { SpaceProps } from "../../Styled/space";
import typography, { TypographyProps } from "../../Styled/typography";
import Text from "../../Typography/Text";
import Row from "../../Layout/Row";
import Box from "../../Layout/Box";
import { FlexItemProps } from "../../Styled/flexItem";
import Column from "../../Layout/Column";
import useControlledState from "../../../Helpers/useControlledState";

export type TextInputProps = {
  elementLeft?: ReactElement;
  elementRight?: ReactElement;
  maxLength?: number;
  minLength?: number;
  spellCheck?: boolean;
  disabled?: boolean;
  error?: boolean;
  label?: string;
  placeholder?: string;
  helpText?: string;
  errorText?: string | ReactElement;
  outline?: boolean;
  autoFocus?: boolean;
  value?: string;
  type?: "email" | "password" | "search" | "tel" | "text" | "url";
  autoComplete?:
    | "off"
    | "on"
    | "email"
    | "name"
    | "url"
    | "tel"
    | "street-address"
    | "username"
    | "language"
    | "organization-title"
    | "organization"
    | "country"
    | "new-password"
    | "current-password"
    | "one-time-code"
    | "honorific-prefix"
    | "honorific-suffix"
    | "given-name"
    | "additional-name"
    | "family-name"
    | "nickname";
  onChange?: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onBeforeInput?: FormEventHandler<HTMLInputElement>;
  onInput?: FormEventHandler<HTMLInputElement>;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  onPaste?: ClipboardEventHandler<HTMLInputElement>;
} & PositionProps &
  Pick<LayoutProps, "width" | "minWidth" | "maxWidth"> &
  MarginProps &
  FlexItemProps &
  Pick<TypographyProps, "fontFamily">;

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      fontFamily,
      elementLeft,
      elementRight,
      maxLength,
      minLength,
      spellCheck = false,
      disabled = false,
      error = false,
      label,
      placeholder,
      helpText,
      errorText,
      outline = false,
      autoFocus = false,
      value: controlledValue = "",
      type = "text",
      autoComplete = "off",
      onChange,
      onFocus,
      onBlur,
      onBeforeInput,
      onInput,
      onKeyDown,
      onPaste,
      ...props
    },
    ref
  ) => {
    const [value, setValue] = useControlledState(controlledValue, onChange);
    const [isFocused, setIsFocused] = useState<boolean>(autoFocus);

    return (
      <Column gap="8px" {...props}>
        <Box
          overflow="hidden"
          height="56px"
          borderRadius="md"
          backgroundColor="white"
          border={outline || error ? "1pxSolid" : "none"}
          borderColor={error ? "red" : "greyMedium"}
        >
          <Row
            m={outline || error ? "-1px" : undefined}
            size={outline || error ? "100%+2px" : "100%"}
            as="label"
            position="relative"
            alignItems="center"
            px="16px"
            gap="8px"
            css={`
              cursor: ${disabled ? "default" : "text"};
            `}
          >
            {label && (
              <Text
                as="span"
                $color={error ? "red" : "greyDark"}
                position="absolute"
                left="16px"
                top={isFocused || value || placeholder ? "8px" : "auto"}
                fontSize={isFocused || value || placeholder ? "sm" : "md"}
              >
                {label}
              </Text>
            )}
            {elementLeft && (
              <Box flexShrink={0} ml="-8px">
                {elementLeft}
              </Box>
            )}

            <Input
              ref={ref}
              fontFamily={fontFamily}
              maxLength={maxLength}
              minLength={minLength}
              spellCheck={spellCheck}
              mt={
                label && (isFocused || value || placeholder)
                  ? "16px"
                  : undefined
              }
              fontSize="md"
              disabled={disabled}
              placeholder={placeholder}
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
              }}
              autoFocus={autoFocus}
              autoComplete={autoComplete}
              type={type}
              onFocus={() => {
                setIsFocused(true);
                onFocus?.();
              }}
              onBlur={() => {
                setIsFocused(false);
                onBlur?.();
              }}
              onBeforeInput={onBeforeInput}
              onInput={onInput}
              onKeyDown={onKeyDown}
              onPaste={onPaste}
            />
            {elementRight && (
              <Box flexShrink={0} mr="-8px">
                {elementRight}
              </Box>
            )}
          </Row>
        </Box>
        {(error &&
          errorText &&
          ((typeof errorText === "string" && (
            <Text px="16px" fontSize="sm" $color="red">
              {errorText}
            </Text>
          )) || <Box>{errorText}</Box>)) ||
          (helpText && (
            <Text px="16px" fontSize="sm" $color="greyDark">
              {helpText}
            </Text>
          ))}
      </Column>
    );
  }
);

export default TextInput;

type InputProps = {} & TypographyProps & SpaceProps;

const Input = styled.input<InputProps>(
  ({ theme }) => css`
    outline: none;
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    width: 100%;
    line-height: ${theme.lineHeights["1.5"]};

    &::placeholder {
      color: ${theme.colors.greyDark};
    }
    &:disabled {
      color: ${theme.colors.greyDark};
    }

    ${typography}
    ${space}
  `
);
