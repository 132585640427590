import { MouseEventHandler, RefObject } from "react";
import RichTextElement from "../../../Types/RichTextElement";
import Link from "../../Input/Link";
import TextButton from "../../Input/TextButton";
import { FlexItemProps } from "../../Styled/flexItem";
import { LayoutProps } from "../../Styled/layout";
import { TypographyProps } from "../../Styled/typography";
import Text from "../Text";
import Container from "./Container";

type TagElement =
  | Exclude<
      RichTextElement,
      {
        type: "plain_text" | "hash_tag" | "url";
        content: string;
      }
    >
  | {
      type: "hash_tag";
      content: string;
    };

type RichText = {
  richText: RichTextElement[];
  onTagClick?: (tag: TagElement, event?: any) => void;
  inline?: boolean;
  fontSize?: TypographyProps["fontSize"];
  stopTagClickPropagation?: boolean;
  lineClamp?: TypographyProps["lineClamp"];
} & FlexItemProps &
  LayoutProps;

const RichText = ({
  richText,
  onTagClick,
  fontSize,
  stopTagClickPropagation,
  ...props
}: RichText) => {
  return (
    <Text {...props} wordWrap={"break-word"}>
      {richText.map((richTextElement, i) => {
        switch (richTextElement.type) {
          case "plain_text":
            return (
              <Text key={i} fontSize={fontSize} as="span" inline>
                {richTextElement.content}
              </Text>
            );
          case "url":
            return (
              <Link
                key={i}
                fontSize={fontSize}
                href={richTextElement.content}
                inline
              >
                {richTextElement.content}
              </Link>
            );
          case "hash_tag":
            return (
              <TextButton
                key={i}
                fontSize={fontSize}
                inline
                $color="blueDark"
                fontWeight="normal"
                label={richTextElement.content}
                onClick={(event) => {
                  if (stopTagClickPropagation) {
                    event.stopPropagation();
                  }
                  onTagClick?.({ ...richTextElement, type: "hash_tag" });
                }}
              />
            );
          case "user_tag":
            return (
              <TextButton
                key={i}
                fontSize={fontSize}
                inline
                label={richTextElement.content}
                onClick={(event) => {
                  if (stopTagClickPropagation) {
                    event.stopPropagation();
                  }
                  onTagClick?.(richTextElement);
                }}
              />
            );
          case "crop_tag":
            return (
              <TextButton
                key={i}
                fontSize={fontSize}
                inline
                $color="greenApp"
                fontWeight="normal"
                label={richTextElement.content}
                onClick={(event) => {
                  if (stopTagClickPropagation) {
                    event.stopPropagation();
                  }
                  onTagClick?.(richTextElement);
                }}
              />
            );
        }
      })}
    </Text>
  );
};

export { RichText };
export default Container(RichText);
