import IconProps from "../../Types/IconProps";
import theme from "../../Theme";

export default ({ size = "md", color = "currentColor" }: IconProps) => (
  <svg
    width={
      (size === "xl" && "48px") ||
      (size === "lg" && "40px") ||
      (size === "md" && "32px") ||
      (size === "sm" && "24px") ||
      (size === "xs" && "16px") ||
      undefined
    }
    height={
      (size === "xl" && "48px") ||
      (size === "lg" && "40px") ||
      (size === "md" && "32px") ||
      (size === "sm" && "24px") ||
      (size === "xs" && "16px") ||
      undefined
    }
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.5 12.4706C4.08579 12.4706 3.75 12.1348 3.75 11.7206C3.75 11.3064 4.08579 10.9706 4.5 10.9706V12.4706ZM19.5 11.7206L20.0425 11.2027C20.3192 11.4925 20.3192 11.9486 20.0425 12.2384L19.5 11.7206ZM12.781 5.76786C12.495 5.46823 12.506 4.99349 12.8057 4.70748C13.1053 4.42148 13.58 4.43252 13.866 4.73214L12.781 5.76786ZM13.866 18.709C13.58 19.0087 13.1053 19.0197 12.8057 18.7337C12.506 18.4477 12.495 17.9729 12.781 17.6733L13.866 18.709ZM4.5 10.9706H19.5V12.4706H4.5V10.9706ZM18.9575 12.2384L12.781 5.76786L13.866 4.73214L20.0425 11.2027L18.9575 12.2384ZM20.0425 12.2384L13.866 18.709L12.781 17.6733L18.9575 11.2027L20.0425 12.2384Z"
      fill={theme.colors[color]}
    />
  </svg>
);
