import { LayoutProps } from "../../Base/Styled/layout";

type BadgeProps = {
  size: string;
};

export default ({ size }: BadgeProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 26C5.8203 26 0 20.1797 0 13C0 5.8203 5.8203 0 13 0C20.1797 0 26 5.8203 26 13C26 20.1797 20.1797 26 13 26Z"
      fill="#F2B06B"
    />
    <path
      d="M12.7317 8.53666C12.8422 8.31554 13.1578 8.31554 13.2683 8.53666L15.3229 12.6458C15.4118 12.8236 15.6459 12.8673 15.793 12.7336L20.2377 8.693C20.4598 8.49111 20.8079 8.70436 20.7289 8.99392L18.201 18.2631C18.0823 18.6982 17.6872 19 17.2362 19H15.9244H10.0581H8.7638C8.31284 19 7.91769 18.6982 7.79903 18.2631L5.27107 8.99392C5.1921 8.70436 5.54022 8.49111 5.7623 8.693L10.207 12.7336C10.3541 12.8673 10.5882 12.8236 10.6771 12.6458L12.7317 8.53666Z"
      fill="white"
    />
  </svg>
);
