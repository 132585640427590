export const encodeFileToBase64String = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(
        (reader.result as string).replace("data:", "").replace(/^.+,/, "")
      );
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsDataURL(file);
  });
};

export const fileToImageDataUrl = (
  file: File,
  imageType: "png" | "jpg" | "jpeg" = "png",
  maxSize?: number
): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const image = new Image();
      image.onload = () => {
        let width = image.width;
        let height = image.height;
        //resize
        if (maxSize) {
          if (width > height) {
            if (width > maxSize) {
              height = height * (maxSize / width);
              width = maxSize;
            }
          } else {
            if (height > maxSize) {
              width = width * (maxSize / height);
              height = maxSize;
            }
          }
        }
        // convert
        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx!.drawImage(image, 0, 0, width, height);

        resolve(canvas.toDataURL(`image/${imageType}`));
      };
      image.onerror = (error) => {
        reject(error);
      };
      image.src = reader.result as string;
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsDataURL(file);
  });
};

export const dataUrlToBase64 = (dataUrl: string) => {
  return dataUrl.replace("data:", "").replace(/^.+,/, "");
};

export const base64ToJpegDataUrl = (base64: string) => {
  return "data:image/jpeg;base64," + base64;
};

export const isDataUrl = (dataUrl: string) => {
  return dataUrl.startsWith("data:");
};
