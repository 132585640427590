import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Theme from "../../../../../Core/Theme";
import ChevronRight from "../../../../../Resources/SVG/ChevronRight";
import {
  getDurationInDaysForYear,
  getPlantHasPrecultivation,
} from "../../../../../Reducers/Planning/PlanningUtils";

import moment from "moment";
import { PlantPeriodsBar } from "../PlantPeriodsBar";
import {
  DATE_FORMAT_API,
  DATE_FORMAT_FRONTEND,
} from "../../../../../Core/Constants";
import {
  lockGarden,
  unlockGarden,
} from "../../../../../Reducers/GardenLock/Actions";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { getDaysInYear } from "../../seasonHelpers";
import { PlantModal } from "../../PlantModal";

const useStyles = makeStyles((theme) => ({
  ItemContainer: {
    backgroundColor: Theme.colors.beigeMedium,
    borderColor: Theme.colors.beigeDarkest,
    borderWidth: 1,
    borderRadius: 8,
    padding: 4,
    height: 54,
    zIndex: 1,
    marginTop: 8,
    overflow: "hidden",
    cursor: "pointer",
  },
  ImageContainer: {
    height: 32,
    width: 32,
    backgroundColor: Theme.colors.white,
    borderRadius: 16,
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
  Image: { height: 32, width: 32 },
  TextContainer: {
    marginLeft: 4,
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
}));

export const SeasonalPlantItem = ({ plant }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { year, garden } = useSelector((state) => state.Planning);
  const { isLockedByOtherDevice } = useSelector((state) => state.GardenLock);

  const [plantPeriodDates, setPlantPeriodDates] = useState([]);
  const [plantDetailsVisible, setPlantDetailsVisible] = useState(false);
  const [plantStartDayOfYear, setPlantStartDayOfYear] = useState("");
  const [plantTotalDuration, setPlantTotalDuration] = useState(0);
  const hasPrecultivation = getPlantHasPrecultivation(plant);

  useEffect(() => {
    calculatePlantPeriods();
    calculatePlantTotalDuration();
    calculatePlantStartDayOfYear();
  }, [plant]);

  const calculatePlantTotalDuration = () => {
    let start = plant.datePlanted || plant.dateInPatch;
    let end = plant.dateHarvestEnd;

    //If it starts in past year
    if (moment(start, DATE_FORMAT_API).year() < year) {
      start = moment(year, "YYYY").startOf("year").format(DATE_FORMAT_API);
    }

    //If plant the end of growth is in future year
    if (moment(end, DATE_FORMAT_API).year() > year) {
      end = moment(year, "YYYY").endOf("year").format(DATE_FORMAT_API);
    }
    setPlantTotalDuration(getDurationInDaysForYear(start, end, year));
  };

  const calculatePlantStartDayOfYear = () => {
    let startDate = plant.datePlanted || plant.dateInPatch;

    if (moment(startDate, DATE_FORMAT_API).year() < year) {
      startDate = moment(year, "YYYY").startOf("year").format(DATE_FORMAT_API);
    }

    setPlantStartDayOfYear(
      getDurationInDaysForYear(`${year}-01-01`, startDate, year)
    );
  };

  const calculatePlantPeriods = () => {
    const periodDates = [];

    if (plant.datePlanted && hasPrecultivation) {
      periodDates.push({
        color: Theme.colors.greenLight,
        value: moment(plant.datePlanted).format(DATE_FORMAT_FRONTEND),
      });
      periodDates.push({
        color: Theme.colors.greenMedium,
        value: moment(plant.dateInPatch).format(DATE_FORMAT_FRONTEND),
      });
    } else {
      periodDates.push({
        color: Theme.colors.greenMedium,
        value: moment(plant.datePlanted).format(DATE_FORMAT_FRONTEND),
      });
    }

    periodDates.push({
      color: Theme.colors.greenDark,
      value: moment(plant.dateHarvestStart).format(DATE_FORMAT_FRONTEND),
    });

    periodDates.push({
      color: Theme.colors.greenDark,
      value: moment(plant.dateHarvestEnd).format(DATE_FORMAT_FRONTEND),
    });

    setPlantPeriodDates(periodDates);
  };

  const daysInYear = getDaysInYear(year);

  return (
    <div
      className={classes.ItemContainer}
      style={{
        width: `${(plantTotalDuration / daysInYear) * 100}%`,
        left: `${(plantStartDayOfYear / daysInYear) * 100}%`,
        position: "relative",
      }}
    >
      <div
        style={{ width: "100%", height: "100%" }}
        onClick={() => {
          if (garden) {
            dispatch(lockGarden(garden.id));
          }
          setPlantDetailsVisible(true);
        }}
        data-testid={`SeasonalPlantItem.${plant.crop.name}`}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            flex: 1,
            userSelect: "none",
          }}
        >
          <div className={classes.ImageContainer}>
            <img className={classes.Image} src={plant.crop.imageUrl} />
          </div>
          <div className={classes.TextContainer}>
            <span
              style={{
                fontWeight: 700,
                fontSize: Theme.sizes.small,
                lineHeight: 1.2,
              }}
            >
              {plant.crop.name}
            </span>
            <span
              style={{
                fontWeight: 400,
                fontSize: Theme.sizes.small,
                lineHeight: 1.2,
              }}
            >
              {plant.crop.varietyName || plant.crop.latinName}
            </span>
          </div>
          <ChevronRight size="sm" />

          <PlantModal
            plantData={{
              ...plant,
              plantDuration: plantTotalDuration,
              plantStart: plantStartDayOfYear,
            }}
            visible={plantDetailsVisible && !isLockedByOtherDevice}
            onDismiss={() => {
              if (garden) {
                dispatch(unlockGarden(garden.id));
              }
              setPlantDetailsVisible(false);
            }}
          />
        </div>
        <PlantPeriodsBar
          style={{
            marginTop: 4,
          }}
          periodDates={plantPeriodDates}
          withPeriodMarks={false}
          year={year}
        />
      </div>
    </div>
  );
};
