import Api from "../../Core/Api";
import { goToLogout, resetToken } from "../User/Actions";
export const REQUEST_DATA = "REQUEST_DATA";
export const POST_DATA = "POST_DATA";
export const RECEIVE_DATA = "RECEIVE_DATA";
export const PUT_DATA = "PUT_DATA";

const actionsQueue = [];

const saveAction = (method, source, data, receiver) => {
  actionsQueue.push({ method, source, data, receiver });
};

const requestData = (source) => ({
  type: REQUEST_DATA,
  source,
});

export const postRequest = (source, data, options) => ({
  type: POST_DATA,
  source,
  data,
  options,
});

const receiveData = (source, data, status) => ({
  type: RECEIVE_DATA,
  source,
  data,
  status,
  isConnected:
    status === 200 ||
    status === 401 ||
    status === 403 ||
    status === 404 ||
    status === 409 ||
    status === 400,
  receivedAt: Date.now(),
});

let _retryNumberOfAttempts = 0;
const handleResponse = (source, request, receiver, dispatch) =>
  request
    .then((response) => {
      _retryNumberOfAttempts = 0;
      dispatch(receiver(response.data, response.status));
      return dispatch(receiveData(source, response.data, response.status));
    })
    .catch((error) => {
      const errorObj = JSON.parse(JSON.stringify(error));
      console.log(errorObj);
      console.log(error);

      let status = 0;
      let data = {};
      if (error.request) {
        status = error.request.status;
      }
      if (error.response) {
        data = error.response.data;
      }

      if (status === 0 && _retryNumberOfAttempts < 3) {
        setTimeout(() => {
          retryAction(
            {
              method: errorObj.config.method,
              source,
              data: errorObj.config.data,
              receiver,
            },
            dispatch
          );
        }, 100 * 3 ** _retryNumberOfAttempts);
        _retryNumberOfAttempts += 1;
        return;
      }
      if (status !== 401) {
        saveAction(
          errorObj.config.method,
          source,
          errorObj.config.data,
          receiver
        );
      }

      if (status === 403) {
        handleAuthError(dispatch).catch(dispatch(goToLogout()));
      }

      dispatch(receiver(data, status));
      return dispatch(receiveData(source, error, status));
    });

const handleAuthError = async (dispatch) => {
  await dispatch(resetToken());
  window.location.reload();
};

export const fetchData = (source, receiver) => (dispatch) => {
  dispatch(requestData(source));
  return handleResponse(source, Api.request(source), receiver, dispatch);
};

export const postData =
  (source, data, receiver, method, options = {}) =>
  (dispatch) => {
    dispatch(postRequest(source, data, options));

    if (method === undefined) {
      method = "post";
    }

    return handleResponse(
      source,
      Api.request({ url: source, method: method, data: data }),
      receiver,
      dispatch
    );
  };

export const patchData = (source, data, receiver) => (dispatch) => {
  dispatch(postData(source, data, receiver, "patch"));
};

export const deleteData = (source, data, receiver) => (dispatch) => {
  return dispatch(postData(source, data, receiver, "delete"));
};

export const putData = (source, data, receiver) => (dispatch) => {
  dispatch(postData(source, data, receiver, "put"));
};

const retryAction = (action, dispatch) => {
  switch (action.method) {
    case "get":
      return dispatch(fetchData(action.source, action.receiver));
    case "post":
    case "put":
    case "patch":
    case "delete":
      return dispatch(
        postData(action.source, action.data, action.receiver, action.method)
      );
    default:
      return;
  }
};

export const dispatchSavedActions = () => (dispatch) => {
  actionsQueue.forEach((action) => retryAction(action, dispatch));
  actionsQueue.length = 0;
};
