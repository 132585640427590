import React from "react";
export default ({ width = 32, height = 32 }) => (
  <svg
    width={`${width}`}
    height={`${height}`}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
        <rect x="4" y="4" width="24" height="24" rx="5" fill="currentColor" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.615 7.6923H21.9996C23.2741 7.6923 24.3073 8.72549 24.3073 10V22.0068C24.3073 23.2813 23.2741 24.3145 21.9996 24.3145H9.99959C8.72508 24.3145 7.69189 23.2813 7.69189 22.0068V10C7.69189 8.72549 8.72508 7.6923 9.99959 7.6923H11.3842V7.23076C11.3842 6.97586 11.5908 6.76923 11.8457 6.76923C12.1006 6.76923 12.3073 6.97586 12.3073 7.23076V7.6923H19.6919V7.23076C19.6919 6.97586 19.8985 6.76923 20.1534 6.76923C20.4083 6.76923 20.615 6.97586 20.615 7.23076V7.6923ZM19.6919 8.61538H12.3073V9.07692C12.3073 9.33182 12.1006 9.53846 11.8457 9.53846C11.5908 9.53846 11.3842 9.33182 11.3842 9.07692V8.61538H9.99959C9.23489 8.61538 8.61497 9.2353 8.61497 10V12.3077H23.3842V10C23.3842 9.2353 22.7643 8.61538 21.9996 8.61538H20.615V9.07692C20.615 9.33182 20.4083 9.53846 20.1534 9.53846C19.8985 9.53846 19.6919 9.33182 19.6919 9.07692V8.61538ZM8.61497 13.2308H23.3842V22.0068C23.3842 22.7715 22.7643 23.3914 21.9996 23.3914H9.99959C9.23489 23.3914 8.61497 22.7715 8.61497 22.0068V13.2308ZM9.53805 14.6154C9.53805 14.3605 9.74469 14.1538 9.99959 14.1538H11.8457C12.1006 14.1538 12.3073 14.3605 12.3073 14.6154V16.4615C12.3073 16.7164 12.1006 16.9231 11.8457 16.9231H9.99959C9.74469 16.9231 9.53805 16.7164 9.53805 16.4615V14.6154ZM10.4611 16V15.0769H11.3842V16H10.4611Z"
          fill="white"
        />
  </svg>
);
