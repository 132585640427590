import {} from "styled-components/macro";

import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import Box from "../../Layout/Box";
import Row from "../../Layout/Row";

type SwitchProps = {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
};

const Switch = ({ checked: controlledChecked, onChange }: SwitchProps) => {
  const [checked, setChecked] = useState<boolean>(controlledChecked ?? false);

  useEffect(() => {
    setChecked(controlledChecked ?? false);
  }, [controlledChecked]);
  useEffect(() => {
    onChange?.(checked);
  }, [checked]);

  return (
    <Box>
      <Row
        as="label"
        position="relative"
        overflow="hidden"
        height="32px"
        width="60px"
        borderRadius="full"
        backgroundColor={checked ? "greenApp" : "greyMedium"}
        css={`
          cursor: pointer;
        `}
      >
        <HiddenCheckbox
          checked={checked}
          onChange={(e) => {
            if (controlledChecked == null) {
              setChecked(e.target.checked);
            } else {
              onChange?.(e.target.checked);
            }
          }}
        />
        <Thumb checked={checked} />
      </Row>
    </Box>
  );
};

export default Switch;

const Thumb = styled(Box)<{ checked: boolean }>(
  ({ theme, checked }) => css`
    box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.15);
    background-color: ${theme.colors.white};
    width: 28px;
    height: 28px;
    margin: 2px;
    border-radius: ${theme.radii.full};
    position: absolute;
    ${checked
      ? css`
          left: 28px;
        `
      : css`
          left: 0px;
        `}
    transition: left 250ms ease;
  `
);

const HiddenCheckbox = styled.input.attrs(() => ({
  type: "checkbox",
}))`
  opacity: 0;
  height: 0;
  width: 0;
  margin: 0;
  padding: 0;
`;
