import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { SpaceProps } from "../../Base/Styled/space";
import { LayoutProps } from "../../Base/Styled/layout";
import { PlantingType } from "../../Screens/Gardening/Consts";
import { Row, Text } from "../../Base";
import RadioSelect from "../../Base/Input/RadioSelect";
import typography from "../../Base/Styled/typography";

const ItemLabel = styled(Text).attrs({
  as: "span",
  fontSize: "md",
  lineHeight: "1.5",
  fontWeight: "bold",
})`
  width: 100%;
  ${typography}
`;

type PlantingTypeChoiceProps = {
  value: keyof typeof PlantingType;
  onChange: (value: keyof typeof PlantingType) => void;
  testId?: string;
} & SpaceProps &
  LayoutProps;

const PlantingTypeChoice = ({
  value,
  onChange,
  testId,
  ...props
}: PlantingTypeChoiceProps) => {
  const { t } = useTranslation("season");
  return (
    <RadioSelect<keyof typeof PlantingType>
      options={Object.entries(PlantingType).map(([k, v]) => ({
        value: k as keyof typeof PlantingType,
        label: (
          <Row alignItems="center" gap="8px" width={"100%"}>
            <ItemLabel>{t(`plantingTypes.${v}`)}</ItemLabel>
          </Row>
        ),
      }))}
      value={value}
      onChange={onChange}
      radioButtonIsLeft={false}
      testId={testId}
      {...props}
    />
  );
};
export default PlantingTypeChoice;
