import theme from "../../Theme";
import IconProps from "../../Types/IconProps";

export default ({ size = "md", color = "currentColor" }: IconProps) => (
  <svg
    width={size === "sm" ? "24px" : "70px"}
    height={size === "sm" ? "24px" : "71px"}
    viewBox="0 0 70 71"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M69.854 29.1142C69.854 37.2803 70.2215 43.6314 66.8752 51.1862C62.032 62.1207 57.9788 68.2064 45.2822 69.7608C34.0586 71.1349 26.3415 67.7175 17.3423 61.3814C7.74227 54.6222 1.87342 47.8786 0.342802 36.83C-0.695869 29.3324 0.609593 24.5175 4.4805 17.8551C11.5429 5.69958 22.7268 1.05425 37.6529 0.216368C48.5744 -0.39671 55.0977 1.31099 62.6426 8.61867C69.3669 15.1314 69.854 20.1354 69.854 29.1142Z"
      fill={theme.colors[color]}
    />
  </svg>
);
