import React, { useCallback, useEffect, useState } from "react";

import { Flex } from "../../../Base";

import { useTranslation } from "react-i18next";
import {
  BooleanFilter,
  CategoryFilter,
  CropSortBy,
  DemandFilter,
  FavoritesFilter,
  ICropFilter,
  ISortBy,
  LightingDemandFilter,
  NutrientDemandFilter,
  PropertyFilter,
  SortOrder,
  WaterDemandFilter,
} from "../libraryHelpers";
import Dropdown, { ChoiceType, OptionType } from "../../../Base/Input/Dropdown";
import Crop from "../../../Types/API/Crop";

import {
  LightingDemandType,
  NutrientDemandType,
  WaterDemandType,
} from "../../Planning/PatchPlan/Consts";
import { useDispatch, useSelector } from "react-redux";
import Property, {
  PropertyGroupWithProperties,
} from "../../../Types/API/Property";
import CropCategory, {
  ParentCropCategoryWithCategories,
} from "../../../Types/API/CropCategory";
import styled, { css } from "styled-components";
import ToggleButton from "../../../Base/Input/ToggleButton";
import {
  loadCropcategories,
  loadProperties,
} from "../../../Reducers/CropData/Actions";
import { CropSortType } from "../../../Helpers/FilterHelper";

export enum CategoryType {
  VEGETABLES = "vegetables",
  FRUITS = "fruits",
  CEREALS = "cereals",
  HERBS = "herbs",
  FLOWERING_PLANTS = "floweringPlants",
}

type FilterContainerProps = {
  compact: boolean;
  show?: boolean;
  childrenHaveShadows?: boolean;
};

const capitalizeFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

const FilterContainer = styled(Flex)<FilterContainerProps>`
  ${({ show = false }) =>
    !show &&
    css`
      display: none;
    `}
  ${({ theme }) => css`
    > .dropdown {
      min-width: ${theme.sizes["180px"]};
    }
  `}

  ${({ compact = false, theme }) =>
    compact &&
    css`
      flex: 0 0 auto;
      > * {
        width: 100%;
      }
    `}
  ${({ childrenHaveShadows = false, theme }) =>
    childrenHaveShadows &&
    css`
      > * {
        box-shadow: ${theme.shadows.lg};
      }
    `}
`;

export type FilterOption = boolean | string;

type CropFiltersProps = {
  compactDisplay?: boolean;
  onChange: (
    filters: ICropFilter<FilterOption>[],
    sortBy: ISortBy<Crop, CropSortType> | undefined
  ) => void;
  chosenSortBy?: CropSortBy;
  resetValues?: boolean;
  chosenFilters: ICropFilter<FilterOption>[];
  isOpen?: boolean;
};

const processParentCropCategories = (cropcategories: any) =>
  cropcategories.reduce(
    (parentCats: ParentCropCategoryWithCategories[], cat: CropCategory) => {
      if (cat.parent) {
        const parent = cropcategories.find(
          (c: CropCategory) => c.id === cat.parent?.id
        );
        if (parent) {
          let parentCropCategory = parentCats.find(
            (c) => c.parent.id === parent.id
          );
          if (!parentCropCategory) {
            parentCropCategory = new ParentCropCategoryWithCategories(
              parent,
              []
            );
            parentCats.push(parentCropCategory);
          }
          parentCropCategory.push(cat);
        }
      } else {
        let parentCropCategory = parentCats.find((c) => c.parent.id === cat.id);
        if (!parentCropCategory) {
          parentCropCategory = new ParentCropCategoryWithCategories(cat, []);
          parentCats.push(parentCropCategory);
        }
      }
      return parentCats;
    },
    []
  );

const onChangeCategory = (
  categoryFilters: ICropFilter<FilterOption>[],
  onChange: CropFiltersProps["onChange"],
  parentCategory: ParentCropCategoryWithCategories,
  parentCropCategories: ParentCropCategoryWithCategories[],
  values: OptionType["value"][]
) => {
  const newCategoryFilters = [...categoryFilters];
  const idx = newCategoryFilters.findIndex(
    (f) => f.id === parentCategory.parent.name
  );
  if (idx === -1) {
    if (values.length > 0) {
      const pcc = parentCropCategories.find(
        (pcc) => pcc.parent.name === parentCategory.parent.name
      );
      if (pcc) {
        const options = pcc.categories.map((cc) => cc.name);
        const filter = new CategoryFilter(
          parentCategory.parent.name,
          options,
          values
        );
        newCategoryFilters.push(filter);
      } else {
        throw new Error(
          `unknown parentCategory '${parentCategory.parent.name}'`
        );
      }
    }
  } else {
    const filter = newCategoryFilters[idx];
    if (values.length > 0) {
      filter.chosen = values;
    } else {
      newCategoryFilters.splice(idx, 1);
    }
  }
  onChange([...newCategoryFilters], undefined);
};

const ParentCropFilters = ({
  onChange,
  chosenSortBy,
  resetValues,
  compactDisplay = false,
  chosenFilters = [],
  isOpen = false,
}: CropFiltersProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("library");
  const { cropcategories, properties } = useSelector(
    (state: any) => state.CropData
  );
  const { isLoggedIn } = useSelector((state: any) => state.User);

  useEffect(() => {
    if (properties.length === 0) {
      dispatch(loadProperties());
    }
    if (cropcategories.length === 0) {
      dispatch(loadCropcategories());
    }
  }, [properties, cropcategories]);

  const [categoryFilters, setCategoryFilters] = useState<
    ICropFilter<FilterOption>[]
  >(chosenFilters.filter((cf) => cf instanceof CategoryFilter));
  const [booleanFilters, setBooleanFilters] = useState<
    ICropFilter<FilterOption>[]
  >(chosenFilters.filter((cf) => cf instanceof BooleanFilter));

  const [demandFilters, setDemandFilters] = useState<
    ICropFilter<FilterOption>[]
  >(chosenFilters.filter((cf) => cf instanceof DemandFilter));
  const [sortBy, setSortBy] = useState<ISortBy<Crop, CropSortType> | undefined>(
    chosenSortBy
  );

  const [activeCategoryFilters, setActiveCategoryFilters] = useState<string[]>(
    chosenFilters
      .filter((cf) => cf instanceof CategoryFilter)
      .filter((f) => f.chosen.length > 0)
      .map((f) => f.id)
  );
  const [activeBooleanFilters, setActiveBooleanFilters] = useState<string[]>(
    chosenFilters
      .filter((cf) => cf instanceof BooleanFilter)
      .filter((f) => f.chosen.length > 0)
      .map((f) => f.id)
  );

  useEffect(() => {
    setCategoryFilters(
      chosenFilters.filter((cf) => cf instanceof CategoryFilter)
    );
    setActiveCategoryFilters(
      chosenFilters
        .filter((cf) => cf instanceof CategoryFilter)
        .filter((f) => f.chosen.length > 0)
        .map((f) => f.id)
    );
    setBooleanFilters(
      chosenFilters.filter((cf) => cf instanceof BooleanFilter)
    );
    setActiveBooleanFilters(
      chosenFilters
        .filter((cf) => cf instanceof BooleanFilter)
        .filter((f) => f.chosen.length > 0)
        .map((f) => f.id)
    );
    setDemandFilters(chosenFilters.filter((cf) => cf instanceof DemandFilter));
  }, [chosenFilters]);

  useEffect(() => {
    setSortBy(chosenSortBy);
  }, [chosenSortBy]);

  const sortOptions: OptionType[] = [
    CropSortType.ABC,
    CropSortType.LATEST,
    CropSortType.MOST_POPULAR,
  ].map((st) => ({ value: st, label: t(`filters.sortBy.value.${st}`) }));

  const lightingDemandOptions: OptionType[] = [
    LightingDemandType.LIGHT,
    LightingDemandType.MEDIUM,
    LightingDemandType.HEAVY,
  ].map((st) => ({
    value: st,
    label: t(`filters.lightingDemand.value.${st}`),
  }));

  const waterDemandOptions: OptionType[] = [
    WaterDemandType.LIGHT,
    WaterDemandType.MEDIUM,
    WaterDemandType.HEAVY,
  ].map((st) => ({
    value: st,
    label: t(`filters.waterDemand.value.${st}`),
  }));

  const nutrientDemandOptions: OptionType[] = [
    NutrientDemandType.LIGHT,
    NutrientDemandType.MEDIUM,
    NutrientDemandType.HEAVY,
  ].map((st) => ({
    value: st,
    label: t(`filters.nutrientDemand.value.${st}`),
  }));

  const parentCropCategories: ParentCropCategoryWithCategories[] =
    processParentCropCategories(cropcategories);

  const onChangeSortBy = (sortBy?: CropSortBy) => {
    onChange([...categoryFilters, ...booleanFilters, ...demandFilters], sortBy);
  };
  const onChangeBoolean = (filterID: string, value: boolean) => {
    const newBooleanFilters: ICropFilter<FilterOption>[] = [...booleanFilters];
    if (value) {
      setActiveBooleanFilters([...activeBooleanFilters, filterID]);
      switch (filterID) {
        case "favorites":
          newBooleanFilters.push(new FavoritesFilter(filterID, value));
          break;
        default:
          throw new Error(`unknown boolean filter '${filterID}'`);
      }
    } else {
      const idx = newBooleanFilters.findIndex((f) => f.id === filterID);
      if (idx !== -1) {
        newBooleanFilters.splice(idx, 1);
        setActiveBooleanFilters(newBooleanFilters.map((f) => f.id));
      }
    }
    onChange(
      [...categoryFilters, ...newBooleanFilters, ...demandFilters],
      sortBy
    );
  };

  const onChangeDemand = (filterID: string, values: OptionType["value"][]) => {
    const newDemandFilters: ICropFilter<FilterOption>[] = [...demandFilters];
    const idx = newDemandFilters.findIndex((f) => f.id === filterID);
    if (idx !== -1) {
      newDemandFilters.splice(idx, 1);
    }
    if (values.length > 0) {
      switch (filterID) {
        case "lightingDemand":
          newDemandFilters.push(new LightingDemandFilter(filterID, values[0]));
          break;
        case "nutrientDemand":
          newDemandFilters.push(new NutrientDemandFilter(filterID, values[0]));
          break;
        case "waterDemand":
          newDemandFilters.push(new WaterDemandFilter(filterID, values[0]));
          break;
        default:
          throw new Error(`unknown demand filter '${filterID}'`);
      }
    }
    onChange(
      [...categoryFilters, ...booleanFilters, ...newDemandFilters],
      sortBy
    );
  };

  const getChosenCategories = useCallback(
    (pc: ParentCropCategoryWithCategories) => {
      const filters = categoryFilters.filter((f) => f.id === pc.parent.name);
      if (filters.length === 1) {
        return filters[0].chosen.map((o) => o.toString());
      } else {
        if (filters.length > 1) {
          throw new Error(
            `found too many category filters by the name of ${pc.parent.name}`
          );
        }
        return [];
      }
    },
    [categoryFilters]
  );

  const getChosenDemand = useCallback(
    (filterID: string) => {
      const filters = demandFilters.filter((f) => f.id === filterID);
      if (filters.length === 1) {
        return filters[0].chosen.map((o) => o.toString());
      } else {
        if (filters.length > 1) {
          throw new Error(
            `found too many demand filters by the name of ${filterID}`
          );
        }
        return [];
      }
    },
    [demandFilters]
  );

  const getChosenSortBy = useCallback(() => {
    if (sortBy) {
      return [sortBy.type.toString()];
    } else {
      return [];
    }
  }, [sortBy]);

  const parentCropCategoriesForDisplay = [...parentCropCategories];
  let vegetableParentCropCategory: ParentCropCategoryWithCategories | undefined;
  const vegetableParentCropCategoryIdx =
    parentCropCategoriesForDisplay.findIndex(
      (pc) => pc.parent.name === "Gemüse"
    );
  if (vegetableParentCropCategoryIdx !== -1) {
    vegetableParentCropCategory = parentCropCategoriesForDisplay.splice(
      vegetableParentCropCategoryIdx,
      1
    )[0];
  }

  return (
    <Flex
      flexDirection={"column"}
      gap={compactDisplay ? "8px" : "16px"}
      width={"100%"}
    >
      <FilterContainer
        flexWrap={"wrap"}
        gap={compactDisplay ? "8px" : "16px"}
        justifyContent={"center"}
        flexDirection={compactDisplay ? "column" : "row"}
        compact={compactDisplay}
        show={
          isOpen && (properties.length !== 0 || cropcategories.length !== 0)
        }
        width={"100%"}
      >
        {vegetableParentCropCategory && (
          <Dropdown
            type={ChoiceType.MULTIPLE}
            options={vegetableParentCropCategory.categories.map<OptionType>(
              (c) => ({
                value: c.name,
                label: capitalizeFirstLetter(c.name),
              })
            )}
            values={getChosenCategories(vegetableParentCropCategory)}
            resetValues={!!resetValues}
            label={vegetableParentCropCategory.parent.name}
            resetLabel={t("filters.resetLabel")}
            selectAllLabel={t("filters.vegetables.label.selectAll")}
            onReset={() => {}}
            compactDisplay={compactDisplay}
            onChange={(values) => {
              if (vegetableParentCropCategory !== undefined) {
                onChangeCategory(
                  categoryFilters,
                  onChange,
                  vegetableParentCropCategory,
                  parentCropCategories,
                  values
                );
              }
            }}
          />
        )}
        <ToggleButton
          width={compactDisplay ? "100%" : "min-content"}
          justifyContent={"space-between"}
          active={!!activeBooleanFilters.find((f) => f === "favorites")}
          label={t("filters.favorites.title")}
          onChange={(v) => {
            onChangeBoolean("favorites", v);
          }}
        />
        {parentCropCategoriesForDisplay.map((pc) => {
          return (
            <ToggleButton
              key={`toggle-${pc.parent.id}`}
              width={compactDisplay ? "100%" : "min-content"}
              justifyContent={"space-between"}
              active={!!activeCategoryFilters.find((f) => f === pc.parent.name)}
              label={pc.parent.name}
              onChange={(v) => {
                if (v) {
                  setActiveCategoryFilters(
                    activeCategoryFilters.concat([pc.parent.name])
                  );

                  onChangeCategory(
                    categoryFilters,
                    onChange,
                    pc,
                    parentCropCategories,
                    pc.categories.map<string>((p) => p.name)
                  );
                } else {
                  const idx = activeCategoryFilters.findIndex(
                    (f) => f === pc.parent.name
                  );
                  const newActiveCategoryFilters =
                    activeCategoryFilters.slice();
                  newActiveCategoryFilters.splice(idx, 1);
                  setActiveCategoryFilters(newActiveCategoryFilters);
                  onChangeCategory(
                    categoryFilters,
                    onChange,
                    pc,
                    parentCropCategories,
                    []
                  );
                }
              }}
            />
          );
        })}
      </FilterContainer>
      <FilterContainer
        flexWrap={"wrap"}
        gap={compactDisplay ? "8px" : "16px"}
        justifyContent={"center"}
        flexDirection={compactDisplay ? "column" : "row"}
        compact={compactDisplay}
        show={
          isOpen && (properties.length !== 0 || cropcategories.length !== 0)
        }
        width={"100%"}
      >
        <Dropdown
          type={ChoiceType.SINGLE}
          options={lightingDemandOptions}
          values={getChosenDemand("lightingDemand")}
          label={t("filters.lightingDemand.title")}
          resetLabel={t("filters.resetLabel")}
          resetValues={!!resetValues}
          onReset={() => {}}
          compactDisplay={compactDisplay}
          onChange={(values) => onChangeDemand("lightingDemand", values)}
        />
        <Dropdown
          type={ChoiceType.SINGLE}
          options={waterDemandOptions}
          values={getChosenDemand("waterDemand")}
          label={t("filters.waterDemand.title")}
          resetLabel={t("filters.resetLabel")}
          resetValues={!!resetValues}
          onReset={() => {}}
          compactDisplay={compactDisplay}
          onChange={(values) => onChangeDemand("waterDemand", values)}
        />
        <Dropdown
          type={ChoiceType.SINGLE}
          options={nutrientDemandOptions}
          values={getChosenDemand("nutrientDemand")}
          label={t("filters.nutrientDemand.title")}
          resetLabel={t("filters.resetLabel")}
          resetValues={!!resetValues}
          onReset={() => {}}
          compactDisplay={compactDisplay}
          onChange={(values) => onChangeDemand("nutrientDemand", values)}
        />
      </FilterContainer>
      <FilterContainer
        flexWrap={"wrap"}
        gap={compactDisplay ? "8px" : "16px"}
        justifyContent={"center"}
        flexDirection={compactDisplay ? "column" : "row"}
        compact={compactDisplay}
        show={
          isOpen && (properties.length !== 0 || cropcategories.length !== 0)
        }
        width={"100%"}
      >
        <Dropdown
          type={ChoiceType.SINGLE}
          options={sortOptions}
          values={getChosenSortBy()}
          label={t("filters.sortBy.title")}
          resetLabel={t("filters.resetLabel")}
          resetValues={!!resetValues}
          open={false}
          onReset={() => {}}
          compactDisplay={compactDisplay}
          onChange={(values) => {
            if (values.length === 0) {
              onChangeSortBy();
            } else {
              if (values[0] === CropSortType.MOST_POPULAR.toString()) {
                onChangeSortBy(
                  new CropSortBy(CropSortType.MOST_POPULAR, SortOrder.DESC)
                );
              } else if (values[0] === CropSortType.ABC.toString()) {
                onChangeSortBy(new CropSortBy(CropSortType.ABC, SortOrder.ASC));
              } else if (values[0] === CropSortType.LATEST.toString()) {
                onChangeSortBy(
                  new CropSortBy(CropSortType.LATEST, SortOrder.DESC)
                );
              }
            }
          }}
        />
      </FilterContainer>
    </Flex>
  );
};

type VarietyFiltersProps = CropFiltersProps & {
  properties: Property[];
  isOpen?: boolean;
};

export const VarietyFilters = ({
  onChange,
  properties,
  chosenSortBy,
  resetValues,
  compactDisplay = false,
  chosenFilters = [],
  isOpen = false,
}: VarietyFiltersProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("library");
  const { cropcategories } = useSelector((state: any) => state.CropData);
  useEffect(() => {
    if (cropcategories.length === 0) {
      dispatch(loadCropcategories());
    }
  }, [cropcategories]);

  // TODO find a better way of determining whether a property
  // should be a quick access property
  const quickAccessPropertyNames = ["Sonstige"];

  const [quickAccessFilters, setQuickAccessFilters] = useState<
    ICropFilter<FilterOption>[]
  >(
    chosenFilters.filter(
      (cf) =>
        quickAccessPropertyNames.indexOf(cf.id) !== -1 &&
        cf instanceof PropertyFilter
    )
  );
  const [propertyFilters, setPropertyFilters] = useState<
    ICropFilter<FilterOption>[]
  >(
    chosenFilters.filter(
      (cf) =>
        quickAccessPropertyNames.indexOf(cf.id) === -1 &&
        cf instanceof PropertyFilter
    )
  );
  const [sortBy, setSortBy] = useState<ISortBy<Crop, CropSortType> | undefined>(
    chosenSortBy
  );

  useEffect(() => {
    setQuickAccessFilters(
      chosenFilters.filter(
        (cf) =>
          quickAccessPropertyNames.indexOf(cf.id) !== -1 &&
          cf instanceof PropertyFilter
      )
    );
    setPropertyFilters(
      chosenFilters.filter(
        (cf) =>
          quickAccessPropertyNames.indexOf(cf.id) === -1 &&
          cf instanceof PropertyFilter
      )
    );
  }, [chosenFilters]);
  useEffect(() => {
    setSortBy(chosenSortBy);
  }, [chosenSortBy]);

  const sortOptions: OptionType[] = [
    CropSortType.ABC,
    CropSortType.LATEST,
    CropSortType.MOST_POPULAR,
  ].map((st) => ({ value: st, label: t(`filters.sortBy.value.${st}`) }));

  const othersGroupTitle = t("filters.others.title");
  const propertyGroupsWithProperties: PropertyGroupWithProperties[] =
    properties.reduce((pgs: PropertyGroupWithProperties[], p: Property) => {
      let pgwp = pgs.find(
        (pgwp) =>
          pgwp.propertyGroup.name ===
          (p.propertygroup ? p.propertygroup.name : othersGroupTitle)
      );
      if (!pgwp) {
        pgwp = new PropertyGroupWithProperties(
          p.propertygroup ?? { id: -1, name: othersGroupTitle },
          []
        );
        pgs.push(pgwp);
      }
      pgwp.push(p);
      return pgs;
    }, []);

  // TODO implement proper quick access config
  const idx = propertyGroupsWithProperties.findIndex(
    (pgs) => quickAccessPropertyNames.indexOf(pgs.propertyGroup.name) !== -1
  );
  let quickAccessPropertyGroups: PropertyGroupWithProperties[] = [];
  if (idx !== -1) {
    quickAccessPropertyGroups = propertyGroupsWithProperties.splice(idx, 1);
  }

  const onChangeSortBy = (sortBy?: CropSortBy) => {
    onChange([...quickAccessFilters, ...propertyFilters], sortBy);
  };

  const onChangeProperty = (
    filterID: string,
    values: OptionType["value"][]
  ) => {
    const newPropertyFilters = [...propertyFilters];
    let idx = newPropertyFilters.findIndex((f) => f.id === filterID);
    if (idx === -1) {
      if (values.length > 0) {
        const pgwp = propertyGroupsWithProperties.find(
          (pgwp) => pgwp.propertyGroup.name === filterID
        );
        if (pgwp) {
          const options = pgwp.properties.map((p) => p.name);
          const filter = new PropertyFilter(filterID, options, values);
          newPropertyFilters.push(filter);
        } else {
          throw new Error(`unknown property '${filterID}'`);
        }
      }
    } else {
      const filter = newPropertyFilters[idx];
      if (values.length > 0) {
        filter.chosen = values;
      } else {
        newPropertyFilters.splice(idx, 1);
      }
    }
    onChange([...quickAccessFilters, ...newPropertyFilters], sortBy);
  };

  const onChangeQuickAccess = (
    filterID: string,
    value: OptionType["value"]
  ) => {
    const newQuickAccessFilters = [...quickAccessFilters];
    let idx = newQuickAccessFilters.findIndex((f) => f.id === filterID);
    if (idx === -1) {
      const pgwp = quickAccessPropertyGroups.find(
        (pgwp) => pgwp.propertyGroup.name === filterID
      );
      if (pgwp) {
        const options = pgwp.properties.map((p) => p.name);
        const filter = new PropertyFilter(filterID, options, [value]);
        newQuickAccessFilters.push(filter);
      } else {
        throw new Error(`unknown quick access property '${filterID}'`);
      }
    } else {
      const filter = newQuickAccessFilters[idx];
      const chosenIdx = filter.chosen.indexOf(value);
      if (chosenIdx === -1) {
        filter.chosen.push(value);
      } else {
        filter.chosen.splice(chosenIdx, 1);
      }
      if (filter.chosen.length === 0) {
        newQuickAccessFilters.splice(idx, 1);
      }
    }
    onChange([...newQuickAccessFilters, ...propertyFilters], sortBy);
  };

  const quickAccessPropertyIsChosen = useCallback(
    (pgs: PropertyGroupWithProperties, p: Property) => {
      const filters = quickAccessFilters.filter(
        (f) => f.id === pgs.propertyGroup.name
      );
      if (filters.length === 1) {
        return filters[0].chosen.indexOf(p.name) !== -1;
      } else {
        if (filters.length > 1) {
          throw new Error(
            `found too many quick access filters by the name of ${pgs.propertyGroup.name}`
          );
        }
        return false;
      }
    },
    [quickAccessFilters]
  );

  const getChosenProperties = useCallback(
    (pgs: PropertyGroupWithProperties) => {
      const filters = propertyFilters.filter(
        (f) => f.id === pgs.propertyGroup.name
      );
      if (filters.length === 1) {
        return filters[0].chosen.map((o) => o.toString());
      } else {
        if (filters.length > 1) {
          throw new Error(
            `found too many quick access filters by the name of ${pgs.propertyGroup.name}`
          );
        }
        return [];
      }
    },
    [propertyFilters]
  );
  const getChosenSortBy = useCallback(() => {
    if (sortBy) {
      return [sortBy.type.toString()];
    } else {
      return [];
    }
  }, [sortBy]);

  return (
    <FilterContainer
      flexWrap={"wrap"}
      gap={compactDisplay ? "8px" : "16px"}
      justifyContent={"center"}
      flexDirection={compactDisplay ? "column" : "row"}
      compact={compactDisplay}
      show={isOpen}
      width={"100%"}
      childrenHaveShadows={compactDisplay}
    >
      {quickAccessPropertyGroups
        .map((pgwp) => {
          return pgwp.properties.map((p) => (
            <ToggleButton
              key={`${pgwp.propertyGroup.name}_${p.name}`}
              width={compactDisplay ? "100%" : "min-content"}
              justifyContent={"space-between"}
              active={quickAccessPropertyIsChosen(pgwp, p)}
              label={p.name}
              onChange={(v) =>
                onChangeQuickAccess(pgwp.propertyGroup.name, p.name)
              }
            />
          ));
        })
        .reduce((arr, b) => {
          arr.push(...b);
          return arr;
        }, [])}
      <Dropdown
        type={ChoiceType.SINGLE}
        options={sortOptions}
        values={getChosenSortBy()}
        label={t("filters.sortBy.title")}
        resetLabel={t("filters.resetLabel")}
        resetValues={!!resetValues}
        open={false}
        compactDisplay={compactDisplay}
        onReset={() => {}}
        onChange={(values) => {
          if (values.length === 0) {
            onChangeSortBy();
          } else {
            if (values[0] === CropSortType.MOST_POPULAR.toString()) {
              onChangeSortBy(
                new CropSortBy(CropSortType.MOST_POPULAR, SortOrder.DESC)
              );
            } else if (values[0] === CropSortType.ABC.toString()) {
              onChangeSortBy(new CropSortBy(CropSortType.ABC, SortOrder.ASC));
            } else if (values[0] === CropSortType.LATEST.toString()) {
              onChangeSortBy(
                new CropSortBy(CropSortType.LATEST, SortOrder.DESC)
              );
            }
          }
        }}
      />
      {propertyGroupsWithProperties.map((pgwp) => (
        <Dropdown
          key={`dropdown-${pgwp.propertyGroup.name}-${pgwp.propertyGroup.id}`}
          type={ChoiceType.MULTIPLE}
          resetValues={!!resetValues}
          options={pgwp.properties.map<OptionType>((p) => ({
            value: p.name,
            label: capitalizeFirstLetter(p.name),
          }))}
          values={getChosenProperties(pgwp)}
          compactDisplay={compactDisplay}
          label={pgwp.propertyGroup.name}
          resetLabel={t("filters.resetLabel")}
          onReset={() => {}}
          onChange={(values) =>
            onChangeProperty(pgwp.propertyGroup.name, values)
          }
        />
      ))}
    </FilterContainer>
  );
};

export const CategoryFilters = ({
  onChange,
  chosenFilters = [],
}: CropFiltersProps) => {
  const dispatch = useDispatch();
  const { cropcategories } = useSelector((state: any) => state.CropData);
  const compactDisplay = false;
  const isOpen = true;

  useEffect(() => {
    if (cropcategories.length === 0) {
      dispatch(loadCropcategories());
    }
  }, [cropcategories]);

  const [categoryFilters, setCategoryFilters] = useState<
    ICropFilter<FilterOption>[]
  >(chosenFilters.filter((cf) => cf instanceof CategoryFilter));

  const [activeCategoryFilters, setActiveCategoryFilters] = useState<string[]>(
    chosenFilters
      .filter((cf) => cf instanceof CategoryFilter)
      .filter((f) => f.chosen.length > 0)
      .map((f) => f.id)
  );

  useEffect(() => {
    setCategoryFilters(
      chosenFilters.filter((cf) => cf instanceof CategoryFilter)
    );
    setActiveCategoryFilters(
      chosenFilters
        .filter((cf) => cf instanceof CategoryFilter)
        .filter((f) => f.chosen.length > 0)
        .map((f) => f.id)
    );
  }, [chosenFilters]);

  const parentCropCategories: ParentCropCategoryWithCategories[] =
    processParentCropCategories(cropcategories);

  return (
    <FilterContainer
      flexWrap={"nowrap"}
      gap={compactDisplay ? "8px" : "16px"}
      justifyContent={"flex-start"}
      flexDirection={compactDisplay ? "column" : "row"}
      compact={compactDisplay}
      show={isOpen && cropcategories.length !== 0}
      width={"100%"}
      data-testid={"CategoryFilters"}
    >
      {parentCropCategories.map((pc) => {
        return (
          <ToggleButton
            key={`${pc.parent.id}`}
            width={compactDisplay ? "100%" : "min-content"}
            justifyContent={"space-between"}
            active={!!activeCategoryFilters.find((f) => f === pc.parent.name)}
            label={pc.parent.name}
            onChange={(v) => {
              if (v) {
                setActiveCategoryFilters(
                  activeCategoryFilters.concat([pc.parent.name])
                );

                onChangeCategory(
                  categoryFilters,
                  onChange,
                  pc,
                  parentCropCategories,
                  pc.categories.map<string>((p) => p.name)
                );
              } else {
                const idx = activeCategoryFilters.findIndex(
                  (f) => f === pc.parent.name
                );
                const newActiveCategoryFilters = activeCategoryFilters.slice();
                newActiveCategoryFilters.splice(idx, 1);
                setActiveCategoryFilters(newActiveCategoryFilters);
                onChangeCategory(
                  categoryFilters,
                  onChange,
                  pc,
                  parentCropCategories,
                  []
                );
              }
            }}
          />
        );
      })}
    </FilterContainer>
  );
};

export default ParentCropFilters;
