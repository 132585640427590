import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import Theme from "../../../Core/Theme";

import NavigationButton from "../NavigationButton";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import IconButton from "@material-ui/core/IconButton";
import { savePlan } from "../../../Reducers/Planning/Actions";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    color: Theme.colors.white,
    backgroundColor: Theme.colors.aubergineMedium,
  },
}));

// TODO create missing routes in Core/Routes

const SecondaryNavigationBar = ({ buttonList }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const classes = useStyles({ matches });
  const dispatch = useDispatch();
  const { t } = useTranslation("navigation");
  const { savedAt, planSaved } = useSelector((state) => state.Planning);
  const { isFetching } = useSelector((state) => state.Api);
  const savedAtFormat = t("savedAtFormat");
  const savedAtFormatted = savedAt.format(savedAtFormat);

  const backgroundColor = {
    hover: Theme.colors.aubergineLight,
    active: Theme.colors.aubergineLight,
  };

  return (
    <div className={classes.root}>
      {buttonList.map((inf, idx) => {
        return (
          <NavigationButton
            key={idx}
            routePath={inf.routePath}
            exactForActiveState={inf.exactForActiveState}
            backgroundColor={backgroundColor}
            testId={inf.testId}
          >
            <span style={{ paddingLeft: 16, paddingRight: 16, marginTop: 4 }}>
              {inf.title}
            </span>
          </NavigationButton>
        );
      })}
      <div style={{ marginLeft: "auto" }} />
      <div
        style={{
          display: "inline-flex",
          textAlign: "right",
          height: 24,
          marginTop: 8,
          marginRight: 4,
        }}
      >
        <span
          style={{
            width: 192,
            verticalAlign: "middle",
            marginLeft: 8,
            paddingRight: 8,
          }}
        >
          {savedAtFormatted}
        </span>

        <div>
          {isFetching ? (
            <div style={{ marginTop: 6, marginLeft: 8, marginRight: 16 }}>
              <CircularProgress color={Theme.colors.white} size={24} />
            </div>
          ) : planSaved ? (
            <div style={{ marginTop: 4, marginLeft: 4, marginRight: 12 }}>
              <i
                className={"zwicon-checkmark"}
                style={{ color: Theme.colors.white, fontSize: 32 }}
              />
            </div>
          ) : (
            <div style={{ marginTop: 0, marginLeft: 4, marginRight: 4 }}>
              <IconButton
                onClick={() => dispatch(savePlan())}
                style={{
                  color: Theme.colors.black,
                  backgroundColor: Theme.colors.white,
                  borderRadius: "50%",
                  width: 40,
                  height: 40,
                }}
              >
                <i className={"zwicon-floppy"} style={{ fontSize: 24 }} />
              </IconButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SecondaryNavigationBar;
