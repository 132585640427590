export type PropertyGroup = {
  id: number;
  name: string;
};

type Property = {
  id: number;
  name: string;
  propertygroup?: PropertyGroup;
  selected?: boolean;
};

export class PropertyGroupWithProperties {
  propertyGroup: PropertyGroup;
  properties: Property[];
  constructor(propertyGroup: PropertyGroup, properties: Property[]) {
    this.propertyGroup = propertyGroup;
    this.properties = properties;
  }
  push(property: Property) {
    this.properties.push(property);
  }
}

export default Property;
