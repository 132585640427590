import theme from "../../Theme";
import IconProps from "../../Types/IconProps";

export default ({ size = "md", color = "currentColor" }: IconProps) => (
  <svg
    width={
      (size === "xl" && "48px") ||
      (size === "lg" && "40px") ||
      (size === "md" && "32px") ||
      (size === "sm" && "24px") ||
      (size === "xs" && "16px") ||
      undefined
    }
    height={
      (size === "xl" && "48px") ||
      (size === "lg" && "40px") ||
      (size === "md" && "32px") ||
      (size === "sm" && "24px") ||
      (size === "xs" && "16px") ||
      undefined
    }
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 11.7929L16.1464 7.64645C16.3417 7.45118 16.6583 7.45118 16.8536 7.64645C17.0488 7.84171 17.0488 8.15829 16.8536 8.35355L12.7071 12.5L16.8536 16.6464C17.0488 16.8417 17.0488 17.1583 16.8536 17.3536C16.6583 17.5488 16.3417 17.5488 16.1464 17.3536L12 13.2071L7.85355 17.3536C7.65829 17.5488 7.34171 17.5488 7.14645 17.3536C6.95118 17.1583 6.95118 16.8417 7.14645 16.6464L11.2929 12.5L7.14645 8.35355C6.95118 8.15829 6.95118 7.84171 7.14645 7.64645C7.34171 7.45118 7.65829 7.45118 7.85355 7.64645L12 11.7929Z"
      fill={theme.colors[color]}
    />
  </svg>
);
