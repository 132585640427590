import { RECEIVE_CONTENT_ELEMENT } from "./Actions";

export const initialState = {
  contentElement: undefined,
};

export default function LocationReducer(
  state: any = initialState,
  action: any = {}
) {
  switch (action.type) {
    case RECEIVE_CONTENT_ELEMENT: {
      return { ...state, contentElement: action.data };
    }

    default:
      return state;
  }
}
