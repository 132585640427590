/**
 * Theme Specification : https://system-ui.com/theme/
 */

const theme = {
  colors: {
    currentColor: "currentColor",
    transparent: "rgba(255,255,255,0)",

    hover: "rgba(0,0,0,0.1)",
    active: "rgba(0,0,0,0.2)",
    scrim: "rgba(0,0,0,0.4)",
    highlight: "rgba(255,255,255,0.3)",

    aubergineMedium: "#765673",
    aubergineDark: "#593c57",
    aubergineLight: "#9a7e98",
    aubergineApp: "#6f1a66",
    aubergineAppInactive: "#a97fa5",
    greenDark: "#414b18",
    greenMedium: "#7d894e",
    greenLight: "#d0d5b3",
    greenApp: "#13905b",
    greenAppInactive: "#a6cbbb",
    greenAppHover: "#4eac84",
    greenAppHauptkultur: "#80c27e",
    blueHandle: "#24b0ff",
    blueDark: "#7198d3",
    blueMedium: "#a4bde2",
    blueLight: "#d8e2f1",
    beigeDarkest: "#c1b3a4",
    beigeDark: "#d5c6b6",
    beigeMidi: "#e9daca",
    beigeMedium: "#f1e9de",
    beigeLight: "#F6F2ED",
    orangeDark: "#db8541",
    orangeMedium: "#f2b06b",
    orangeLight: "#fbcd9d",
    pinkDark: "#a45d57",
    pinkMedium: "#eb9b95",
    pinkLight: "#f9bdb8",
    red: "#e74d32",
    yellow: "#ffb800",
    black: "#150807",
    greyDark: "#8c8c8c",
    greyMidi: "#b0b0b0",
    greyMedium: "#d4d4d4",
    greyLight: "#f1f1f1",
    white: "#ffffff",
  },
  spaces: {
    auto: "auto", // only: margin, top, right, bottom, left
    "50%": "50%",
    "-50%": "-50%",
    "-80px": "-80px",
    "-32px": "-32px",
    "-24px": "-24px",
    "-16px": "-16px",
    "-12px": "-12px",
    "-8px": "-8px",
    "-4px": "-4px",
    "-1px": "-1px",
    "0px": "0px",
    "1px": "1px",
    "2px": "2px",
    "4px": "4px",
    "8px": "8px",
    "12px": "12px",
    "16px": "16px",
    "20px": "20px",
    "24px": "24px",
    "28px": "28px",
    "32px": "32px",
    "36px": "36px",
    "40px": "40px",
    "44px": "44px",
    "48px": "48px",
    "52px": "52px",
    "56px": "56px",
    "60px": "60px",
    "80px": "80px",
  },
  sizes: {
    auto: "auto", // only: width, height, min-width, min-height
    none: "none", // only: max-width, max-height
    "max-content": "max-content",
    "min-content": "min-content",
    "fit-content": "fit-content",
    "100vh": "100vh",
    "100vw": "100vw",
    "100%+2px": "calc(100% + 2px)",
    "100%": "100%",
    "75%": "75%",
    "50%": "50%",
    "25%": "25%",
    "0px": "0px",
    "1px": "1px",
    "2px": "2px",
    "4px": "4px",
    "8px": "8px",
    "12px": "12px",
    "16px": "16px",
    "20px": "20px",
    "24px": "24px",
    "28px": "28px",
    "32px": "32px",
    "36px": "36px",
    "40px": "40px",
    "44px": "44px",
    "48px": "48px",
    "52px": "52px",
    "56px": "56px",
    "60px": "60px",
    "72px": "72px",
    "80px": "80px",
    "100px": "100px",
    "120px": "120px",
    "140px": "140px",
    "160px": "160px",
    "180px": "180px",
    "200px": "200px",
    "250px": "250px",
    "300px": "300px",
    "360px": "360px",
    "375px": "375px",
    "400px": "400px",
    "440px": "440px",
    "480px": "480px",
    "512px": "512px",
    "600px": "600px",
    "768px": "768px",
    "800px": "800px",
    "1024px": "1024px",
    "1042px": "1042px",
    "1200px": "1200px",
  },
  fonts: {
    vollkorn: "Vollkorn,serif",
    karla: "Karla,sans-serif",
    mono: "'Courier Prime',monospace",
  },
  fontWeights: {
    normal: 400,
    semiBold: 600,
    bold: 700,
  },
  fontSizes: {
    sm: "13px",
    md: "16px",
    lg: "20px",
    xl: "24px",
    "2xl": "28px",
    "3xl": "32px",
    "4xl": "36px",
    "5xl": "40px",
    "6xl": "46px",
  },
  lineHeights: {
    "1": 1,
    "1.25": 1.25,
    "1.3": 1.3,
    "1.5": 1.5,
  },

  radii: {
    none: "0px",
    sm: "4px",
    md: "8px",
    lg: "24px",
    full: "9999px",
  },
  borders: {
    none: "none",
    "1pxSolid": "1px solid",
    "2pxSolid": "2px solid",
    "2pxDashed": "2px dashed",
  },
  shadows: {
    none: "none",
    md: "0px 8px 20px rgba(167,163,160,0.2)",
    lg: "0px 8px 20px rgba(167,163,160,0.7)",
    xl: "2px 2px 15px 0px rgba(0,0,0,0.2)",
  },
  breakpoints: {
    base: "0px",
    sm: "480px",
    md: "768px",
    lg: "992px",
    xl: "1280px",
    "2xl": "1536px",
  },
};

export default theme;
