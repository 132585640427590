import Crop, { CropWithVarieties } from "../../Types/API/Crop";
import {
  LightingDemandType,
  NutrientDemandType,
  Season,
  WaterDemandType,
} from "../Planning/PatchPlan/Consts";
import { FilterOption } from "./CropFilters";
import { isPlantInSeason } from "../../Reducers/Planning/PlanningUtils";
import { CropSortType } from "../../Helpers/FilterHelper";

export interface ICropFilter<T> {
  id: string;
  options: T[];
  chosen: T[];
  matches: (crop: CropWithVarieties) => boolean;
}

export enum SortOrder {
  ASC,
  DESC,
}

export interface ISortBy<I, T> {
  type: T;
  order: SortOrder;
  compare: (a: I, b: I) => number;
}

/**
 * Toggles single choice filter `changedFilter` in list of
 * `existingFilters`. If filter is active, will be put at
 * start of updated filters list.
 * If it is inactive (i.e. already inside `existingFilters`,
 * will be removed from updated filters list. If `null` is
 * used for `changedFilter`, there will be no updates to
 * filters list and `null` will be returned as the new
 * filters.
 * Toggles sortBy object `changedSortBy`. If it is `null`,
 * it will come out as `null`. If it is the same as
 * `existingSortBy`, it will be passed as `undefined`.
 * Otherwise, it will be returned as the new sort by
 * object.
 */
export const toggleSingleChoiceFiltersAndSortBy = (
  existingFilters: ICropFilter<any>[],
  existingSortBy: CropSortBy | undefined,
  changedFilter: ICropFilter<any> | null,
  changedSortBy: CropSortBy | null
) => {
  let newFilters: ICropFilter<string>[] | null = null;
  if (changedFilter !== null) {
    newFilters = [...existingFilters];
    const idx = newFilters.findIndex((f) => f.id === changedFilter.id);
    if (idx !== -1) {
      const [removedFilter] = newFilters.splice(idx, 1);
      if (removedFilter.chosen[0] !== changedFilter.chosen[0]) {
        newFilters.unshift(changedFilter);
      }
    } else {
      newFilters.unshift(changedFilter);
    }
  }
  let newSortBy: CropSortBy | undefined | null = changedSortBy;
  if (changedSortBy !== null) {
    if (existingSortBy && changedSortBy) {
      if (
        existingSortBy.type === changedSortBy.type &&
        existingSortBy.order === changedSortBy.order
      )
        newSortBy = undefined;
    } else {
      newSortBy = changedSortBy;
    }
  }
  return [newFilters, newSortBy];
};

export class CropSortBy implements ISortBy<Crop, CropSortType> {
  type: CropSortType;
  order: SortOrder;
  constructor(type: CropSortType, order: SortOrder) {
    this.type = type;
    this.order = order;
  }
  compare(a: CropWithVarieties, b: CropWithVarieties) {
    switch (this.type) {
      case CropSortType.MOST_POPULAR:
        return this.order === SortOrder.ASC
          ? (a.popularity || 0) - (b.popularity || 0)
          : (b.popularity || 0) - (a.popularity || 0);
      case CropSortType.ABC:
        return this.order === SortOrder.ASC
          ? (a.name || "")
              .toLocaleLowerCase()
              .localeCompare((b.name || "").toLocaleLowerCase())
          : (b.name || "")
              .toLocaleLowerCase()
              .localeCompare((a.name || "").toLocaleLowerCase());
      case CropSortType.LATEST:
        return this.order === SortOrder.ASC ? a.id - b.id : b.id - a.id;
    }
  }
}

abstract class CropFilterBase<T> implements ICropFilter<T> {
  id: string;
  options: T[];
  chosen: T[];
  constructor(id: string, options: T[] = [], chosen: T[] = []) {
    this.id = id;
    this.options = options;
    this.chosen = chosen;
  }
  abstract matches(crop: CropWithVarieties): boolean;
}

export class PropertyFilter extends CropFilterBase<string> {
  matches(crop: CropWithVarieties) {
    let rv =
      !!crop.properties &&
      crop.properties.some((t) => this.chosen.indexOf(t.name) !== -1);
    if (!rv && crop.varieties && crop.varieties.length > 0) {
      rv = crop.varieties.some((c) => this.matches(c));
    }
    return rv;
  }
}

export class CategoryFilter extends CropFilterBase<string> {
  matches(crop: CropWithVarieties) {
    if (!crop.cropcategory) {
      return false;
    }
    return this.chosen.indexOf(crop.cropcategory.name) !== -1;
  }
}

export abstract class BooleanFilter extends CropFilterBase<boolean> {
  abstract matches(crop: CropWithVarieties): boolean;
}

export class BooleanAttributeFilter extends BooleanFilter {
  attributeName: string;
  constructor(id: string, attributeName: string, chosen: boolean) {
    super(id, [true, false], [chosen]);
    this.attributeName = attributeName;
  }
  matches(crop: CropWithVarieties) {
    return (
      !!crop[this.attributeName as keyof CropWithVarieties] === this.chosen[0]
    );
  }
}

export class FavoritesFilter extends BooleanFilter {
  constructor(id: string, chosen: boolean) {
    super(id, [true, false], [chosen]);
  }
  matches(crop: CropWithVarieties) {
    return crop.isFavorite === this.chosen[0];
  }
}

export abstract class DemandFilter extends CropFilterBase<string> {
  abstract matches(crop: CropWithVarieties): boolean;
}

export class NutrientDemandFilter extends DemandFilter {
  constructor(id: string, chosen: string) {
    super(id, Object.keys(NutrientDemandType), [chosen]);
  }
  matches(crop: CropWithVarieties) {
    switch (this.chosen[0]) {
      case "heavy":
        return crop.nutrientDemand === "hoch";
      case "medium":
        return crop.nutrientDemand === "mittel";
      case "light":
        return crop.nutrientDemand === "niedrig";
      default:
        return false;
    }
  }
}

export class LightingDemandFilter extends DemandFilter {
  constructor(id: string, chosen: string) {
    super(id, Object.keys(LightingDemandType), [chosen]);
  }
  matches(crop: CropWithVarieties) {
    switch (this.chosen[0]) {
      case "heavy":
        return crop.lightingDemand === "hoch";
      case "medium":
        return crop.lightingDemand === "mittel";
      case "light":
        return crop.lightingDemand === "niedrig";
      default:
        return false;
    }
  }
}

export class WaterDemandFilter extends DemandFilter {
  constructor(id: string, chosen: string) {
    super(id, Object.keys(WaterDemandType), [chosen]);
  }
  matches(crop: CropWithVarieties) {
    switch (this.chosen[0]) {
      case "heavy":
        return crop.waterDemand === "sehr feucht";
      case "medium":
        return crop.waterDemand === "feucht";
      case "light":
        return crop.waterDemand === "trocken";
      default:
        return false;
    }
  }
}

export class SeasonFilter extends DemandFilter {
  year: number;
  constructor(id: string, year: number, chosen: string) {
    super(id, Object.keys(Season), [chosen]);
    this.year = year;
  }
  matches(crop: CropWithVarieties) {
    const season = this.chosen[0];
    return season.includes(season) || !!(crop.varieties?.some(variety => variety.seasons?.includes(season)))
  }
}

export const filterCrops = (
  searchTerm: string,
  filters: ICropFilter<FilterOption>[],
  sortBy?: CropSortBy,
  searchParents?: boolean
) => {
  const term = searchTerm.toLowerCase();
  const categoryFilters = filters.filter((f) => f instanceof CategoryFilter);
  const otherFilters = filters.filter((f) => !(f instanceof CategoryFilter));
  const filterMatcher =
    categoryFilters.length > 0
      ? (c: Crop) =>
          categoryFilters.some((f) => f.matches(c)) &&
          otherFilters.every((f) => f.matches(c))
      : (c: Crop) => otherFilters.every((f) => f.matches(c));

  const searchTermMatcher = (c: Crop): boolean =>
    (c.name ?? "").toLowerCase().indexOf(term) !== -1 ||
    (c.latinName ?? "").toLowerCase().indexOf(term) !== -1 ||
    (c.synonyms
      ? c.synonyms.length > 0 &&
        c.synonyms.some((s) => s.toLowerCase().indexOf(term) !== -1)
      : false) ||
    (!!(searchParents && c.parentCrop) && searchTermMatcher(c.parentCrop));

  if (searchTerm !== "" && filters.length > 0) {
    const matcher = (c: Crop) => searchTermMatcher(c) && filterMatcher(c);
    return (crops: Crop[]) => {
      const filtered = crops.filter(matcher);
      if (sortBy) {
        return filtered.sort(sortBy.compare.bind(sortBy));
      } else {
        return filtered;
      }
    };
  } else if (searchTerm !== "" && filters.length === 0) {
    return (crops: Crop[]) => {
      const filtered = crops.filter(searchTermMatcher);
      if (sortBy) {
        return filtered.sort(sortBy.compare.bind(sortBy));
      } else {
        return filtered;
      }
    };
  } else if (
    searchTerm === "" &&
    filters.filter((f) => f instanceof FavoritesFilter).length > 0
  ) {
    return (crops: Crop[]) => {
      const filtered = crops.filter(filterMatcher);

      if (sortBy) {
        return filtered.sort(sortBy.compare.bind(sortBy));
      } else {
        return filtered;
      }
    };
  } else if (
    searchTerm === "" &&
    filters.filter((f) => !(f instanceof FavoritesFilter)).length > 0
  ) {
    return (crops: Crop[]) => {
      const filtered = crops.filter(
        (c) =>
          filterMatcher(c) ||
          (c.varieties !== undefined
            ? (c.varieties || []).some(filterMatcher)
            : false)
      );

      if (sortBy) {
        return filtered.sort(sortBy.compare.bind(sortBy));
      } else {
        return filtered;
      }
    };
  } else {
    return (crops: Crop[]) => {
      const filtered = [...crops];
      if (sortBy) {
        return filtered.sort(sortBy.compare.bind(sortBy));
      } else {
        return filtered;
      }
    };
  }
};
