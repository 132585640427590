import md5 from "md5";
import { deleteData, fetchData, patchData, postData } from "../Api/Actions";
import Routes from "../../Core/Routes";
import moment from "moment";
import {
  getCropPlantingDates,
  getSeasonForVariety,
  calculatePlantAreaForSeason,
  seasons,
} from "./PlanningUtils";
import { RECEIVE_CROP_CATEGORIES } from "../CropData/Actions";

export const SET_DATA_INITIALIZED = "PLANNING_SET_DATA_INITIALIZED";
export const SET_PLAN_INITIALIZED = "PLANNING_SET_PLAN_INITIALIZED";
export const SET_FAVORITES_DEFINED = "SET_FAVORITES_DEFINED";
export const SET_MICROCLIMATE_DEFINED = "SET_MICROCLIMATE_DEFINED";
export const INCREMENT_TUTORIAL_STEP = "INCREMENT_TUTORIAL_STEP";
export const COMPLETE_TUTORIAL = "COMPLETE_TUTORIAL";
export const HIDE_TUTORIAL = "HIDE_TUTORIAL";
export const SET_DATA = "PLANNING_SET_DATA";
export const REMOVE_PLANT = "PLANNING_REMOVE_PLANT";
export const REMOVE_PATCH = "PLANNING_REMOVE_PATCH";
export const REMOVE_ALL_PATCHES = "PLANNING_REMOVE_ALL_PATCHES";
export const INCREASE_YEAR = "PLANNING_INCREASE_YEAR";
export const DECREASE_YEAR = "PLANNING_DECREASE_YEAR";
export const SET_SEASON = "PLANNING_SET_SEASON";
export const SET_GARDEN_LOCATION = "SET_GARDEN_LOCATION";
export const SET_GARDEN_PATCH_AREA = "PLANNING_SET_GARDEN_PATCH_AREA";
export const SET_GARDEN_OCCUPANCY = "PLANNING_SET_GARDEN_OCCUPANCY";
export const SET_GARDEN_SUNLIGHT = "PLANNING_SET_GARDEN_SUNLIGHT";
export const SET_GARDEN_GROUND_AND_WATER =
  "PLANNING_SET_GARDEN_GROUND_AND_WATER";
export const SELECT_PATCH_BY_UUID = "PLANNING_SELECT_PATCH_BY_UUID";
export const SET_PATCH_VALUE = "PLANNING_SET_PATCH_VALUE";
export const SELECT_PLANT_BY_UUID = "PLANNING_SELECT_PLANT_BY_UUID";
export const UPDATE_PLANT = "PLANNING_UPDATE_PLANT";
export const SET_PATCH_TUTORIAL_SHOWN = "PLANNING_SET_PATCH_TUTORIAL_SHOWN";
export const SET_PLANT_TUTORIAL_SHOWN = "PLANNING_SET_PLANT_TUTORIAL_SHOWN";
export const SET_READY_TUTORIAL_SHOWN = "PLANNING_SET_READY_TUTORIAL_SHOWN";
export const RESET_SELECTED_PLANT = "PLANNING_RESET_SELECTED_PLANT";
export const UPDATE_PREV_AND_NEXT_PLANT = "PLANNING_UPDATE_PREV_AND_NEXT_PLANT";
export const SELECT_CROP = "PLANNING_SELECT_CROP";
export const ADD_PATCH_NAMES = "PLANNING_ADD_PATCH_NAMES";
export const SELECT_VARIETY_FOR_PLANT = "PLANNING_SELECT_VARIETY_FOR_PLANT";
export const INITIAL_PLANNING_COMPLETED = "INITIAL_PLANNING_COMPLETED";
export const SET_PLANNING_SCREEN_SEEN = "SET_PLANNING_SCREEN_SEEN";
export const START_SAVE_GARDEN = "PLANNING_START_SAVE_GARDEN";
export const START_LOAD_GARDEN = "PLANNING_START_LOAD_GARDEN";
export const RECEIVE_SAVE_GARDEN = "PLANNING_RECEIVE_SAVE_GARDEN";
export const RECEIVE_SAVE_GARDEN_ERROR = "PLANNING_RECEIVE_SAVE_GARDEN_ERROR";
export const START_SAVE_PLAN = "PLANNING_START_SAVE_PLAN";
export const QUEUE_SAVE_PLAN = "PLANNING_QUEUE_SAVE_PLAN";
export const RECEIVE_SAVE_PLAN = "PLANNING_RECEIVE_SAVE_PLAN";
export const RECEIVE_SAVE_PLAN_ERROR = "PLANNING_RECEIVE_SAVE_PLAN_ERROR";
export const RECEIVE_GARDENS = "PLANNING_RECEIVE_GARDENS";
export const RECEIVE_GARDEN = "PLANNING_RECEIVE_GARDEN";
export const RECEIVE_GARDEN_ERROR = "PLANNING_RECEIVE_GARDEN_ERROR";
export const RECEIVE_DELETE_GARDEN = "PLANNING_RECEIVE_DELETE_GARDEN";
export const SET_USE_SEASONS = "PLANNING_SET_USE_SEASONS";
export const RESET_SAVE_STATUS = "PLANNING_RESET_SAVE_STATUS";
export const HIDE_BETA_HINT = "PLANNING_HIDE_BETA_HINT";
export const MAGIC_WAND_START_AUTO_FILL = "PLANNING_MAGIC_WAND_START_AUTO_FILL";
export const MAGIC_WAND_COMPLETE_AUTO_FILL =
  "PLANNING_MAGIC_WAND_COMPLETE_AUTO_FILL";
export const MAGIC_WAND_START_OPTIMIZATION =
  "PLANNING_MAGIC_WAND_START_OPTIMIZATION";
export const MAGIC_WAND_COMPLETE_OPTIMIZATION =
  "PLANNING_MAGIC_WAND_COMPLETE_OPTIMIZATION";
export const MAGIC_WAND_FINISH = "PLANNING_MAGIC_WAND_FINISH";
export const MAGIC_WAND_RECEIVE_ERROR = "PLANNING_MAGIC_WAND_RECEIVE_ERROR";
export const MAGIC_WAND_RECEIVE_WARNING = "PLANNING_MAGIC_WAND_RECEIVE_WARNING";
export const SET_PLAN_MODE = "PLANNING_SET_PLAN_MODE";

export const setUseSeasons = (useSeasons) => ({
  type: SET_USE_SEASONS,
  useSeasons,
});

export const loadGardens = () => (dispatch, getState) => {
  dispatch(fetchData(Routes.API_ROUTE_GARDENS, receiveGardens));
};

export const receiveGardens = (data, status) => async (dispatch) => {
  await dispatch({
    type: RECEIVE_GARDENS,
    gardens: data.gardens,
  });
  // directly load first garden
  // todo: change this when we allow multiple gardens per user
  if (data.gardens && data.gardens.length > 0) {
    const garden = data.gardens[0];
    console.log("load first garden of user with id " + garden.id);
    // todo: check hashes and load garden only if hashes have changed
    dispatch(loadGarden(garden.id));
  } else {
    console.log("user has no gardens");
    dispatch(initializeYear());
    dispatch(setDataInitialized());
  }
};

export const loadGarden = (gardenId) => (dispatch) => {
  dispatch({ type: START_LOAD_GARDEN });
  dispatch(
    fetchData(
      Routes.API_ROUTE_GARDEN.replace("{gardenId}", gardenId),
      receiveGarden,
      receiveGardenError
    )
  );
};

export const loadGardenHash = (gardenId) => (dispatch) => {
  dispatch(
    fetchData(
      Routes.API_ROUTE_GARDEN_HASH.replace("{gardenId}", gardenId),
      receiveGardenHash,
      receiveGardenHashError
    )
  );
};

export const receiveGardenError = () => (dispatch) => {
  dispatch({ type: RECEIVE_GARDEN_ERROR });
  console.warn("error loading garden");
};

export const receiveGarden = (data, status) => async (dispatch, getState) => {
  // data consists of gardenData and planData
  // -> here we did load the whole garden including the plan
  await dispatch({
    type: RECEIVE_GARDEN,
    data: data,
  });
  dispatch(savePlanLocally(true));
  const gardenData = { ...getState().Planning.garden };

  await saveGardenLocally(gardenData);
  dispatch(initializeYear());
  dispatch(setData(getState().Planning.planData, true));
  dispatch(setGardenLocation(gardenData, true));
  dispatch(setDataInitialized());
};

export const receiveGardenHash = (data) => async (dispatch, getState) => {
  const localDeviceId = getState().GardenLock.deviceDescription?.deviceId;
  const gardenId = getState().Planning.garden.id;
  const { planSaveTimestamp: lastSaveTimestamp } = getState().Planning;
  const { deviceId, updatedAt } = data;

  if (!localDeviceId || gardenId) {
    return;
  }

  if (deviceId === localDeviceId) {
    return;
  }

  if (moment(updatedAt).isSameOrBefore(moment.unix(lastSaveTimestamp))) {
    return;
  }

  dispatch(loadGarden(gardenId));
};

export const receiveGardenHashError = (data) => (dispatch, getState) => {
  console.warn("error loading garden hash");
  if (data.error === "not_found") {
    console.log("reload gardens for user because garden was not found");
    dispatch(loadGardens());
  }
};

export const initializeData = () => async (dispatch, getState) => {
  // TODO: if user is offline load local data and set read only mode
  // TODO: display information about read only when offline
  dispatch(loadGardens());
};

export const initializeYear = () => (dispatch, getState) => {
  const now = moment();
  const { year } = getState().Planning;
  if (now.month() > 8 && year === now.year()) {
    const { isProAccount, isPlusAccount } = getState().Account;
    if (!isProAccount && !isPlusAccount) {
      dispatch(increaseYear());
      // todo: move all plants to next year
    } else {
      // if no plants in patch -> automatically switch to next year
      const { planData } = getState().Planning;
      let hasPlants = false;
      planData?.patches?.forEach((patch) => {
        if (patch.plants.length > 0) {
          hasPlants = true;
        }
      });
      if (!hasPlants) {
        dispatch(increaseYear());
      }
    }
  }
};

let _globalSaveTimeout = undefined;
export const savePlanLocally = (skipSave) => async (dispatch, getState) => {
  const { planSaveTimestamp, planSaveIsInQueue } = getState().Planning;
  const now = moment().unix();
  if (_globalSaveTimeout) {
    clearTimeout(_globalSaveTimeout);
  }
  if (!planSaveTimestamp || planSaveTimestamp + 60 < now) {
    dispatch(savePlan());
  } else if (planSaveIsInQueue) {
    dispatch(clearSaveTimeout());
    dispatch(savePlan());
  } else {
    dispatch({ type: QUEUE_SAVE_PLAN });
    _globalSaveTimeout = setTimeout(() => {
      dispatch(savePlan());
      _globalSaveTimeout = undefined;
    }, 60000);
  }
};

export const clearSaveTimeout = () => (dispatch, getState) => {
  clearTimeout(_globalSaveTimeout);
  dispatch({ type: RESET_SAVE_STATUS });
};

export const savePlan = () => async (dispatch, getState) => {
  const { garden, planData } = getState().Planning;
  if (!garden || !planData) {
    console.warn("save plan triggered without garden or planData");
    return;
  }

  const isSaving = getState().Planning.planIsSaving;
  if (!isSaving) {
    let gardenId = getState().Planning.garden?.id;
    if (!gardenId) {
      await dispatch(saveGarden());
      gardenId = getState().Planning.garden.id;
    } else {
      if (!getState().Planning.gardenSaved) {
        await dispatch(saveGarden());
      }
    }
    dispatch({ type: START_SAVE_PLAN });
    await dispatch(updatePrevAndNextPlant());
    const { planData, garden } = getState().Planning;
    const { deviceId, deviceDescription } = getState().GardenLock;
    const gardenHash = await generateGardenHash(garden, planData);

    dispatch(
      patchData(
        Routes.API_ROUTE_GARDEN.replace("{gardenId}", gardenId),
        {
          plan: planData,
          hash: gardenHash,
          deviceDescription,
          deviceId,
        },
        receiveSavePlan,
        receiveSavePlanError
      )
    );
  } else {
    // todo: implement
    console.log("plan is already saving -> queue save for later");
  }
};

export const receiveSavePlan = () => (dispatch) => {
  dispatch({
    type: RECEIVE_SAVE_PLAN,
  });
};

export const receiveSavePlanError = () => ({
  type: RECEIVE_SAVE_PLAN_ERROR,
});

export const saveGarden = () => async (dispatch, getState) => {
  const gardenData = { ...getState().Planning.garden } ?? {};
  const planData = getState().Planning.planData;
  const { deviceId, deviceDescription } = getState().GardenLock;

  dispatch({ type: START_SAVE_GARDEN });
  const hash = await generateGardenHash(gardenData, planData);
  const data = {
    garden: gardenData,
    ...(gardenData.id ? { id: gardenData.id } : {}),
    hash,
    deviceId,
    deviceDescription,
  };

  return dispatch(postData(Routes.API_ROUTE_GARDENS, data, receiveSaveGarden));
};

export const receiveSaveGarden = (data) => async (dispatch, getState) => {
  await dispatch({
    type: RECEIVE_SAVE_GARDEN,
    data,
  });
  await saveGardenLocally(getState().Planning.garden);
};

const saveGardenLocally = async (garden) => {
  const { gardenImage, ...restOfGarden } = garden;
};

export const receiveSaveGardenError = (data) => (dispatch) => {
  console.warn("garden save error");
  dispatch({
    type: RECEIVE_SAVE_GARDEN_ERROR,
    data,
  });
};

export const setData = (data, skipSave) => (dispatch, getState) => {
  dispatch({
    type: SET_DATA,
    data,
  });
  dispatch({ type: ADD_PATCH_NAMES });

  // save data locally
  if (!skipSave) {
    dispatch(savePlanLocally());
  }
};

export const removePlant = (plant) => (dispatch, getState) => {
  dispatch({
    type: REMOVE_PLANT,
    plant,
  });
  dispatch(savePlanLocally());
};

export const removePatch = (patch) => (dispatch, getState) => {
  dispatch({
    type: REMOVE_PATCH,
    patch,
  });
  dispatch(savePlanLocally());
};

export const removeALLPatches = () => (dispatch, getState) => {
  dispatch({
    type: REMOVE_ALL_PATCHES,
  });
  dispatch(savePlan());
};

export const setDataInitialized = () => ({
  type: SET_DATA_INITIALIZED,
});

export const setPlanInitialized = (initialized) => ({
  type: SET_PLAN_INITIALIZED,
  initialized,
});

export const setFavoritesDefined = (defined) => ({
  type: SET_FAVORITES_DEFINED,
  defined,
});

export const setMicroclimateDefined = (defined) => ({
  type: SET_MICROCLIMATE_DEFINED,
  defined,
});

export const hideTutorial = () => ({
  type: HIDE_TUTORIAL,
});

export const incrementTutorialStep = (nextStep) => ({
  type: INCREMENT_TUTORIAL_STEP,
  nextStep,
});

export const completeTutorial = () => (dispatch) => {
  dispatch({
    type: COMPLETE_TUTORIAL,
  });
  //saveLocalSetting(SETTING_TUTORIAL_COMPLETED, true);
};

export const completeInitialPlanning = () => (dispatch) => {
  dispatch({
    type: INITIAL_PLANNING_COMPLETED,
  });
  //saveLocalSetting(SETTING_INITIAL_PLANNING_COMPLETED, true);
};

export const setPlanningScreenSeen = () => (dispatch) => {
  dispatch({
    type: SET_PLANNING_SCREEN_SEEN,
  });
  //saveLocalSetting(SETTING_PLANNING_SCREEN_SEEN, true);
};

export const increaseYear = () => ({
  type: INCREASE_YEAR,
});

export const decreaseYear = () => ({
  type: DECREASE_YEAR,
});

export const setSeason = (season) => (dispatch, getState) => {
  const { useSeasons } = getState().Planning;
  if (useSeasons) {
    dispatch({
      type: SET_SEASON,
      season,
    });
  }
};

export const setGardenLocation = (garden, skipSave) => async (dispatch) => {
  await dispatch({
    type: SET_GARDEN_LOCATION,
    garden,
  });
  if (!skipSave) {
    await dispatch(saveGarden());
  }
};

export const setGardenMicroclimate =
  ({
    lightRaster,
    lightLines,
    lightPainterHeight,
    groundType,
    hasWaterAccess,
  }) =>
  async (dispatch, getState) => {
    const oldGarden = getState().Planning.garden;
    const garden = {
      ...oldGarden,
      lightRaster,
      lightLines,
      lightPainterHeight,
      groundType: groundType ? groundType : oldGarden.groundType,
      hasWaterAccess: hasWaterAccess
        ? hasWaterAccess
        : oldGarden.hasWaterAccess,
    };
    await dispatch(setGardenLocation(garden, true));
    dispatch(setMicroclimateDefined(true));
    // await saveLocalSetting(SETTING_MICROCLIMATE_DEFINED, true);

    await dispatch(saveGarden());
  };

export const setGardenSunlight = (lightRaster, lightLines) => ({
  type: SET_GARDEN_SUNLIGHT,
  lightRaster,
  lightLines,
});

export const setGardenGroundAndWater = (groundType, hasWaterAccess) => ({
  type: SET_GARDEN_GROUND_AND_WATER,
  groundType,
  hasWaterAccess,
});

export const selectPatchByUuid = (uuid) => ({
  type: SELECT_PATCH_BY_UUID,
  uuid,
});

export const setPatchValue =
  (field, value, skipUpdate) => (dispatch, getState) => {
    dispatch({
      type: SET_PATCH_VALUE,
      field,
      value,
      skipUpdate,
    });
  };

export const selectPlantByUuid = (uuid) => (dispatch, getState) => {
  dispatch({
    type: SELECT_PLANT_BY_UUID,
    uuid,
  });
  const selectedPlant = getState().Planning.selectedPlant;
  if (selectedPlant?.crop) {
    const cropMapById = getState().CropData.cropMapById;
    const cropId = selectedPlant.crop.id;
    const varietyId = selectedPlant.crop.varietyId;
    const crop = cropMapById[cropId];
    const variety = cropMapById[varietyId];
    dispatch({ type: SELECT_CROP, crop, variety });
  } else {
    console.warn("selected plant has no crop set");
    console.log(selectedPlant);
  }
};

export const updatePlant = (plant, values) => async (dispatch, getState) => {
  await dispatch({
    type: UPDATE_PLANT,
    plant,
    values,
  });
  dispatch(savePlanLocally());
};

export const selectVarietyForPlant =
  (plant, variety, keepDatesOfPlant = false) => (dispatch, getState) => {
    const { year, season } = getState().Planning;
    const { isPlusAccount } = getState().Account;
    const varietySeason = getSeasonForVariety(variety, year, season);

    const dates = keepDatesOfPlant
        ? {
          datePlanted: plant.datePlanted,
          dateInPatch: plant.dateInPatch,
          dateHarvestPlanned: plant.dateHarvestPlanned,
          dateHarvestStart: plant.dateHarvestStart,
          dateHarvestEnd: plant.dateHarvestEnd,
        }
        : getCropPlantingDates(variety, year, varietySeason);

    return dispatch({
      type: SELECT_VARIETY_FOR_PLANT,
      plant,
      variety,
      dates,
      season: isPlusAccount ? varietySeason : season,
    });
  };

export const resetSelectedPlant = () => ({
  type: RESET_SELECTED_PLANT,
});

export const setPatchTutorialShown = () => ({
  type: SET_PATCH_TUTORIAL_SHOWN,
});

export const setPlantTutorialShown = () => ({
  type: SET_PLANT_TUTORIAL_SHOWN,
});

export const setReadyTutorialShown = () => ({
  type: SET_READY_TUTORIAL_SHOWN,
});

export const updatePrevAndNextPlant = () => ({
  type: UPDATE_PREV_AND_NEXT_PLANT,
});

export const receiveDeleteGarden = (data, status) => ({
  type: RECEIVE_DELETE_GARDEN,
  data: data,
  status: status,
});

export const deleteGarden = () => (dispatch, getState) => {
  const gardenId = getState().Planning.garden?.id;
  if (gardenId) {
    dispatch(
      deleteData(
        Routes.API_ROUTE_GARDEN.replace("{gardenId}", gardenId),
        undefined,
        receiveDeleteGarden
      )
    );
  }
};

export const setGardenPatchArea = (area) => ({
  type: SET_GARDEN_PATCH_AREA,
  area,
});

export const setGardenOccupancy = (occupancy) => ({
  type: SET_GARDEN_OCCUPANCY,
  occupancy,
});

export const updateGardenOccupancy = () => (dispatch, getState) => {
  const { planData } = getState().Planning;
  const { totalPatchArea } = getState().Planning.garden;
  const AMOUNT_OF_YEARS = 2;
  const occupancyByYear = {};
  const seasonsKeys = Object.keys(seasons);

  if (!planData || !totalPatchArea) {
    return;
  }

  for (let i = 0; i < AMOUNT_OF_YEARS; i++) {
    const year = moment().add(i, "year").year();
    const occupancy = {};
    for (let j = 0; j < seasonsKeys.length; j++) {
      const plantArea = calculatePlantAreaForSeason(
        planData.patches,
        seasonsKeys[j],
        year
      );
      occupancy[seasonsKeys[j]] = plantArea / totalPatchArea;
    }
    occupancyByYear[year] = occupancy;
  }

  dispatch(setGardenOccupancy(occupancyByYear));
  dispatch(saveGarden());
};
export const setPlanMode = (mode) => ({
  type: SET_PLAN_MODE,
  mode,
});

export const startMagicAutoFill = (mode) => ({
  type: MAGIC_WAND_START_AUTO_FILL,
  mode,
});

export const completeMagicAutoFill = () => ({
  type: MAGIC_WAND_COMPLETE_AUTO_FILL,
});

export const startMagicOptimization = () => ({
  type: MAGIC_WAND_START_OPTIMIZATION,
});

export const completeMagicOptimization = () => ({
  type: MAGIC_WAND_COMPLETE_OPTIMIZATION,
});

export const hideBetaHint = () => ({
  type: HIDE_BETA_HINT,
});

export const finishMagicProcess = () => ({
  type: MAGIC_WAND_FINISH,
});

export const receiveMagicError = (warningType) => ({
  type: MAGIC_WAND_RECEIVE_ERROR,
  warningType,
});

export const receiveMagicWarning = (warningType) => ({
  type: MAGIC_WAND_RECEIVE_WARNING,
  warningType,
});
const generateGardenHash = async (garden, plan) => {
  const jsonString = JSON.stringify(garden) + JSON.stringify(plan);
  return md5(jsonString);
};
