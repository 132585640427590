import theme from "../../Theme";
import IconProps from "../../Types/IconProps";

export default ({ size = "md", color = "aubergineApp" }: IconProps) => (
  <svg
    width={size === "xs" ? "16px" : size === "sm" ? "24px" : "32px"}
    height={size === "xs" ? "16px" : size === "sm" ? "24px" : "32px"}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="13" fill={theme.colors[color]} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.0585 11.1962C22.4784 11.5167 22.5591 12.1169 22.2386 12.5369L15.3038 21.6238C15.1365 21.8431 14.8825 21.9795 14.6073 21.9979C14.332 22.0163 14.0622 21.9149 13.8671 21.7198L10.2802 18.1329C9.90661 17.7594 9.90661 17.1537 10.2802 16.7802C10.6537 16.4066 11.2593 16.4066 11.6329 16.7802L14.4465 19.5938L20.7178 11.3763C21.0383 10.9563 21.6386 10.8757 22.0585 11.1962Z"
      fill={"white"}
    />
  </svg>
);
