import React from "react";
import { LayoutProps } from "../../Styled/layout";
import Text from "../../Typography/Text";
import ButtonEffect from "../../Display/ButtonEffect";
import Close from "../../../Resources/SVG/Close";
import { positionDefaultProps } from "../../Styled/position";
import { marginDefaultProps } from "../../Styled/margin";
import { FlexContainerProps } from "../../Styled/flexContainer";
import styled, { css } from "styled-components";
import theme from "../../../Theme";
import Row from "../../Layout/Row";

type StyledButtonProps = {
  hoverBackgroundColor?: keyof typeof theme.colors;
  hoverBorderColor?: keyof typeof theme.colors;
};

const StyledButton = styled(Row)<StyledButtonProps>`
  user-select: none;
  ${(props) =>
    !!props.hoverBackgroundColor &&
    css`&:hover{background-color: ${
      props.theme.colors[props.hoverBackgroundColor]
    };`}
  ${(props) =>
    !!props.hoverBorderColor &&
    css`
      &:hover {
        border-color: ${props.theme.colors[props.hoverBorderColor]};
        svg > path {
          fill: ${props.theme.colors[props.hoverBorderColor]};
        }
      }
    `}
`;
export type ToggleButtonProps = {
  active?: boolean;
  label: string;
  onChange?: (value: boolean) => void;
  onReset?: () => void;
  width?: LayoutProps["width"];
  justifyContent?: FlexContainerProps["justifyContent"];
  fontWeight?: keyof typeof theme.fontWeights;
};

const ToggleButton = ({
  active,
  onChange,
  label,
  onReset,
  justifyContent = "center",
  width = "auto",
  fontWeight = "bold",
}: ToggleButtonProps) => {
  const handleClick = () => {
    if (onChange) {
      onChange(!active);
    }

    if (active && onReset) {
      onReset();
    }
  };
  return (
    <StyledButton
      draggable={"false"}
      position={"relative"}
      alignItems={"center"}
      justifyContent={justifyContent}
      flexShrink={0}
      as="button"
      overflow="hidden"
      borderRadius="md"
      height="40px"
      $color={"black"}
      px={"16px"}
      gap={"12px"}
      borderColor={"beigeDarkest"}
      border={active ? "1pxSolid" : "none"}
      backgroundColor={active ? "beigeMidi" : "white"}
      width={width}
      onClick={handleClick}
      hoverBackgroundColor={"beigeMedium"}
      hoverBorderColor={"beigeDark"}
    >
      <Text fontWeight={fontWeight}>{label}</Text>
      {active && <Close size={"xs"} />}
    </StyledButton>
  );
};

ToggleButton.defaultProps = {
  ...positionDefaultProps,
  ...marginDefaultProps,
  width: "max-content",
};

export default ToggleButton;
